import React from "react";
import { DateSelector } from "../DateSelector/DateSelector.jsx";
import styles from "./styles.module.scss";

export const ChartBox = ({
  heading,
  subheading,
  chartId,
  minWidth,
  value,
  onChange,
  menuItems = [],
  label,
  graphId,
  barChartId
}) => {
  return (
    <div className={styles.box} style={{ minWidth }}>
      <div className={styles.subBox}>
        <div>
          <p className={styles.heading}>{heading}</p>
          <p className={styles.subHeading}>{subheading}</p>
        </div>
        <DateSelector value={value} onChange={onChange} label={label} menuItems={menuItems} sx={{ ml: 2 }} />
      </div>

      {value === "By event" ? (
        <div className={"row"}>
          {chartId ? <div className={"col-md"} id={chartId}></div> : null}
          {graphId ? <div className={"col-md"} id={graphId}></div> : null}
        </div>
      ) : value === "By Account manager" || value === "By Client account" ? (
        <div className={"row"}>
          {barChartId ? <div className={"w-full overflow-auto"} id={barChartId}></div> : null}
        </div>
      ) : null}
    </div>
  );
};
