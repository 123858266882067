import { Link } from "react-router-dom";
import "./TooltipEvent.scss";

export const TooltipEvent = ({ event }) => {
  return (
    <Link to={`/events/${event.category?.toLowerCase()}/${event.name}/${event.id}`}>
      <li className="tooltip-event">
        <button type="button">
          <img alt="event" src={event.image} />
          <strong>{event.title}</strong>
          <span>{event.location}</span>
        </button>
      </li>
    </Link>
  );
};
