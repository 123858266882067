
export const EventOrganizer = () => {
  return (
    <div className="box">
      <h2>COMMISSION AGENT’S FEE AGREEMENT</h2>
      <h4>
        as per ARTICLE 5 of the GENERAL TERMS AND CONDITIONS published on the website&nbsp;
        <a href="https://plentix.co/">https://plentix.co/</a>
      </h4>
      <p>
        This Commission Agent’s Fee Agreement (this “Agreement”) sets forth the agreement and understanding between:
      </p>
      <p>
        <strong>Evedo Bulgaria LTD</strong>, a limited liability company incorporated and exists under the law of
        Republic of Bulgaria, registered into the Commercial Register with the Registry Agency under UIN (Unique
        Identification Number): 201598848, having headquarters and registered office at: 2А Emiliyan Stanev Street,
        block 5, entrance D, 2nd Floor, Apt 3, Sofia, the Republic of Bulgaria, which is the operator of the internet
        platform on the website <a href="https://plentix.co/">https://plentix.co/</a>, hereinafter referred as to
        “Evedo” and “Commission Agent” herein
      </p>
      <p>and</p>
      <p>
        <strong>each Organizer</strong> which creates an event on the website&nbsp;
        <a href="https://plentix.co/">https://plentix.co/</a> by agreeing with this Agreement and with the General Terms
        and Conditions of that website and which participate in the Event’s platform by adding any kind of event with
        purpose to promote this event and/or to offer online tickets for sale for this event, hereinafter referred as to
        “the Organizer” herein
      </p>
      <h3>WHEREAS:</h3>
      <ol>
        <li>
          The Organizer has created a profile on the Evedo’s platform representing itself as a legal entity registered
          in Bulgaria or other state, according to the applicable law, which organizes an event (paid or free of charge)
          and which adds this event on the website <a href="https://plentix.co/">https://plentix.co/</a> in order to
          create an event, to promote this event or/and to sale online tickets for that event. This agreement is
          applicable in case of paid tickets.
        </li>
        <li>
          There are people (Attendees) who would like to buy online tickets for this event organized by the Organizers
          via Evedo’s platform.
        </li>
        <li>
          Evedo is acting as an intermediary platform ensuring contact between Organizers and Attendees. In event of
          financial transaction from the Attendee to the Organizer, the Attendee shall use the account of Evedo (in
          PayPal or other similar payment system) as “a bridge” to the bank account of the Organizer.
        </li>
        <li>
          Evedo shall be entitled to hold on its account any payment made by a Attendee to an Organizer for up to 5
          working days as from the date of the event, under the rules of the current payment system in use before
          releasing it to the ultimate receiver (the Organizer). This period is a guarantee period so that if any User,
          both Organizer and Attendee, breaches the rules set out herein in this Agreement or in the General Terms and
          Conditions, the payment shall be returned to the non-infringing party.
        </li>
        <li>
          The platform <a href="https://plentix.co/">https://plentix.co/</a> is a media open for the Organizer and the
          potential Attendee to establish and develop their relations.
        </li>
        <li>
          The relations between the Organizer and the Attendee do not concern, prejudice or bind the platform itself,
          i.e. the platform is a third non-bounded party.
        </li>
        <li>
          For the purposes of this Agreement:
          <ol type="i">
            <li>
              the website <a href="https://plentix.co/">https://plentix.co/</a> shall be referred hereinafter to as “the
              website” and/or “the platform”;
            </li>
            <li>
              “tickets price collected” shall means the amount that the Attendee have transferred to the account of
              Evedo for issuance of the online tickets, which sum Evedo received from the Attendees for the tickets sold
              on behalf and at the expense of the Organizer;
            </li>
            <li>
              the terms mentioned herein that have explicitly definitions in the General Terms and Conditions, shall
              have the meaning given in the General Terms and Conditions.
            </li>
          </ol>
        </li>
      </ol>
      <h3>THE COMMISSION AGENT AND THE ORGANIZER CONCLUDED THE PRESENT AGREEMENT</h3>
      <h4>on the following terms and conditions:</h4>
      <p>
        <strong>Art. 1.</strong>
      </p>
      <ol>
        <li>
          If the Organizer as a result of a creation of an event on the website get a sale of ticket/s bought by any
          Attendees (registered and non-registered users of the Evedo’s platform) through the intermediation ensured by
          The Commission Agent via its platform, the Organizer shall be required to pay a fee to The Commission Agent
          (Commission Agent’s fee).
        </li>
        <li>
          The Commission Agent’s fee is payable in case that:
          <ol type="a">
            <li>
              the Attendee has transferred the ticket’s price for an event organized by the current Organizer to the
              account of the Evedo;
            </li>
            <li>the event has happened;</li>
            <li>
              within 3 days after the date of the event performance, there are no claims submitted by the Attendee
              against the Organizer (that the event has been not performed/that the event has been postponed/that the
              event has been canceled).
            </li>
            <li>
              when Attendee (Buyer of tickets) buys tickets via PayPal or other payment methods and there are no
              chargebacks. In case of chargebacks, Evedo considers the ticket as not purchased.
            </li>
          </ol>
        </li>
      </ol>
      <p></p>
      <p>
        <strong>Art. 2.</strong>
      </p>
      <ol>
        <li>
          The Commission Agent’s fee shall be paid at full at the time of the transferring the ticket’s price collected,
          after the deductions agreed, from the account of Evedo to the account of the Organizer.
        </li>
        <li>
          The deductions that shall be made from the collected tickets price are: Commission Agent’s fee at the amount
          of 5% (five percent) of the ticket’s price (VAT not included).
        </li>
        <li>
          An additional 3% to 8% can be deducted from the collected ticket price by explicit prior agreement between the
          Organizer and the Commission Agent, in which case the Commission Agent provides a marketing package for
          additional advertising of the relevant event.
        </li>
        <li>
          The Commission Agent shall transfer to the account of the Organizer the difference between the received amount
          form the Attendees for the tickets price collected and the amount of the deductions (as described in para.2.,
          namely 5% ) due by the Organizer to the Commission Agent according to terms settled by the present agreement.
        </li>
        <li>
          The moment of the transfer of the collected sum representing tickets price for a current event from the
          account of Evedo to the account of the Organizer shall be made up to 5 working days after the event’s
          performance.
        </li>
        <li>
          Evedo shall issue an invoice to the Organizer for the amount deducted as Commission Agent’s fee within 5
          working days period after the Commission Agent’s fee payment.
        </li>
      </ol>
      <p></p>
      <p>
        <strong>Art. 3.</strong> The Organizer confirms that in consideration for the platform usage and ticket bought
        by the Attendee, he has agreed to be charged by the Commission Agent as agreed herein. The Organizer also agrees
        that the Commission Agent has the right to deduct its Commission Agent’s fee and Fee related to the payment
        transaction from the amount that should be transferred to the Organizer on behalf of the Attendee.
      </p>
      <p>
        <strong>Art. 4.</strong>
      </p>
      <ol>
        <li>
          The “Commission Agent’s Fee” shall be calculated in the following manner: multiply 5% (five percent) by total
          sum collected as tickets price for a current event which sum is transferred by the Attendee to the account of
          Evedo.
        </li>
        <li>When applicable, VAT shall be included.</li>
      </ol>
      <p></p>
      <p>
        <strong>Art. 5.</strong>It is the responsibility of the Organizer to communicate its refund policy to Buyers and
        to issue refunds to Buyers via the Site or otherwise. If an Attendee (Buyer of tickets) desires to request a
        refund, the latter (Buyer of tickets) must request the refund from the Organizer. All communications or disputes
        regarding refunds are between the Organizer and the Attendee (Buyer of tickets) and Evedo will not be
        responsible or liable in any way for refunds, errors in issuing refunds, or lack of refunds in connection with
        the Services. All communications and disputes regarding chargebacks and refunds are between the Organizer and
        Attendee (Buyer of tickets) and Evedo will not be responsible or liable in any way for chargebacks in connection
        with a Buyers use of the Services.
      </p>
      <p>
        <strong>Art. 6.</strong>The Commission Agent is an operator of a device for communication from distance, namely
        the web platform <a href="https://plentix.co/">https://plentix.co/</a> under the meaning of art. 54, subart. 3
        and 4 of the Customer Protection Act of Bulgaria, therefore the Commission Agent is not a party on the relation
        between the Organizer and the Attendee.
      </p>
      <p>
        <strong>Art. 7.</strong>
      </p>
      <ol>
        <li>
          The Organizer agrees that Evedo reserves the right to modify, complement or replace the provisions of this
          Agreement, without notice, that will take effect when displayed on&nbsp;
          <a href="https://plentix.co/">https://plentix.co/</a> or by any other form of notification. For example, Evedo
          displays a message on the site (pop-up) in case of modification of this Agreement so that the Organizer can
          see such changes before continuing to use the Services.
        </li>
        <li>
          The Organizer acknowledges that it is invited to review the most current version of this Agreement, available
          to all and accessible at all times on all pages of the Platform in the General Terms and Conditions page.
        </li>
      </ol>
      <p></p>
      <p>
        <strong>Art. 8.</strong>This Agreement is concluded automatically by the registration of a profile by the
        Organizer on the website and the combination of name and password represent an electronic signature, in
        accordance to the General Terms and Conditions.
      </p>
      <p>This Agreement shall be governed by the laws of Republic of Bulgaria.</p>
      <p>
        THIS COMMISSION AGENT’S FEE AGREEMENT CONSTITUTES A CONTRACT BETWEEN EVEDO AND ANY ORGANIZER AND REGULATES THE
        TERMS AND CONDITIONS FOR COMISSION AGENT’S FEE PAYMENT. BY CLICKING ON THE BUTTON&nbsp;
        <strong>“PAID TICKETS”</strong>&nbsp;AS AN ORGANIZER, THE ORGANIZER DECLARES THAT IT UNDERSTANDS THESE TERMS AND
        AGREES THEREWITH AND UNDERTAKES TO COMPLY WITH THEM.
      </p>
      <p>
        THIS AGREEMENT CONSTITUTES AN ELECTRONIC STATEMENT WITHIN THE MEANING OF THE ELECTRONIC DOCUMENT AND ELECTRONIC
        SIGNATURE ACT (EDESA) OF REPUBLIC OF BULGARIA. BY RECORDING THIS ELECTRONIC STATEMENT TO THE SERVER SYSTEM OF
        EVEDO THROUGH A COMMON STANDARD FOR CONVERSION IN A TECHNICAL WAY, MAKING POSSIBLE ITS REPRODUCTION, ELECTRONIC
        STATEMENT BECOMES AN ELECTRONIC DOCUMENT WHOSE AUTHOR AND TITLEHOLDER IS THE ORGANIZER WITHIN THE MEANING OF
        EDESA.
      </p>
      &nbsp;
    </div>
  );
};
