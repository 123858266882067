import { useEffect, useState } from "react";
import { InterestTag } from "./InterestTag";
import searchTags from "./tags.json";
import { useOuterClick } from "../../hooks";
import "./Interests.scss";
import { postUserTags } from "../../services/userService";

export const Interests = ({ user }) => {
  const innerRef = useOuterClick(() => setSearchResults([]));
  const [tags, setTags] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (user.tags) {
      setTags(user.tags);
    }
  }, [user]);

  const removeTag = async (tag) => {
    setTags(tags.filter((x) => x !== tag));
    await postUserTags(user._id, { tags: tag, status: 0 });
  };

  const addTag = async (tag) => {
    if (!tags.includes(tag)) {
      setShowAll(tags.length > 5);
      await postUserTags(user._id, { tags: tag, status: 1 });
      setTags([...tags, tag]);
    }
    setSearchResults([]);
    setSearchValue("");
  };

  const handleChange = (e) => {
    const tag = e.target.value;
    setSearchValue(tag);
    if (tag.length >= 2) {
      setSearchResults(searchTags.filter((x) => x.toLowerCase().startsWith(tag.toLowerCase())));
    } else {
      setSearchResults([]);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (searchResults.length) {
      addTag(searchResults[0].toLowerCase());
    }
  };

  return (
    <div className="tags-wrapper col-12 mt-3">
      <h1 className="box-header">Interests</h1>
      {tags.length > 0 && (
        <>
          <ul className={`tags transition ${showAll ? "show-all" : ""}`}>
            {tags.map((tag) => (
              <InterestTag key={tag} tag={tag} removeTag={removeTag} />
            ))}
          </ul>

          <button className={`all-tags ${tags.length <= 6 ? "hide-btn" : ""}`} onClick={() => setShowAll(!showAll)}>
            {showAll ? <span className="hide">hide all tags</span> : <span className="show">show all tags</span>}
          </button>
        </>
      )}

      <form onSubmit={submitForm}>
        <input
          name="tag"
          placeholder="Add your interests (e.g. live music, rock, hip hop, jazz, art, comedy, etc.)"
          type="text"
          className="tag-input form-control form-control-lg ui-autocomplete-input"
          autoComplete="off"
          onChange={handleChange}
          value={searchValue}
        ></input>
        {searchResults.length ? (
          <ul
            id="ui-id-1"
            tabIndex="0"
            className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front"
            ref={innerRef}
          >
            {searchResults.map((tag) => (
              <li key={tag} className="ui-menu-item" onClick={() => addTag(tag.toLowerCase())}>
                <div tabIndex="-1" className="ui-menu-item-wrapper">
                  {tag}
                </div>
              </li>
            ))}
          </ul>
        ) : null}
      </form>
    </div>
  );
};
