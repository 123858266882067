import { get, post, patch, deleteReq } from "../helpers/fetcher";

export const getTickets = async () => {
  const endpoint = `/tickets`;

  return get(endpoint);
};

export const postTickets = async (user, body) => {
  const endpoint = `/tickets`;
  const header = { user };

  return post(endpoint, header, body);
};

export const patchTickets = async (user, ticketsId, body) => {
  const endpoint = `/tickets/${ticketsId}`;
  const header = { user };

  return patch(endpoint, header, body);
};

export const deleteTickets = async (user, ticketsId) => {
  const endpoint = `/tickets/${ticketsId}`;
  const header = { user };

  return deleteReq(endpoint, header);
};
