import { ActivityCard } from "./ActivityCard";

export const ActivityFeed = ({ activities }) => {
  return (
    <div className="feed col-sm-9 col-12 activity-content">
      {activities.map((activity) => (
        <ActivityCard key={activity.id} activity={activity} />
      ))}
    </div>
  );
};
