import { useEffect, useState } from "react";
import { Details } from "./Details";
import { TicketsForm } from "./TicketsForm";
import { SittingChartPreview } from "./SittingChartPreview";
import { getEvent } from "../../services/eventService";

export const Orders = () => {
  const [event, setEvent] = useState({});
  const [selectedTickets, setSelectedTickets] = useState([]);

  const locationPathSplitted = window.location.pathname.split("/");
  const eventId = locationPathSplitted[2];

  useEffect(() => {
    getEvent(eventId)
      .then((result) => {
        setEvent(result);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="container" style={{ background: "#fff" }}>
      <div className="row justify-content-center">
        <Details event={event} selectedTickets={selectedTickets} email={""} isNew />
        <TicketsForm event={event} selectedTickets={selectedTickets} setSelectedTickets={setSelectedTickets} />
        <SittingChartPreview />
      </div>
    </div>
  );
};
