import "./NotFoundPage.scss";

export const NotFoundPage = () => {
  return (
    <div className="col-12 py-5 page-404" style={{ backgroundImage: "url('/img/errors/404-bg.jpeg')" }}>
      <div className="row ms-1">
        <div className="container py-5">
          <div className="row ms-0">
            <div className="col-lg-7 col-12 py-5 px-4 px-lg-0">
              <h1 className="fw-700 page-404_title">404: Oops, there’s nobody here yet!</h1>
              <p className="page-404_desc text-l mt-5 mb-4">This Page does not exist.</p>
              <p className="h6">
                <a className="page-404_link text-l" href="/">
                  Go home
                </a>{" "}
                or{" "}
                <a className="page-404_link" href="/contacts">
                  Contact us
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
