import { SimilarEventCard } from "./SimilarEventCard";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "../../../node_modules/swiper/react/swiper-react";
import "../../../node_modules/swiper/swiper-bundle.css";
import "./SimilarEvents.scss";

export const SimilarEvents = ({ similarEvents }) => {
  return (
    <div className="similar-events col-md-5 mt-4 pe-md-0">
      <section className="events-widget white-bg p-2">
        <small className="mb-3">Similar Events</small>

        <div className="events-swiper ">
          <Swiper modules={[Navigation, Pagination]} slidesPerView={2} navigation pagination={{ clickable: true }}>
            {similarEvents.map((event) => (
              <SwiperSlide key={event.id}>
                <SimilarEventCard similarEvent={event} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
};
