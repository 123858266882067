// import allActivities from "../../data/userRecentActivities.json";
import { useEffect, useState } from "react";
import { ActivityFeed } from "./ActivityFeed";
import { ActivityCalendar } from "./ActivityCalendar";
import "./RecentActivity.scss";
import { getUserRsvp } from "../../services/userService";

import Placeholder from "../../assets/placeholder.png";

const years = [];
//get all years and months

export const RecentActivity = () => {
  const [activities, setActivities] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [allActivities, setAllActivities] = useState([]);
  const answerTexts = {
    attending: "Attending",
    interested: "Interested",
    "love-it": "Love it but can't"
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    getUserRsvp(userId)
      .then((result) => {
        const data = result.map((element) => {
          const date = new Date(element.event.startAt);
          const day = date.getDate();
          const month = date.toLocaleString("default", { month: "long" });
          const details = `On ${month} ${day} set RSVP \'${answerTexts[element.response]}\' status  for`;
          const event = {
            id: element.event._id,
            image: element.event.image ? element.event.image : Placeholder,
            details: details,
            title: element.event.name,
            date: element.event.startAt,
            answer: answerTexts[element.response]
          };
          return event;
        });

        if (data.length > 0) {
          data.forEach((x) => {
            x.date = new Date(x.date);
            const year = x.date.getFullYear();
            const month = x.date.toLocaleString("default", { month: "long" });

            const index = years.map((x) => x.year).indexOf(year);
            if (index !== -1) {
              //year exists
              if (!years[index].months.includes(month)) {
                //month doesn't exist
                years[index].months.push(month);
              }
            } else {
              years.push({ year, months: [month] });
            }
            setSelectedPeriod(`${years[0].months[0]}-${years[0].year}`);
          });
        }
        setAllActivities(data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    setActivities(
      allActivities.filter((x) => {
        const activityYear = x.date.getFullYear();
        const activityMonth = x.date.toLocaleString("default", { month: "long" });
        const [selectedMonth, selectedYear] = selectedPeriod.split("-");

        return +activityYear === +selectedYear && activityMonth === selectedMonth;
      })
    );
  }, [selectedPeriod, allActivities]);

  const updateSelected = (month, year) => {
    setSelectedPeriod(`${month}-${year}`);
  };

  return (
    <section className="box recent-activities row my-3">
      <div className="col-12">
        <h1 className="box-header">Recent activities</h1>

        <div className="row">
          <ActivityFeed activities={activities} />
          <ActivityCalendar years={years} selectedPeriod={selectedPeriod} updateSelected={updateSelected} />
        </div>
      </div>
    </section>
  );
};
