import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NotFoundPage } from "../../Screens/NotFoundPage.jsx";

import { Image } from "./Image.jsx";
import { UserDetails } from "./UserDetails.jsx";
import { Interactions } from "./Interactions.jsx";
import { FavouriteCategories } from "./FavouriteCategories.jsx";
import { FavouritePromoters } from "./FavouritePromoters.jsx";
import { Interests } from "./Interests.jsx";
import { Achievements } from "./Achievements.jsx";
import { RecentActivity } from "./RecentActivity.jsx";
import { CategoryListElement } from "../Setup/components/CategoryListElement";
import { SectionCategories } from "./SectionCategories";

import { getUser } from "../../services/userService";

export const Profile = () => {
  const [user, setUser] = useState({});
  const [showNotFoundPage, setShowNotFoundPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const locationParams = useParams();

  useEffect(() => {
    let userId = locationParams.id ? locationParams.id : localStorage.getItem("userId");

    if (userId) {
      getUser(userId)
        .then((result) => {
          setLoading(false);
          setUser(result);
        })
        .catch((err) => {
          setLoading(false);
          setShowNotFoundPage(true);
          console.error(err);
        });
    }
  }, []);

  return (
    <>
      {loading ? (
        <h1 className="text-center vh-100">Loading...</h1>
      ) : showNotFoundPage ? (
        <NotFoundPage />
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-12 text-dark-blue">
              <h1>Profile</h1>
            </div>

            <div className="profile col-12 col-md-6 col-lg-4 col-xl-3">
              <Image user={user} />
              <UserDetails user={user} setUser={setUser} />
            </div>

            <div className="profile col-12 col-md-6 col-lg-8 col-xl-9">
              <Interactions />
              <section className="box row mb-3" style={{ background: "#fff" }}>
                <SectionCategories user={user} />
              </section>
              <section className="box row" style={{ background: "#fff" }}>
                <FavouritePromoters user={user} />
              </section>

              <Achievements userAchievements={user.achievements} />
              <RecentActivity />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
