import { emailValidator } from "./emailValidator";

export const changeNamesFormValidator = (data) => {
  const emailError = emailValidator(data.email);
  if (emailError) {
    return emailError;
  }

  if (Object.values(data).some((x) => x === "")) {
    return "Please fill in all fields.";
  }

  return null;
};
