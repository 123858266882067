
export const Privacy = () => {
  return (
    <div className="box">
      <h1 className="text-center h5">
        <p>PRIVACY POLICY</p>
        <p className="mt-0">of https://plentix.co/</p>
      </h1>
      <p>
        <u>Version signature: </u>
        <b>1.0</b>
      </p>
      <p>
        <u>Effective from: </u>
        <b>August 26 2021</b>
      </p>
      <p>
        <u>Last amended: </u>
        <b>August 26 2021</b>
      </p>
      <h3>1. A short introduction: What is Plentix.co and what is the purpose of this document?</h3>
      <p>
        Plentix.co, powered by EVEDO, is a B2C platform, located at https://plentix.co/, designed with the aim to create
        a unique marketplace to facilitate the purchase of tickets and event attendance and market events to new or
        existing audiences. By directly linking existing and potential Ticket Buyers (Attendees) to Event Organizers and
        Venue Owners, Plentix.co provides an entire living and breathing event ecosystem which makes personalized
        solutions for all participants in its marketplace simple and fast.
      </p>
      <p>
        The aim of this Privacy Policy is to provide all users of our website https://plentix.co/ (“The Website”) with
        the legally required information on how their personal data is being processed by Plentix.co for the purposes of
        managing the B2C platform.
      </p>
      <p>
        This Privacy Policy applies to all users, regardless of their role in the website (Ticket Buyers /Attendees/,
        Event Organizers, registered and non-registered users, etc.) as long as they constitute a “data subject” in the
        sense of the General Data Protection Regulation (GDPR).
      </p>
      <h3>2. What is “personal data”?</h3>
      <p>
        Personal data is information that directly or indirectly identifies you as an individual (a natural person);
        indirectly meaning when it is combined with other information such as your names, e-mail address, telephone
        number, website preferences, etc.
      </p>
      <h3>3. Definitions</h3>
      <p>
        Where a legal term connected to data processing is used in this Privacy Policy (such as “personal data”, “data
        controller”, “data processor”, “transfer of data”, “consent for data processing”, “special categories of data”,
        etc.), it is used in the sense and has the meaning under the General Data Protection Regulation (GDPR).
      </p>
      <p>
        Additionally, this Privacy Policy uses the following terms which shall have their specific definitions
        hereunder:
      </p>
      <ul>
        <li>
          <b>“Users” </b>shall mean all types of users (visitors) of The Website, including Ticket Buyers (Attendees),
          Event Organizers and others, as well as both registered users and non-registered users;
        </li>
        <li>
          <b>“Event Organizers” / “Promoters” </b>shall all mean the same thing, and namely – the persons that create
          and promote events on The Website;
        </li>
        <li>
          <b>“Hosts” / “Venue Owners” </b>shall mean owners of venues available for booking on The Website.
        </li>
        <li>
          <b>“Attendees” / “Ticket Buyers” </b>shall mean those specific Users (registered or non - registered) that
          purchase tickets for their personal use or on behalf of other persons for current events posted on The Website
          or have the intention of buying online tickets for current events promoted via The Website for themselves or
          for other persons;
        </li>
        <li>
          <b>“The Website” </b>shall mean https://plentix.co/;
        </li>
        <li>
          <b>“Data Controller” </b>shall mean EVEDO BULGARIA Ltd.
        </li>
      </ul>
      <h3>4. How do we process your personal data?</h3>
      <p>
        We at EVEDO BULGARIA Ltd. (hereinafter shortly referred to only as “Plentix” or “Plentix.co”) - the website
        administrator of https://plentix.co/ - will use your personal data only for the purposes described in the next
        section of this Privacy Policy.
      </p>
      <p>
        Rest assured that we do not collect or process more or other types of personal data than we absolutely need to
        fulfill the purposes set out in this Privacy Policy.
      </p>
      <p>
        We will only use personal information as set out in this policy, unless you have given us your explicit consent
        to another use of your personal information.
      </p>
      <h3>
        5. What types of personal data do we collect and process? What are the legal grounds and for processing the
        collected data?
      </h3>
      <p>
        Below are the types of personal data being processed by Plentix through The Website. They are the following:
      </p>
      <ul>
        <li>Full legal names;</li>
        <li>E-mail address;</li>
        <li>Age &amp; Date of birth;</li>
        <li>
          Device location where The Website is accessed by The User, should the latter choose to enable location
          tracking;
        </li>
        <li>
          The Users’ event and website preferences (such as which events they plan on attending, which events they
          enjoyed/disliked, etc.);
        </li>
        <li>IP address and type of device used to access The Website;</li>
        <li>
          Additional website preferences that The User chooses to change in the Settings menu and web-browsing history
          being gathered via the Plentix platform cookies and automatically processed afterwards (for more information
          please visit our <a href="/legal/cookie-policy/">Cookie Policy</a>);
        </li>
        <li>
          Profile picture – Users have the option to attach a profile picture to their profile or link it with their
          other social media accounts;
        </li>
        <li>Friends accounts on The Website;</li>
        <li>
          T-shirt size and biological gender – this only applies should The User choose to input this information in
          order to receive a T-shirt related to the respective event and should Event Organizer choose to request this
          information in the first place;
        </li>
        <li>
          Any other information which The User may choose to share with Plentix through the live chat bot on the webpage
          or by commenting on events. Typically, this information will be questions on events, sharing event experience
          and expectations or reporting of experienced bugs on The Website, but ultimately it may be whatever The User
          decides to share.
        </li>
      </ul>
      <p>
        Please note that necessary personal information needed for account creation are the first three categories of
        personal data: full legal names, age and date of birth and valid e-mail address, which you need to verify your
        account creation.
      </p>
      <p>Plentix processes the collected data for the following purposes and on the following legal grounds:</p>
      <div style={{ overflowY: "auto" }}>
        <table className="border-table my-4">
          <tbody>
            <tr>
              <th>№</th>
              <th>Type of data processing activity</th>
              <th>Purpose for data processing</th>
              <th>Legal grounds under GDPR</th>
            </tr>
            <tr>
              <td>1.</td>
              <td>
                Gathering of personal information on non-registered Users of The Website, including IP address and how
                they use the website
              </td>
              <td>
                Developing and maintaining the Plentix Platform – the main business interest and activity of the Company
              </td>
              <td>
                Legitimate interests of the Data Controller
                <br />
                <b className="text-center">Art. 6, para 1, (e) </b>
                <br />
                <b className="text-center">GDPR</b>
              </td>
            </tr>
            <tr>
              <td>2.</td>
              <td>
                Processing of data of Users, necessary for their registration and use of the website services as a
                registered user
              </td>
              <td>Account creation and use of The Website as a registered User</td>
              <td>
                Legitimate interests of the Data Controller
                <br />
                <b className="text-center">Art. 6, para 1, (e) </b>
                <br />
                <b className="text-center">GDPR</b>
              </td>
            </tr>
            <tr>
              <td>3.</td>
              <td>Processing personal data of Ticket Buyers when buying tickets through The Website</td>
              <td>Fulfilling the Attendees Agreement with the Ticket Buyers </td>
              <td>
                Consent for the specific purpose
                <br />
                <b className="text-center">Art. 6, para 1, (a) </b>
                <br />
                <b className="text-center">GDPR</b>
              </td>
            </tr>
            <tr>
              <td>4.</td>
              <td>Processing of geolocation</td>
              <td>
                Improving the services of the Plentix Platform and providing The Users with additional information on
                events located near them, which may interest them
              </td>
              <td>
                Processing is necessary for the performance of a contract to which the data subject is party
                <br />
                <b className="text-center">Art. 6, para 1, (b) </b>
                <br />
                <b className="text-center">GDPR</b>
              </td>
            </tr>
            <tr>
              <td>5.</td>
              <td>Processing of information using marketing and statistical (“non-essential”) cookies</td>
              <td>
                Improving the services of The Website and providing Users with additional personalized information on
                nearby or similar events, which may interest them
              </td>
              <td>
                Consent for the specific purpose
                <br />
                <b className="text-center">Art. 6, para 1, (a) </b>
                <br />
                <b className="text-center">GDPR</b>
              </td>
            </tr>
            <tr>
              <td>6.</td>
              <td>
                Profiling, i.e. using personal data from Ticket Buyers’ / Attendees’ preferences in order to profile
                different types of users and offer them recommended events and venues
              </td>
              <td>Providing Ticket Buyers / Attendees with a personalized experience when using the Plentix website</td>
              <td>
                Legitimate interests of the Data Controller
                <br />
                <b className="text-center">Art. 6, para 1, (e) </b>
                <br />
                <b className="text-center">GDPR</b>
              </td>
            </tr>
            <tr>
              <td>7.</td>
              <td>
                Sending e-communication to the Ticket Buyers / Attendees regarding events in which they may be
                interested or regarding upcoming events that they are attending
              </td>
              <td>
                Sending e-communication to the Ticket Buyers / Attendees regarding events in which they may be
                interested or regarding upcoming events that they are attending
              </td>
              <td>
                Legitimate interests of the Data Controller
                <br />
                <b className="text-center">Art. 6, para 1, (e) </b>
                <br />
                <b className="text-center">GDPR</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <u>Further clarifications on the purposes of the data processing:</u>
      </p>
      <ul>
        <li>
          <u>Profiling Activities:</u>
          <p className="mt-0">
            In order to provide the best possible personalized experience for each of our Users, Plentix undertakes
            automated profiling actions on its Users and carries out estimations and predictions on what each User may
            prefer, including offering our Users with additional personalized information on events and happenings which
            may interest them. If you, as a User, do not wish to be subject to such profiling based on your attended
            events, please object to the profiling in written form on the following address: gdpr@evedo.co.
          </p>
        </li>
        <li>
          <u>Direct marketing:</u>
          <p className="mt-0">
            Plentix may also send Users direct e-communication (including direct marketing messages) regarding events in
            which they may be interested or regarding upcoming events that they are attending or have bought tickets to.
            As required by law, Users are provided with an opt-out (unsubscribe) option, so that they are in control of
            the process and in the use of their personal data for this purpose.
          </p>
        </li>
        <li>
          <u>Syncing with social media:</u>
          <p className="mt-0">
            As part of the functionalities of the Webpage, Users may link their account with online accounts you have
            with third-party service providers (each such account, a “Third-Party Account”). By allowing Plentix to
            access your Third-Party Account, as is permitted under the applicable terms and conditions that govern your
            use of each Third-Party Account, The Users represents and warrants that they grant Plentix access to their
            Third-Party Account, without breach on their behalf of any of the terms and conditions that govern your use
            of the applicable Third-Party Account, and without obligating Plentix to pay any fees or making Plentix
            subject to any usage limitations imposed by the third-party service provider of the Third-Party Account.
            Further information on this is available in The Website’s Terms &amp; Conditions, available&nbsp;
            <a href="/legal/terms-of-use/">here.</a>
          </p>
        </li>
        <li>
          <u>Website Search Toolbar:</u>
          <p className="mt-0">
            The Website has a built-in Search Toolbar where all Registered Users’ names and accounts are visible. Should
            you, as a registered User, wish to not show up when a third party is searching for you with the Website
            Toolbar, you have the option to turn this function off by using “Search recognition” setting in the Privacy
            settings of your account. Please be notified that if you do not turn this toolbar off, you will remain
            visible in the Search Toolbar after your account creation.
          </p>
        </li>
        <li>
          <u>“People you may know” functionality:</u>
          <p className="mt-0">
            This functionality recommends other Registered Users as a Registered User’s next potential friends in order
            to aid each User in expanding his/her connections in the event marketplace. Should you, as a registered
            User, wish to not show up a potential friend to another user, you have the option to turn this function off
            by using “Friend recommendation” setting in the Privacy settings of your account.
          </p>
        </li>
        <li>
          <u>Personal data processing for regulatory compliance and assisting authorities:</u>
          <p className="mt-0">
            Plentix is also required and saves the right to further process and store Users’ personal data on where
            compelling public interest applies and in order to achieve regulatory compliance, such as to aid authorities
            in preventing, establishing and/or investigating crimes, in order to prevent loss, fraud or other financial
            misconduct, etc.
          </p>
        </li>
        <li>
          <u>No processing of payment or banking information:</u>
          <p className="mt-0">
            Lastly, please note that Plentix does not process personal data regarding Users’ payment and bank details
            (such as bank accounts, credit cards, etc.), since the payment process for attending and/or organizing
            events (when it is applicable), redirects Users to a separate secured website, not owned by Plentix.
          </p>
        </li>
      </ul>
      <h3>6. Use of The Website:</h3>
      <p>
        This Privacy Policy also applies to your use of our website https://plentix.co/ with the following mechanics and
        features related to the protection of personal data:
      </p>
      <p>
        <b>(1) Cookies: </b>Our Website uses cookies and other technologies to improve the user’s work and functioning
        of The Website, usability and security, as well as for the purposes of research related to advertising
        effectiveness. Please consult our <a href="/legal/cookie-policy/">Cookie Policy</a> for more details.
      </p>
      <p>
        <b>(2) Third Party Websites: </b>For the convenience of our visitors, this web-platform contains links to
        various websites that are not linked, controlled or managed by us. The policies and procedures we describe here
        do not apply to these websites. We are not responsible for the security or protection of any data collected by
        these third parties. We encourage you to contact these websites directly for information on their data
        protection policies.
      </p>
      <h3>7. With whom do we share your personal data?</h3>
      <p>
        We will disclose your personal data only for the purposes and only to those third parties listed below - unless
        we have received additional consent from you to transfer personal data to other categories of third parties
        given elsewhere, such as by declaration of consent for a specific purpose. Plentix will take appropriate
        measures to ensure that your personal data is processed, protected and transferred in accordance with applicable
        law.
      </p>
      <p>
        <b>(1)</b>
        <u>External service providers:</u>When necessary, we engage other companies and individuals to perform certain
        tasks on our behalf, such as specialized companies to maintain The Website. We will share or give access to such
        information to external service providers only to the extent necessary for the respective purposes. This
        information cannot be used by them for any other purpose, in particular neither for their own purposes nor for
        the purposes of third parties. The external service providers of Plentix are obliged by law or by contract to
        observe the confidentiality and integrity of your personal data.
      </p>
      <p>
        <b>(2)</b>
        <u>ransformation of a commercial company and purchase of shares:</u>In connection with any potential
        transformation, restructuring, merger, sale or other type of transfer of shares or assets, Plentix may transfer
        information, including personal data, on a reasonable scale to the acquirer of these shares or assets, and as
        necessary for the transfer of ownership, after the receiving party agrees to process your personal data and to
        apply the necessary measures for their protection in a manner that complies with the applicable European
        legislation.
      </p>
      <p>
        <b>(3)</b>
        <u>Public authorities:</u>We have the obligation to disclose your personal data to public authorities when
        required by law. For example, Plentix will comply with the request and orders of courts, administrative and
        other public state or municipal bodies, which may include similar bodies of state authority.
      </p>
      <p>
        <b>(4)</b>
        <u>Event Organizers and Venue Owners:</u>Event Organizers and Venue Owners have access to some of the Ticket
        Buyers’ personal data (namely – names and event, for which the tickets have been bought). This makes it possible
        for the Event Organizers to verify a Ticket Buyer’s identity when showing up for the event and accommodate him
        or her. Event Organizers may also contact Ticket Buyers to remind them about an upcoming event or for the
        purposes of direct marketing via e-mail. Ticket Buyers are given the opt-out (unsubscribe) option if they do not
        wish to receive any further direct marketing by the Event Organizer / Venue Owner in question.
      </p>
      <h3>8. Processing of personal data of children</h3>
      <p>
        Plentix does not collect or process personal data of children under the age of 16 (sixteen), except with the
        consent of a parent or guardian in accordance with applicable local law. If we learn that a child’s personal
        data has been accidentally collected without the necessary consent of a parent or guardian, we will immediately
        delete all data, connected to it.
      </p>
      <h3>9. Processing of special categories of personal data</h3>
      <p>
        Plentix does not process special categories of personal data, such as the following: personal data revealing
        racial or ethnic origin, political views, religious or philosophical beliefs or trade union membership, as well
        as the processing of genetic data, biometric data for the sole purpose of identifying an individual, data on the
        health condition or data of the sexual life or sexual orientation of the individual. If we learn that such
        categories of personal data have been accidentally collected, we will delete them immediately.
      </p>
      <h3>10. Security and integrity of your personal data:</h3>
      <p>
        Plentix takes the security of your personal data seriously. We apply the appropriate level of protection and to
        this end we have developed reasonable physical, electronic and technical procedures to protect the data we
        collect from accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access to
        transmitted, stored or otherwise processed personal data. Access to your personal data is allowed only to those
        employees who need it to perform their official duties and obligations within our structures. In the unlikely
        event of a personal data beach, Plentix will follow all applicable rules for notification in such cases in
        accordance with its Breach Policy.
      </p>
      <h3>11. What are your legal rights related to your personal data?</h3>
      <p>
        As a data subject, you have specific legal rights related to the personal data we collect from you. This applies
        to all processing activities that Plentix performs with your personal data and which are listed above. Plentix
        respects your individual rights and will always answer your questions and inquiries regarding the ways in which
        we process the personal data received from you.
      </p>
      <p>
        The list that follows contains information about your rights arising from the applicable data protection laws:
      </p>
      <ul>
        <li>
          <b className="h6">Right of correction: </b>You have the right to correct personal data concerning you. We make
          reasonable efforts to ensure that the personal data at our disposal and under our control, which is regularly
          processed, is accurate, complete, up-to-date and relevant, based on the latest information available to us.
        </li>
        <li>
          <b className="h6">Right to restriction: </b>You have the right to restrict the processing of your personal
          data if:<p>- Challenge the accuracy of your personal data for the period we need to verify their accuracy;</p>
          <p>
            - The processing is unlawful and you request a restriction on the processing instead of deleting your
            personal data;
          </p>
          <p>
            - We no longer need your personal data, but you require them for the establishment, exercise or protection
            of rights and legitimate interests; or
          </p>
          <p>
            - Objections to the processing pending verification that our legal grounds for said processing take
            precedence over your rights and legal interests.
          </p>
        </li>
        <li>
          <b className="h6">Right of access: </b>You have the right to ask us for information about the personal data
          about you that we store, including information about what categories of personal data we have or control, what
          they are used for, where we received them, if not directly from you, and to whom they have been disclosed, if
          applicable. You have the right to a free copy of the personal data we hold about you. Under current law, we
          reserve the right to charge a reasonable fee for each additional copy after the first one you may request.
        </li>
        <li>
          <b className="h6">Right of portability: </b>At your request, we will transfer your personal data to another
          controller when technically feasible, provided that the processing is based on your consent or contractual
          obligation. Instead of receiving a copy of your personal information, you may request that we transfer it
          directly to another data controller you specify.
        </li>
        <li>
          <b className="h6">Right to delete:</b>You have the right to delete your personal data if:
          <p>
            - Personal data are no longer needed for the purposes for which they were collected or otherwise processed;
          </p>
          <p>
            - You have the right to object to the further processing of your personal data (see below) and exercise this
            right to object to the processing;
          </p>
          <p>
            - The processing is based on your consent, you withdraw this consent and there is no other legal basis for
            the processing;
          </p>
          <p>- Personal data have been processed illegally,</p>
          <p>
            <u>Unless</u> processing is necessary for any of the following reasons:
          </p>
          <p className="mt-0">- To comply with a legal obligation that requires processing by us;</p>
          <p className="mt-0">- In particular, for archiving purposes in the public interest;</p>
          <p className="mt-0">- For the establishment, exercise or protection of rights and legitimate interests.</p>
        </li>
        <li>
          <b className="h6">Right to object to further processing of personal data: </b>You can object at any time to
          the processing of your personal data in connection with your specific situation, in cases where the processing
          is not based on our legal obligation to do so. This right also applies to any and all profiling activities,
          carried out by Plentix in order to offer you the best possible personalized experience on The Website. In such
          a case, we terminate all active actions for the processing of your personal data, unless we prove that there
          are compelling legal grounds that take precedence over your interests, or for the establishment, exercise or
          protection of legal claims. If you object to the processing, please specify whether you wish to delete your
          personal data or restrict the processing by us.
        </li>
        <li>
          <p>
            <b className="h6">Right to file a complaint: </b>In case of an alleged violation of the laws applicable to
            personal data protection, you can file a complaint to the Lead Supervisory Authority for personal data
            protection for Plentix which is the Bulgarian Commission for Personal Data Protection (CPDP) -&nbsp;
            <a href="https://www.cpdp.bg/en/index.php?p=home&amp;aid=0">
              https://www.cpdp.bg/en/index.php?p=home&amp;aid=0
            </a>
            .
          </p>
          <p></p>
          <p>
            <b className="h6">Bulgarian Commission for Personal Data Protection:</b>
          </p>
          <p className="mt-0">
            <u>Address:</u> 2 Prof. Tsvetan Lazarov Blvd., Sofia 1592, Republic of Bulgaria
          </p>
          <p className="mt-0">
            <b>
              <u>GPS coordinates: </u>N 42.668839&nbsp; E 23.377495
            </b>
          </p>
          <p className="mt-0">
            <u>Е-mail: </u>
            <a href="mailto:kzld@cpdp.bg">kzld@cpdp.bg</a>
          </p>
          <p className="mt-0">
            <u>Telephone number for complaints, signals and questions: </u>+359/2/91-53-519.
          </p>
          <p className="mt-0">
            <u>Additional information needed to exercise your rights:</u>
          </p>
        </li>
        <li>
          <b className="h6">Deadline: </b>We will try to fulfill your requests for exercising rights or requesting
          information from us concerning your personal data within the statutory period of 30 (thirty) days, and this
          period may be extended for specific reasons related to the specific legal right, or due to the complexity of
          your request, of which we will notify you before the expiration of this period.
        </li>
        <li>
          <b className="h6">Restriction of access: </b>In certain situations, we may not be able to give you access to
          all or part of your personal data due to legal provisions. If we deny your request for access, we will notify
          you of the reason for that denial.
        </li>
        <li>
          <b className="h6">Impossibility of identification: </b>In some cases, we may not be able to verify your
          personal data based on the identifiers provided in your request. In such cases, when we cannot identify you as
          a data subject, we do not have the objective opportunity to comply with your request to exercise your legal
          rights as set out in this section; unless you provide us with additional information that would allow us to
          identify you in a satisfactory manner.
        </li>
        <li>
          <b className="h6">Exercising your legal rights: </b>To exercise your legal rights, please contact us at the
          addresses listed at the end of this policy.
        </li>
      </ul>
      <h3>12. Retention periods:</h3>
      <p>We store all collected personal data only to the extent that we need it.</p>
      <p>
        All data, which is not related to The User’s activity in events and purchases of tickets, is promptly deleted
        upon account deletion. Plentix may retain data on the Users’ purchases and created events for a longer period of
        time (but no longer than the applicable prescription period), so that Plentix can prove fulfillment of its
        obligations towards the participants in the B2C market of providing the platform for event organization, which
        is the company’s primary business operation.
      </p>
      <p>
        Furthermore, Plentix retains the right and obligation to keep the data of a person who has breached the terms of
        use of the website and has been banned or is susceptible to being banned for such reasons (including, but not
        limited to: hate speech, verbal harassment, threats to other users, posting of illicit materials on the website,
        etc.). If the inappropriate action constitutes a crime, Plentix is obliged to keep records of this in order to
        fulfill its legal obligation to assist the public authorities and provide information related to the crime or
        illegal conduct in question (as is set out in Section 5 (F) above).
      </p>
      <h3>13. Privacy policy changes and amendments</h3>
      <p>
        We reserve the right to change our data protection practices and to update and amend this policy at any time.
        For this reason, we invite you to consult the policy regularly. This data protection policy is current as of the
        date indicated as the "Last amendment" of the document.
      </p>
      <h3>14. Information identifying the Data Controller</h3>
      <p>
        EVEDO BULGARIA Ltd., a company registered under the laws of the Republic of Bulgaria, with Unified
        Identification Code (UIC) 201598848, with its registered seat in the city of Sofia, and its address at: Republic
        of Bulgaria, Sofia 1124, 52 Khan Omurtag str., ground floor, represented by the managing director Mr. Stoyan
        Dobrinov Angelov, e-mail: gdpr@evedo.co.
      </p>
      <p>You can also contact our Data Protection Officer (DPO) Mr. Benislav Tsankov Vatev at dpo@evedo.co.</p>
      <p>Plentix.co – powered by EVEDO</p>
      <p>The Global Event Platform for social explorers</p>&nbsp;
    </div>
  );
};
