import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ChartBox } from "./ChartBox/ChartBox.jsx";
import { DateSelector } from "./DateSelector/DateSelector.jsx";
import { Header } from "./Header/Header.jsx";
import { NumberBox } from "./NumberBox/NumberBox.jsx";

import { getEvent } from "../../services/eventService.js";

import styles from "./styles.module.scss";

var Highcharts = require("highcharts");

export const Dashboard = () => {
  const [range, setRange] = useState("");
  const [totalRevenue, settotalRevenue] = useState("By event");
  const [event, setEvent] = useState({});
  const locationParams = useParams();

  const handleChange = (event) => {
    setRange(event.target.value);
  };

  const changeTotalRevenue = (val) => {
    if (val.target.value === "By event") {
      settotalRevenue(val.target.value);

      setTimeout(() => {
        LineChart();
        PieChart();
      }, 500);
    }
    if (val.target.value === "By Account manager") {
      settotalRevenue(val.target.value);

      setTimeout(() => {
        BarChart();
      }, 500);
    }
    if (val.target.value === "By Client account") {
      settotalRevenue(val.target.value);

      setTimeout(() => {
        BarChartbyClient();
      }, 500);
    }
  };

  const BarChart = () => {
    Highcharts.chart("BarChartContainer", {
      chart: {
        type: "bar"
      },
      title: {
        text: "Bar"
      },
      subtitle: {
        text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      },
      xAxis: {
        categories: ["Africa", "America", "Asia", "Europe", "Oceania"],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: "Population (millions)",
          align: "high"
        },
        labels: {
          overflow: "justify"
        }
      },
      tooltip: {
        valueSuffix: " millions"
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Year 1800",
          data: [107, 31, 635, 203, 2]
        },
        {
          name: "Year 1900",
          data: [133, 156, 947, 408, 6]
        },
        {
          name: "Year 2000",
          data: [814, 841, 3714, 727, 31]
        },
        {
          name: "Year 2016",
          data: [1216, 1001, 4436, 738, 40]
        }
      ]
    });
  };

  const BarChartbyClient = () => {
    Highcharts.chart("BarChartContainerByClient", {
      chart: {
        type: "bar"
      },
      title: {
        text: "Bar"
      },
      subtitle: {
        text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
      },
      xAxis: {
        categories: ["Africa", "America", "Asia", "Europe", "Oceania"],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: "Population (millions)",
          align: "high"
        },
        labels: {
          overflow: "justify"
        }
      },
      tooltip: {
        valueSuffix: " millions"
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Year 1800",
          data: [107, 31, 635, 203, 2]
        },
        {
          name: "Year 1900",
          data: [133, 156, 947, 408, 6]
        },
        {
          name: "Year 2000",
          data: [814, 841, 3714, 727, 31]
        },
        {
          name: "Year 2016",
          data: [1216, 1001, 4436, 738, 40]
        }
      ]
    });
  };

  const LineChart = () => {
    Highcharts.chart("container", {
      title: {
        text: "Chart"
      },

      yAxis: {
        title: {
          text: "Number of Employees"
        }
      },

      xAxis: {
        accessibility: {
          rangeDescription: "Range: 2010 to 2017"
        }
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle"
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          },
          pointStart: 2010
        }
      },

      series: [
        {
          name: "Installation",
          data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
        },
        {
          name: "Manufacturing",
          data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
        },
        {
          name: "Sales & Distribution",
          data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
        },
        {
          name: "Project Development",
          data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
        },
        {
          name: "Other",
          data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
        }
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom"
              }
            }
          }
        ]
      }
    });
  };

  const PieChart = () => {
    Highcharts.chart("graphContainer", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie"
      },
      title: {
        text: "Graph"
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
      },
      accessibility: {
        point: {
          valueSuffix: "%"
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %"
          }
        }
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: [
            {
              name: "Chrome",
              y: 61.41,
              sliced: true,
              selected: true
            },
            {
              name: "Internet Explorer",
              y: 11.84
            },
            {
              name: "Firefox",
              y: 10.85
            },
            {
              name: "Edge",
              y: 4.67
            },
            {
              name: "Safari",
              y: 4.18
            },
            {
              name: "Sogou Explorer",
              y: 1.64
            },
            {
              name: "Opera",
              y: 1.6
            },
            {
              name: "QQ",
              y: 1.2
            },
            {
              name: "Other",
              y: 2.61
            }
          ]
        }
      ]
    });
  };

  useEffect(() => {
    LineChart();
    PieChart();
    requestEvent();
  }, []);

  function requestEvent() {
    getEvent(locationParams.id)
      .then((result) => {
        if (result) {
          setEvent(result);
          let a = result.rsvp.filter((el) => el.response == "attending");
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <div>
      <Header />
      <br />
      <div className="container">
        <DateSelector
          value={range}
          onChange={handleChange}
          label={"Duration"}
          menuItems={["Today", "Last week", "Last month", "date-range picker"]}
        />
        <div className={styles.mt}>
          <NumberBox
            heading={"Total events organized"}
            subheading={"some small text"}
            number={3}
            menuItems={["Free", "Paid"]}
            onChange={() => null}
            label={"Type"}
          />
        </div>
      </div>

      <div className="container">
        <div className={"row"}>
          <div className="row">
            <div className="col-md">
              <NumberBox
                heading={"Total events organized"}
                subheading={"some small text"}
                number={3}
                menuItems={["Free", "Paid"]}
                onChange={() => null}
                label={"Type"}
              />
            </div>

            <div className="col-md">
              <NumberBox
                heading={"Total ticket sales"}
                subheading={"some small text"}
                number={3}
                menuItems={["Free", "Paid"]}
                onChange={() => null}
                label={"Type"}
              />
            </div>
            <div className="col-md">
              <NumberBox
                heading={"Average event value"}
                subheading={"some small text"}
                number={3}
                menuItems={["Free", "Paid"]}
                onChange={() => null}
                label={"Type"}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md">
              <NumberBox
                heading={"Seats proposed"}
                subheading={"some small text"}
                number={"24%"}
                menuItems={["time", "location", "category"]}
                onChange={() => null}
                label={"Type"}
              />
            </div>

            <div className="col-md">
              <NumberBox
                heading={"Seats sold"}
                subheading={"some small text"}
                number={"15%"}
                menuItems={["time", "location", "category"]}
                onChange={() => null}
                label={"Type"}
              />
            </div>

            <div className="col-md">
              <NumberBox heading={"Net Promoter Score"} subheading={"some small text"} number={"66%"} />
            </div>

            <div className="col-md">
              <NumberBox
                heading={"Attending"}
                subheading={"some small text"}
                number={event?.rsvp ? event.rsvp.filter((el) => el.response == "attending").length : 0}
                menuItems={[]}
                onChange={() => null}
                label={"Type"}
              />
            </div>

            <div className="col-md">
              <NumberBox
                heading={"Not sure"}
                subheading={"some small text"}
                number={event?.rsvp ? event.rsvp.filter((el) => el.response == "interested").length : 0}
                menuItems={[]}
                onChange={() => null}
                label={"Type"}
              />
            </div>

            <div className="col-md">
              <NumberBox
                heading={"Love it but can't"}
                subheading={"some small text"}
                number={event?.rsvp ? event.rsvp.filter((el) => el.response == "love-it").length : 0}
                menuItems={[]}
                onChange={() => null}
                label={"Type"}
              />
            </div>

            <div className="col-md">
              <NumberBox
                heading={"Not interested"}
                subheading={"some small text"}
                number={event?.rsvp ? event.rsvp.filter((el) => el.response == "not").length : 0}
                menuItems={[]}
                onChange={() => null}
                label={"Type"}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <ChartBox
              heading={"Total Revenue by event"}
              subheading={"some small text"}
              chartId={"container"}
              graphId={"graphContainer"}
              minWidth={"100%"}
              menuItems={["By event", "By Account manager", "By Client account"]}
              barChartId={totalRevenue === "By Account manager" ? "BarChartContainer" : "BarChartContainerByClient"}
              onChange={changeTotalRevenue}
              label={"Type"}
              value={totalRevenue}
            />
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};
