import { Link } from "react-router-dom";
import "./Host.scss";

export const Host = ({ host }) => {
  return (
    <div className="col-md-7 mt-md-4 white-bg event-host-details">
      <section className="promoter p-2">
        <small className="mb-3">Host</small>
        <h5 className="mb-3">
          <Link to={`/promoter/${host.id}`} className="host">
            <strong>{host.name}</strong>
          </Link>
        </h5>
        <img alt="promoter" src={host.image} width="150" className="promoter-image" />

        <p className="smaller pt-2">{host.description}</p>

        <p className="pt-2">
          <Link to={`/promoter/${host.id}`} className="btn btn-secondary btn-sm">
            Follow
          </Link>
        </p>
      </section>
    </div>
  );
};
