import { useEffect, useState } from "react";

export const Information = () => {
  const [show, setShow] = useState(false);
  const buyButton = document.querySelector(".get-ticket");
  const editTicketButton = document.querySelector(".edit-ticket-btn");
  const cancelTicketButton = document.querySelector(".cancel-ticket-btn");
  const formComponent = document.querySelector(".edit-texts");

  useEffect(() => {
    if (editTicketButton && cancelTicketButton) {
      if (show) {
        buyButton.classList.add("disable");
        formComponent.style.setProperty("display", "flex", "important");
        editTicketButton.style.display = "none";
        cancelTicketButton.style.display = "inline-block";
      } else {
        buyButton.classList.remove("disable");
        formComponent.style.setProperty("display", "none", "important");
        editTicketButton.style.display = "inline-block";
        cancelTicketButton.style.display = "none";
      }
    }
  }, [show]);

  return (
    <div className="cta-box my-4 white-bg">
      <div className="sidebar-widget p-4" id="buy-tickets-widget">
        <h1 itemProp="name" className="mt-2" id="event_name" title="Click to edit">
          title
        </h1>
        <h2
          className="subtitle smaller editable-short-description"
          itemProp="description"
          id="event_shortdescription"
          title="Click to edit"
        >
          subtitle
        </h2>

        <div className="pt-4">
          <p className="xs mt-2 price-line">
            <img className="me-1" src="/img/event/icn-ticket.svg" width="20" />
            <span className="date-highlight pe-2 me-2">Nov 16 - Feb 10</span> FREE{" "}
          </p>

          <h3>Get tickets by clicking here:</h3>
          <a className="buy btn btn-primary w-100 mb-0 get-ticket" href="/events/45/orders/new">
            Get Tickets
          </a>
          <form className="edit-texts mt-2 d-none flex-column w-100">
            <input
              autoComplete="off"
              className="rounded top float-left"
              maxLength="64"
              name="description"
              placeholder="Get tickets by clicking here:"
              type="text"
            />
            <input
              autoComplete="off"
              className="rounded bottom float-left"
              maxLength="24"
              name="button"
              placeholder="Get Tickets"
              type="text"
            />
            <button className="btn btn-primary rounded" data-label="Save" type="submit">
              Save
            </button>
          </form>

          <a className="edit text-center d-block" href="#">
            <span className="edit-ticket-btn" onClick={() => setShow(true)}>
              Edit text labels
            </span>
            <span className="cancel-ticket-btn" onClick={() => setShow(false)}>
              Cancel
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};
