import { Link } from "react-router-dom";
import "./KeywordCard.scss";

export const KeywordCard = ({ keyword }) => {
  return (
    <Link to={`/events/tags/${keyword}`} className="event-keyword-card">
      {keyword}
    </Link>
  );
};
