export const Terms = () => {
  return (
    <div className="box">
      <h1>EVEDO ALPHA VERSION</h1>
      <h2>TERMS AND CONDITIONS PLENTIX.CO</h2>
      <a href="https://plentix.co">HTTPS://PLENTIX.CO</a>
      <p>Last updated: 18 October 2021</p>
      <p>PLEASE, READ THESE TERMS AND CONDITIONS PRIOR TO SIGNING UP FOR THE USE OF THE WEBSITE AGREEMENT TO TERMS</p>
      <p>
        The website Plentix.co is operated by the&nbsp;
        <strong>
          Evedo Bulgaria LTD, a limited liability company incorporated and exists under the law of the Republic of
          Bulgaria.
        </strong>
      </p>
      <p>
        By means of mere use of the Webpage (as defined below), or any other form of acceptance, you (whether on your
        own or on behalf of any other legal entity) (“You” or “User”) express your consent to these Terms and Conditions
        (the “Terms”). The latter constitute a legally binding agreement made between you and&nbsp;
        <strong>Evedo Bulgaria LTD</strong>, a limited liability company, incorporated and registered under the laws of
        Republic of Bulgaria, with registry code 201598848, seated at: Sofia, Bulgaria, Tiaditsa region, Emilian Stanev
        Str 2A, block 5, entrance D, 2nd Floor, Apt 3, represented by Mr Stoyan Dobrinov Angelov (“Evedo”, or “We”, or
        “Us”, or “Our”) (together You and Evedo are referred to as the “Parties” and each individually as a “Party”). To
        the extent permitted by the applicable statutory legal provisions, these Terms exclusively govern the relations
        between the Parties concerning the access to and use of the webpage which is developed and designed to have
        basic functionalities consisting in registration or signing up process, discovering events, ticket sale or
        purchase as well as other features which are accessible at https://alpha.evedo.co/ (the “Webpage”). If in any
        case, you disagree with the terms and conditions set herein or our Privacy Policy (make this text as a link to
        the policy), you must immediately cease to use the Webpage.
      </p>
      <p>
        We may unilaterally amend these Terms at any time and to the extent permitted by applicable legal provisions,
        You shall be bound by any such changes. No express notification shall be required for the purposes of
        establishing the binding nature of the amended Terms. You shall bear the obligation to check the latest version
        of these Terms at least once a month. You will be subject to and will be deemed to have been made aware of and
        to have accepted, the changes in any revised Terms by your continued use of the Webpage after the date such
        revised Terms are posted.
      </p>
      <p>
        The information related to and the use of the Webpage are not provided to any users that are domiciled, seated
        or temporarily reside in jurisdiction/s where such information or use is prohibited, regulated or is against any
        statutory legal provisions (“Prohibited Jurisdictions”). You undertake to maintain the obligation to
        systematically check whether You are accessing the information provided on our Webpage or are using its
        functionalities within such a Prohibited Jurisdiction. If affirmative, You are obliged to immediately cease to
        access the information or using the Webpage. Any breach of this obligation shall lead to the personal
        responsibility and liability of the breaching Party.&nbsp;
      </p>
      <p>You shall be at least 18 years old in terms of accessing and using the Webpage.</p>
      <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        We retain any and all registered or not registered proprietary or limited rights over any intellectual property
        related to the Webpage, including all source code, databases, functionalities, software, designs, industrial
        designs, audio, video, text, visuals trademarks, service marks, and logos contained therein, any patents
        (“Intellectual Property”) and rights deriving therefrom are owned or controlled by Us or licensed to Us, and are
        protected by copyright and trademark laws and various other intellectual property rights and unfair competition
        laws in any jurisdiction or under international conventions (‘Intellectual Property Rights’).
      </p>
      <h2>GENERAL PRIVACY INFORMATION</h2>
      <p>
        All personal data transmitted by You in relation to the use of the Webpage is collected in a lawful and
        transparent manner.
      </p>
      <p>
        By consenting to these Terms, You acknowledge and agree that your personal data can be used by Us for the
        purposes of providing and enhancing the services and functionalities offered on the Webpage.
      </p>
      <p>
        The registration form which is used for the purposes of creating profile on the Webpage allows You to register
        and benefit from the services provided on the latter without communicating to Evedo personal data except for
        your e-mail. The compulsory or optional submission of data requested is listed on the form for each field
        collection. In the case where data communication is required, the lack of communication shall result in the
        absence of processing to the application for registration.
      </p>
      <p>
        Evedo does not disclose your personal data to third parties, except for data which is already presented on your
        public profile in accordance with the parameters determined by You, solely for the purposes of supplying our
        services to You, and in cases where disclosure of such data is required by applicable regulations, including at
        the request of a competent state authority. The personal data communicated by You shall be destroyed five years
        after your last connection to the Webpage or at his own request.
      </p>
      <p>
        You shall have the right to access, rectify and delete your personal data which is processed through the
        Webpage, and the right to object to the communication of this information to third parties for good cause.
      </p>
      <p>
        You may exercise these rights by e-mailing us at info@evedo.co or at the following postal address: 2А Emiliyan
        Stanev Street, block 5, entrance D, 2nd Floor, Apt 3, Sofia, Bulgaria;
      </p>
      <p>
        The Webpage uses the technology called "cookies." Cookies are useful in particular to facilitate the
        customization of your access to the Webpage and to enable a secure connection during the authentication and
        continued use process.
      </p>
      <p>
        Overall, cookies are damage free to your device. Cookies maintain the anonymity of the User and are in no way
        used to collect personal data, but only for connection and statistics. Evedo shall take the necessary measures
        to ensure that the personal data collected through the Webpage is neither lost nor diverted, nor accessed,
        modified or disclosed by unauthorized third parties. The data is stored on servers physically identified and
        geographically present in Frankfurt.
      </p>
      <h2>LIMITED USER LICENCE</h2>
      <p>
        Under the conditions of You being eligible to be a Party to and accepting these Terms, you are granted a
        limited, revocable, non-exclusive, non-transferable, licence to access the Webpage and use it for personal or
        commercial purposes only on an “AS IS” basis and in a strict compliance with these Terms. Except as expressly
        provided in these Terms, no part of the Intellectual Property protected by Intellectual Property Rights may be
        copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without
        our express prior written permission.
      </p>
      <p>
        You shall not: (1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt
        the Webpage; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work
        from the Webpage; (3) violate any applicable laws, rules, or regulations in connection with your access or use
        of the Webpage; (4) remove, alter, or obscure any proprietary notice (including any notice of copyright or
        trademark) posted by Evedo; (5) use the Webpage for any revenue generating endeavor, commercial enterprise, or
        other purpose for which it is not designed or intended to be used for; (6) make the Webpage available over a
        network or other environment permitting access or use by multiple devices or users at the same time; (7) use the
        Webpage for creating a product, service, or software that is, directly or indirectly, competitive with or in any
        way a substitute for the Webpage; (8) use the Webpage to send automated queries to any website or to send any
        unsolicited commercial e-mail; or (9) use any proprietary information or any of our interfaces or our other
        intellectual property in the design, development, manufacture, licensing, or distribution of any applications,
        accessories, or devices for use with the Webpage.
      </p>
      <h2>USER REPRESENTATIONS</h2>
      <p>
        Upon acceptance of these Terms, You give Us the following representations and warranties:(1) any information
        provided by You in the registration process is true, complete, accurate and not misleading;(2) You will maintain
        the accuracy of such information and promptly update such registration information as necessary;(3) You have the
        legal capacity and you agree to comply with these Terms;(4) You are not a resident of a Prohibited Jurisdiction
        and will not use the Webpage in such country;(5) You will not use the Webpage for any illegal or unauthorized
        purpose; and(6) your use of the Webpage will not violate any applicable laws, regulation, our or any
        third-party’s Intellectual Property Rights, civil, human, or commercial rights of any kind; and(7) You will not
        use the Webpage for the purposes of committing any criminal offence.If you provide any information that is
        untrue, inaccurate, not current, or incomplete, We have the right to suspend or terminate your account and
        refuse any and all current or future use of the Webpage (or any portion thereof). We further reserve all legal
        remedies which might be at our disposal in terms of defending our lawful interests and recover any losses caused
        to Us.&nbsp;
      </p>
      <h2>USER REGISTRATION</h2>
      <p>
        You do not have to register in order to visit the Webpage and for the purposes to buy/obtain tickets. The
        registration enables You to use all advantages that the Webpage offers such as: to inform you regarding upcoming
        events aligned with your interests and location (if such are shared with Us), or in order to use certain
        features including posting, commenting on or following other users.
      </p>
      <p>
        If You wish to register and create a profile with a screen name ("Name") and password. You may be required to
        register with the Webpage. In this regard, the confidentiality and safekeeping of your access credentials remain
        your own responsibility. We reserve the right to remove, reclaim, or change a username You select if we
        determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
      </p>
      <p>
        The combination of name and passwords represent an electronic signature within the meaning of art. 13 (1) of the
        Electronic document and electronic signature Act of Bulgaria. The User agrees that he recognizes the value of
        this electronic signature (name and password) as hand- written signature in the relations between him and Evedo.
      </p>
      <p>
        Each User who intends to have a registration may receive through the registration process, at the email address
        mentioned by him or her upon registration, an email for activation of his profile.The registration may be made
        by completing the registration form, available online on the Platform (hereafter called the "Registration
        Form"). By completing this form, the User confirms that he/she has read these Terms and agrees to be bound by
        them without any reservations. By completing the Registration Form, You commit to provide an email address of
        which You are the actual owner. In the event that the registered You provide false, inaccurate, incurrent or
        incomplete information, Evedo would be entitled to suspend or terminate the Agreement and the profile and to
        refuse in the future, the access to all or part of the services provided. You agree to create only one profile
        corresponding to your email.
      </p>
      <p>
        The profile gives You access to the services and functionality that we may establish and maintain from time to
        time and in our sole discretion.
      </p>
      <p>
        You must never use another User's profile without permission. When creating your profile, You must provide
        accurate and complete information. You are solely responsible for the activity that occurs on your profile, and
        You must keep your profile password secure. You must notify Evedo immediately of any breach of security or
        unauthorized use of your profile. Although Evedo shall not be liable for your losses caused by any unauthorized
        use of your profile, you shall be liable for the losses of Evedo or others due to such unauthorized use.
      </p>
      <h2>PROVIDED SERVICES</h2>
      <p>
        Evedo is a platform – based market place where individuals and legal entities organizing different kind of
        events (“Event Organizers”) and individuals willing to purchase tickets for the events which are created and
        promoted on the Webpage (“Attendees”).
      </p>
      <p>
        By no means is Evedo part of the legal relations between the Event Organizers and the Attendees. Moreover, Evedo
        does not conduct any due diligence regarding the registered Event Organizers and does not give any guarantees to
        the Attendees with consideration to the legitimacy and good faith of the Event Organizers. You hereby
        acknowledge that by purchasing tickets by means of the Webpage, You are fully aware that the seller of the
        tickets is an Event Organizer who is acting completely independently from Evedo and there is nor joint venture,
        nor any kind of partnership which may trigger common liability on behalf of Evedo and the Event Organizer. The
        latter shall be considered to be the sole services provider with regard to the event for which a ticket is sold
        and purchased.
      </p>
      <p>
        Тhe Event Organizer may use the Webpage for the purposes of creating events and determining the number and
        prices of the tickets which are offered for sale to the public. Accordingly, by creating an event, the Event
        Organizer bears the obligation to publish only true and accurate information thereof, as well as to maintain the
        highest possible due care in terms of conducting the event by applying the best practices thereto. We don’t
        apply any charges on the price of the ticket which is determined by the Event Organizer. Upon pressing the
        “Purchase” button the Attendee confirms the purchase of the ticket of choice and agrees to all terms and
        conditions introduced by the Event Organizer. After the payment of the price, the Attendee receives on his email
        account, indicated by him upon registration or indicated by him upon the ticket’s order without registration, a
        ticket in PDF-format. The purchase of a ticket establishes a legal relation between the Event Organizer and the
        Attendee. Under these relations the Event Organizer bears the obligations to organize and conduct the event as
        described at its announcement and the Attendee receives the right to attend the event. Evedo shall not be a
        party to these contractual relations and cannot and does not provide any guarantees to the Attendees regarding
        the events for which tickets are sold on the Webpage. There are two methods for payment available: via PayPall
        and/or via a Debit card/Credit card. The price of the ticket displayed on the order window is the final one and
        it may be calculated in BGN, EUR or GBP. Depending on the chosen method of payment, the amount of the price may
        be exchanged into other currency. In such case the currency exchanged shall be made by the paying institution
        (for example: PayPal, Bank, etc.) as per its terms and currency exchanging rate. In case of payment by credit
        card or bank account in a currency other than currency of the tickets price indicated, it is possible that the
        bank shall charge additional bank fees. The moment the Attendee orders a ticket and pays its price, he/she shall
        pay in full the amount of the ticket’s price determined by the Event Organizer. The ticket’s price paid to Evedo
        shall be held by Evedo until the event’s performance has been done and up to 5 working days afterwards. The
        Attendee shall have 3 days after the date of the event to claim that the event has been not performed /
        organized. In that case, Evedo shall control the truthfulness of the claim and at its sole discretion shall
        grant back the money to the Attendee.
      </p>
      <h2>CANCELLATION OF EVENTS AND RETURN POLICY</h2>
      <p>
        In case that a current event is cancelled by the Event Organizer, Evedo shall return to the Attendees the
        tickets price collected. The money return shall be made via the account of the Attendee used upon the payment of
        the ticket’s price. The refund shall be executed within 10 working days after the official announcement of the
        Event Organizer that the event is cancelled. Evedo does not offer an option for ticket’s return. In case that
        the Attendee has changed his mind and he does not want to attend a current event, he may feel free to give his
        ticket to anyone.
      </p>
      <p>
        Notwithstanding the above, the Event Organizer may include different terms and conditions regarding the event
        cancellation and return policy. The Attendees shall pay attention to the description of each event on the
        Webpage and beware that the event cancellation and return policy of the respective Event Organizer shall apply
        to them. In such cases, Evedo does not bear any obligation or responsibility to facilitate or perform any return
        of funds and you shall contact directly the respective Event Organizer to resolve this matter.
      </p>
      <h2>PROHIBITED ACTIVITIES</h2>
      <p>
        You may not access or use the Webpage for any purpose other than that for which We make it available for. The
        Webpage may not be used in connection with any commercial endeavours except those that are specifically endorsed
        or approved by Us.
      </p>
      <p>As a user of the Webpage, You agree not to:</p>
      <ol>
        <li>
          circumvent, disable, or otherwise interfere with security-related features of the Webpage, including features
          that prevent or restrict the use or copying of any Intellectual Property or enforce limitations on the use of
          the Webpage and/or the Intellectual Property contained therein;
        </li>
        <li>
          trick, defraud, or mislead Us and other users, especially in an attempt to learn sensitive account information
          such as user passwords;
        </li>
        <li>make improper use of our support services or submit false reports of abuse or misconduct;</li>
        <li>
          engage in any automated use of the Webpage, such as using scripts to send comments or messages, or using any
          data mining, robots, or similar data gathering and extraction tools;
        </li>
        <li>
          interfere with, disrupt, or create an undue burden on the Webpage or the networks or services connected
          thereto;
        </li>
        <li>attempt to impersonate another user or person or use the username of another user.</li>
        <li>sell or otherwise transfer your profile;</li>
        <li>
          decipher, decompile, disassemble, prepare derivative work, or reverse engineer any of the software comprising
          or in any way making up a part of the Webpage;
        </li>
        <li>
          attempt to bypass any measures of the Webpage designed to prevent or restrict access to the source code, or
          any part of the Webpage in providing any portion of it to you;
        </li>
        <li>delete the copyright or other proprietary rights notice from any content;</li>
        <li>
          copy or adapt the Webpage’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other
          code;
        </li>
        <li>use the Webpage in a manner inconsistent with any applicable laws or regulations.</li>
      </ol>
      <h2>BREACH OF THE TERMS</h2>
      <p>
        Without prejudice to our other rights under these Terms, in case of a breach on your behalf, or if We reasonably
        suspect that You have breached these Terms in any way, We may:
      </p>
      <ol>
        <li>send You one or more formal warnings;</li>
        <li>temporarily suspend your access to our services;</li>
        <li>permanently prohibit You from accessing our Webpage;</li>
        <li>block computers/phones using your IP address/other methods from accessing the Webpage;</li>
        <li>commence legal action against You, whether for breach of contract or otherwise; and/or</li>
        <li>suspend or delete your account altogether.</li>
      </ol>
      <p>
        In the event that We suspend or prohibit or block your access to our Webpage, You must not take any action to
        circumvent such suspension or prohibition or blocking including without limitation, creating and/or using a
        different account.
      </p>
      <p>
        If You breach any of the terms provided herein, You acknowledge that you shall be solely responsible for any
        damages, losses, lost profits or any other indemnities that third-parties may have against You or Us.
      </p>
      <h2>SYNCING WITH SOCIAL MEDIA</h2>
      <p>
        As part of the functionalities of the Webpage, you may link your account with online accounts you have with
        third-party service providers (each such account, a “Third-Party Account”). By allowing Us to access your
        Third-Party Account, as is permitted under the applicable terms and conditions that govern your use of each
        Third-Party Account, You represent and warrant that You grant Evedo access to your Third-Party Account, without
        breach on your behalf of any of the terms and conditions that govern your use of the applicable Third-Party
        Account, and without obligating Us to pay any fees or making Us subject to any usage limitations imposed by the
        third-party service provider of the Third-Party Account.
      </p>
      <p>
        By granting Us access to any Third-Party Account, you understand that (1) we may access, make available, and
        store (if applicable) any content that you have provided to and stored in your Third-Party Account (the “Social
        Network Content”) so that it is available on and through the Webpage via your account, including without
        limitation any friend lists and (2) we may submit to and receive from your Third-Party Account additional
        information to the extent you are notified when you link your account with the Third-Party Account. Please note
        that if a Third- Party Account or associated service becomes unavailable or our access to such Third-Party
        Account is terminated by the third-party service provider, then Social Network Content may no longer be
        processed by the Webpage. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED
        WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD- PARTY SERVICE PROVIDERS.
        We make no effort to review any Social Network Content for any purpose, including but not limited to, for
        accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content.
      </p>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>
        To the maximum extent permitted by applicable statutory legal provisions, in no event shall Evedo, nor its
        directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental,
        special, consequential or punitive damages, including without limitation, loss of profits, data, use, or other
        intangible losses, resulting from:
      </p>
      <ol>
        <li>the use of the Webpage or any of the records created in the process;</li>
        <li>any loss or non-existence of records created in the process of using the Webpage;</li>
        <li>
          bugs and malfunction caused by the malfunction of your phone or other device’s operating system, as Evedo is
          not committed to supporting the Webpage, updating it, and responding to inquiries;
        </li>
        <li>
          any unavailability, interruptions or malfunctioning in the performance of our Webpage, as in such case we may
          do all reasonable steps to reduce the unavailability of their use as short as possible, but We may not be held
          liable for any such unavailability, interruptions or malfunctioning;
        </li>
        <li>
          any unauthorized access to or use of our secure servers and/or any and all personal/financial or any other
          valuable information stored by using the Webpage.
        </li>
        <li>
          any bugs, viruses, trojan horses, or other malware that may be transmitted through the Webpage by any
          third-party;
        </li>
        <li>
          any breach of any local statutory legal provisions that may derive for the User by means of using the Webpage,
          as the User bears the responsibility to abide the laws of the jurisdiction that the usage takes place in.
          Moreover, Evedo does not grant any warranties for the availability of such compliance.
        </li>
      </ol>
      <h2>ADVERTISING</h2>
      <p>
        By accepting these Terms, You consent to the displaying of advertising content within the Webpage. We reserve
        the right to choose our advertisers and the content to be displayed without providing any warranties or
        recommendations for the services or products that are advertised by third-parties on the Webpage. Furthermore,
        we are not а party to your legal relations with any of the advertising third – parties on the Webpage. In this
        regard, we encourage You to personally research the advertised products or services and We do not bear any
        liability for damages or losses that might be caused by the use of such.
      </p>
      <h2>SUBMISSIONS</h2>
      <p>
        You acknowledge and agree that any pictures, audio, video, questions, comments, suggestions, ideas, feedback, or
        other information regarding the Webpage ("Submissions") provided by You to Us are non-confidential and shall
        become our sole property. To the maximum extent permitted by the applicable law, We shall own exclusive rights,
        including all Intellectual Property Rights, and shall be entitled to the unrestricted use and dissemination of
        these Submissions for any lawful purpose, commercial or otherwise, without acknowledgement or compensation to
        You. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such Submissions
        are original or that you have the right to submit such Submissions. You agree there shall be no recourse against
        Us for any alleged or actual infringement or misappropriation of any proprietary right in your Submissions.
      </p>
      <h2>THIRD-PARTY WEBSITES AND CONTENT</h2>
      <p>
        The Webpage may contain (or You may be sent via e-mail) links to other websites ("Third-Party Websites") as well
        as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications,
        software, and other content or items belonging to or originating from third-parties ("Third-Party Content").
        Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy,
        appropriateness, or completeness by Us, and We are not responsible for any Third-Party Websites accessed through
        the Webpage or any Third-Party Content posted on, available through, or installed from the Webpage, including
        the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or
        contained in the Third-Party Websites or the Third-Party Content. The inclusion of, linking to, or permitting
        the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or
        endorsement thereof by Us. If you decide to leave the Webpage and access the Third-Party Websites or to use or
        install any Third-Party Content, You do so at your own risk, and You should be aware that these Terms no longer
        govern. You should review the applicable terms and policies, including privacy and data gathering practices, of
        any website to which You navigate from the Webpage. Any purchases You make through Third-Party Websites will be
        through other websites and from other companies, and we take no responsibility whatsoever in relation to such
        purchases which are exclusively between you and the applicable third party. You agree and acknowledge that We do
        not endorse the products or services offered on Third-Party Websites and You shall hold Us harmless from any
        harm caused by your purchase of such products or services. Additionally, You shall hold Us harmless from any
        losses sustained by You or harm caused to You relating to or resulting in any way from any Third-Party Content
        or any contact with Third-Party Websites.
      </p>
      <h2>TERM AND TERMINATION</h2>
      <p>
        These Terms shall remain in full force and effect while You use the Webpage. WITHOUT LIMITING ANY OTHER
        PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE WEBPAGE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR
        ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
        COVENANT CONTAINED IN THESE TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
        PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN
        OUR SOLE DISCRETION. &nbsp;
      </p>
      <h2>MODIFICATIONS AND INTERRUPTIONS</h2>
      <p>
        We cannot guarantee the Webpage will be available at all times. We may experience hardware, software, or other
        problems or need to perform maintenance related to the Webpage, resulting in interruptions, delays, or errors.
        We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Webpage at any
        time or for any reason without notice to You. You agree that We have no liability whatsoever for any loss,
        damage, or inconvenience caused by your inability to access or use the Webpage during any downtime or
        discontinuance that may take place. Nothing in these Terms will be construed to obligate Us to maintain and
        support the Webpage or to supply any corrections, updates, or releases in connection therewith.
      </p>
      <h2>NO WARRANTIES AND GUARANTEES</h2>
      <p>
        To the fullest extent permitted by applicable law, Evedo hereby excludes all promises, whether express or
        implied, including any promises that the Webpage is fit for purpose, of satisfactory quality, non-infringing, is
        free of defects, is able to operate on an uninterrupted basis, that the use of the Webpage by You is in
        compliance with laws or that any information that You transmit in connection with this Webpage will be
        successfully, accurately or securely transmitted. The Webpage is provided for use on an ‘AS IS’ and ‘AS
        AVAILABLE’ basis with no implied guarantees or warranties of any kind. You acknowledge that You use the Webpage
        at your own risk.
      </p>
      <h2>GOVERNING LAW</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of the Republic of Bulgaria, without
        regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not
        be considered as a waiver of those rights. If any provision of these Terms is held to be invalid or
        unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute
        the entire agreement between us regarding the use of the Webpage and supersede and replace any prior agreements
        the Parties might have had in this regard.
      </p>
      <p>Supervisory authority:</p>
      <ol>
        <li>
          <p>Commission for Personal Data Protection</p>
          <p>Address: Sofia, Prof. Tsvetan Lazarov ”№ 2,</p>
          <p>tel.: (02) 940 20 46, fax: (02) 940 36 40</p>
          <p>
            e-mail: <a href="kzld@government.bg">kzld@government.bg</a>, <a href="kzld@cpdp.bg">kzld@cpdp.bg</a>
          </p>
          <p>
            Website: <a href="www.cpdp.bg">www.cpdp.bg</a>.
          </p>
        </li>
        <li>
          <p>Consumer Protection Commission</p>
          <p>Address: 1000 Sofia, 4A Slaveykov Square, 3rd, 4th and 6th floors,</p>
          <p>tel.: 02/980 25 24, fax: 02/988 42 18</p>
          <p>hotline: 0700 111 22</p>
          <p>
            Website: <a href="www.kzp.bg">www.kzp.bg</a>.
          </p>
        </li>
      </ol>
      <h2>DISPUTE RESOLUTION</h2>
      <p>Binding Arbitration</p>
      <p>
        To expedite resolution and control the cost of any dispute, controversy or claim related to these Terms (each a
        "Dispute" and collectively, “Disputes”), any Dispute brought by either You or Us (individually, a “Party” and
        collectively, the “Parties”) shall be finally and exclusively resolved by binding arbitration unless compulsory
        statutory provisions at the place of residence of the User prohibit such dispute resolution methods. YOU
        UNDERSTAND THAT ONLY IF EXPLICITLY PROHIBITED BY THE LAW OF THE COUNTRY WHERE YOU RESIDE YOU WILL BE ENTITLED TO
        SUE IN COURT. The arbitration shall be commenced and conducted under the Rules of the Court of Arbitration at
        the Bulgarian Chambers of Commerce and Industry (“BCCIRs”) which are available at
        https://www.bcci.bg/rulescort-en.html. All arbitration fees and allocation of arbitrator compensation shall be
        governed by the BCCIRs. The arbitration may be conducted in person, through the submission of documents, by
        phone, or online. If the Parties cannot choose an arbitrator within one week of the receipt of a notice for the
        commencement of the arbitration procedure, the chairman of the BCCI shall appoint an arbitrator. The arbitrator
        will make a decision in writing. The arbitrator must follow applicable law, and any award may be challenged if
        the arbitrator fails to do so. Except where otherwise required by the applicable BCCIRs or applicable law, the
        arbitration will take place in Sofia, Bulgaria and the procedure will be conducted in the English language.
      </p>
      <p>
        If for any reason, the arbitration is prohibited as means for dispute resolution by compulsory statutory
        provisions at the place of residence of the User, any Dispute shall be commenced or prosecuted before the
        competent court in Sofia, Bulgaria under the rules of the Bulgarian Code of Civil Procedure. In this regard, the
        Parties hereby consent to and waive all defences of lack of, personal jurisdiction, and forum non-convenience
        with respect to venue and jurisdiction in such state and federal courts.&nbsp;Application of the United Nations
        Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act
        (UCITA) are excluded from these Terms.
      </p>
      <p>
        The General Terms and Conditions are written and translated from English. In case of discrepancy, the English
        text shall prevail over any other translation.
      </p>
      <h2>INDEMNIFICATION</h2>
      <p>
        You agree to defend, indemnify, and hold Us harmless, including our subsidiaries, affiliates, and all of our
        respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or
        demand, including reasonable attorneys’ fees and expenses, made by any third-party due to or arising out of: (1)
        use of the Webpage; (2) breach of these Terms; (3) any breach of your representations and warranties set forth
        in these Terms; (4) your violation of the rights of a third-party, including but not limited to intellectual
        property rights. Notwithstanding the foregoing, We reserve the right, at your expense, to assume the exclusive
        defense and control of any matter for which You are required to indemnify Us, and You agree to cooperate, at
        your expense, with our defense of such claims. We will use reasonable efforts to notify You of any such claim,
        action, or proceeding which is subject to this indemnification upon becoming aware of it.&nbsp;
      </p>
      <h2>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>
      <p>
        Visiting our Webpage, sending Us emails, Submissions, and completing online forms constitute electronic
        communications. You consent to receive electronic communications, and You agree that all agreements, notices,
        disclosures, and other communications We provide to You electronically, via email, in the Webpage, satisfy any
        legal requirement to qualify such communication to be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
        SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
        OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE WEBPAGE OR EMAIL. You hereby waive any rights or
        requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require
        an original signature or delivery or retention of non-electronic records, or to payments or the granting of
        credits by any means other than electronic means.
      </p>
      <h2>CORRESPONDENCE DETAILS</h2>
      <p>
        In order to resolve a complaint regarding the Webpage or to receive further information regarding use of the
        Webpage, please contact us at:&nbsp;
      </p>
      <address>
        Evedo Bulgaria LTD
        <br />
        Contact person: Mr Stoyan Dobrinov Angelov
        <br />
        Legal status: a limited liability company incorporated and exists under the law of the Republic of Bulgaria,
        registered in the Commercial Register with the Registry Agency under UIN (Unique Identification Number):
        201598848
        <br />
        Address: 2А Emiliyan Stanev Street, block 5, entrance D, 2nd Floor, Apt 3, Sofia, the Republic of Bulgaria;
        <br />
        E-mail: stoyan@evedo.co
        <br />
      </address>
      &nbsp;
    </div>
  );
};
