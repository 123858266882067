import { ActivityYear } from "./ActivityYear";
import "./ActivityCalendar.scss";

export const ActivityCalendar = ({ years, selectedPeriod, updateSelected }) => {
  return (
    <aside className="activity-calendar col-sm-3 col-12">
      {years.map((yearObj) => (
        <ActivityYear
          key={yearObj.year}
          yearObj={yearObj}
          selectedPeriod={selectedPeriod}
          updateSelected={updateSelected}
        />
      ))}
    </aside>
  );
};
