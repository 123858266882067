import axios from "axios";

export const get = async (endpoint, headers = {}) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, {
      headers,
      validateStatus: () => true
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.error);
      }
    });
};

export const post = async (endpoint, headers = {}, body) => {
  let url = endpoint;
  if (!endpoint.startsWith("http")) {
    url = `${process.env.REACT_APP_SERVER_URL}${endpoint}`;
  }
  return axios
    .post(url, body, {
      headers,
      validateStatus: () => true
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.error);
      }
    });
};

export const patch = async (endpoint, headers = {}, body) => {
  return axios
    .patch(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, body, {
      headers,
      validateStatus: () => true
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.error);
      }
    });
};

export const deleteReq = async (endpoint, headers = {}) => {
  return axios
    .delete(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, {
      headers,
      validateStatus: () => true
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.error);
      }
    });
};
