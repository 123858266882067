import { compareDates, getMonth, getYear } from "../../helpers/dateHelpers";
import { UpcomingEventCard } from "./UpcomingEventCard";
import "./UpcomingEvents.scss";

export const UpcomingEvents = ({ events, setEvents, filters, date }) => {
  const filteredEvents = events.filter(
    (event) =>
      filters[event?.response] &&
      compareDates(
        new Date(getYear(date), getMonth(date) - 1, 1),
        new Date(getYear(new Date(event.endAt)), getMonth(new Date(event.endAt)) - 1, 1)
      ) &&
      new Date(event.endAt) >= new Date()
  );

  const updateResponse = (eventId, response) => {
    const updatedEvents = events.map((event) => {
      if (event.id === eventId) {
        if (response === "blue") {
          if (window.confirm("This action will remove the event from your calendar. Are you sure?")) {
            event.response = response;
          }
        } else {
          event.response = response;
        }
      }
      return event;
    });
    setEvents(updatedEvents);
  };

  return (
    <section className="upcoming-events row mt-5 box text-start">
      <h1 className="box-header col-12">Upcoming Events</h1>

      {filteredEvents.length > 0 ? (
        <div className="upcoming-events-list col-12">
          {filteredEvents.map((ev) => (
            <UpcomingEventCard key={ev.id} event={ev} updateResponse={updateResponse} />
          ))}
        </div>
      ) : (
        <div className="no-events">
          <br />
          <h4 style={{ padding: "0px 20px 20px 20px" }}>There are no upcoming events this month</h4>
        </div>
      )}
    </section>
  );
};
