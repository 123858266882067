import { useState, useEffect } from "react";
import { Tooltip } from "bootstrap";
import "./BillingInformation.scss";
import { postBilling } from "../../../services/billingService";

export const BillingInformation = () => {
  const [currentState, setCurrentState] = useState();
  const [vat, setVat] = useState(false);

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
  }, []);

  const handleChange = (e) => {
    const changedCurrentState = { ...currentState };
    setCurrentState(changedCurrentState);

    if (e.target.type === "checkbox") {
      changedCurrentState[[`${e.target.name}`]] = e.target.checked;
      setVat(!vat);
    } else {
      changedCurrentState[`${e.target.name}`] = e.target.value;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    let numberOfCategories = [];

    if (!form.checkValidity()) {
      // e.stopPropagation();
      form.classList.add("was-validated");
    } else {
      form.classList.add("was-validated");

      const billingData = {
        companyName: currentState["company_name"],
        frp: currentState["company_frp"],
        uic: currentState["company_uic"],
        varNumber: currentState["company_vat"] ? currentState["company_vat_number"] : "",
        country: currentState["company_country"],
        city: currentState["company_city"],
        zipCode: currentState["company_postal"],
        streetAddress: currentState["company_street"],
        bankName: currentState["bank_name"],
        bankAddress: currentState["bank_name"],
        iban: currentState["bank_iban"],
        bic: currentState["bank_bic"],
        email: currentState["contact_email"],
        phone: currentState["contact_phone"]
      };

      await postBilling({}, billingData)
        .then(() => {
          window.location.href = window.location.origin;
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <>
      <form className="modal-body needs-validation" onSubmit={handleSubmit} noValidate>
        <div className="row wrapper-form">
          <div className="col-6 ">
            <fieldset className="company-details">
              <legend>Company details:</legend>
              <div className="name">
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="company_name"
                  placeholder="Company name"
                  type="text"
                  defaultValue=""
                  onChange={(e) => handleChange(e)}
                  required="true"
                  className="form-control form-control-lg"
                />
              </div>
              <div className="frp">
                <a
                  className="hint"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title=""
                  data-bs-original-title="Financially responsible person"
                  aria-label="Financially responsible person"
                ></a>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="company_frp"
                  placeholder="FRP"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="uic">
                <a
                  className="hint"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title=""
                  data-bs-original-title="Unified identification code"
                  aria-label="Unified identification code"
                ></a>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="company_uic"
                  placeholder="UIC"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="vat">
                <label
                  className="d-flex align-items-center "
                  style={
                    vat
                      ? { opacity: "0.5", width: "35px", float: "left" }
                      : { opacity: "1", width: "100%", float: "none" }
                  }
                >
                  <input className="company-vat" name="company_vat" type="checkbox" onChange={(e) => handleChange(e)} />
                  <p style={vat ? { display: "none" } : { display: "block" }} className="m-0 ms-2">
                    Registered for VAT
                  </p>
                </label>
                <input
                  style={vat ? { display: "block" } : { display: "none" }}
                  autoComplete="off"
                  autoCorrect="off"
                  name="company_vat_number"
                  placeholder="VAT number"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </fieldset>

            <fieldset className="company-address">
              <legend>Company address:</legend>
              <div>
                <select
                  onChange={(e) => handleChange(e)}
                  name="company_country"
                  required
                  className="form-control form-control-lg"
                >
                  <option value="">Country</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="Albania">Albania</option>
                  <option value="Algeria">Algeria</option>
                  <option value="American Samoa">American Samoa</option>
                  <option value="Andorra">Andorra</option>
                  <option value="Angola">Angola</option>
                  <option value="Anguilla">Anguilla</option>
                  <option value="Antarctica">Antarctica</option>
                  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Armenia">Armenia</option>
                  <option value="Aruba">Aruba</option>
                  <option value="Australia">Australia</option>
                  <option value="Austria">Austria</option>
                  <option value="Azerbaijan">Azerbaijan</option>
                  <option value="Bahamas, The">Bahamas, The</option>
                  <option value="Bahrain">Bahrain</option>
                  <option value="Baker Island">Baker Island</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="Barbados">Barbados</option>
                  <option value="Belarus">Belarus</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Belize">Belize</option>
                  <option value="Benin">Benin</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Bhutan">Bhutan</option>
                  <option value="Bolivia">Bolivia</option>
                  <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                  <option value="Botswana">Botswana</option>
                  <option value="Bouvet Island">Bouvet Island</option>
                  <option value="Brazil">Brazil</option>
                  <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                  <option value="British Virgin Islands">British Virgin Islands</option>
                  <option value="Brunei">Brunei</option>
                  <option value="Bulgaria">Bulgaria</option>
                  <option value="Burkina Faso">Burkina Faso</option>
                  <option value="Burundi">Burundi</option>
                  <option value="Cambodia">Cambodia</option>
                  <option value="Cameroon">Cameroon</option>
                  <option value="Canada">Canada</option>
                  <option value="Cape Verde">Cape Verde</option>
                  <option value="Cayman Islands">Cayman Islands</option>
                  <option value="Central African Republic">Central African Republic</option>
                  <option value="Chad">Chad</option>
                  <option value="Chile">Chile</option>
                  <option value="China">China</option>
                  <option value="Christmas Island">Christmas Island</option>
                  <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Comoros">Comoros</option>
                  <option value="Congo, Democratic Republic of the">Congo, Democratic Republic of the</option>
                  <option value="Congo, Republic of the">Congo, Republic of the</option>
                  <option value="Cook Islands">Cook Islands</option>
                  <option value="Costa Rica">Costa Rica</option>
                  <option value="Cote dIvoire">Cote dIvoire</option>
                  <option value="Croatia">Croatia</option>
                  <option value="Cuba">Cuba</option>
                  <option value="Cyprus">Cyprus</option>
                  <option value="Czech Republic">Czech Republic</option>
                  <option value="Denmark">Denmark</option>
                  <option value="Djibouti">Djibouti</option>
                  <option value="Dominica">Dominica</option>
                  <option value="Dominican Republic">Dominican Republic</option>
                  <option value="East Timor">East Timor</option>
                  <option value="Ecuador">Ecuador</option>
                  <option value="Egypt">Egypt</option>
                  <option value="El Salvador">El Salvador</option>
                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                  <option value="Eritrea">Eritrea</option>
                  <option value="Estonia">Estonia</option>
                  <option value="Ethiopia">Ethiopia</option>
                  <option value="Falkland Islands (Islas Malvinas)">Falkland Islands (Islas Malvinas)</option>
                  <option value="Faroe Islands">Faroe Islands</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finland">Finland</option>
                  <option value="France">France</option>
                  <option value="French Guiana">French Guiana</option>
                  <option value="French Polynesia">French Polynesia</option>
                  <option value="French Southern and Antarctic Lands">French Southern and Antarctic Lands</option>
                  <option value="Gabon">Gabon</option>
                  <option value="Gambia, The">Gambia, The</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Gibraltar">Gibraltar</option>
                  <option value="Greece">Greece</option>
                  <option value="Greenland">Greenland</option>
                  <option value="Grenada">Grenada</option>
                  <option value="Guadeloupe">Guadeloupe</option>
                  <option value="Guam">Guam</option>
                  <option value="Guatemala">Guatemala</option>
                  <option value="Guinea">Guinea</option>
                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                  <option value="Guyana">Guyana</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Heard Island and McDonald Islands">Heard Island and McDonald Islands</option>
                  <option value="Holy See (Vatican City)">Holy See (Vatican City)</option>
                  <option value="Honduras">Honduras</option>
                  <option value="Hong Kong">Hong Kong</option>
                  <option value="Howland Island">Howland Island</option>
                  <option value="Hungary">Hungary</option>
                  <option value="Iceland">Iceland</option>
                  <option value="India">India</option>
                  <option value="Indonesia">Indonesia</option>
                  <option value="Iran">Iran</option>
                  <option value="Iraq">Iraq</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Isle of Man">Isle of Man</option>
                  <option value="Israel">Israel</option>
                  <option value="Italy">Italy</option>
                  <option value="Jamaica">Jamaica</option>
                  <option value="Jan Mayen">Jan Mayen</option>
                  <option value="Japan">Japan</option>
                  <option value="Jarvis Island">Jarvis Island</option>
                  <option value="Jersey">Jersey</option>
                  <option value="Johnston Atoll">Johnston Atoll</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kazakhstan">Kazakhstan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Kingman Reef">Kingman Reef</option>
                  <option value="Kiribati">Kiribati</option>
                  <option value="Korea, North">Korea, North</option>
                  <option value="Korea, South">Korea, South</option>
                  <option value="Kosovo">Kosovo</option>
                  <option value="Kuwait">Kuwait</option>
                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                  <option value="Laos">Laos</option>
                  <option value="Latvia">Latvia</option>
                  <option value="Lebanon">Lebanon</option>
                  <option value="Lesotho">Lesotho</option>
                  <option value="Liberia">Liberia</option>
                  <option value="Libyan Arab">Libyan Arab</option>
                  <option value="Liechtenstein">Liechtenstein</option>
                  <option value="Lithuania">Lithuania</option>
                  <option value="Luxembourg">Luxembourg</option>
                  <option value="Macau">Macau</option>
                  <option value="Madagascar">Madagascar</option>
                  <option value="Malawi">Malawi</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Maldives">Maldives</option>
                  <option value="Mali">Mali</option>
                  <option value="Malta">Malta</option>
                  <option value="Marshall Islands">Marshall Islands</option>
                  <option value="Martinique">Martinique</option>
                  <option value="Mauritania">Mauritania</option>
                  <option value="Mauritius">Mauritius</option>
                  <option value="Mayotte">Mayotte</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                  <option value="Midway Islands">Midway Islands</option>
                  <option value="Moldova">Moldova</option>
                  <option value="Monaco">Monaco</option>
                  <option value="Mongolia">Mongolia</option>
                  <option value="Montenegro">Montenegro</option>
                  <option value="Montserrat">Montserrat</option>
                  <option value="Morocco">Morocco</option>
                  <option value="Mozambique">Mozambique</option>
                  <option value="Myanmar">Myanmar</option>
                  <option value="Namibia">Namibia</option>
                  <option value="Nauru">Nauru</option>
                  <option value="Nepal">Nepal</option>
                  <option value="Netherlands">Netherlands</option>
                  <option value="Netherlands Antilles">Netherlands Antilles</option>
                  <option value="New Caledonia">New Caledonia</option>
                  <option value="New Zealand">New Zealand</option>
                  <option value="Nicaragua">Nicaragua</option>
                  <option value="Niger">Niger</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Niue">Niue</option>
                  <option value="No Mans Land">No Mans Land</option>
                  <option value="Norfolk Island">Norfolk Island</option>
                  <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                  <option value="Norway">Norway</option>
                  <option value="Oman">Oman</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Palau">Palau</option>
                  <option value="Palmyra Atoll">Palmyra Atoll</option>
                  <option value="Panama">Panama</option>
                  <option value="Papua New Guinea">Papua New Guinea</option>
                  <option value="Paraguay">Paraguay</option>
                  <option value="Peru">Peru</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Pitcairn Islands">Pitcairn Islands</option>
                  <option value="Poland">Poland</option>
                  <option value="Portugal">Portugal</option>
                  <option value="Puerto Rico">Puerto Rico</option>
                  <option value="Qatar">Qatar</option>
                  <option value="Reunion">Reunion</option>
                  <option value="Romania">Romania</option>
                  <option value="Russia">Russia</option>
                  <option value="Rwanda">Rwanda</option>
                  <option value="Saint Helena">Saint Helena</option>
                  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                  <option value="Saint Lucia">Saint Lucia</option>
                  <option value="Saint Martin">Saint Martin</option>
                  <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                  <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                  <option value="Samoa">Samoa</option>
                  <option value="San Marino">San Marino</option>
                  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                  <option value="Saudi Arabia">Saudi Arabia</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Serbia">Serbia</option>
                  <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                  <option value="Seychelles">Seychelles</option>
                  <option value="Sierra Leone">Sierra Leone</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Slovakia">Slovakia</option>
                  <option value="Slovenia">Slovenia</option>
                  <option value="Solomon Islands">Solomon Islands</option>
                  <option value="Somalia">Somalia</option>
                  <option value="South Africa">South Africa</option>
                  <option value="South Georgia and the Islands">South Georgia and the Islands</option>
                  <option value="Spain">Spain</option>
                  <option value="Sri Lanka">Sri Lanka</option>
                  <option value="Sudan">Sudan</option>
                  <option value="Suriname">Suriname</option>
                  <option value="Svalbard">Svalbard</option>
                  <option value="Swaziland">Swaziland</option>
                  <option value="Sweden">Sweden</option>
                  <option value="Switzerland">Switzerland</option>
                  <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                  <option value="Taiwan">Taiwan</option>
                  <option value="Tajikistan">Tajikistan</option>
                  <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                  <option value="Thailand">Thailand</option>
                  <option value="The Former Yugoslav Republic of Macedonia">
                    The Former Yugoslav Republic of Macedonia
                  </option>
                  <option value="Togo">Togo</option>
                  <option value="Tokelau">Tokelau</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                  <option value="Tunisia">Tunisia</option>
                  <option value="Turkey">Turkey</option>
                  <option value="Turkmenistan">Turkmenistan</option>
                  <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                  <option value="Tuvalu">Tuvalu</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Ukraine">Ukraine</option>
                  <option value="United Arab Emirates">United Arab Emirates</option>
                  <option value="United Kingdom">United Kingdom</option>
                  <option value="United States">United States</option>
                  <option value="Uruguay">Uruguay</option>
                  <option value="Uzbekistan">Uzbekistan</option>
                  <option value="Vanuatu">Vanuatu</option>
                  <option value="Venezuela">Venezuela</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Virgin Islands (US)">Virgin Islands (US)</option>
                  <option value="Wake Island">Wake Island</option>
                  <option value="Wallis and Futuna">Wallis and Futuna</option>
                  <option value="Western Sahara">Western Sahara</option>
                  <option value="Yemen">Yemen</option>
                  <option value="Zambia">Zambia</option>
                  <option value="Zimbabwe">Zimbabwe</option>
                  <option value="Eland Islands">Eland Islands</option>
                </select>
              </div>
              <div>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="company_city"
                  placeholder="City"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="company_postal"
                  placeholder="ZIP/Postal code"
                  type="number"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="company_street"
                  placeholder="Street address"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </fieldset>
          </div>
          <div className="col-6">
            <fieldset className="bank-details">
              <legend>Bank details:</legend>
              <div>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="bank_name"
                  placeholder="Bank name"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="bank_address"
                  placeholder="Bank address (optional)"
                  type="text"
                  defaultValue=""
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <a
                  className="hint"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title=""
                  data-bs-original-title="International bank account number"
                  aria-label="International bank account number"
                ></a>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="bank_iban"
                  placeholder="IBAN"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <a
                  className="hint"
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title=""
                  data-bs-original-title="Bank identifier code"
                  aria-label="Bank identifier code"
                ></a>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="bank_bic"
                  placeholder="BIC"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </fieldset>

            <fieldset className="contact">
              <legend>Contact with you:</legend>
              <div>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="contact_email"
                  placeholder="Email"
                  type="text"
                  defaultValue=""
                  required
                  className="form-control form-control-lg"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <input
                  autoComplete="off"
                  autoCorrect="off"
                  name="contact_phone"
                  placeholder="Phone (optional)"
                  type="text"
                  defaultValue=""
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </fieldset>
          </div>
        </div>
        <div className="d-flex align-items-center flex-column w-100 mt-4">
          <button className="btn btn-primary rounded w-25">Save</button>

          <div className="notice mt-2">
            <span className="star">*</span> We are not responsible for any incorrect data you've provided.{" "}
          </div>
        </div>
      </form>
    </>
  );
};
