import React from "react";

import Header from "./components/Header";
import FeaturedSlider from "./components/FeaturedSlider";
import NearbyEvents from "./components/search-events/NearbyEvents";
import OnlineEvents from "./components/online-events/OnlineEvents";
import InfoSection from "./components/InfoSection";
import CallToAction from "../Discover/components/CtaSection";
import LatestOnEvedo from "./components/latest/LatestOnEvedo";
import initLocationAutocomplete from "./initAutocomplete";

import categories from "../../data/categories.json";

import organizers from "./organizers.json";
import venues from "./venues.json";
import { getEventFeatured, getEventOnline, getEvents } from "../../services/eventService";
import { propsHome } from "../../constants/props";

export class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSearchQuery: "",
      featuredCards: [],
      isLoaded: false,
      nearbyEventCards: [],
      nearbyEventCardsToShow: 5,
      eventsWhenSelected: "Whenever",
      eventsCategorySelected: "all",
      latestSelected: "performers",
      latestOnEvedo: [],
      cardsLength: 0,
      onlineEvents: [],
      cityData: {},
      viewAllLink: "search/?&categories=" + Object.keys(categories).map((key) => key),
      moreEventsAvailable: true,
      eventsWhenKey: "whenever",
      queryCategory: "all"
    };
    this.loadMore = this.loadMore.bind(this);
    this.eventsWhenSwitch = this.eventsWhenSwitch.bind(this);
    this.switchEventCategory = this.switchEventCategory.bind(this);
    this.handleHeaderSearchParams = this.handleHeaderSearchParams.bind(this);
    this.handleLatestOnEvedo = this.handleLatestOnEvedo.bind(this);
    this.loadNearbyEvents = this.loadNearbyEvents.bind(this);
    this.getFilterEvents = this.getFilterEvents.bind(this);
  }
  componentDidMount() {
    /* Get events by query */
    this.getFilterEvents();

    getEventFeatured()
      .then((result) => {
        this.setState({
          featuredCards: result
        });
      })
      .catch((err) => console.error(err));

    /* Get online events */
    getEventOnline()
      .then((result) => {
        this.setState({
          onlineEvents: result
        });
      })
      .catch((error) => console.log(error));

    /*Get nearby events */
    // this.loadNearbyEvents(`pageId=1${this.props.props.currentCityId ? '&cityId='+this.props.props.currentCityId : ''}`) // to replace city id with city data

    if (!this.state.isLoaded) {
      this.setState({
        // featuredCards: this.state.featuredCards.length ? this.state.featuredCards : nearbyCards,
        nearbyEventCards: this.state.nearbyEventCards.length ? this.state.nearbyEventCards : [],
        isLoaded: true,
        latestOnEvedo: organizers ? organizers : [],
        isLoaded: true
      });
      /* Get latest promoters */
      // fetch( this.props.props.domain + '/promoters.json').then(res=>res.json())
      // .then((result) => (
      //     this.setState({
      //         latestOnEvedo: result
      //     },
      //     console.log(result))
      // ))
      initLocationAutocomplete(document.querySelector("#location-autocomplete"), this);
    }
  }
  loadNearbyEvents(params) {
    // fetch(`/events-loader/${params}`)
    //   .then((res) => res.json())
    //   .then((result) => {
    //     this.setState({
    //       nearbyEventCards: Object.values(result.events),
    //       cardsLength: result.eventsToLoad <= result.totalCount ? result.eventsToLoad : result.totalCount,
    //       moreEventsAvailable: this.state.nearbyEventCardsToShow < result.totalCount ? true : false
    //     });
    //   })
    //   .catch((error) => console.log(error));
  }
  handleHeaderSearchParams(e) {
    let val = e.target.value;
    // e.target
    this.setState({
      headerSearchQuery: val ? val : this.state.headerSearchQuery
    });
  }

  loadMore(e) {
    // load more nearby event cards
    e.preventDefault();
    this.setState({
      nearbyEventCardsToShow:
        this.state.nearbyEventCardsToShow + 3 < this.state.cardsLength
          ? this.state.nearbyEventCardsToShow + 3
          : this.state.cardsLength
    });
    if (this.state.nearbyEventCardsToShow == this.state.cardsLength && this.state.moreEventsAvailable) {
      // window.location = window.location.href + this.state.viewAllLink;
      window.location = window.location.href + "search?show=all";
    }
  }
  eventsWhenSwitch(e) {
    e.preventDefault();
    let el = e.target;
    let when =
      el.getAttribute("data-when") == "Today"
        ? "today"
        : el.getAttribute("data-when") == "This Weekend"
        ? "weekend"
        : el.getAttribute("data-when") == "This Week"
        ? "week"
        : el.getAttribute("data-when") == "This Month"
        ? "month"
        : el.getAttribute("data-when") == "Whenever"
        ? "whenever"
        : "";

    this.loadNearbyEvents(
      `${
        this.state.eventsCategorySelected !== "all" ? this.state.eventsCategorySelected + "/" : ""
      }?pageId=1&timelimit=${when}`
    );
    this.setState({
      eventsWhenSelected: el.getAttribute("data-when"),
      eventsWhenKey: when
    });
    this.getFilterEvents(when, this.state.queryCategory);
  }
  switchEventCategory(e) {
    let el = e.target;
    let when = this.state.eventsWhenKey ? "&timelimit=" + this.state.eventsWhenKey : "";
    this.loadNearbyEvents(`${el.getAttribute("data-cat")}/?pageId=1&cityId=${propsHome.currentCityId}${when}`);
    e.preventDefault();
    let currentCategory =
      el.getAttribute("data-cat") !== "all"
        ? Object.values(categories).filter((cat) => cat.name.toLowerCase() == el.getAttribute("data-cat"))
        : [{ id: Object.keys(categories).map((key) => key) }];
    this.setState({
      eventsCategorySelected: el.getAttribute("data-cat"),
      viewAllLink: "search/?&categories=" + currentCategory[0].id,
      queryCategory: el.getAttribute("data-cat")
    });
    this.getFilterEvents(this.state.eventsWhenKey, el.getAttribute("data-cat"));
  }

  handleLatestOnEvedo(e) {
    e.preventDefault();
    let el = e.target;
    // el.getAttribute('data-latest') == 'venues' ?
    //     fetch( propsHome.domain + '/venues.json',{ mode: 'no-cors' })
    //     .then((result) => (
    //         this.setState({
    //             latestOnEvedo: result,
    //             latestSelected: 'venues'
    //         })
    //     ))
    // :
    //     fetch( propsHome.domain + '/promoters.json',{ mode: 'no-cors' })
    //     .then((result) => (
    //         this.setState({
    //             latestOnEvedo: result,
    //             latestSelected: 'promoters'
    //         })
    //     ))
    this.setState({
      latestOnEvedo: el.getAttribute("data-latest") == "venues" ? venues : organizers,
      latestSelected: el.getAttribute("data-latest") == "venues" ? "venues" : "performers"
    });
  }

  getFilterEvents(days, category) {
    getEvents([{ days: days }, { category: category }])
      .then((result) => {
        this.setState({
          nearbyEventCards: result,
          cardsLength: result.length
        });
      })
      .catch((err) => console.error(err));
  }

  render() {
    return (
      <div className="bg-white">
        <Header
          categories={categories}
          currentCategory={this.props.currentCategory}
          mode={"light"}
          handleChange={this.handleHeaderSearchParams}
          content={propsHome.texts.header}
          page={"home"}
        />
        <div className="container">
          {this.state.featuredCards.length ? (
            <FeaturedSlider
              cardTexts={propsHome.texts.event_card}
              categories={categories}
              currentCategory={this.props.currentCategory}
              cards={this.state.featuredCards}
              content={propsHome.texts.featured_events}
            />
          ) : (
            ""
          )}
          <NearbyEvents
            cardTexts={propsHome.texts.event_card}
            moreEvents={this.state.moreEventsAvailable}
            content={propsHome.texts.events}
            categories={Object.values(categories)}
            props={this.props}
            eventsCategorySelected={this.state.eventsCategorySelected}
            eventsWhenSelected={this.state.eventsWhenSelected}
            cardsLength={this.state.cardsLength}
            cards={this.state.nearbyEventCards}
            cardsToShow={this.state.nearbyEventCardsToShow}
            loadMore={this.loadMore}
            switchEvents={this.eventsWhenSwitch}
            switchEventCategory={this.switchEventCategory}
          />
          <OnlineEvents
            viewAllLink={this.state.viewAllLink}
            cardTexts={propsHome.texts.event_card}
            cards={this.state.onlineEvents}
            content={propsHome.texts.online_events}
            categories={Object.values(categories)}
          />
          <InfoSection content={propsHome.texts.about} />
        </div>
        <CallToAction
          content={propsHome.texts.marketplace}
          trusted={propsHome.texts.trusted}
          isLogged={this.props.isLogged}
        />
        {/* <div className="bg-dark-violet container-fluid overflow-hidden mt-5">
                    <LatestOnEvedo props={this.props} selected={this.state.latestSelected} slides={this.state.latestOnEvedo} changeSlides={this.handleLatestOnEvedo} content={propsHome.latestEvedoSection}/>
                </div> */}
      </div>
    );
  }
}
