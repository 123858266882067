import { get, post, patch, deleteReq } from "../helpers/fetcher";

export const getHost = async (id) => {
  const endpoint = `/hosts/${id}`;
  const header = { "Content-Type": "application/json" };

  return get(endpoint, header);
};

export const postHost = async (user, body) => {
  const endpoint = `/hosts`;
  // const header = {user};
  const header = {};

  return post(endpoint, header, body);
};

export const postHostEvents = async (id, data) => {
  const endpoint = `/hosts/${id}/events`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, data);
};

export const postHostFollowers = async (id, data) => {
  const endpoint = `/hosts/${id}/followers`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, data);
};

export const postHostDisable = async (id, data) => {
  const endpoint = `/hosts/${id}/disable`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, data);
};

export const patchHost = async (id, body) => {
  const endpoint = `/hosts/${id}`;
  const header = {};

  return patch(endpoint, header, body);
};

export const patchHostFollowers = async (id, data) => {
  const endpoint = `/hosts/${id}/followers`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, data);
};
