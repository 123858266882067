import { useEffect, useState } from "react";

import { InteractionCard } from "./InteractionCard";
import { getUserRsvp } from "../../services/userService";

import "./Interactions.scss";

export const Interactions = () => {
  const [showAll, setShowAll] = useState(false);
  const [userEvents, setUserEvents] = useState([]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    getUserRsvp(userId)
      .then((result) => {
        setUserEvents(result.map((el) => el.event));
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <section className="events interactions-wrapper">
      <h1>Interactions</h1>

      {userEvents?.length > 3 && (
        <nav>
          <button onClick={() => setShowAll(!showAll)} type="button">
            {showAll ? "« back to the full profile" : "show all interactions"}
          </button>
        </nav>
      )}

      <ul className="events-list row">
        {showAll
          ? userEvents.map((event) => <InteractionCard key={event._id} event={event} />)
          : userEvents.slice(0, 3).map((event) => <InteractionCard key={event._id} event={event} />)}
        {}
      </ul>
    </section>
  );
};
