import { Message } from "./Message";
import "./ConversationBody.scss";

export const ConversationBody = ({ messages }) => {
  return (
    <div className="conversation-body-wrapper col-12">
      <div className="conversation-body">
        {messages?.map((message) => (
          <Message key={message._id} message={message} />
        ))}
      </div>
    </div>
  );
};
