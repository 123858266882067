import { TicketCard } from "./TicketCard";
import { TicketOptions } from "./TicketOptions";
import "./OrderCard.scss";

export const OrderCard = ({ ticket }) => {
  return (
    <div className="order-card-wrapper col-12 col-sm-6 col-md-4 col-lg-2">
      {new Array(ticket.amount > 3 ? 3 : ticket.amount).fill(0).map((_, i) => (
        <TicketCard key={i} ticket={ticket} />
      ))}

      <TicketOptions ticket={ticket} />
    </div>
  );
};
