import { useEffect, useRef, useState } from "react";
import { getAllChat } from "../../services/chatService";
import { io } from "socket.io-client";
import fakeFriends from "../../data/allFriends.json";
import { Conversation } from "./Conversation";
import { Panel } from "./Panel";

export const Messages = () => {
  const [friendId, setFriendId] = useState(null);
  const [friends, setFriends] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [sendMessage, setSendMessage] = useState(null);
  const [receiveMessage, setReceiveMessage] = useState(null);
  const socket = useRef();

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SERVER_URL);
    socket.current.emit("new-user-add", friendId);
    socket.current.on("get-users", (users) => {
      setOnlineUsers(users);
    });
  }, [friendId]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      getAllChat(userId)
        .then((result) => {
          setFriends(result);
        })
        .catch((err) => console.error(err));
    }

    socket.current.on("receive-message", (data) => {
      setReceiveMessage(data);
    });
  }, []);

  useEffect(() => {
    if (sendMessage !== null) {
      socket.current.emit("send-message", sendMessage);
    }
  }, [sendMessage]);

  return (
    <div className="container">
      <div className="col-12">
        <div>
          <h1>Messages</h1>
        </div>

        <div className="row">
          <Panel friendId={friendId} setFriendId={setFriendId} friends={friends} setFriends={setFriends} />
          <Conversation
            friend={friends.find((x) => x._id === friendId)}
            friends={friends}
            setFriends={setFriends}
            setSendMessage={setSendMessage}
            receiveMessage={receiveMessage}
          />
        </div>
      </div>
    </div>
  );
};
