import { Link } from "react-router-dom";
import "./GetTickets.scss";

export const GetTickets = ({ event }) => {
  return (
    <div className="col-lg-4 col-md-12 d-none d-sm-none d-md-none d-lg-block sidebar event-details-tickets">
      <div className="sticky-top">
        <div className="cta-box my-4">
          <div className="ticket sidebar-widget p-4">
            <h1 className="name">{event.title}</h1>
            <h2 className="subtitle smaller">{event.description}</h2>

            <div className="pt-4">
              <p className="xs mt-2 price-line">
                <img src="https://plentix.co/img/event/icn-ticket.svg" width="20" alt="tickets" />
                <span className="date-highlight pe-2 me-2">
                  {event.startAt.split(" ").slice(1, 3).join(" ")} - {event.endAt.split(" ").slice(1, 3).join(" ")}
                </span>
                {event.price}
              </p>

              <h3>{event.getTicketsText}</h3>
              <Link to={`/orders/${event.id}/new`} className="buy btn btn-primary w-100 mb-0">
                Get Tickets
              </Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <span className="type-of-event">
              <img src="https://plentix.co/img/event/public-icn.svg" width="14" alt="privacy" />
              &nbsp;
              <span className="me-2 public">Public</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
