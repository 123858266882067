import { getDayString, getMonthString, getDay } from "../../helpers/dateHelpers";
import { TooltipEvent } from "./TooltipEvent";
import "./CalendarCellTooltip.scss";

export const CalendarCellTooltip = ({ date, innerRef, events }) => {
  return (
    <div className="calendar-cell-tooltip" ref={innerRef}>
      <h2>
        {getDayString(date)}, {getMonthString(date)} {getDay(date)}
      </h2>
      <ul className={events.length ? "list" : ""}>
        {events.length ? (
          events.map((ev) => <TooltipEvent key={ev.id} event={ev} />)
        ) : (
          <li className="message">No recommendations for this day.</li>
        )}
      </ul>
    </div>
  );
};
