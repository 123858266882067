import { EventDetails } from "../Orders/Details";
import { RequiredInfoForm } from "../Orders/RequiredInfoForm";
import { Navigation } from "../Orders/Navigation";
import { TicketsReserved } from "../Orders/TicketsReserved";

export const RequiredInfo = () => {
  return (
    <div style={{ background: "#fff" }}>
      <header style={{ boxShadow: "inset 0px -1px 0px #ECEAF0" }}>
        <tNavigation />
      </header>
      <div className="container" style={{ marginTop: "67px" }}>
        <div className="row justify-content-center">
          <tEventDetails
            event={JSON.parse(localStorage.getItem("event"))}
            selectedTickets={JSON.parse(localStorage.getItem("tickets"))}
            email={""}
          />
          <TicketsReserved />
        </div>
      </div>
    </div>
  );
};
