import { get, post, patch, deleteReq } from "../helpers/fetcher";

export const getMessages = async (chatId) => {
  const endpoint = `/messages/${chatId}`;
  const header = { "Content-Type": "application/json" };

  return await get(endpoint, header);
};

export const postMessages = async (data) => {
  const endpoint = `/messages`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header,data);
};