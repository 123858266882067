import React from "react";

import ResultsList from "./ResultsList";

import "./Tabs.scss";

const Tabs = (props) => {
  return (
    <React.Fragment>
      <ul className="nav nav-tabs" role="tablist">
        <li className="nav-item">
          <a
            onClick={props.tabSelect}
            className="nav-link position-relative active text-tabs"
            id="events-tab"
            data-bs-toggle="tab"
            href="#events-results"
            role="tab"
            aria-controls="events"
            aria-selected="true"
          >
            {props.texts.events} <span className="count">({props.events ? props.eventsCount : 0})</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={props.tabSelect}
            className="nav-link position-relative text-tabs"
            id="users-tab"
            data-bs-toggle="tab"
            href="#users-results"
            role="tab"
            aria-controls="users"
            aria-selected="false"
          >
            {props.texts.promoters_and_users} <span className="count">({props.users ? props.usersCount : 0})</span>
          </a>
        </li>
        {props.activeTab == "events" ? (
          <div className="d-flex ms-auto align-items-center">
            <span className="text-s me-2">{props.texts.sort_by}: </span>
            <div className="dropdown show">
              <a
                className="dropdown-toggle d-flex align-items-center text-dark"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {props.sortedBy}
              </a>
              <div className="dropdown-menu widget-dropdown sort-dropdown" aria-labelledby="dropdownMenuLink">
                {props.sortingOptions.map((option) => (
                  <a
                    href="#"
                    key={`${option}`}
                    className={`dropdown-item d-flex justify-content-between align-items-center ${
                      props.sortedBy === option ? "current" : ""
                    }`}
                    data-sort={option}
                    onClick={props.sort}
                  >
                    {option} {props.sortedBy === option ? <i className="fad fa-check-circle text-pink ms-4"></i> : ""}{" "}
                  </a>
                ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </ul>
      <div className="tab-content mt-5">
        <div className="tab-pane fade show active" id="events-results" role="tabpanel" aria-labelledby="events-tab">
          <ul className="list-group">
            <ResultsList data={props.events} tabType={"event"} texts={props.texts} />
          </ul>
        </div>
        <div className="tab-pane fade" id="users-results" role="tabpanel" aria-labelledby="users-tab">
          <ul className="list-group">
            <ResultsList data={props.users} tabType={"promoter"} texts={props.texts} />
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Tabs;
