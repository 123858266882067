import { Link } from "react-router-dom";
import { getDay, getDayString, getMonth, getYear } from "../../helpers/dateHelpers";
import "./PastEventCard.scss";

export const PastEventCard = ({ event }) => {
  const responseText = {
    attending: "Attending",
    interested: "Interested",
    "love-it": "Love it but can't"
  };

  return (
    <div className="past-event-card row mb-5">
      <div className="left-container col-3 col-lg-2">
        <Link to={`/events/${event.id}`} className="mask">
          <img alt="event" src={"https://local.evedo.co/img/web/event-no-image-14-medium.gif"} />
        </Link>
        <time>
          <strong>{`${getDay(new Date(event.startAt))}.${getMonth(new Date(event.startAt))}.${getYear(
            new Date(event.startAt)
          )}`}</strong>
          {getDayString(new Date(event.startAt))}
        </time>
      </div>

      <div className="info col-9 col-lg-10">
        <Link to={`/events/${event.id}`} className="title">
          {event.title}
        </Link>
        {event.category}
        <span>·</span>
        {event.location}
        <span>·</span>
        <br />

        <button type="button" className={`marker marker-${event.response}`}>
          {responseText[event.response]}
        </button>
      </div>
    </div>
  );
};
