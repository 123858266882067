import "./EventCard.scss";
import ProfileImage from "../../../../src/assets/profile-image.jpg";

const Card = (props) => {
  // let dateStart = new Date(props.dateStart);
  // let dateEnd = new Date(props.dateEnd);
  // let shortMonth = dateStart.toLocaleString('en-us', { month: 'short' });
  // let dayStart = dateStart.getDate();
  // let dayEnd = dateEnd.getDate();
  let type = props.type
    ? props.categories.map((cat) => (cat.name.toLowerCase() == props.type.toLowerCase() ? cat.name : ""))
    : "event";
  // Comment this because we do not have image of different type
  // let categoryPic = props.type ? props.type : "1";
  let categoryPic = "1";
  let texts = props.cardTexts;

  return (
    <div
      className={
        props.isFeaturedComponent || props.isOnlineComponent
          ? `card border-0 bg-white h-100`
          : `card border-0 bg-transparent col-md-4 col-sm-6 mt-4`
      }
    >
      <div className={`nearby-card position-relative d-flex ${props.cardHorizontal ? "" : "flex-column"} h-100`}>
        {/* <a href={props.url} className={`card-bg ${props.cardHorizontal ? 'card-horizontal col-6' : 'w-100'} d-flex pb-3 align-items-start justify-content-end position-relative overflow-hidden`}  style={{backgroundImage: `url(${props.picture ? props.picture : '/img/web/event-no-image-'+ categoryPic +'-thumb.gif'})`}}> */}
        <a
          href={props.url}
          className={`card-bg ${
            props.cardHorizontal ? "card-horizontal col-6" : "w-100"
          } d-flex pb-3 align-items-start justify-content-end position-relative overflow-hidden`}
          style={{
            backgroundImage: `url(${
              props.picture ? props.picture : "/img/web/event-no-image-" + categoryPic + "-thumb.gif"
            })`
          }}
        >
          <p className="card-date py-3 px-3 mx-3 text-white text-uppercase d-flex flex-column align-items-center position-relative">
            {
              props.dateStart && props.dateEnd ? `${props.dateStart} - ${props.dateEnd}` : ""
              // `<span className="days">${dayStart ? dayStart : ''} - ${dayEnd ? dayEnd : ''}</span><span className="month">${shortMonth ? shortMonth : ''}</span>`
            }
          </p>
          <div className="overlay d-flex align-items-end position-absolute">
            {props.going
              ? props.going.length > 3
                ? props.going.map((user, index) =>
                    index <= 2 ? (
                      <img
                        className="rounded-circle overlay-image"
                        src={user.image ? user.image : ProfileImage}
                        key={index}
                      />
                    ) : index == 3 ? (
                      <div
                        className="rounded-circle d-flex justify-content-center align-items-center overlay-count px-1"
                        key={index}
                      >
                        <small>{props.going.length - 3}+</small>
                      </div>
                    ) : null
                  )
                : ""
              : ""}
          </div>
        </a>
        {props.cardHorizontal ? (
          <div className="card-body pt-3 ps-3 pe-3 pb-0 bg-white position-relative overflow-hidden col-6">
            {!props.isOnlineComponent ? (
              <p className="card-type text-uppercase label-s mb-0">
                {type}{" "}
                {props.where == "online" ? (
                  <span className="online-event ms-1 text-uppercase">
                    <i className="fal fa-globe"></i> {texts.online}
                  </span>
                ) : (
                  ""
                )}
              </p>
            ) : (
              ""
            )}
            <p className="card-text d-flex justify-content-between text-l my-2">
              <a className="text-dark" href={props.url}>
                <b className="line-clamp">{props.title}</b>
              </a>
              {/* { props.featured ? <i className="far fa-star text-pink ms-3"></i> : ''} */}
            </p>

            {props.isFeaturedComponent ? (
              <div className="py-3 d-flex justify-content-between align-items-center">
                <a href={props.url} className="header-button text-white nav-link-normal border-0 px-5">
                  {texts?.get_tickets}
                </a>
                {props.price == "free" ? <p className="card-type text-uppercase label-s m-1">{texts.free}</p> : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="card-body pt-3 ps-3 pe-3 pb-0 bg-white position-relative overflow-hidden">
            {!props.isOnlineComponent ? (
              <p className="card-type text-uppercase label-s mb-0">
                {type}{" "}
                {props.where == "online" ? (
                  <span className="online-event ms-1 text-uppercase">
                    <i className="fal fa-globe"></i> {texts.online}
                  </span>
                ) : (
                  ""
                )}
              </p>
            ) : (
              ""
            )}
            <p className="card-text d-flex justify-content-between text-l my-2">
              <a className="text-dark" href={props.url}>
                <b className="line-clamp">{props.title}</b>
              </a>
              {/* { props.featured ? <i className="far fa-star text-pink ms-3"></i> : ''} */}
            </p>
          </div>
        )}
        {props.isFeaturedComponent && !props.cardHorizontal ? (
          <div className="p-3 d-flex justify-content-between align-items-center">
            {/* <a href={props.url} className="header-button text-white nav-link-normal border-0 px-5">{texts.get_tickets}</a> */}
            <a href={props.url} className="header-button text-white nav-link-normal border-0 px-5">
              Get Tickets
            </a>
            {props.price == "free" ? <p className="card-type text-uppercase label-s m-1">{texts.free}</p> : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default Card;
