import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Autocomplete from "react-google-autocomplete";

import CONFIG from "../../config/config";
import { getUser, updateUser } from "../../services/userService";
import "./AdditionalDetails.scss";

const days = new Array(31).fill(0).map((_, i) => i + 1);
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const years = new Array(66).fill(0).map((_, i) => 2007 - i);

export const AdditionalDetails = ({ setOpenModal, details, setDetails }) => {
  const date = new Date(details.birthday);
  const [gender, setGender] = useState(details.gender);
  const [birthday, setBirthday] = useState(
    details.birthday
      ? {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear()
        }
      : { day: "", month: "", year: "" }
  );
  const [birthplace, setBirthplace] = useState(details.birthplace);
  const [homeplace, setHomeplace] = useState(details.homeplace);

  const updateBirthday = (e) => {
    const { name, value } = e.target;
    const updatedBirthday = { ...birthday };
    updatedBirthday[name] = value;
    setBirthday(updatedBirthday);
  };

  const submitForm = () => {
    const data = {
      gender: gender,
      birthCity: birthplace,
      homeCity: homeplace
    };
    const { day, month, year } = birthday;
    if (day && month && year) {
      const dayStr = `${day < 10 ? `0${day}` : day}`;
      const monthStr = months[month - 1];

      data.birthday = `${dayStr} ${monthStr}, ${year}`;
    }
    updateUser(details._id, data)
      .then(() => setDetails(data))
      .catch((err) => console.error(err));

    setOpenModal(false);
  };

  useEffect(() => {
    // document.body.style.overflow = 'hidden';
    // return () => (document.body.style.overflow = 'unset');
  }, []);

  return ReactDOM.createPortal(
    <div className={`additional-details-modal-wrapper modal`}>
      <div className="additional-details-modal modal-sm modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h3>Edit Your Profile</h3>
            <button type="button" className="close" aria-label="Close" onClick={() => setOpenModal(false)}>
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <form className="edit-profile-modal">
              <fieldset>
                <label>Gender:</label>
                <select
                  className="gender-select"
                  name="gender"
                  id="profile-gender-input"
                  defaultValue={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="">Not specified</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </fieldset>

              <fieldset>
                <label>Born on:</label>
                <br />
                <select
                  className="day-select"
                  name="day"
                  style={{ width: "58px" }}
                  defaultValue={birthday.day}
                  onChange={updateBirthday}
                >
                  <option value="" />
                  {days.map((x) => (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  ))}
                </select>

                <select
                  className="month-select"
                  name="month"
                  style={{ width: "100px" }}
                  defaultValue={birthday.month}
                  onChange={updateBirthday}
                >
                  <option value="" />
                  {months.map((x, i) => (
                    <option value={x !== "" ? i + 1 : ""} key={x}>
                      {x}
                    </option>
                  ))}
                </select>

                <select
                  className="year-select"
                  name="year"
                  style={{ width: "72px" }}
                  defaultValue={birthday.year}
                  onChange={updateBirthday}
                >
                  <option value="" />
                  {years.map((x) => (
                    <option value={x} key={x}>
                      {x}
                    </option>
                  ))}
                </select>
              </fieldset>

              <fieldset>
                <label>Home city:</label>
                <Autocomplete
                  className="birthplace-input"
                  apiKey={CONFIG.googlePlacesAPI}
                  onPlaceSelected={(place) => setBirthplace(place.formatted_address)}
                  placeholder="Search cities"
                  autoComplete="off"
                  type="text"
                  defaultValue={birthplace}
                  language="en"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setBirthplace("");
                    }
                  }}
                />
              </fieldset>

              <fieldset>
                <label>Currently in:</label>
                <Autocomplete
                  className="homeplace-input"
                  apiKey={CONFIG.googlePlacesAPI}
                  onPlaceSelected={(place) => setHomeplace(place.formatted_address)}
                  placeholder="Search cities"
                  autoComplete="off"
                  type="text"
                  defaultValue={homeplace}
                  language="en"
                  onChange={(e) => {
                    if (!e.target.value) {
                      setHomeplace("");
                    }
                  }}
                />
              </fieldset>

              <br className="clear" />
            </form>
          </div>

          <div className="modal-footer actions">
            <button onClick={submitForm} className="btn btn-primary" type="button">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>,
    // document.getElementById('root')
    document.getElementById("portal")
  );
};
