import { useEffect } from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";

export const Modal = ({ setShowModal, children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  return ReactDOM.createPortal(
    <div className="modal-wrapper">
      <div className="modal-dim" onClick={() => setShowModal(false)} />
      {children}
    </div>,
    document.getElementById("portal")
  );
};
