import React from "react";
import OnlineSlider from "./OnlineSlider";

const OnlineEvents = (props) => {
  return (
    <div className="row mt-5 mb-5">
      <div className="col-12">
        <h2 className="title-bilo-m text-dark">{props.content.title}</h2>
        <div className="text-m cta-text my-4">{props.content.description}</div>
      </div>
      <OnlineSlider cards={props.cards} categories={props.categories} />
      <div className="row">
        <a href={`${props.viewAllLink}&online-event=true`} className="link-pink fw-600">
          {props.content.view_all}
        </a>
      </div>
    </div>
  );
};
export default OnlineEvents;
