import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckBox } from "../Form/CheckBox.jsx";
import { Input } from "../Form/Input.jsx";
import { Select } from "../Form/Select.jsx";
import { TextArea } from "../Form/TextArea.jsx";
import {tags as allTags} from '../../../constants/tags'

export const Description = ({ categories, selected = "", formData, handleInputChange }) => {
  const [newtag, setNewtag] = useState("");
  const [timeOut, setTime] = useState(0);
  const [tags, setTags] = useState(allTags);

  useEffect(() => {
    if (newtag.length === 0) return;
    if (timeOut) {
      clearTimeout(timeOut);
    }

    setTime(
      setTimeout(() => {
        setTags([
          {
            title: newtag
          },
          ...tags
        ]);
      }, 500)
    );
  }, [newtag]);

  const copyCategory = categories.map((el) => {
    return { _id: el, name: el };
  });

  return (
    <div className="row">
      <div className="col-12 my-5">
        <h2>What?</h2>
        <div className="row">
          <div className="col-12 my-1">
            <Input
              placeholder="Title"
              name="title"
              value={formData?.title}
              onChange={handleInputChange}
              maxLength={260}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 my-1">
            <Input
              placeholder="Subtitle (optional)"
              name="subtitle"
              value={formData?.subtitle}
              onChange={handleInputChange}
              maxLength={180}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 my-1">
            <Select
              placeholder="Choose Category"
              options={copyCategory}
              selected={formData?.category}
              name="category"
              onSelect={handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 my-1">
            <Input
              placeholder="Topic"
              name="topic"
              value={formData?.topic}
              onChange={handleInputChange}
              maxLength={80}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 my-1">
            <Autocomplete
              value={formData?.tags}
              onChange={(event, newValue) => {
                if (newValue.length <= 5) {
                  if (typeof newValue === "string") {
                    handleInputChange({ target: { name: "tags", value: newValue } });
                  } else if (newValue && newValue.inputValue) {
                    handleInputChange({ target: { name: "tags", value: newValue } });
                  } else {
                    handleInputChange({ target: { name: "tags", value: newValue } });
                  }
                }
              }}
              multiple
              id="tags-outlined"
              options={tags?.sort((a, b) => {
                return a.title.localeCompare(b.title);
              })}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tags"
                  placeholder="Tags"
                  variant="outlined"
                  onChange={(e) => setNewtag(e.target.value)}
                  className="tags-text-field"
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 my-1">
            <TextArea
              placeholder="Description"
              name="description"
              value={formData?.description}
              onChange={handleInputChange}
              required={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 my-1">
            <CheckBox labelText="Private event" name="private" value={formData?.private} onChange={handleInputChange} />
            <CheckBox labelText="Online event" name="online" value={formData?.online} onChange={handleInputChange} />
          </div>
        </div>
      </div>
    </div>
  );
};
