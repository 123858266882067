import React from "react";
import { Input } from "../Form/Input.jsx";

export const LocationInfo = ({ handleInputChange, formData }) => {
  return (
    <div className={"bg-white p-4"}>
      <p className={"text-xl font-bold"}>Location</p>
      <Input placeholder={"Place, Venue, etc."} />
    </div>
  );
};
