import privacySettings from "../../data/privacySettings.json";
import userSelectedPrivacySettings from "../../data/userSelectedPrivacySettings.json";
import { useState } from "react";
import { PrivacySettingSwitch } from "./PrivacySettingSwitch";
import "./PrivacySettingsList.scss";

export const PrivacySettingsList = () => {
  const [selectedPrivacy, setSelectedPrivacy] = useState(userSelectedPrivacySettings);

  return (
    <div className="privacy-settings-list box py-3">
      <div className="container-fluid">
        {privacySettings.map((x) => (
          <PrivacySettingSwitch
            key={x.id}
            setting={x}
            state={selectedPrivacy[x.id]}
            setSettings={setSelectedPrivacy}
            settings={selectedPrivacy}
          />
        ))}
      </div>
    </div>
  );
};
