import { useEffect, useState } from "react";

import imagePlaceholder from "../../assets/profile-image.jpg";
import { postUserImages } from "../../services/userService";

import "./Image.scss";

export const Image = ({ user }) => {
  const [image, setImage] = useState(user?.image);
  const [names, setNames] = useState(`${user?.firstName} ${user?.lastName ? user?.lastName : ""}`);
  const [editNames, setEditNames] = useState(false);
  const supportedTypes = ["image/png", "image/jpeg"];

  const pickedImage = (e) => {
    console.log("pickedImage");
    const file = e.target.files[0];
    if (supportedTypes.find((type) => type === file.type)) {
      const image = {
        path: URL?.createObjectURL(file),
        file: file
      };

      const formData = new FormData();
      formData.append("image", file);
      setImage(image.path);
      if (user._id) {
        postUserImages(user._id, formData).catch((err) => console.error(err));
      }
    }
  };

  useEffect(() => {
    setImage(user?.image);
    setNames(`${user?.firstName} ${user?.lastName ? user?.lastName : ""}`);
  }, [user]);

  const removeImage = () => {
    setImage("");
  };

  return (
    <div className="profile-image-wrapper">
      <form className="picture-form">
        <img className="w-100 object-cover" src={image || imagePlaceholder} alt="you" />

        <div className="browse">
          <strong>browse images</strong>click here to upload a new image
          <input onChange={pickedImage} type="file" accept="image/png, image/jpeg"></input>
          <br />
          {image && <button type="button" onClick={removeImage} className="remove" />}
        </div>
      </form>

      <div className="name" onClick={() => setEditNames(true)}>
        {!editNames ? (
          names
        ) : (
          <input
            autoComplete="off"
            value={names}
            onChange={(e) => setNames(e.target.value)}
            onBlur={() => setEditNames(false)}
          ></input>
        )}
      </div>
    </div>
  );
};
