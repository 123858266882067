import React from "react";

export const Radio = ({ onChange, selected, label, value, name, required }) => {
  return (
    <div className="form-check mt-3">
      <input
        className="form-check-input"
        type="radio"
        name={name}
        id={`radio-${label}`}
        checked={selected}
        onChange={() => onChange({ target: { name, value } })}
        required={true}
      />
      <label className="form-check-label" htmlFor={`radio-${label}`}>
        {label}
      </label>
    </div>
  );
};
