import React, { useState } from "react";
import { Button } from "../Form/Button.jsx";
import { Select } from "../Form/Select.jsx";

export const Promoter = ({ promoters, selectedPromoter, selectPromoter }) => {
  function selectPromoterFnc(e) {
    selectPromoter((prev) => ({ ...prev, selectedPromoter: e.target.value }));
  }

  return (
    <div className="row  alert alert-warning">
      <div className="col-12 col-md-6">
        <label className="col-form-label col-form-label-sm">
          <strong>Link this event to promoter:</strong>
          <br />
          <small>If no promoter is chosen, the event will be linked to your profile.</small>
        </label>
      </div>
      <div className="col-12 col-md-6">
        <div className="input-group">
          <Select
            options={promoters}
            placeholder="Choose promoter"
            selected={selectedPromoter}
            onSelect={selectPromoterFnc}
            className="custom-select"
          />
          <div className="input-group-append">
            <Button onClick={() => null} text={"New"} className={"form-control"} style="primary" />
          </div>
        </div>
      </div>
    </div>
  );
};
