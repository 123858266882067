import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "./PromoterSlider.scss";
import "./slick-theme.css";

class PromoterSlider extends Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    let currentCategory = this.props.currentCategory;
    const settings = {
      dots: false,
      arrows: false,
      speed: 1000,
      slidesToShow: 5,
      autoplay: false,
      pauseOnFocus: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className="row">
        <div className="col-11 ms-auto pb-4 px-0">
          <div className="row">
            <div className="col-12 ps-0">
              <div className="container ms-0 ps-0 d-flex align-items-center" id="promoters-title-wrap">
                <h2 className="text-dark promoters-title pt-3 flex-fill">
                  {this.props.content.title} {currentCategory}
                </h2>
                {this.props.slides.length > settings.slidesToShow ? (
                  <>
                    <div className="custom-prev-arrow custom-arrow" id="custom-prev-arrow" onClick={this.previous}>
                      {prevArrowIcon()}
                    </div>
                    <div className="custom-next-arrow ms-1 custom-arrow" id="custom-next-arrow" onClick={this.next}>
                      {nextArrowIcon()}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              {this.props.slides.length < settings.slidesToShow ? (
                <div className="row mt-3">
                  {this.props.slides.map((slide, i) => (
                    <div className={`col-${Math.ceil(12 / settings.slidesToShow)}`} key={"promoter-" + i}>
                      <a
                        href={slide.url}
                        className="d-flex promoter-card slide-rounded overflow-hidden me-3 position-relative link-transition"
                      >
                        <div
                          className="promoter-content position-absolute link-transition"
                          style={{ backgroundImage: `url(${slide.picture})` }}
                        ></div>
                        <h6 className="promoter-title w-100 position-absolute text-white m-0 link-transition">
                          {slide.name}
                        </h6>
                      </a>
                    </div>
                  ))}
                </div>
              ) : (
                <Slider {...settings} ref={(c) => (this.slider = c)}>
                  {this.props.slides.map((slide, i) => (
                    <a
                      href={slide.url}
                      key={i}
                      className="promoter-card slide-rounded overflow-hidden me-3 position-relative link-transition"
                    >
                      <div
                        className="promoter-content position-absolute link-transition"
                        style={{ backgroundImage: `url(${slide.picture})` }}
                      ></div>
                      <h6 className="promoter-title w-100 position-absolute text-white m-0 link-transition">
                        {slide.name}
                      </h6>
                    </a>
                  ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PromoterSlider;

const prevArrowIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.626003 15.6584C0.626003 24.0028 7.36171 30.7652 15.668 30.7652C23.9744 30.7652 30.7101 24.0028 30.7101 15.6584C30.7101 7.31401 23.9744 0.55163 15.668 0.55163C7.36171 0.55163 0.626003 7.31401 0.626003 15.6584Z"
      fill="white"
      stroke="#D6D3DF"
      strokeWidth="0.583729"
    />
    <path
      d="M16.1113 12.4404L12.9291 15.6589L16.1113 18.8774"
      stroke="#F347BA"
      strokeWidth="1.16746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const nextArrowIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.5509 20.812C40.5509 31.7337 31.7348 40.5847 20.863 40.5847C9.99111 40.5847 1.17498 31.7337 1.17498 20.812C1.17498 9.89027 9.99111 1.03924 20.863 1.03924C31.7348 1.03924 40.5509 9.89027 40.5509 20.812Z"
      fill="white"
      stroke="#D6D3DF"
      strokeWidth="0.764022"
    />
    <path
      d="M20.2832 16.5996L24.4483 20.8121L20.2832 25.0247"
      stroke="#F347BA"
      strokeWidth="1.52804"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
