import React, { useState } from "react";
import { Input } from "./Input.jsx";
import { Select } from "./Select.jsx";
import { Switch } from "./Switch.jsx";
import { Chip } from "@mui/material";

export const Category = ({
  data,
  onDeleteTicket,
  onDeleteInfo,
  onAddInfo,
  formData,
  handleInputChange,
  index,
  minPrice,
  maxPrice,
  colours
}) => {
  const { free = false, selectedInfo = [] } = data;
  const [isOpen, setIsOpen] = useState(true);
  const [color, setColor] = useState(formData["ticket-category-color"][index]);

  const customType = () => {
    const value = prompt("Add your custom question in the field below. Keep it short.");

    if (value === "" || value === null) return;

    onAddInfo(value);
  };

  const updateTicketValue = (e, index) => {
    const { name, value } = e.target;
    const currentValue = formData[name];
    currentValue[index] = value;
    handleInputChange({ target: { value: currentValue, name } });
  };

  const validSaleStartDate = (startAt, ticket_start_date) => {
    if (!ticket_start_date) return true;
    return new Date(startAt) >= new Date(ticket_start_date);
  };

  const validSaleStartTime = (startAt, start_time, ticket_start_date, ticket_start_time) => {
    if (!ticket_start_date || !ticket_start_time) return true;
    const startDate = new Date(`${startAt} ${start_time}`);
    const ticketStartDate = new Date(`${ticket_start_date} ${ticket_start_time}`);
    return startDate > ticketStartDate;
  };

  const validSaleEndDate = (ticket_start_date, ticket_end_date) => {
    if (!ticket_start_date || !ticket_end_date) return true;
    return new Date(ticket_start_date) <= new Date(ticket_end_date);
  };

  const validSaleEndTime = (ticket_start_date, ticket_start_time, ticket_end_date, ticket_endTime) => {
    if (!ticket_endTime || !ticket_start_time || !ticket_start_date || !ticket_end_date) return true;
    const startDate = new Date(`${ticket_start_date} ${ticket_start_time}`);
    const endAt = new Date(`${ticket_end_date} ${ticket_endTime}`);

    return startDate < endAt;
  };

  return (
    <div className={"row"} style={{ borderLeft: `solid 5px ${color}` }}>
      <div className={`row`}>
        <div className={"col-md-6"}>
          <Input
            mt={1}
            placeholder={"Eg; Promo Tickets"}
            label={"NAME:"}
            name="ticket-category-name"
            onChange={(e) => updateTicketValue(e, index)}
            value={formData["ticket-category-name"][index]}
            required={true}
          />
        </div>
        <div className={"col-4 col-md-2"}>
          <Input
            mt={1}
            placeholder={"100"}
            label={"QUANTITY:"}
            name="ticket-category-quantity"
            onChange={(e) =>
              updateTicketValue(
                {
                  target: { name: e.target.name, value: e.target.value.replace(/[^0-9.]/g, "") }
                },
                index
              )
            }
            value={formData["ticket-category-quantity"][index]}
            required={true}
          />
        </div>
        <div className={"col-4 col-md-2"}>
          <Input
            disabled={free}
            value={formData["ticket-category-price"][index]}
            mt={1}
            placeholder={free ? "FREE" : "10.0"}
            label={"PRICE:"}
            name="ticket-category-price"
            onChange={(e) =>
              updateTicketValue(
                {
                  target: { name: e.target.name, value: e.target.value.replace(/[^0-9.]/g, "") }
                },
                index
              )
            }
            required={!free}
            min={minPrice}
            max={maxPrice}
            type="number"
          />
        </div>
        <div className={"col-2 col-md-1"}>
          <Switch
            mt={1}
            heading={"ACTIVE:"}
            name="ticket-category-active"
            onChange={(e) => updateTicketValue(e, index)}
            checked={formData["ticket-category-active"][index] || false}
          />
        </div>
        <div className={"col-2 col-md-1 pt-5 text-center"}>
          <a onClick={onDeleteTicket}>
            <i className={"fa fa-trash"}></i>
          </a>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-12">
          <center>
            {isOpen ? (
              <a onClick={() => setIsOpen(false)} className={"m-3"}>
                less options&nbsp;
              </a>
            ) : (
              <a onClick={() => setIsOpen(true)} className={"m-3"}>
                more options
              </a>
            )}
          </center>
        </div>
      </div>

      {isOpen && (
        <div className="col-12">
          <div className="row">
            <div className="col-md-8">
              <Input
                type="date"
                label="SALES START ON:"
                name="ticket-start-date"
                onChange={(e) => {
                  if (!formData.startAt) {
                    const timeout = setTimeout(() => {
                      const eventStartInput = document.querySelector(".start-date-input");
                      eventStartInput.scrollIntoView({ behavior: "smooth", block: "center" });
                      eventStartInput.focus();
                      clearTimeout(timeout);
                    }, 1);
                    return;
                  }
                  e.target.setCustomValidity(validSaleStartDate(formData.startAt, e.target.value) ? "" : "Error");
                  document
                    .querySelector(".ticket-end-date-input")
                    .setCustomValidity(
                      validSaleEndDate(e.target.value, formData["ticket-end-date"][index]) ? "" : "Error"
                    );
                  document
                    .querySelector(".ticket-end-time-input")
                    .setCustomValidity(
                      validSaleEndTime(
                        e.target.value,
                        formData["ticket-start-time"][index],
                        formData["ticket-end-date"][index],
                        formData["ticket-end-time"][index]
                      )
                        ? ""
                        : "Error"
                    );

                  updateTicketValue(e, index);
                }}
                value={formData["ticket-start-date"][index]}
                hint="If not set, sales will start immediately after creating your event"
                className={
                  (validSaleStartDate(formData.startAt, formData["ticket-start-date"][index]) ? "" : "is-invalid") +
                  " ticket-start-date-input"
                }
                style={{ backgroundImage: "none", paddingRight: "inherit" }}
              />
              <div className="invalid-feedback">Sale start must be before event start.</div>
            </div>
            <div className="col-md-4 pt-4">
              <Input
                noLabel
                type="time"
                placeholder="SALES START ON:"
                name="ticket-start-time"
                onChange={(e) => {
                  if (!formData.startTime) {
                    const timeout = setTimeout(() => {
                      const eventTimeInput = document.querySelector(".start-time-input");
                      eventTimeInput.scrollIntoView({ behavior: "smooth", block: "center" });
                      eventTimeInput.focus();
                      clearTimeout(timeout);
                    }, 1);
                    return;
                  }
                  e.target.setCustomValidity(
                    validSaleStartTime(
                      formData.startAt,
                      formData.startTime,
                      formData["ticket-start-date"][index],
                      e.target.value
                    )
                      ? ""
                      : "Error"
                  );
                  document
                    .querySelector(".ticket-end-time-input")
                    .setCustomValidity(
                      validSaleEndTime(
                        formData["ticket-start-date"][index],
                        e.target.value,
                        formData["ticket-end-date"][index],
                        formData["ticket-end-time"][index]
                      )
                        ? ""
                        : "Error"
                    );
                  updateTicketValue(e, index);
                }}
                value={formData["ticket-start-time"][index]}
                className={
                  (validSaleStartTime(
                    formData.startAt,
                    formData.startTime,
                    formData["ticket-start-date"][index],
                    formData["ticket-start-time"][index]
                  )
                    ? ""
                    : "is-invalid") + " ticket-start-time-input"
                }
                style={{ backgroundImage: "none", paddingRight: "inherit" }}
              />
              <div className="invalid-feedback">Sale start must be before event start.</div>
            </div>
          </div>
          <div className="row">
            <div className={"col-md-8"}>
              <Input
                type={"date"}
                label={"SALES END ON:"}
                name="ticket-end-date"
                onChange={(e) => {
                  e.target.setCustomValidity(
                    validSaleEndDate(formData["ticket-start-date"][index], e.target.value) ? "" : "Error"
                  );
                  document
                    .querySelector(".ticket-end-time-input")
                    .setCustomValidity(
                      validSaleEndTime(
                        formData["ticket-start-date"][index],
                        formData["ticket-start-time"][index],
                        e.target.value,
                        formData["ticket-end-time"][index]
                      )
                        ? ""
                        : "Error"
                    );
                  updateTicketValue(e, index);
                }}
                value={formData["ticket-end-date"][index]}
                hint="If not set, sales will end right before your event"
                className={
                  (validSaleEndDate(formData["ticket-start-date"][index], formData["ticket-end-date"][index])
                    ? ""
                    : "is-invalid") + " ticket-end-date-input"
                }
                style={{ backgroundImage: "none", paddingRight: "inherit" }}
              />
              <div className="invalid-feedback">Sale end must be after sale start.</div>
            </div>
            <div className={"col-md-4 pt-4"}>
              <Input
                noLabel
                type={"time"}
                placeholder={"SALES END ON:"}
                name="ticket-end-time"
                onChange={(e) => {
                  e.target.setCustomValidity(
                    validSaleEndTime(
                      formData["ticket-start-date"][index],
                      formData["ticket-start-time"][index],
                      formData["ticket-end-date"][index],
                      e.target.value
                    )
                      ? ""
                      : "Error"
                  );
                  updateTicketValue(e, index);
                }}
                value={formData["ticket-end-time"][index]}
                className={
                  (validSaleEndTime(
                    formData["ticket-start-date"][index],
                    formData["ticket-start-time"][index],
                    formData["ticket-end-date"][index],
                    formData["ticket-end-time"][index]
                  )
                    ? ""
                    : "is-invalid") + " ticket-end-time-input"
                }
                style={{ backgroundImage: "none", paddingRight: "inherit" }}
              />
              <div className="invalid-feedback">Sale end must be after sale start.</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <Input
                placeholder={"additional information"}
                label={"TICKET DESCRIPTION:"}
                maxLength={80}
                name="ticket-category-description"
                onChange={(e) => updateTicketValue(e, index)}
                value={formData["ticket-category-description"][index]}
                required={true}
              />
            </div>
            <div className={"col-10 col-md-3"}>
              <Select
                placeholder={"Unlimited"}
                label={"LIMIT PER USER"}
                options={[
                  { _id: 1, name: 1 },
                  { _id: 2, name: 2 },
                  { _id: 3, name: 3 },
                  { _id: 4, name: 4 },
                  { _id: 5, name: 5 }
                ]}
                name="ticket-category-limit"
                onSelect={(e) => updateTicketValue(e, index)}
                selected={formData["ticket-category-limit"][index]}
              />
            </div>
            <div className={"col-2 col-md-1"}>
              <Input
                icon={"eye-dropper"}
                type={"color"}
                placeholder={"additional information"}
                label={"Color"}
                name="ticket-category-color"
                value={color}
                onChange={(e) => {
                  setColor(e.target.value);
                  updateTicketValue(e, index);
                }}
                required={true}
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-12"}>
              {selectedInfo?.length > 0 && (
                <>
                  <label htmlFor="information" className="form-label">
                    REQUEST INFORMATION:
                  </label>
                  <div className={"flex flex-wrap"}>
                    {selectedInfo?.map((item, i) => (
                      <span key={i} className={i === 0 ? "" : "ml-1"}>
                        <Chip label={item.field_type} variant="outlined" onDelete={() => onDeleteInfo(item)} />
                      </span>
                    ))}
                  </div>
                </>
              )}

              <Select
                customOptions={
                  <>
                    <option value={"not"}>Need to know someting additional about buyers</option>
                    <optgroup label="Ask them for:">
                      <option value="Full name">Full name</option>
                      <option value="Email">Email</option>
                      <option value="T-shirt size">T-shirt size</option>
                      <option value="Age">Age</option>
                      <option value="Gender">Gender</option>
                      <option value="Phone">Phone</option>
                    </optgroup>
                    <optgroup label="Ask them for:">
                      <option value="custom">Ask custom question</option>
                    </optgroup>
                  </>
                }
                label={selectedInfo?.length > 0 ? "" : "Request Information"}
                onSelect={(e) => {
                  if (selectedInfo.length >= 5) {
                    return;
                  }
                  if (e.target.value === "custom") {
                    customType();
                    return;
                  }
                  onAddInfo(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
