import React from "react";

import "./ResultsList.scss";

const ResultsList = ({ data, tabType, texts }) => {
  return (
    <React.Fragment>
      {data.map(({ _id, image, category, url, startAt, endAt, place, online, type, name }, cnt) => {
        /* Transform date
                
                let dateStart = new Date(startAt);
                let dateEnd = new Date(endAt);
                let shortMonthStart = dateStart.toLocaleString('en-us', { month: 'short' });
                let shortMonthEnd = dateEnd.toLocaleString('en-us', { month: 'short' });
                let dayStart = dateStart.getDate();
                let dayEnd = dateEnd.getDate();
                let eventDate = dayStart == dayEnd && shortMonthStart == shortMonthEnd ? 
                    `${dayStart} ${shortMonthStart}` :
                    `${dayStart} ${shortMonthStart} - ${dayEnd} ${shortMonthEnd}`;
                */
        return (
          <li className="list-group-item result-list-item" key={`result-item-${tabType}-${cnt}`}>
            <a className="d-flex flex-column flex-sm-row" href={`/events/${name}/${category}/${_id}`}>
              <div
                className="search-result-image"
                style={{ backgroundImage: `url(${image ? image : "/img/test/test.png"})` }}
              ></div>
              <div className="card-body e-card">
                <h6 className="card-subtitle mb-2 text-muted">{tabType == "event" ? category : type}</h6>
                <div className="card-text text-dark">{name}</div>
                <div className="d-flex align-items-baseline">
                  <span className="date me-3">{`${new Date(startAt).toLocaleString("default", { month: "short", day: "numeric" })} - ${new Date(endAt).toLocaleString("default", { month: "short", day: "numeric" })}`}</span>
                  <span className="location me-4">{place}</span>
                  {online ? (
                    <span className="online pink-dot-before text-uppercase search-result-tag">{texts.online}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </a>
          </li>
        );
      })}
    </React.Fragment>
  );
};

export default ResultsList;
