import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import Card from "../../../Discover/components/EventCard";

import "../../../../slick-theme.css";
import "./OnlineSlider.scss";

class OnlineSlider extends React.Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    let cardsList = this.props.cards.length
      ? this.props.cards.map((card, id) => (
          <Card
            key={id}
            cardTexts={card.description}
            isOnlineComponent={true}
            picture={card.image}
            url={`/events/${card.category?.toLowerCase()}/${card.name}/${card._id}`}
            type={card.category}
            dateStart={`${new Date(card.startAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
            dateEnd={`${new Date(card.endAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
            title={card.name}
            going={card.going}
            featured={card.featured}
            dateShort={card.shortdate}
            where={card.where}
            price={card.price}
            categories={Object.values(this.props.categories)}
          />
        ))
      : "";
    const settings = {
      dots: false,
      arrows: false,
      slidesToShow: 5,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className="col-12 online-slider pt-4">
        <div className="row">
          <div className="col-12 ms-auto pb-4 position-relative">
            {this.props.cards.length > settings.slidesToShow ? (
              <>
                <div className="custom-prev-arrow custom-arrow position-absolute" onClick={this.previous}>
                  {prevArrowIcon()}
                </div>
                <div className="custom-next-arrow custom-arrow position-absolute" onClick={this.next}>
                  {nextArrowIcon()}
                </div>
                <Slider {...settings} ref={(c) => (this.slider = c)}>
                  {cardsList}
                </Slider>
              </>
            ) : (
              <div className="row">
                {this.props.cards.map((card, id) => (
                  <div key={id} className={`col`}>
                    <Card
                      cardTexts={this.props.cardTexts}
                      isOnlineComponent={true}
                      picture={card.picture}
                      url={card.url}
                      type={card.category_id}
                      dateStart={`${new Date(card.startAt).toLocaleString("default", {
                        month: "short",
                        day: "numeric"
                      })}`}
                      dateEnd={`${new Date(card.endAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
                      title={card.what}
                      going={card.going}
                      featured={card.featured}
                      dateShort={card.shortdate}
                      where={card.where}
                      price={card.price}
                      categories={Object.values(this.props.categories)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default OnlineSlider;

const prevArrowIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.626003 15.6584C0.626003 24.0028 7.36171 30.7652 15.668 30.7652C23.9744 30.7652 30.7101 24.0028 30.7101 15.6584C30.7101 7.31401 23.9744 0.55163 15.668 0.55163C7.36171 0.55163 0.626003 7.31401 0.626003 15.6584Z"
      fill="white"
      stroke="#D6D3DF"
      strokeWidth="0.583729"
    />
    <path
      d="M16.1113 12.4404L12.9291 15.6589L16.1113 18.8774"
      stroke="#F347BA"
      strokeWidth="1.16746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const nextArrowIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.5509 20.812C40.5509 31.7337 31.7348 40.5847 20.863 40.5847C9.99111 40.5847 1.17498 31.7337 1.17498 20.812C1.17498 9.89027 9.99111 1.03924 20.863 1.03924C31.7348 1.03924 40.5509 9.89027 40.5509 20.812Z"
      fill="white"
      stroke="#D6D3DF"
      strokeWidth="0.764022"
    />
    <path
      d="M20.2832 16.5996L24.4483 20.8121L20.2832 25.0247"
      stroke="#F347BA"
      strokeWidth="1.52804"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
