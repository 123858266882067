import React from "react";

export const Switch = ({ label, heading, mt, name, onChange, checked }) => {
  return (
    <div className={` ${mt > -1 ? mt : "mt-3"}`}>
      {heading && (
        <label htmlFor={name} className="form-label">
          {heading}
        </label>
      )}

      <div className={`form-check form-switch form-control-lg`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={name}
          onChange={(e) => onChange({ target: { name: e.target.name, value: e.target.checked } })}
          name={name}
          checked={checked}
        />
        {label && (
          <label className="form-check-label" htmlFor={name}>
            {label}
          </label>
        )}
      </div>
    </div>
  );
};
