import { useState } from "react";

import { createChat, getOneChat } from "../../services/chatService";
import { patchAcceptFriend, patchRemoveFriend, patchUserCancelRequest } from "../../services/userService";
import { MessageModal } from "./MessageModal";
import "./Card.scss";

export const Card = ({ friend, setFriends, friends, setSuggested, suggested, getUserRequest }) => {
  const [showModal, setShowModal] = useState(false);
  const [chatId, setChatId] = useState(undefined);

  const statusTexts = {
    receivedRequest: "Friendship request",
    sentRequest: "Waiting for response",
    suggested: "Add as a friend"
  };

  const acceptRequest = async () => {
    try {
      await patchAcceptFriend(localStorage.getItem("userId"), { friend: friend.id });
      getUserRequest();
    } catch (err) {
      console.error(err);
    }
  };

  async function cancelFriendRequest() {
    try {
      await patchUserCancelRequest(localStorage.getItem("userId"), { friend: friend.id });
      getUserRequest();
    } catch (err) {
      console.error(err);
    }
  }

  async function removeFriend() {
    try {
      const newUser = await patchRemoveFriend(localStorage.getItem("userId"), { friend: friend.id });
      getUserRequest();
    } catch (err) {
      console.error(err);
    }
  }

  const removeSuggested = () => {
    setSuggested(suggested.filter((x) => x.id !== friend.id));
  };

  const sendFriendRequest = () => {
    removeSuggested();
    setFriends((friends) => [...friends, { ...friend, status: "sentRequest" }]);
  };

  function statusButtons(user) {
    const statusButtons = {
      receivedRequest: (
        <>
          <button className="primary accept" onClick={acceptRequest}></button>
          <button className="unfriend" onClick={removeFriend}></button>
          <button className="message" onClick={() => getShowModal(user.id)}></button>
        </>
      ),
      sentRequest: (
        <>
          <button className="primary unfriend" onClick={removeFriend}></button>
          <button className="message" onClick={() => getShowModal(user.id)}></button>
        </>
      ),
      friend: (
        <>
          <button className="primary message" onClick={() => setShowModal(true)}></button>
          <button className="unfriend" onClick={removeFriend}></button>
        </>
      ),
      suggested: (
        <>
          <button className="primary add-friend" onClick={sendFriendRequest}></button>
          <button className="unfriend" onClick={removeSuggested}></button>
          <button className="message" onClick={() => getShowModal(user.id)}></button>
        </>
      )
    };

    return statusButtons[user.status];
  }

  async function getShowModal(friendId) {
    try {
      let getChatId = null;
      getChatId = await getOneChat(localStorage.getItem("userId"), friendId);
      if (!getChatId) {
        getChatId = await createChat({ senderId: localStorage.getItem("userId"), receiverId: friendId });
      }
      setChatId(getChatId._id);
      setShowModal(true);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <li className={`friend-card col-12 col-xl-3 col-lg-4 col-md-6 my-2 ${friend.status !== "friend" ? "pending" : ""}`}>
      <div className="row">
        <div className="col-4 col-md-6 p-0">
          <button className="image">
            <img alt="user" src={friend.image} />
          </button>
        </div>

        <div className="col-8 col-md-6 px-0 py-1">
          <div className="row my-4 my-md-0">
            <div className="col-6 col-md-12">
              <button type="button" className="user">
                {friend.names}
              </button>
              <span className="location">
                {friend.status === "friend" ? friend.location : statusTexts[friend.status]}
              </span>
            </div>
            <div className="round-buttons col-6 col-md-12">{statusButtons(friend)}</div>
          </div>
        </div>
      </div>
      {showModal && <MessageModal setShowModal={setShowModal} friend={friend} chatId={chatId} />}
    </li>
  );
};
