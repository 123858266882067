import { useEffect } from "react";

import { getUser } from "../../services/userService.js";

import { ChangeNamesForm } from "./ChangeNamesForm.jsx";
import { ChangePasswordForm } from "./ChangePasswordForm.jsx";
import { DeleteProfileButton } from "./DeleteProfileButton.jsx";
import { NotificationSettingsList } from "./NotificationSettingsList.jsx";
import { PrivacySettingsList } from "./PrivacySettingsList.jsx";
import { WalletConnect } from "./WalletConnect.jsx";
import { useState } from "react";

export const Settings = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      getUser(userId)
        .then((result) => setUser(result))
        .catch((err) => console.error(err));
    }
  }, []);

  return (
    <div className="container">
      <div className="col-12 text-dark-blue">
        <h1 className="h4 pt-5 pb-4 mt-2">Settings</h1>
      </div>

      <section className="general row">
        <h1 className="box-header">General Settings</h1>
        <ChangeNamesForm user={user} />
        <ChangePasswordForm />
        <WalletConnect />
      </section>

      <section className="notifications">
        <h1 className="box-header">Email Notifications</h1>
        <NotificationSettingsList user={user} setUser={setUser} />
      </section>

      <section className="privacy">
        <h1 className="box-header">Privacy Settings</h1>
        <PrivacySettingsList />
        <DeleteProfileButton />
      </section>
    </div>
  );
};
