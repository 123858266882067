import { passwordValidator } from "./passwordValidator";

export const changePasswordFormValidator = (data) => {
  if (Object.values(data).some((x) => x === "")) {
    return "Please fill in all fields.";
  }

  const passwordError = passwordValidator(data.newPassword);
  if (passwordError) {
    return passwordError;
  }

  if (data.newPassword !== data.newPasswordRe) {
    return "Passwords don't match.";
  }

  return null;
};
