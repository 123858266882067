import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Tooltip } from "bootstrap";

import {
  getUser,
  patchAcceptFriend,
  patchRemoveFriend,
  patchUserCancelRequest,
  postUserFriendRequest
} from "../../services/userService";
import { AdditionalDetails } from "./AdditionalDetails";

import "./UserDetails.scss";

export const UserDetails = ({ user, setUser }) => {
  const [openModal, setOpenModal] = useState(false);
  const [details, setDetails] = useState(user);
  const [showButtons, setShowButtons] = useState("settings");
  let [searchParams, setSearchParams] = useSearchParams();
  const locationParams = useParams();

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

  useEffect(() => {
    const actions = searchParams.get("status");
    if (localStorage.getItem("accessToken")) {
      if (searchParams.has("status") && ["accept", "decline"].includes(actions)) {
        handleRespond(actions);
      }
    } else {
      window.location.href = `${window.location.origin}/login`;
    }
  }, []);

  useEffect(() => {
    setDetails(user);
    const currentUser = localStorage.getItem("userId");

    if (user._id !== localStorage.getItem("userId")) {
      if (user?.friends?.length > 0) {
        user.friends.forEach((el) => {
          if (el.friend._id === currentUser) {
            if (el.status === "receivedRequest") {
              setShowButtons("sentRequest");
            } else if (el.status === "sentRequest") {
              setShowButtons("receivedRequest");
            } else if (el.status === "friend") {
              setShowButtons("friend");
            }
          }
        });
      } else {
        setShowButtons("settings");
      }
    }
  }, [user]);

  function handleRespond(actions) {
    if (actions === "accept") {
      acceptRequest();
    } else if (actions === "decline") {
      cancelFriendRequest();
    }
  }

  const acceptRequest = async () => {
    try {
      await patchAcceptFriend(localStorage.getItem("userId"), { friend: locationParams.id });
      const newUser = await getUser(locationParams.id);

      setUser(newUser);
    } catch (err) {
      console.error(err);
    }
  };

  async function cancelFriendRequest() {
    try {
      await patchUserCancelRequest(localStorage.getItem("userId"), { friend: locationParams.id });
      const newUser = await getUser(locationParams.id);

      setUser(newUser);
    } catch (err) {
      console.error(err);
    }
  }

  async function sendFriendRequest(e) {
    e.preventDefault();
    try {
      const result = await postUserFriendRequest(localStorage.getItem("userId"), { friend: locationParams.id });
      const newUser = await getUser(result._id);

      setUser(newUser);
    } catch (err) {
      console.error(err);
    }
  }

  async function removeFriend() {
    try {
      const result = await patchRemoveFriend(localStorage.getItem("userId"), { friend: user._id });
      const newUser = await getUser(result._id);

      setUser(newUser);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="user-details-wrapper">
      <ul className="information" id="profile-info">
        {details.gender && (
          <li>
            I am <span>{details.gender}</span>
          </li>
        )}
        {details.birthday && (
          <li>
            Born on <span>{details.birthday}</span>
          </li>
        )}
        {details.birthCity && (
          <li>
            From <span>{details.birthCity}</span>
          </li>
        )}
        {details.homeCity && (
          <li>
            Currently in <span>{details.homeCity}</span>
          </li>
        )}
      </ul>

      <div className="buttons-container text-center mt-3">
        {user._id === localStorage.getItem("userId") ? (
          <>
            <button onClick={() => setOpenModal(true)} className="edit primary" type="button" />
            <Link to="/settings">
              <button className="settings" type="button" />
            </Link>
          </>
        ) : showButtons == "friend" ? (
          <>
            <button
              class="unfriend"
              href="#"
              data-bs-toggle="tooltip"
              data-bs-original-title="Decline friend request"
              onClick={removeFriend}
            ></button>
            <button
              class="message"
              href="#"
              aria-label="Send a message"
              data-bs-toggle="tooltip"
              data-bs-original-title="Send a message"
            ></button>
          </>
        ) : showButtons == "sentRequest" ? (
          <>
            <button
              class="primary cancel"
              href="#"
              aria-label="Cancel friend request"
              data-bs-toggle="tooltip"
              data-bs-original-title="Cancel friend request"
              onClick={cancelFriendRequest}
            ></button>
            <button
              class="message"
              href="#"
              aria-label="Send a message"
              data-bs-toggle="tooltip"
              data-bs-original-title="Send a message"
            ></button>
          </>
        ) : showButtons == "receivedRequest" ? (
          <>
            <button
              class="accept"
              href="#"
              data-bs-toggle="tooltip"
              data-bs-original-title="Accept friend request"
            ></button>
            <button
              class="decline"
              href="#"
              data-bs-toggle="tooltip"
              data-bs-original-title="Decline friend request"
            ></button>
            <button
              class="message"
              href="#"
              aria-label="Send a message"
              data-bs-toggle="tooltip"
              data-bs-original-title="Send a message"
            ></button>
          </>
        ) : (
          <>
            <button
              class="primary friend"
              href="#"
              aria-label="Add as friend"
              data-bs-toggle="tooltip"
              data-bs-original-title="Add as friend"
              onClick={sendFriendRequest}
            ></button>
            <button
              class="message"
              href="#"
              aria-label="Send a message"
              data-bs-toggle="tooltip"
              data-bs-original-title="Send a message"
            ></button>
          </>
        )}
      </div>
      {openModal && <AdditionalDetails setOpenModal={setOpenModal} details={details} setDetails={setDetails} />}
    </div>
  );
};
