import { Link } from "react-router-dom";
import { getDay, getDayString, getMonthString } from "../../helpers/dateHelpers";
import "./CalendarEventCard.scss";

export const CalendarEventCard = ({ event, close }) => {
  const responseStrings = {
    attending: "Attending",
    interested: "Interested",
    "love-it": "Love it but can't"
  };

  return (
    <div className="calendar-event-card col-10 col-sm-4 p-0">
      <div className="event-info">
        <button type="button" className="close" onClick={close}></button>

        <Link to={`/events/${event.category?.toLowerCase()}/${event.name}/${event.id}`}>
          <button className="event-card-img-container">
            <img alt="event" src={event.image} />
          </button>
        </Link>
        <time>{`${getDayString(new Date(event.startAt))} · ${getDay(new Date(event.startAt))} ${getMonthString(
          new Date(event.startAt)
        )}`}</time>
        <Link to={`/events/${event.category?.toLowerCase()}/${event.name}/${event.id}`}>
          <button type="button" className="event-title">
            {event.title}
          </button>
        </Link>
        <table className="details p-20">
          <tbody>
            <tr>
              <td>Category:</td>
              <td>
                <Link to={`/events/${event.category?.toLowerCase()}`}>{event.category}</Link>
              </td>
            </tr>
            <tr>
              <td>Place:</td>
              <td>{event.location}</td>
            </tr>
            <tr>
              <td>Price:</td>
              <td>{event.price}</td>
            </tr>
          </tbody>
        </table>

        <div className={`marker-${event.response} marker`}>{responseStrings[event.response]}</div>
      </div>
    </div>
  );
};
