import "./GiveATry.scss";

export const GiveATry = () => {
  return (
    <div className="maincta-container fadefrombottom wf-section">
      <h2 className="heading-5 cta-heading">Ready to Give it a try?</h2>
      <h4 className="subtitle">Start using Plentix for free.</h4>
      <a href="/event/create" className="btn-main w-inline-block logged-action-items">
        <div>Get Started</div>
      </a>
    </div>
  );
};
