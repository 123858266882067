import { get, post, patch, deleteReq } from "../helpers/fetcher";

export const getAllChat = async (userId) => {
  const endpoint = `/chats/${userId}`;
  const header = { "Content-Type": "application/json" };

  return await get(endpoint, header);
};

export const getOneChat = async (senderId,receiverId) => {
  const endpoint = `/chats/find/${senderId}/${receiverId}`;
  const header = { "Content-Type": "application/json" };

  return await get(endpoint, header);
};

export const createChat = async (data) => {
  const endpoint = `/chats`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header,data);
};