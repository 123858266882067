import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import Card from "../../Discover/components/EventCard";
import { getDay, getMonth } from "../../../helpers/dateHelpers";

import "../../Discover/components/slick-theme.css";
import "./FeaturedSlider.scss";

class FeaturedSlider extends Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: true,
      arrows: false,
      speed: 500,
      infinite: true,
      slidesToShow: 2,
      centerMode: false,
      slidesToScroll: 2,
      appendDots: (dots) => {
        return (
          <div
            style={{
              padding: "0",
              top: "-50px",
              width: "100%",
              left: "0",
              height: "40px"
            }}
          >
            <ul className="slick-dots-list ps-0 d-flex" style={{ margin: "0px" }}>
              {" "}
              {dots}{" "}
            </ul>
            <div className="d-flex ms-auto me-sm-4 align-items-center">
              <div className="custom-prev-arrow custom-arrow" id="custom-prev-arrow" onClick={this.previous}>
                {prevArrowIcon()}
              </div>
              <div className="custom-next-arrow ms-1 custom-arrow" id="custom-next-arrow" onClick={this.next}>
                {nextArrowIcon()}
              </div>
            </div>
          </div>
        );
      },
      customPaging: (i) => <div className="featured-slider-dot" style={{ borderRadius: "50%" }}></div>,
      responsive: [
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    let cardsList = this.props.cards.length
      ? this.props.cards.map((card, id) => {
          let rsvp = [];
          if (card?.rsvp) {
            let users = [];
            if (card.rsvp.users?.length > 0) {
              rsvp = [...users, ...card.rsvp.users];
            }

            if (card.rsvp.hosts?.length > 0) {
              rsvp = [...users, ...card.rsvp.users];
            }
          }

          return (
            <Card
              key={card._id}
              cardTexts={card.description}
              dateShort={`${getMonth(new Date(card.startAt))}-${getDay(new Date(card.startAt))}`}
              isFeaturedComponent={true}
              picture={card.image}
              url={`/events/${card.category?.toLowerCase()}/${card.name}/${card._id}`}
              type={card.category}
              dateStart={`${new Date(card.startAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
              dateEnd={`${new Date(card.endAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
              title={card.name}
              going={card.rsvp}
              featured={card.featured}
              where={card.where}
              price={card?.settings?.priceType}
              categories={Object.values(this.props.categories)}
            />
          );
        })
      : "";

    return (
      <div className="row mt-5 mb-4 featured-slider">
        <div className="col-lg-4 mb-5 mb-lg-0 col-md-5 col-sm-8 col-12">
          <div className="row">
            <div className="col-lg-10 col-12">
              <h2 className="h3 featured-title mb-4 text-dark-blue">{this.props.content.title}</h2>
              <div className="text-s mb-4">{this.props.content.description}</div>
              <div className="d-flex justify-content-start dropdown">
                <a
                  href="/why-plentix"
                  className="p-2 nav-link-hover featured-link"
                  role="button"
                  id="helpDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="/img/svg/icon-questionmark.svg" alt={this.props.content.help} />
                  <span className="ms-1">{this.props.content.help}</span>
                </a>
                <ul className="dropdown-menu widget-dropdown transition-200 col-10" aria-labelledby="helpDropdown">
                  <li className="text-xs">{this.props.content.dropdown_content}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 pt-5">
          {this.props.cards.length == 1 ? (
            <Card
              cardHorizontal={true}
              isFeaturedComponent={true}
              cardTexts={this.props.description}
              dateShort={this.props.cards[0].shortdate}
              picture={this.props.cards[0].image}
              url={`/events/${this.props.cards[0].category.toLowerCase()}/${this.props.cards[0].name}/${
                this.props.cards[0]._id
              }`}
              type={this.props.cards[0].category}
              dateStart={`${new Date(this.props.cards[0].startAt).toLocaleString("default", {
                month: "short",
                day: "numeric"
              })}`}
              dateEnd={`${new Date(this.props.cards[0].endAt).toLocaleString("default", {
                month: "short",
                day: "numeric"
              })}`}
              title={this.props.cards[0].name}
              going={this.props.cards[0].going}
              featured={this.props.cards[0].featured}
              where={this.props.cards[0].where}
              price={this.props.cards[0].price}
              categories={Object.values(this.props.categories)}
            />
          ) : this.props.cards.length > 1 ? (
            <Slider {...settings} ref={(c) => (this.slider = c)}>
              {cardsList}
            </Slider>
          ) : (
            <div className="col-12 h6 text-center py-5">{this.props.content.no_results}</div>
          )}
        </div>
      </div>
    );
  }
}
export default FeaturedSlider;

const prevArrowIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.626003 15.6584C0.626003 24.0028 7.36171 30.7652 15.668 30.7652C23.9744 30.7652 30.7101 24.0028 30.7101 15.6584C30.7101 7.31401 23.9744 0.55163 15.668 0.55163C7.36171 0.55163 0.626003 7.31401 0.626003 15.6584Z"
      fill="white"
      stroke="#D6D3DF"
      strokeWidth="0.583729"
    />
    <path
      d="M16.1113 12.4404L12.9291 15.6589L16.1113 18.8774"
      stroke="#F347BA"
      strokeWidth="1.16746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const nextArrowIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.5509 20.812C40.5509 31.7337 31.7348 40.5847 20.863 40.5847C9.99111 40.5847 1.17498 31.7337 1.17498 20.812C1.17498 9.89027 9.99111 1.03924 20.863 1.03924C31.7348 1.03924 40.5509 9.89027 40.5509 20.812Z"
      fill="white"
      stroke="#D6D3DF"
      strokeWidth="0.764022"
    />
    <path
      d="M20.2832 16.5996L24.4483 20.8121L20.2832 25.0247"
      stroke="#F347BA"
      strokeWidth="1.52804"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
