import { Empty } from "./Empty";
import { Card } from "./Card";
import "./List.scss";

export const List = ({ friends, search, setFriends, getUserRequest }) => {
  let filteredFriends = [];
  if (friends.length) {
    filteredFriends = friends.filter((user) => {
      console.log("userrrr", user);
      return user.names
        .toLowerCase()
        .split(" ")
        .some(
          (name) => name.startsWith(search.toLowerCase()) || user.names.toLowerCase().startsWith(search.toLowerCase())
        );
    });
  }

  return filteredFriends.length || !friends.length ? (
    <ul className="row box friends-list">
      {friends.length ? (
        filteredFriends
          .sort((a, b) => {
            if (b.status === "friend") {
              return -1;
            } else if (a.status === "friend") {
              return 1;
            } else {
              return a.status.localeCompare(b.status);
            }
          })
          .map((friend) => (
            <Card
              key={friend.id}
              friend={friend}
              setFriends={setFriends}
              friends={friends}
              getUserRequest={getUserRequest}
            />
          ))
      ) : (
        <Empty tab={"friends"} />
      )}
    </ul>
  ) : (
    <div className="row no-friends-found-container">
      <div className="message dashed-border">
        <div className="inner">
          <h3>No friends found.</h3>
        </div>
      </div>
    </div>
  );
};
