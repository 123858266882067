import React from "react";
import { Link } from "react-router-dom";

import Autocomplete from "./autocomplete";

const DesktopNav = (props) => {
  let isLogged = props.isLogged;
  let mode = props.mode;
  let languageKeys = Object.keys(props.languages);
  let languageValues = Object.values(props.languages);

  let fontColor = mode == "dark" ? "text-white" : "text-mode-dark";
  let languageBar = (
    <div className="btn-group dropright header-language">
      <a
        href="#"
        className={`trigger-dropdown d-flex align-items-center cta-text text-s`}
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div className="header-icon d-flex justify-content-center text-pink me-1">
          <img src="/img/svg/icon-globe.svg" alt="Choose language" />
        </div>
        &nbsp;
        {languageValues[languageKeys.indexOf(props.selectedLanguage)].name.substring(0, 2)}
      </a>
      <div className="dropdown-menu mt-3 transition-200 widget-dropdown px-0">
        <div className="d-flex flex-column">
          {languageKeys.map((lang, id) => (
            <a
              href="#"
              onClick={props.languageSwitch}
              data-lang={lang}
              className={`language-link text-grey d-flex align-items-center py-2 px-4 text-s rounded-0 ${
                props.selectedLanguage === lang ? "current" : ""
              }`}
              key={`language-${id}`}
            >
              <div className={`language-flag me-2 ${lang}`}></div>
              {languageValues[id].name.substring(0, 2)}&nbsp;
              {props.selectedLanguage === lang ? <i className="fad fa-check-circle text-pink ms-auto"></i> : ""}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
  let education = [];
  let music = [];
  let hobbies = [];
  let other = [];

  props.categories.map((cat, id) => {
    return cat.parent_category == "Education"
      ? education.push(
          <a key={`${cat}-${id}`} className="dropdown-item mb-2" href={`/events/${cat.name_plural.toLowerCase()}`}>
            {cat.name_plural_translated}
          </a>
        )
      : cat.parent_category == "Music & Entertainment"
      ? music.push(
          <a key={`${cat}-${id}`} className="dropdown-item mb-2" href={`/events/${cat.name_plural.toLowerCase()}`}>
            {cat.name_plural_translated}
          </a>
        )
      : cat.parent_category == "Hobbies"
      ? hobbies.push(
          <a key={`${cat}-${id}`} className="dropdown-item mb-2" href={`/events/${cat.name_plural.toLowerCase()}`}>
            {cat.name_plural_translated}
          </a>
        )
      : cat.parent_category == "Other"
      ? other.push(
          <a key={`${cat}-${id}`} className="dropdown-item mb-2" href={`/events/${cat.name_plural.toLowerCase()}`}>
            {cat.name_plural_translated}
          </a>
        )
      : null;
  });

  return (
    <React.Fragment>
      <nav className="navbar navbar-default aaa navbar-light bg-white py-2 desktop-nav">
        <div className="col-12 mx-auto">
          <div className="row flex-nowrap">
            {window.innerWidth < 992 && props.openSearch ? (
              <Autocomplete
                openSearch={props.openSearch}
                toggleDynamicSearch={props.toggleDynamicSearch}
                suggestions={props.searchParams}
                texts={props.texts}
              />
            ) : (
              ""
            )}
            <div className="col d-flex">
              <a className="navbar-brand logo d-flex align-items-center" href="/">
                <img
                  src={`${mode == "dark" ? "/img/Plentix-white.svg" : "/img/Plentix.svg"}`}
                  width="130"
                  className="d-inline-block align-top mt-1"
                  alt="Plentix logo"
                />
              </a>
              <div className="nav-item d-flex align-items-center">{languageBar}</div>
              {window.innerWidth >= 992 ? (
                <Autocomplete openSearch={props.openSearch} suggestions={props.searchParams} texts={props.texts} />
              ) : (
                <div
                  onClick={props.toggleDynamicSearch}
                  className="d-flex align-items-center ms-4 search-icon"
                  id="search-icon"
                >
                  <img src="/img/svg/icon-search.svg" alt="Search" />
                  &nbsp;
                </div>
              )}
            </div>
            <ul className="d-flex col-auto nav not-logged flex-nowrap m-0 align-items-center justify-content-end nav-wrap ms-auto">
              <li className="nav-item d-flex">
                <div className="dropdown">
                  <a
                    href="#"
                    className={`nav-link nav-link-normal dropdown-angle-pink mb-0 link-transition d-flex align-items-center ${fontColor}`}
                    data-bs-toggle="dropdown"
                    role="button"
                    id="discoverDropdown"
                    aria-expanded="false"
                  >
                    {props.texts.discover}
                  </a>
                  <div
                    className="dropdown-menu mt-3 menu-transition-to-bottom transition-200 widget-dropdown category-dropdown"
                    aria-labelledby="discoverDropdown"
                  >
                    <div className="d-flex flex-wrap flex-lg-nowrap">
                      <div className="dropdown-col col-auto col-sm-6 col-lg-auto">
                        <p className="label-m mb-2 text-uppercase ps-3">{props.texts.education}</p>
                        {education.map((el) => el)}
                      </div>
                      <div className="dropdown-col col-auto col-sm-6 col-lg-auto">
                        <p className="label-m mb-2 text-uppercase ps-3">{props.texts.music}</p>
                        {music.map((el) => el)}
                      </div>
                      <div className="dropdown-col col-auto col-sm-6 col-lg-auto">
                        <p className="label-m mb-2 text-uppercase ps-3">{props.texts.hobbies}</p>
                        {hobbies.map((el) => el)}
                      </div>
                      <div className="dropdown-col col-auto col-sm-6 col-lg-auto">
                        <p className="label-m mb-2 text-uppercase ps-3">{props.texts.other}</p>
                        {other.map((el) => el)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dropdown">
                  <a
                    href="#"
                    className={`nav-link nav-link-normal dropdown-angle-pink mb-0 link-transition d-flex align-items-center ${fontColor}`}
                    data-bs-toggle="dropdown"
                    role="button"
                    id="organizersDropdown"
                    aria-expanded="false"
                  >
                    {props.texts.forOrganizers}
                  </a>
                  <div
                    className="dropdown-menu mt-3 menu-transition-to-bottom transition-200 widget-dropdown organizers-dropdown p-0"
                    aria-labelledby="organizersDropdown"
                  >
                    <div className="organizers-container p-4 py-3">
                      <div className="row">
                        <div className="col-12">
                          <p className="label-m mb-2 text-uppercase">{props.texts.learnAboutPlentix}</p>
                          <div className="col-12">
                            <div className="dropdown-col col-5 p-0 d-flex align-items-start flex-column">
                              <a className="dropdown-item" href="/manage-discount">
                                {props.texts.features}
                              </a>
                              <a className="dropdown-item" href="/pricing">
                                {props.texts.pricing}
                              </a>
                            </div>
                            <div className="dropdown-col col-5 p-0 d-flex align-items-start flex-column">
                              <a className="dropdown-item" href="/why-plentix">
                                {props.texts.whyPlentix}
                              </a>
                            </div>
                          </div>
                        </div>
                        {isLogged ? (
                          <>
                            {/* events */}
                            <div className="col-12 mt-3">
                              <div className="label-m mb-2 text-uppercase d-flex align-items-center">
                                {props.texts.admin.my_events}
                                <div className="d-flex align-items-center ms-1 text-dark circle-gray circle-gray-sm justify-content-center fw-500">
                                  {props.myEvents?.length > 0 ? props.myEvents.length : 0}
                                </div>
                                <a href="/event/create/" className="ms-auto mb-1">
                                  <img alt={props.texts.admin.my_events} src="/img/svg/add-button-icon.svg" />
                                </a>
                              </div>
                              <div className="col-12">
                                <div className="dropdown-col col-12 p-0 d-flex align-items-start flex-column">
                                  {props.myEvents.map((event) => (
                                    <Link
                                      key={"event-" + event._id}
                                      className="dropdown-item"
                                      to={`/events/${event.category}/${event.name}/${event._id}`}
                                    >
                                      {event.name}
                                    </Link>
                                  ))}
                                </div>
                              </div>
                            </div>
                            {/* promoters */}
                            <div className="col-12 mt-3">
                              <div className="label-m mb-2 text-uppercase d-flex align-items-center">
                                {props.texts.admin.my_promoters}
                                <div className="d-flex align-items-center ms-1 text-dark circle-gray circle-gray-sm justify-content-center fw-500">
                                  {props.myHosts?.length > 0 ? props.myHosts.length : 0}
                                </div>
                                <a href="/promoter/create/" className="ms-auto mb-1">
                                  <img alt={props.texts.admin.create_promoter} src="/img/svg/add-button-icon.svg" />
                                </a>
                              </div>
                              <div className="col-12">
                                <div className={`dropdown-col p-0 d-flex flex-column`}>
                                  {props.myHosts.map((host, id) => (
                                    <div
                                      key={"host-" + id}
                                      className={`d-flex flex-wrap justify-content-between align-items-center ${
                                        host.events?.length ? "mb-3" : ""
                                      }`}
                                    >
                                      <a className="dropdown-item" href={`/promoter/${host._id}`}>
                                        {host.name}
                                      </a>
                                      <a href={host.new} className="ms-auto mb-1">
                                        <img
                                          alt={host.name}
                                          width="20"
                                          height="20"
                                          src="/img/svg/add-button-icon.svg"
                                        />
                                      </a>
                                      {host.events?.length ? (
                                        <div className="col-12 ps-4 d-flex flex-column">
                                          {host.events?.map((event, evId) => (
                                            <Link
                                              key={event._id}
                                              className="link-pink py-1 my-1"
                                              to={`/events/${event.category}/${event.name}/${event._id}`}
                                            >
                                              {event.name}
                                            </Link>
                                          ))}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div className="col-12">
                          <div className="row dropdown-footer flex-nowrap align-items-center mt-4 pt-4">
                            <div className="col-5 d-flex">
                              <img src="/img/svg/icon-organizer.svg" alt={props.texts.eventPartnersLookFor} />
                            </div>
                            <div className="col-7">
                              <div className="d-flex flex-column align-items-start">
                                <div className="category-label mb-3">{props.texts.eventPartnersLookFor}</div>
                                <a
                                  href="https://alpha.evedo.co/"
                                  target="_blank"
                                  className="dropdown-link link-transition"
                                >
                                  {props.texts.browseOnB2B}&nbsp;
                                  <span className="icon position-absolute d-flex">
                                    <img src="/img/svg/icon-diagonal-arrow.svg" alt="Evedo B2B" />
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {!isLogged ? (
                <>
                  <li className="nav-item nav-item d-flex d-lg-flex d-none">
                    <a
                      id="register"
                      href="/register"
                      className={`nav-link-hover nav-link-normal px-4 text-nowrap d-flex align-items-center ${fontColor}`}
                    >
                      {props.texts.signUp}
                    </a>
                    <a
                      id="login"
                      href="/login"
                      // onClick={() => window.evedo.openLogin()}
                      className="nav-button-primary nav-link-normal text-white px-4 text-nowrap ms-2"
                    >
                      {props.texts.signIn}
                    </a>
                  </li>
                  <div className="dropdown d-lg-none">
                    <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className={`menu-trigger-icon`} alt="hamburger" src={"/img/svg/icon-hamburger.svg"} />
                    </a>
                    <div
                      className="d-flex align-items-center dropdown-menu dropdown-menu-end widget-dropdown"
                      aria-labelledby="log-actions"
                    >
                      <div className="d-flex flex-column align-items-center justify-content-center w-100 pt-4">
                        <img className="mobile-footer-icon" alt="Hey, Stranger" src="/img/svg/icon-hand.svg" />
                        <div className="mobile-nav-title h6">{props.texts.hey_stranger}</div>
                        {!isLogged ? (
                          <div className="mobile-footer-actions py-4 d-flex align-items-center">
                            <a id="register" href="/register" className={`button-ghost nav-link-normal px-5 text-dark`}>
                              {props.texts.signUp}
                            </a>
                            <a
                              id="login"
                              href="/login"
                              // onClick={() => window.evedo.openLogin()}
                              className="nav-button-primary nav-link-normal text-white px-5 ms-2"
                            >
                              {props.texts.signIn}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <li className="nav-item d-flex align-items-center">
                  <div className="logged-menu bg-white d-flex align-items-center justify-content-between px-2 ms-4">
                    <div className="logged-notification custom-drop dropleft text-center rounded-circle d-flex align-items-center justify-content-center">
                      <a
                        href="#"
                        className={`h6 mb-0 link-transition d-flex ${fontColor}`}
                        data-bs-toggle="dropdown"
                        role="button"
                        id="notificationDropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={
                            props.notifications == 0 ? "/img/svg/icon-bell-gray.svg" : "/img/svg/icon-bell-violet.svg"
                          }
                          alt="Notificaitons"
                        />
                      </a>
                      <div
                        className="dropdown-menu menu-transition-to-bottom transition-200 widget-dropdown notifications-dropdown px-0 py-4"
                        aria-labelledby="notificationDropdown"
                      >
                        <p className="label-m cta-text text-uppercase letter-spacing-5 mb-2 px-4">
                          {props.texts.notifications}
                        </p>
                        <a className="dropdown-item px-4 text-s" href="/notifications">
                          {props.texts.youHave} {props.notifications} <b>{props.texts.new}</b>&nbsp;
                          {props.notifications > 1 ? props.texts.notifications : props.texts.notification}.
                        </a>
                      </div>
                    </div>
                    <div className="profile custom-drop dropleft text-center rounded-circle d-flex align-items-center justify-content-center">
                      <a
                        href="#"
                        className={`h6 mb-0 link-transition d-flex ${fontColor}`}
                        data-bs-toggle="dropdown"
                        role="button"
                        id="profileDropdown"
                        aria-expanded="false"
                      >
                        <div className="username-letter d-flex align-items-center justify-content-center">
                          {props.image !== "/img/web/profile-no-image-small.jpg" ? (
                            <img className="w-100" src={props.image} />
                          ) : (
                            props.name.charAt(0)
                          )}
                        </div>
                      </a>
                      <div
                        className="dropdown-menu mt-3 menu-transition-to-bottom transition-200 widget-dropdown py-2 px-0"
                        aria-labelledby="profileDropdown"
                      >
                        <div className="dropdown-header menu-left-header d-flex align-items-center">
                          <div className="label-l text-dark fw-500">
                            {props.texts.hey}, {props.name}
                          </div>
                          <a
                            href="/settings"
                            className="d-flex align-items-center ms-auto text-dark circle-gray justify-content-center"
                          >
                            <img src="/img/svg/icon-settings.svg" alt="Settings" />
                          </a>
                        </div>
                        <div className="dropdown-body">
                          <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/dashboard">
                            Dashboard
                          </a>
                          <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/profile">
                            {props.texts.admin.profile}
                          </a>
                          <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/tickets">
                            {props.texts.admin.my_tickets}
                          </a>
                          <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/my-events">
                            {props.texts.admin.my_calendar}
                          </a>
                          <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/friends">
                            {props.texts.admin.friends}
                          </a>
                          <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/messages">
                            {props.texts.admin.messages}
                            <div className="d-flex align-items-center ms-1 text-dark circle-gray circle-gray-sm justify-content-center">
                              {props.messages !== 0 ? props.messages : 0}
                            </div>
                          </a>
                        </div>
                        <div className="dropdown-footer d-flex py-3 mt-3">
                          <a href="/logout" className="dropdown-item d-flex align-items-center">
                            <div>
                              <img src="/img/svg/icon-logout.svg" alt={props.texts.admin.logout} />
                            </div>
                            <div className="ms-2 cta-text">
                              <small>
                                {props.texts.admin.logout} {props.name}
                              </small>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default DesktopNav;
