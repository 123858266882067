import { useState, useEffect } from "react";

import { loginUser } from "../../services/userService";

import "./Login.scss";

export const Login = ({ setIsLogged, setUserFirstName, setUserImage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);

  const onClickForm = async (e) => {
    e.preventDefault();

    if (email.length > 0 && password.length > 0) {
      const data = {
        email,
        password
      };
      try {
        const result = await loginUser(data);
        localStorage.setItem("accessToken", result.accessToken);
        localStorage.setItem("name", result.user[0].firstName);
        localStorage.setItem("image", result.user[0]?.image);
        localStorage.setItem("userId", result.user[0]._id);

        window.location.href = `${window.location.origin}/`;
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <>
      <div className="container d-flex flex-column justify-content-md-center">
        <div className="modal-actions col-md-11 col-lg-9 col-11 mx-auto">
          <div className="row gx-5">
            <div className="col-12 col-md-6 sign-wrapper">
              {/* <!-- LOGIN --> */}
              <form
                action="#"
                className="column standard w-100"
                id="login"
                method="post"
                // onsubmit="window.evedo.loginRequest(); return false;"
                style={{ display: "block" }}
              >
                <div className="row">
                  <h3 className="title-bilo-m px-0 mb-1">Sign In</h3>
                  <label className="text-xs mt-2" htmlFor="login-email">
                    Email Address
                  </label>
                  <input
                    className="mt-0"
                    name="email"
                    id="login-email"
                    placeholder="Email"
                    type="text"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="text-xs mt-2" htmlFor="login-pass">
                    Password
                  </label>
                  <input
                    className="mt-0"
                    name="password"
                    id="login-pass"
                    placeholder="Password"
                    type="password"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="col-12 d-flex justify-content-between align-items-center px-0 my-3">
                    <label className="text-s fw-500 text-dark d-flex align-items-center">
                      <input
                        className="form-check-input my-0 me-1"
                        name="remember"
                        type="checkbox"
                        defaultChecked={checked}
                        onClick={() => setChecked(!checked)}
                      />{" "}
                      Remember me
                    </label>
                    <p className="w-auto">
                      <a
                        className="text-s text-dark fw-500 text-nowrap"
                        href="/forgot-password"
                        // onClick="window.evedo.toggleLoginForms('forgotten'); return false;"
                      >
                        Forgot password?
                      </a>
                    </p>
                  </div>
                </div>

                <label className="warning alert alert-warning" style={{ display: "none" }}></label>

                <div className="form-actions row mt-2">
                  <button
                    className="nav-button-primary nav-link-normal text-white text-nowrap border-0"
                    onClick={onClickForm}
                  >
                    Sign in
                  </button>
                </div>
                <div className="pt-3 mb-3 row gx-0">
                  <div className="col d-flex align-items-center">
                    <div className="grey-separator w-100"></div>
                  </div>
                  <div className="col d-flex align-items-center justify-content-center">
                    <small className="text-nowrap cta-text">Or continue with</small>
                  </div>
                  <div className="col d-flex align-items-center">
                    <div className="grey-separator w-100"></div>
                  </div>
                </div>
                <div className="row mt-3 gx-1">
                  <div className="col-4">
                    <div
                      className="button-ghost-square py-1 px-0"
                      style={{ cursor: "pointer" }}
                      // onClick='window.open("/common/facebook-url", "fbwindow", "width=800,height=410,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + ((screen.width - 800) /2) + ",top=" + ((screen.height - 410) / 2))'
                    >
                      <img src="/img/svg/facebook-button.svg" alt="Facebook" />
                      <span className="text-xs ps-1">Facebook</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className="button-ghost-square py-1 px-0"
                      style={{ cursor: "pointer" }}
                      // onClick='window.open("/common/google-url", "fbwindow", "width=800,height=410,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + ((screen.width - 800) /2) + ",top=" + ((screen.height - 410) / 2))'
                    >
                      <img src="/img/svg/google-button.svg" alt="Google" />
                      <span className="text-xs ps-1">Google</span>
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className="button-ghost-square py-1 px-0"
                      style={{ cursor: "pointer" }}
                      // onClick='window.open("/common/twitter-url", "fbwindow", "width=800,height=410,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + ((screen.width - 800) /2) + ",top=" + ((screen.height - 410) / 2))'
                    >
                      <img src="/img/svg/twitter-button.svg" alt="Twitter" />
                      <span className="text-xs ps-1">Twitter</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6 col-lg-5 col-sm-10 col-12 mx-auto mx-md-0 bg-pink-pale d-flex flex-column pt-5 pb-4 px-4 ms-md-auto align-items-center rounded-4">
              <img
                className="mt-4 mb-2 rounded-circle"
                src="/img/affiliates/zzzzapp/irina-small.png"
                width="92"
                height="92"
                alt="Irina Altanova"
              />
              <div className="mb-5 text-m text-center px-4">
                Plentix helps us achieve our goals and reach more people. I love it! It’s user-friendly, the support is
                very fast so Plentix makes it easier!
              </div>
              <div className="mb-2 text-m">Irina Altanova</div>
              <div className="mb-2 text-m">
                <small>Administrative coordinator @ MOVE.BG</small>
              </div>
              <div className="d-flex mt-5 flex-wrap justify-content-center">
                <img src="/img/svg/tomorrowland-dark-pale.svg" className="me-1" alt="Tomorrowland" />
                <img src="/img/svg/lovefest-dark-pale.svg" className="me-1" alt="Lovefest" />
                <img src="/img/svg/drooble-dark-pale.svg" className="me-1" alt="Drooble" />
                <img src="/img/svg/logo-stagecast.svg" alt="Stagecast" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
