export const getYear = (date) => {
  return date.getFullYear();
};

export const getMonth = (date) => {
  return date.getMonth() + 1;
};

export const getMonthString = (date) => {
  return date.toLocaleString("default", { month: "long" });
};

export const getDay = (date) => {
  return date.getDate();
};

export const getDayString = (date) => {
  return date.toLocaleDateString("default", { weekday: "long" });
};

export const getWeekdayOfMonthStart = (year, month) => {
  return new Date(year, month - 1, 1).getDay();
};

export const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const checkIfToday = (day, month, year) => {
  const todayDate = new Date();
  return day === getDay(todayDate) && month === getMonth(todayDate) && year === getYear(todayDate);
};

export const compareDates = (date1, date2) => {
  return getDay(date1) === getDay(date2) && getMonth(date1) === getMonth(date2) && getYear(date1) === getYear(date2);
};

export const checkIfThisMonth = (date) => {
  const todayDate = new Date();
  if (
    (getYear(date) === getYear(todayDate) && getMonth(date) !== getMonth(todayDate)) ||
    getYear(date) !== getYear(todayDate)
  ) {
    return false;
  }
  return true;
};

export const checkIfBeforeToday = (day, date) => {
  const todayDate = new Date();
  const compareDate = new Date(getYear(date), getMonth(date) - 1, day);
  if (checkIfThisMonth(compareDate)) {
    return getDay(compareDate) < getDay(todayDate);
  } else {
    return compareDate < todayDate;
  }
};

export const getMonthArr = (date) => {
  const year = getYear(date);
  const month = getMonth(date);
  const weekdayOfMonthStart = getWeekdayOfMonthStart(year, month);
  const daysInMonth = getDaysInMonth(year, month);

  const monthArr = [];

  let row = 0;
  let i = 1;
  while (i <= daysInMonth) {
    monthArr[row] = [];
    for (let k = 0; k < 7; k++) {
      if (row === 0 && weekdayOfMonthStart !== 1) {
        while (k + 1 < weekdayOfMonthStart) {
          monthArr[row][k] = new Date(year, month - 1, k + 2 - weekdayOfMonthStart).getDate();
          k++;
        }
      }
      monthArr[row][k] = i;
      i++;
      if (i > daysInMonth) {
        let newMonthDay = 1;
        while (monthArr[row].length < 7) {
          monthArr[row][monthArr[row].length] = newMonthDay;
          newMonthDay++;
        }
        break;
      }
    }
    row++;
  }

  return monthArr;
};

export const betweenDates = (startAt, endAt, date) => {
  return endAt >= date && startAt <= new Date(date.setHours(23, 59, 59));
};
