export const ActivityYear = ({ yearObj, selectedPeriod, updateSelected }) => {
  const [selectedMonth, selectedYear] = selectedPeriod.split("-");

  return (
    <>
      <span className="year">{yearObj.year}</span>
      {yearObj.months.map((month) => (
        <button
          key={month}
          type="button"
          className={`${month === selectedMonth && +yearObj.year === +selectedYear ? "active" : ""} activity-btn`}
          onClick={() => updateSelected(month, yearObj.year)}
        >
          {month}
        </button>
      ))}
    </>
  );
};
