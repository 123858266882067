import { Interests } from "./Interests";
import { CategoryListElement } from "../Setup/components/CategoryListElement";

import "./SectionCategories.scss";

export const SectionCategories = ({ user }) => {
  return (
    <>
      <div id="categories-wrapper" className="col-12 mt-3">
        <h1 className="box-header">Favourite Categories</h1>

        <form className=""></form>
      </div>
      <CategoryListElement user={user} />
      <Interests user={user} />
    </>
  );
};
