import { get, patch, post } from '../helpers/fetcher';

export const getOrder = async (id) => {
  const endpoint = `/orders/${id}`;

  return get(endpoint);
};

export const postOrder = async (body) => {
  const endpoint = "/orders";
  const header = { "Content-Type": "application/json" };

  return post(endpoint, header, body);
};

export const patchRequired = async (id, body) => {
  const endpoint = `/orders/${id}/required`;
  const header = { "Content-Type": "application/json" };

  return patch(endpoint, header, body);
};

export const completeOrder = async (id, body) => {
  const endpoint = `/orders/${id}/complete`;
  const header = { "Content-Type": "application/json" };

  return patch(endpoint, header, body);
};