import { useEffect, useState } from "react";
import { updateUser } from "../../services/userService.js";
import { changeNamesFormValidator } from "../../validators/changeNamesFormValidator.js";
import { GeneralSettingsForm } from "./GeneralSettingsForm.jsx";

export const ChangeNamesForm = ({ user }) => {
  const [message, setMessage] = useState({ type: "", text: "" });
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: ""
  });

  useEffect(() => {
    setData({
      firstName: user.firstName,
      lastName: user?.lastName,
      email: user.email
    });
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...data };
    updatedData[name] = value;
    setData(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = changeNamesFormValidator(data);
    if (error) {
      setMessage({ type: "error", text: error });

      return;
    } else {
      let body = {
        firstName: data.firstName,
        email: data.email
      };

      if (data.lastName?.length > 0) {
        body.lastName = data.lastName;
      }

      await updateUser(user._id, body)
        .then(() => {
          setMessage({ type: "success", text: "You have successfully changed your profile information." });
        })
        .catch((err) => {
          setMessage({ type: "error", text: err.message });
        });
    }

    const timeout = setTimeout(() => {
      setMessage({ type: "", text: "" });
      clearTimeout(timeout);
    }, 3000);
  };

  return (
    <GeneralSettingsForm>
      <form className="box py-3" onSubmit={handleSubmit}>
        <div className="container-fluid">
          <h2>Change names or email</h2>
          <div className="col-12">
            <div className="row g-1">
              <div className="col-12 col-md-6">
                <input
                  className="form-control form-control-lg"
                  name="firstName"
                  placeholder="First name"
                  type="text"
                  value={data.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 col-md-6">
                <input
                  className="form-control form-control-lg"
                  name="lastName"
                  placeholder="Last name"
                  type="text"
                  value={data.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row g-1 my-1">
              <div className="col-12 col-sm-6">
                <input
                  className="form-control form-control-lg"
                  name="email"
                  placeholder="Email"
                  type="text"
                  value={data.email}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          {message.type && <div className={`message ${message.type}`}>{message.text}</div>}
          <button className="btn btn-primary" type="submit">
            Save changes
          </button>
        </div>
      </form>
    </GeneralSettingsForm>
  );
};
