import { Link } from "react-router-dom";
import ProfileImage from "../../assets/profile-image.jpg";

import "./Card.scss";

export const Card = ({ friend, active, setFriendId, lastMessage, setFriends, friends }) => {
  const handleChoice = (friendId) => {
    setFriendId(friendId);
    setFriends(
      friends.map((x) => {
        if (x._id === friendId) {
          x.unreadCount = 0;
        }
        return x;
      })
    );
  };

  return (
    <li className="messaged-friend-card" onClick={() => handleChoice(friend._id)}>
      <Link to="#" className={`${active ? "active" : ""}`}>
        <img alt="user" src={friend.image ? friend.image : ProfileImage} />
        <strong>{friend.names}</strong>
        <span className="last-msg">{lastMessage}</span>
        {friend.unreadCount > 0 && <span className="unread-count">{friend.unreadCount}</span>}
      </Link>
    </li>
  );
};
