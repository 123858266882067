import { CategoryListElement } from "./CategoryListElement";

export const Elements = ({ newPage, setPreferenceCategory, userFirstName }) => {
  return (
    <section className="profile-setup bg-transparent mx-auto d-flex h-100 flex-column align-items-center justify-content-center">
      <div className="mask steps mb-5 mt-5">
        <div className="wrapper">
          <div className="slide h-100 justify-content-center interests d-flex flex-column align-items-center">
            <img className="mx-auto my-5" src="/img/svg/icon-welcome.svg" alt="Welcome to Plentix" />
            <h2 className="h4 text-dark-blue">Welcome aboard {userFirstName}</h2>
            <div className="dropdown d-flex">
              <div className="text-m text-dark-grey">Set up your preferred categories</div>
              <a
                href="#"
                className="link-transition ms-2 d-flex"
                type="button"
                id="whatsThis"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img className="col" src="/img/svg/icon-questionmark-grey.svg" alt="What’s this?" />
                <div className="ms-1 featured-link">
                  <span className="cta-text">What’s this?</span>
                </div>
              </a>
              <ul className="dropdown-menu transition-200 widget-dropdown text-xs" aria-labelledby="whatsThis">
                <li>
                  Selecting your preferred categories will help Plentix with better suggestions for events in the
                  future.
                </li>
              </ul>
            </div>
            <CategoryListElement setPreferenceCategory={setPreferenceCategory} />
          </div>
        </div>
      </div>

      <div className="mask navigation">
        <div className="wrapper">
          <div className="slide steps d-flex justify-content-center align-items-center">
            <div className="steps-bar-control d-none clickable">
              <div className="current">
                Step 1: <strong>Location</strong>
              </div>
              <div>
                Step 2: <strong>Interests</strong>
              </div>
            </div>

            <div id="interestsNavText" className="h5  me-2">
              <span className="text-dark-blue">Almost there ...</span>
            </div>
            <button className="nav-button-primary text-white d-flex align-items-center py-3 px-5" onClick={newPage}>
              Next <img className="ms-3" src="/img/svg/icon-arrow-right-long.svg" alt="Next" />
            </button>
          </div>

          <div className="slide redirect d-none">
            <div>Redirecting in</div>
            <div className="countdown">5</div>
            <div>seconds...</div>
          </div>
        </div>
      </div>
    </section>
  );
};
