import { useState } from "react";
import "./Map.scss";

export const Map = ({ location }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="event-details-map col-12" id="map">
      <div className="map-container">
        <div className="map">
          <div className={`map-text ${expanded ? "show" : ""}`}>
            <div>
              <strong>
                <span>{location}</span>
              </strong>
            </div>

            <button className="collapse-map" onClick={() => setExpanded(false)}></button>
          </div>

          <div className={`event-map-canvas ${expanded ? "expanded" : ""}`}>
            <iframe
              title="map"
              src={`https://maps.google.com/maps?q=${location}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
            ></iframe>

            <button className="expand-map" onClick={() => setExpanded(true)}></button>
          </div>
        </div>
      </div>
    </div>
  );
};
