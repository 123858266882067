import React from "react";

export class SittingChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marker: "",
      sections: [],
      isLoaded: false,
      showSectionBuilder: false,
      availableSection: 0,
      seatsQty: 0,
      reservation: []
    };

    this.handleSeatSelection = this.handleSeatSelection.bind(this);
    this.buildContent = this.buildContent.bind(this);
    this.setSeatsSize = this.setSeatsSize.bind(this);
    this.updateGlobalMap = this.updateGlobalMap.bind(this);
  }
  componentDidMount() {
    if (!this.state.isLoaded) {
      let aSection = this.props.floorMap[this.props.section].rows;

      let reservedPlaces = 0;
      aSection.forEach((row) => {
        row.forEach((seat) => {
          if (seat === "R") reservedPlaces++;
        });
      });

      this.setState(
        {
          isLoaded: true,
          seatsQty: this.props.quantity - reservedPlaces,
          availableSection: this.props.section,
          blankRows: this.props.floorMap[this.props.section].rows
            .map((e) => e.every((s) => s === "="))
            .filter((el) => el !== false).length,
          sections: [
            ...this.props.floorMap,
            {
              ...this.props.floorMap[this.props.section],
              rows: aSection
            }
          ]
        },
        this.props.floorMap.length ? this.buildContent : null
      );
    }
  }
  updateGlobalMap() {
    // To be changed with proper details
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.sections)
    };
    fetch("/", requestOptions)
      .then((response) => response.json())
      .then((data) => this.setState({ data: data }));
  }
  buildContent() {
    let sizeOfSeat = this.setSeatsSize();
    let seats = new Array(this.state.columns);
    for (var i = 0; i < seats.length; i++) {
      seats[i] = new Array(this.state.rows);
    }
    let sections = this.state.sections.map((section) => section);
    for (let index = 0; index < sections.length; index++) {
      let actualSeatCount = 0;
      let reverseRows = sections[index].rows;
      let blankRowsCount = 0;
      let col = (
        <div className={`w-100`} key={"section-" + index}>
          <div className="d-flex justify-content-between row">
            <div className="col-12 text-center py-3 my-3">
              <hr />
              <h2>
                <em>{sections[index].name}</em>
              </h2>
              <hr />
            </div>
          </div>
          <div
            className={`area-container d-flex flex-column align-items-center overflow-scroll`}
            style={{ width: sections[index].length * sizeOfSeat + "px" }}
          >
            {reverseRows.map((seat, sIndex) => {
              let seatCountPerRow = 0;
              let isBlankRow = seat.every((el) => el === "=");
              isBlankRow && blankRowsCount++;
              let subtractBlanks = !blankRowsCount ? this.state.blankRows : 0;
              let rowNumber = sections[index].startIndex + sections[index].rows.length - (sIndex + 1) - subtractBlanks;
              return isBlankRow ? (
                <div className={`d-flex align-items-center flex-row`} key={sIndex}>
                  <div className={`d-flex ${sections[index].cols == "rtl" ? "flex-row-reverse" : ""}`} key={sIndex}>
                    {seat.map((el, elIndex) => (
                      <span
                        style={iconStyle}
                        key={"empty-seat-" + elIndex}
                        className="fa-layers fa-fw"
                        style={{ fontSize: sizeOfSeat + "px", width: sizeOfSeat + "px" }}
                      ></span>
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  className={`d-flex align-items-center flex-row w-100`}
                  key={sIndex}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={`Row: ${rowNumber}`}
                >
                  <div className={`d-flex ${sections[index].cols == "rtl" ? "flex-row-reverse" : ""}`} key={sIndex}>
                    {seat.map((el, elIndex) => {
                      if (!sections[index].countRows) {
                        if (el !== "_") {
                          seatCountPerRow++;
                        }
                      }
                      if (el !== "_") {
                        actualSeatCount++;
                      }
                      let seatCounter = sections[index].countRows ? actualSeatCount : seatCountPerRow;

                      let layerFontSize = { fontSize: "12px", width: "10px", height: "10px" };
                      return (
                        <div
                          key={`area-${index + 1}-seat-${elIndex + 1}`}
                          data-seat={`${sections[index].name}, Row: ${rowNumber}, Seat: ${seatCounter}`}
                          data-section={index}
                          data-row={sIndex}
                          data-row-index={elIndex}
                          data-value={el}
                          onClick={(e) => this.handleSeatSelection(e)}
                          className={`seat d-flex ${elIndex + 1} mx-1`}
                          style={{
                            fontSize: sizeOfSeat + "px",
                            width: sizeOfSeat + "px",
                            cursor: `${el !== "_" && el !== "=" && el !== 0 ? "pointer" : "initial"}`
                          }}
                        >
                          {el == "=" ? (
                            <span style={iconStyle} className="fa-layers fa-fw">
                              {" "}
                            </span>
                          ) : el == "_" ? (
                            <span style={iconStyle} className="fa-layers fa-fw">
                              {" "}
                            </span>
                          ) : el == 1 ? (
                            <span style={iconStyle} className="fa-layers fa-fw">
                              <i
                                className={`fa-solid fa-circle ${
                                  index == this.state.availableSection ? "pink" : "text-secondary"
                                }`}
                              ></i>
                              <span
                                style={{ ...layerFontSize }}
                                className="fa-layers-counter  "
                                data-fa-transform="shrink-10 down-2"
                              >
                                {seatCounter}
                              </span>
                            </span>
                          ) : el == 0 ? (
                            <span style={iconStyle} className="fa-layers fa-fw">
                              <i
                                className={`fa-solid fa-circle ${
                                  index == this.state.availableSection ? "text-secondary" : "text-secondary"
                                }`}
                              ></i>
                              <span
                                style={{ ...layerFontSize }}
                                className="fa-layers-counter  "
                                data-fa-transform="shrink-10 down-2"
                              >
                                {seatCounter}
                              </span>
                            </span>
                          ) : el == "R" ? (
                            <span style={iconStyle} className="fa-layers fa-fw">
                              <i
                                className={`fa-solid fa-circle ${
                                  index == this.state.availableSection ? "text-warning" : "text-secondary"
                                }`}
                              ></i>
                              <span
                                style={{ ...layerFontSize }}
                                className="fa-layers-counter  "
                                data-fa-transform="shrink-10 down-2"
                              >
                                {seatCounter}
                              </span>
                            </span>
                          ) : el == "w" ? (
                            <span style={iconStyle} className="fa-layers fa-fw">
                              <i
                                className={`fad fa-wheelchair ${
                                  index == this.state.availableSection ? "pink" : "text-secondary"
                                }`}
                              ></i>
                              <span
                                style={{ ...layerFontSize }}
                                className="fa-layers-counter  "
                                data-fa-transform="shrink-10 down-2"
                              >
                                {seatCounter}
                              </span>
                            </span>
                          ) : el == "x" ? (
                            <span style={iconStyle} className="fa-layers fa-fw">
                              <i
                                className={`fad fa-wheelchair ${
                                  index == this.state.availableSection ? "text-secondary" : "text-secondary"
                                }`}
                              ></i>
                              <span
                                style={{ ...layerFontSize }}
                                className="fa-layers-counter "
                                data-fa-transform="shrink-10 down-2"
                              >
                                {seatCounter}
                              </span>
                            </span>
                          ) : (
                            // el == 'wr' ? <span style={iconStyle} className="fa-layers fa-fw"><i className={`fad fa-wheelchair ${index == this.state.availableSection ? 'text-primary' : 'text-secondary'}`}></i><span style={{ ...layerFontSize }} className="fa-layers-counter " data-fa-transform="shrink-10 down-2">{seatCounter}</span></span> :
                            <span className="fa-layers fa-fw">
                              <i
                                className={`fa-solid fa-circle  ${
                                  index == this.state.availableSection ? "text-primary" : "text-secondary"
                                }`}
                              ></i>
                              <span
                                style={{ ...layerFontSize }}
                                className="fa-layers-counter "
                                data-fa-transform="shrink-10 down-2"
                              >
                                {seatCounter}
                              </span>
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
      index == this.state.availableSection && this.setState({ stageContent: <div className="w-100">{col}</div> });
    }
    // this.updateGlobalMap();
  }
  setSeatsSize() {
    let stage = document.getElementById("stage");
    let stageWidth =
      stage.offsetWidth - parseInt(window.getComputedStyle(stage, null).getPropertyValue("padding-left")) * 2;
    let longestLeftRow = 0;
    let longestMidRow = 0;
    let longestRightRow = 0;
    let areaPadding = 0;
    this.state.sections[this.state.availableSection].rows.map((seat) => {
      if (longestLeftRow < seat.length) {
        longestLeftRow = seat.length;
      }
    });
    let totalRows = longestLeftRow + longestMidRow + longestRightRow;
    let seatSize = (stageWidth - areaPadding) / totalRows;
    return Math.floor(seatSize > 18 ? 18 : seatSize);
  }
  handleSeatSelection(e) {
    let section = e.target.getAttribute("data-section");
    let row = e.target.getAttribute("data-row");
    let index = e.target.getAttribute("data-row-index");
    let value = e.target.getAttribute("data-value");
    let seatnumber = e.target.getAttribute("data-seat");
    let newSections = this.state.sections;
    let allowedSeatCount = this.state.seatsQty;
    let reservedSeats = this.state.reservation;

    if (value == 1 && allowedSeatCount > 0) {
      newSections[section].rows[row][index] = "R";
      allowedSeatCount--;
      reservedSeats.push(seatnumber);
    } else if (value == "R") {
      newSections[section].rows[row][index] = 1;
      allowedSeatCount++;
      const elId = reservedSeats.indexOf(seatnumber);
      if (elId > -1) {
        reservedSeats.splice(elId, 1);
      }
    } else if (value == "w" && allowedSeatCount > 0) {
      newSections[section].rows[row][index] = "R";
      allowedSeatCount--;
      reservedSeats.push(seatnumber);
    } else if (value == "R") {
      newSections[section].rows[row][index] = "w";
      allowedSeatCount++;
      const elId = reservedSeats.indexOf(seatnumber);
      if (elId > -1) {
        reservedSeats.splice(elId, 1);
      }
    } else {
      return false;
    }
    if (allowedSeatCount >= 0) {
      this.setState(
        {
          sections: newSections,
          seatsQty: allowedSeatCount,
          reservation: reservedSeats
        },
        this.buildContent
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.sections.length ? (
          <div className="sitting-chart-wrapper row modal-content mw-80">
            <div className="col-12">
              <div className="row">
                <div
                  className={`stage col-12 d-flex justify-content-center flex-column w-100 align-items-center`}
                  id="stage"
                >
                  <div className={`seats w-100 d-flex flex-row justify-content-between mb-4`}>
                    {this.state.stageContent ? this.state.stageContent : "No seats generated."}
                  </div>
                  <h3 className="text-white bg-dark py-3 w-50 text-center">Stage</h3>
                </div>
                {this.state.reservation.length !== 0
                  ? this.state.reservation.map((seat, ticketId) => {
                      return (
                        <input
                          name="order[seats][][name]"
                          type="hidden"
                          key={`ticket-${ticketId}`}
                          id={`ticket-${ticketId}`}
                          value={seat}
                          name="order[seats]"
                        />
                      );
                    })
                  : ""}
                <input type="hidden" name="order[seats][venueMap]" value={JSON.stringify(this.state.sections)} />
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  {this.state.reservation.length !== 0 ? (
                    <div className="row">
                      <div className="col-12 mt-5">
                        <table className="table table-striped text-uppercase">
                          <thead>
                            <tr>
                              <th>section</th>
                              <th>row</th>
                              <th>seat</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.reservation.map((seat, ticketId) => {
                              return (
                                <tr key={`seat-${ticketId}`}>
                                  {seat.split(",").map((name, labelId) => {
                                    return <td key={`seat-${ticketId}-${labelId}`}>{name}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <h3>No Seat Selected</h3>
                  )}
                </div>
              </div>
              {this.state.reservation.length == this.props.quantity ? (
                <div className="row">
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary rounded mt-3 mr-3">
                      <strong>Continue</strong>
                      <span></span>
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="col-12">
            <div className="row">
              <div className="no-data-box mx-auto">
                <div>
                  <i className="fal fa-meh fa-5x no-data-icon text-primary"></i>
                  <br />
                  <h3 className="my-2">There are no seatings for this event.</h3>
                  <div className="row mt-4">
                    <div className="col-12">
                      <button type="submit" className="btn btn-primary rounded mt-3 mr-3">
                        <strong>Continue</strong>
                        <span></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const iconStyle = {
  pointerEvents: "none"
};
const areaInputStyle = {
  top: 0,
  left: 0,
  width: "100%",
  height: "100%"
};
// = space with number
// _ space without number
// 1 available seat
// 0 taken seat (someone has bought it)
// R reserved seat (reserved for 15min)
// w wheelchair
// x wheelchair taken

// pink - available - 1
// yellow - selected - R
// blue - booked - 0
// gray - unavailable - 0
