import React, { Component, Fragment } from "react";

class Autocomplete extends Component {
  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      expandInput: false
    };
  }

  onChange = (e) => {
    // const { suggestions } = this.props;
    const userInput = e.currentTarget.value;
    this.setState({
      activeSuggestion: 0,
      userInput: e.currentTarget.value
    });

    if (userInput.length > 2) {
      fetch(`/search/advanced?name=${userInput}`)
        .then((res) => res.json())
        .then((result) => {
          let sug = [];
          result.events.reults.map((el) => sug.push(el));
          result.usersAndPromoters.reults.map((el) => sug.push(el));
          // Filter our suggestions that don't contain the user's input
          // console.log(sug)
          //     const filteredSuggestions = sug.filter(
          //     suggestion =>
          //         suggestion.title.toLowerCase().indexOf(userInput.toLowerCase()) > -1
          // );
          this.setState({
            activeSuggestion: 0,
            filteredSuggestions: sug,
            showSuggestions: true
          });
        })
        .catch((error) => console.log(error));
    } else if (userInput <= 2) {
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false
      });
    }
  };

  onClick = (e) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.querySelector(".suggestion-title").innerText
    });
  };

  clearSearch = (e) => {
    this.setState({
      activeSuggestion: 0,
      showSuggestions: false,
      filteredSuggestions: [],
      userInput: ""
    });
  };

  handleInputClick = (e) => {
    this.setState({
      expandInput: true
    });
  };
  handleOutsideclick = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setTimeout(() => {
        this.setState(
          {
            expandInput: false,
            activeSuggestion: 0,
            showSuggestions: false,
            filteredSuggestions: [],
            userInput: ""
          },
          this.props.toggleDynamicSearch
        );
      }, 200);
    }
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    // User pressed the enter key
    if (e.keyCode === 13) {
      let v = e.currentTarget.value;
      this.setState(
        {
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: v
        },
        () => (window.location.href = "/search/?&name=" + v)
      );
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      clearSearch,
      handleInputClick,
      handleOutsideclick,
      state: { activeSuggestion, filteredSuggestions, showSuggestions, userInput }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        let events = [];
        let promoters = [];
        // filteredSuggestions?.map()
        filteredSuggestions?.map((suggestion, index) => {
          return suggestion.type == "event" && events.length <= 3
            ? events.push(
                <div className={"d-flex "} key={"suggestion" + index} onClick={onClick}>
                  <div className="card w-100 border-0 py-3 px-4 suggestion-card transition-500">
                    <a href={suggestion.url} className="row g-2 suggestion-link align-items-start">
                      <div
                        className="col-6 col-xl-3 d-flex suggestion-card-bg"
                        style={{ backgroundImage: `url(${suggestion.image})` }}
                      ></div>
                      <div className="col-6 col-xl-9 flex-fill">
                        <div className="row">
                          <div className="card-body py-1 ps-3 pe-2 col-auto me-auto">
                            <p className="card-title suggestion-title">{suggestion.title}</p>
                            {suggestion.place ? (
                              <p className="suggestion-card-place label-m mb-0">{suggestion.place}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-auto">
                            {suggestion.date ? (
                              <div className="card-date py-3 px-3 text-white text-uppercase d-flex flex-column align-items-center position-relative">
                                {suggestion.date}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )
            : suggestion.type == "promoter" && promoters.length <= 3
            ? promoters.push(
                <div className={"d-flex "} key={"suggestion" + index} onClick={onClick}>
                  <div className="card w-100 border-0 py-3 px-4 suggestion-card transition-500">
                    <a href={suggestion.url} className="row g-2 suggestion-link align-items-start">
                      <div
                        className="col-6 col-xl-3 d-flex suggestion-card-bg"
                        style={{ backgroundImage: `url(${suggestion.image})` }}
                      ></div>
                      <div className="col-6 col-xl-9">
                        <div className="card-body py-1 ps-3 pe-2">
                          <p className="card-title suggestion-title">{suggestion.title}</p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )
            : null;
        });
        suggestionsListComponent = (
          <div className="suggestions widget-dropdown position-absolute mx-auto bg-white p-0">
            {events.length ? (
              <div className="search-events-wrap">
                <p className="category-label mb-0 p-4 text-uppercase">{this.props.texts.events}</p>
                {events}
              </div>
            ) : (
              ""
            )}
            {promoters.length ? (
              <div className="search-promoters-wrap">
                <p className="category-label mb-0 p-4 text-uppercase">{this.props.texts.promoters}</p>
                {promoters}
              </div>
            ) : (
              ""
            )}
            <div className="d-flex dropdown-footer flex-nowrap align-items-center mt-4 p-4">
              <div className="d-flex">
                <img src="/img/svg/icon-not-found.svg" alt="Looking for partners to build your event?" />
              </div>
              <div className="d-flex flex-column align-items-start ms-lg-4">
                <div className="category-label mb-3 text-dark">{this.props.texts.searchTargetNotFound}</div>
                <a
                  href={userInput.length ? `/search/&name=${userInput}` : "#"}
                  className="dropdown-link link-transition"
                >
                  {this.props.texts.searchSeeMore}
                </a>
              </div>
            </div>
          </div>
        );
      } else {
        suggestionsListComponent = (
          <div className="suggestions widget-dropdown position-absolute mx-auto bg-white">
            <em>There are no results matching your search criteria.</em>
          </div>
        );
      }
    }

    return (
      <div
        className={`position-relative d-flex align-items-center search-wrap ms-lg-4 transition-500 ${
          userInput !== "" || this.state.expandInput || this.props.openSearch
            ? "flex-lg-fill active-search bg-white"
            : ""
        }`}
        id="search-wrap"
      >
        {userInput == "" ? (
          <span className="d-flex align-items-center position-absolute search-icon" id="search-icon">
            <img src="/img/svg/icon-search.svg" alt="Search" />
            &nbsp;
          </span>
        ) : (
          <React.Fragment>
            <span
              className="d-flex align-items-center position-absolute search-icon"
              onClick={clearSearch}
              id="clearSearch"
            >
              <img src="/img/svg/icon-close-search-grey.svg" alt="Clear search" />
            </span>
          </React.Fragment>
        )}
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClick={handleInputClick}
          onBlur={handleOutsideclick}
          value={userInput}
          className="border-0 search-input py-1 px-5 text-s text-mode-dark h-100 bg-transparent w-100"
          name="search-query"
          placeholder={this.props.texts.searchPlaceholder}
          aria-describedby="search-icon"
          aria-label="Search"
          autoComplete={"off"}
          id={"nav-autocomplete-search"}
        />
        {suggestionsListComponent}
      </div>
    );
  }
}

export default Autocomplete;
