import React from "react";

export const Select = ({
  options,
  selected,
  className,
  mt,
  label,
  onSelect = () => null,
  placeholder,
  customOptions,
  name,
  required
}) => {
  const optionsSelect = options ? options : [];
  
  function selectOptions() {
    return placeholder === "Choose promoter" ? (
      <optgroup label="Swedish Cars">
        {customOptions ||
          optionsSelect.map((item) => {
            return (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            );
          })}
      </optgroup>
    ) : (
      <>
        {customOptions ||
          optionsSelect.map((item) => {
            return (
              <option key={item._id} value={item._id}>
                {item.name}
              </option>
            );
          })}
      </>
    );
  }
  return (
    <>
      {label ? (
        <>
          <label htmlFor={`${label}Input`} className="form-label">
            {label}:
          </label>
          <select
            id={"select"}
            onChange={onSelect}
            className={`form-select form-select-lg ${className}`}
            aria-label=""
            value={selected}
            name={name}
            required={required}
          >
            <option value="">{placeholder}</option>
            {selectOptions()}
          </select>
        </>
      ) : (
        <>
          <select
            id={"select"}
            onChange={onSelect}
            className={`form-select form-select-lg ${className}`}
            aria-label="Default select example"
            value={selected}
            name={name}
            required={required}
          >
            <option value="">{placeholder}</option>
            {selectOptions()}
          </select>
        </>
      )}
    </>
  );
};
