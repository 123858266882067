import "./CalendarCellEvent.scss";

export const CalendarCellEvent = ({ event, selectedEvent, onClick }) => {
  const backgroundColor = {
    attending: 'green',
    interested: 'yellow',
    "love-it": 'pink'
  }
  
  return (
    <button
      type="button"
      className={`calendar-cell-event marker-${backgroundColor[event.response]} ${event.id === selectedEvent?.id ? "active" : ""}`}
      onClick={onClick}
    >
      <span>{event.title}</span>
    </button>
  );
};
