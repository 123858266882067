import React from "react";

export const WhatIsPromoter = () => {
  return (
    <div className="alert alert-success">
      <div className="col-12">
        <label className="col-form-label col-form-label-sm">
          <strong className={"text-xl text-[#055160]"}>What exactly is a promoter?</strong>
          <br />
          <small className={"text-lg text-[#055160]"}>
            Within Plentix a promoter is an event organizer (e.g bar, gallery, artist, band, etc.) <br /> which can
            promote multiple events.
          </small>
        </label>
      </div>
    </div>
  );
};
