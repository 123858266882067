import { useEffect, useState } from "react";
import { patchTickets } from "../../../services/ticketsService";

import "./TicketsSold.scss";

const queryDataService = [
  {
    order: 1,
    name: "EventTime",
    email: "george@abv.bg",
    price: "10",
    ticket: 6,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 2,
    name: "party",
    email: "maria@abv.bg",
    price: "13",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 3,
    name: "EventTime",
    email: "nick@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 4,
    name: "party",
    email: "ivan@abv.bg",
    price: "10",
    ticket: 8,
    security: "bwfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 5,
    name: "George",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 6,
    name: "George11",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "cwfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 1,
    name: "EventTime",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "nwfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 2,
    name: "party",
    email: "maria@abv.bg",
    price: "13",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 3,
    name: "EventTime",
    email: "nick@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 4,
    name: "party",
    email: "ivan@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 5,
    name: "George",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 6,
    name: "George11",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 1,
    name: "EventTime",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 2,
    name: "party",
    email: "maria@abv.bg",
    price: "13",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 3,
    name: "EventTime",
    email: "nick@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 4,
    name: "party",
    email: "ivan@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 5,
    name: "George",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 6,
    name: "George11",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 1,
    name: "EventTime",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 2,
    name: "party",
    email: "maria@abv.bg",
    price: "13",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 3,
    name: "EventTime",
    email: "nick@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 4,
    name: "party",
    email: "ivan@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 5,
    name: "George",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 6,
    name: "George11",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 1,
    name: "EventTime",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 2,
    name: "party",
    email: "maria@abv.bg",
    price: "13",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 3,
    name: "EventTime",
    email: "nick@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 4,
    name: "party",
    email: "ivan@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 5,
    name: "George",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  },
  {
    order: 6,
    name: "George11",
    email: "george@abv.bg",
    price: "10",
    ticket: 5,
    security: "awfad4fds",
    date: "	Sep 16 2022 11:25"
  }
];

const tableStyle = `
.checkbox {
  min-height: 18px;
  padding-left: 18px;
}
label input[type="checkbox"].custom,
label input[type="radio"].custom {
  display: none;
}
input[type="checkbox"] {
  float: left;
  margin-left: -18px;
}
label input[type="checkbox"].custom + span {
  background: url(#{$domain}/img/web-v2/common/custom-checkbox.png);
  background-size: 14px 26px;
  cursor: pointer;
  display: inline-block;
  height: 13px;
  margin-right: 4px;
  vertical-align: -2px;
  width: 14px;
}
label input[type="checkbox"].custom:checked + span,
label input[type="radio"].custom:checked + span {
  background-position: bottom left;
}
.dataTables_wrapper {
  overflow: hidden;
  position: relative;
}
.dataTables_wrapper .dataTables_length {
  color: #888;
  float: left;
  padding: 10px 0px 10px 10px;
  select {
    margin: 0px 5px 0px 0px;
    width: 60px;
  }
}
.dataTables_wrapper .dataTables_filter {
  color: #888;
  float: right;
  padding: 10px 10px 10px 0px;
  input {
    width: 238px;
  }
}
table.dataTable {
  background: #ffffff;
  border-bottom: 4px solid #10345d;
  border-top: 4px solid #10345d;
  font-size: 12px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.table > thead {
  vertical-align: bottom;
}
table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}
table.dataTable thead th.sorting,
table.dataTable thead th.sorting_asc,
table.dataTable thead th.sorting_desc {
  cursor: pointer;
}
table.dataTable thead th {
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;
  height: 50px;
}
.table thead th {
  vertical-align: bottom;
}
table.dataTable thead th.sorting_desc:after {
  border-color: transparent transparent #ae102e transparent;
  border-style: solid;
  border-width: 0px 5px 6px 5px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  content: "";
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 1px;
  border-color: transparent transparent #12982b transparent;
  border-style: solid;
  border-width: 0px 5px 6px 5px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: -webkit-transform 0.1s ease 0s;
  -moz-transition: -moz-transform 0.1s ease 0s;
  transition: transform 0.1s ease 0s;
}
table.dataTable tbody tr td {
  height: 40px;
  text-align: center;
}
ul.token-list {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
ul.token-list li {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  display: inline-block;
  height: 22px;
  line-height: 22px;
  margin: 0px 3px 3px 0px;
  padding-left: 10px;
  vertical-align: middle;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: 1px 1px 0px 0px rgb(0 0 0 / 2%);
  -moz-box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.02);
  box-shadow: 1px 1px 0px 0px rgb(0 0 0 / 2%);
}
ul.token-list li span:last-child {
  padding-right: 10px;
}
ul.token-list li span {
  display: block;
  float: left;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table th,
.table td {
  padding: 8px;
  line-height: 18px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #d2d2d2;
}
label input[type="checkbox"].circular,
label input[type="radio"].circular {
  display: none;
}
table.dataTable tbody tr td.sorting_1 {
  background: rgba(71, 211, 95, 0.1) !important;
}
label input[type="checkbox"].circular + span,
label input[type="radio"].circular + span {
  background: #ffffff;
  border: 1px solid #d2d2d2;
  display: block;
  float: left;
  height: 16px;
  vertical-align: 0px;
  width: 16px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
label input[type="checkbox"].circular:checked + span, label input[type="radio"].circular:checked + span {
  background: url(#{$domain}/img/web-v2/common/tick-pink.jpg) center center;
  background-size: 20px 20px;
  border: none;
  height: 18px;
  width: 18px;
}
label input[type="checkbox"].circular:checked + span, label input[type="radio"].circular:checked + span {
  background-image: url(#{$domain}/img/web-v2/common/tick-pink.jpg);
}
table.dataTable thead th.sorting_desc:after {
  border-color: transparent transparent #ae102e transparent;
  border-style: solid;
  border-width: 0px 5px 6px 5px;
  height: 0px;
  width: 0px;
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
table.dataTable tbody tr:nth-child(even) {
  background: #f8f8f8;
}
thead.table-tickets > tr > th{
  text-align: center;
}`;

export const TicketsSold = ({ tickets, getDataEvents }) => {
  const [modifyTicket, setModifyTicket] = useState([]);
  const [queryData, setQueryData] = useState(modifyTicket);
  const [currentPage, setCurrentPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const [rows, setRows] = useState(10);
  const [windowPageButtons, setWindowPageButtons] = useState(3);
  const [search, setSearch] = useState("");
  const [isEmailShow, setIsEmailShow] = useState(true);

  useEffect(() => {
    if (tickets?.length > 0) {
      let currentTicket = [];

      tickets.forEach((el) => {
        el.dateSold = new Date(el.createdAt)
          .toLocaleString("default", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false
          })
          .split(",")
          .join("");
        currentTicket.push(el);
      });
      setModifyTicket(currentTicket);
      setQueryData(currentTicket);
    }
  }, [tickets]);

  useEffect(() => {
    buildTable(queryDataService);
  }, [currentPage, rows]);

  useEffect(() => {
    let dataArray = modifyTicket.filter((element) => {
      if (element?.order.toString().includes(search.toString())) {
        return true;
      } else if (element?.name.toString().includes(search.toString())) {
        return true;
      } else if (element?.email.toString().includes(search.toString())) {
        return true;
      } else if (element?.price.toString().includes(search.toString())) {
        return true;
      } else if (element?.number.toString().includes(search.toString())) {
        return true;
      } else if (element?.dataSold.toString().includes(search.toString())) {
        return true;
      }
    });
    buildTable(dataArray);
  }, [search]);

  const buildTable = (dataArray) => {
    let data = pagination(dataArray, currentPage, rows);
    setQueryData(data.querySet);
    setAllPages(data.pages);
  };

  const pagination = (querySet, page, rows) => {
    let trimStart = (page - 1) * rows;
    let trimEnd = page * rows;
    let trimmedData = querySet.slice(trimStart, trimEnd);
    let pages = Math.ceil(querySet.length / rows);

    return {
      querySet: trimmedData,
      pages: pages
    };
  };

  const showPagesLength = (pages) => {
    const previousBtn = document.querySelector(".previous-btn");
    const nextBtn = document.querySelector(".next-btn");
    let maxLeft = currentPage - Math.floor(windowPageButtons / 2);
    let maxRight = currentPage + Math.floor(windowPageButtons / 2);

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = windowPageButtons;
    }
    if (maxRight > pages) {
      maxLeft = pages - (windowPageButtons - 1);
      maxRight = pages;

      if (maxLeft < 1) {
        maxLeft = 1;
      }
    }

    let numbOfButtons = new Array(allPages).fill(0).map((_, index) => {
      if (maxLeft <= index + 1 && maxRight >= index + 1) {
        if (currentPage === index + 1) {
          return (
            <li
              key={index + 1}
              className="page-item active"
              onClick={(e) => {
                e.preventDefault();
                return setCurrentPage(index + 1);
              }}
            >
              <a className="page-link" href="#">
                {index + 1}
              </a>
            </li>
          );
        } else {
          return (
            <li
              key={index + 1}
              className="page-item"
              onClick={(e) => {
                e.preventDefault();
                return setCurrentPage(index + 1);
              }}
            >
              <a className="page-link" href="#">
                {index + 1}
              </a>
            </li>
          );
        }
      }
    });

    if (currentPage === 1) {
      previousBtn?.classList.add("disabled");
    } else {
      previousBtn?.classList.remove("disabled");
    }

    if (currentPage === allPages) {
      nextBtn?.classList.add("disabled");
    } else {
      nextBtn?.classList.remove("disabled");
    }
    return numbOfButtons;
  };

  const lowerPage = (e) => {
    e.preventDefault();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const hightPage = (e) => {
    e.preventDefault();
    if (currentPage < allPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const sortingColumn = (e) => {
    e.preventDefault();

    addArrowOfSorting(e);
    highlightColumn(e);
  };

  const sortFunction = (sortBy, DOMElement, property) => {
    if (sortBy === "number") {
      if (Array.from(DOMElement.target.classList).includes("sorting_desc")) {
        setQueryData((element) => {
          let copyElement = [...element];
          let sortElement = copyElement.sort((a, b) => parseFloat(b[property]) - parseFloat(a[property]));
          return sortElement;
        });
      } else {
        setQueryData((element) => {
          let copyElement = [...element];
          let sortElement = copyElement.sort((a, b) => parseFloat(a[property]) - parseFloat(b[property]));
          return sortElement;
        });
      }
    } else {
      if (Array.from(DOMElement.target.classList).includes("sorting_desc")) {
        setQueryData((element) => {
          let copyElement = [...element];
          let sortElement = copyElement.sort((a, b) => b[property].localeCompare(a[property]));
          return sortElement;
        });
      } else {
        setQueryData((element) => {
          let copyElement = [...element];
          let sortElement = copyElement.sort((a, b) => a[property].localeCompare(b[property]));

          return sortElement;
        });
      }
    }
  };

  const highlightColumn = (e) => {
    const element = document.querySelectorAll("tbody.table-tickets > tr > td.sorting_1");
    element.forEach((el) => el.classList.remove("sorting_1"));

    if (Array.from(e.target.classList).includes("td-1")) {
      const elements = document.querySelectorAll("tbody.table-tickets > tr > td.td-1");
      elements.forEach((el) => el.classList.add("sorting_1"));
      sortFunction("number", e, "order");
    } else if (Array.from(e.target.classList).includes("td-2")) {
      const elements = document.querySelectorAll("tbody.table-tickets > tr > td.td-2");
      elements.forEach((el) => el.classList.add("sorting_1"));
      sortFunction("string", e, "name");
    } else if (Array.from(e.target.classList).includes("td-3")) {
      const elements = document.querySelectorAll("tbody.table-tickets > tr > td.td-3");
      elements.forEach((el) => el.classList.add("sorting_1"));
      sortFunction("number", e, "price");
    } else if (Array.from(e.target.classList).includes("td-4")) {
      const elements = document.querySelectorAll("tbody.table-tickets > tr > td.td-4");
      elements.forEach((el) => el.classList.add("sorting_1"));
      sortFunction("number", e, "ticket");
    } else if (Array.from(e.target.classList).includes("td-5")) {
      const elements = document.querySelectorAll("tbody.table-tickets > tr > td.td-5");
      elements.forEach((el) => el.classList.add("sorting_1"));
      sortFunction("string", e, "security");
    } else if (Array.from(e.target.classList).includes("td-6")) {
      const elements = document.querySelectorAll("tbody.table-tickets > tr > td.td-6");
      elements.forEach((el) => el.classList.add("sorting_1"));
    } else if (Array.from(e.target.classList).includes("td-7")) {
      const elements = document.querySelectorAll("tbody.table-tickets > tr > td.td-7");
      elements.forEach((el) => el.classList.add("sorting_1"));
    } else if (Array.from(e.target.classList).includes("td-8")) {
      const elements = document.querySelectorAll("tbody.table-tickets > tr > td.td-8");
      elements.forEach((el) => el.classList.add("sorting_1"));
    }
  };

  const addArrowOfSorting = (e) => {
    if (Array.from(e.target.classList).includes("sorting_asc")) {
      e.target.classList.remove("sorting_asc");
      e.target.classList.add("sorting_desc");
    } else if (Array.from(e.target.classList).includes("sorting_desc")) {
      e.target.classList.remove("sorting_desc");
      e.target.classList.add("sorting_asc");
    } else if (Array.from(e.target.classList).includes("sorting")) {
      const names = document.querySelectorAll("thead.table-tickets > tr > th");
      names.forEach((element) => {
        if (
          Array.from(element.classList).includes("sorting_asc") ||
          Array.from(element.classList).includes("sorting_desc")
        ) {
          element.classList.remove("sorting_desc");
          element.classList.remove("sorting_asc");
          element.classList.add("sorting");
        }
      });
      e.target.classList.add("sorting_asc");
    }
  };

  const setDateUsed = async (ticketId) => {
    let currentTicket = queryData.find((el) => el._id == ticketId);
    if (currentTicket) {
      const currentData = new Date()
        .toLocaleString("default", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: false
        })
        .split(",")
        .join("");

      await patchTickets({}, ticketId, { used: !currentTicket.used, usedAt: !currentTicket.used ? currentData : "" });
      getDataEvents();
    }
  };

  function tableToCSV() {
    const header = {
      order: "Order",
      name: "Name:",
      email: "Email",
      price: "Price",
      tickets: "Tickets",
      security: "Security",
      "data sold": "Data sold",
      used: "Used",
      "data used": "Data used"
    };
    let copyQueryDataService = [...modifyTicket];
    copyQueryDataService.unshift(header);
    let table = [];
    for (let i = 0; i < copyQueryDataService.length; i++) {
      const rows = Object.values(copyQueryDataService[i]);
      const rowsString = rows.join(",");
      table.push(rowsString);
    }
    table = table.join("\n");
    downloadCSVFile(table);
  }

  function downloadCSVFile(csv_data) {
    let CSVFile = new Blob([csv_data], {
      type: "text/csv"
    });
    let temp_link = document.createElement("a");

    temp_link.download = "GfG.csv";
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    temp_link.style.display = "none";
    document.body.appendChild(temp_link);

    temp_link.click();
    document.body.removeChild(temp_link);
  }

  function PrintTable() {
    var style = "<style>";
    style = style + tableStyle;
    style = style + "</style>";

    var tab = document.querySelector(".table-tickets-sold");
    var win = window.open("", "", "height=700,width=700");
    win.document.write(style);
    win.document.write(tab.outerHTML);
    win.document.close();
    win.print();
  }

  return (
    <>
      <div className="row aa">
        <div className="sold-tickets col12">
          <header>
            <h1>Sold tickets</h1>
            <div className="buttons">
              <a
                className="btn rounded btn-inverse"
                href="/event/tickets/export/?id=30&amp;method=csv"
                target="_blank"
                onClick={tableToCSV}
              >
                <i className="fad fa-download"></i> <strong>CSV</strong>
              </a>
              <a className="btn btn-inverse rounded" href="#" target="_blank" onClick={PrintTable}>
                <strong>Print list</strong>
              </a>
            </div>
            <div className="checkbox pt-3">
              <label>
                <input
                  className="custom rounded"
                  defaultChecked={!isEmailShow}
                  onChange={() => setIsEmailShow(!isEmailShow)}
                  id="toggle-information"
                  type="checkbox"
                  placeholder="Search tickets, codes, dates, etc."
                />
                <span></span>
                <span></span>
                Display information in table{" "}
              </label>
            </div>
          </header>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <div id="sold-tickets_wrapper" className="dataTables_wrapper no-footer">
              <div className="dataTables_length" id="sold-tickets_length">
                <label>
                  <select
                    name="sold-tickets_length"
                    aria-controls="sold-tickets"
                    className=""
                    value={rows}
                    onChange={(e) => setRows(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="1000000">All</option>
                  </select>{" "}
                  records per page
                </label>
              </div>
              <div id="sold-tickets_filter" className="dataTables_filter">
                <label>
                  Search:
                  <input
                    type="search"
                    className=""
                    aria-controls="sold-tickets"
                    defaultValue={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </label>
              </div>
              <table
                className="table table-hover dataTable no-footer table-tickets-sold"
                id="sold-tickets"
                role="grid"
                aria-describedby="sold-tickets_info"
              >
                <thead className="table-tickets">
                  <tr role="row">
                    <th
                      className="d-none d-sm-table-cell sorting td-1"
                      tabIndex="0"
                      aria-controls="sold-tickets"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Order:: activate to sort column ascending"
                      style={{ width: "80.5px" }}
                      onClick={sortingColumn}
                    >
                      Order:
                    </th>
                    <th
                      className="ticket-type d-none d-sm-table-cell sorting td-2"
                      tabIndex="0"
                      aria-controls="sold-tickets"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Tickets type + Information:: activate to sort column ascending"
                      style={{ width: "302.859px" }}
                      onClick={sortingColumn}
                    >
                      Tickets type + Information:
                    </th>
                    <th
                      className="d-none d-md-table-cell sorting td-3"
                      tabIndex="0"
                      aria-controls="sold-tickets"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Price:: activate to sort column ascending"
                      style={{ width: "72.7031px" }}
                      onClick={sortingColumn}
                    >
                      Price:
                    </th>
                    <th
                      className="sorting td-4"
                      tabIndex="0"
                      aria-controls="sold-tickets"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Ticket:: activate to sort column ascending"
                      style={{ width: "84.609px" }}
                      onClick={sortingColumn}
                    >
                      Ticket:
                    </th>
                    <th
                      className="sorting td-5"
                      tabIndex="0"
                      aria-controls="sold-tickets"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Security:: activate to sort column ascending"
                      style={{ width: "124.047px" }}
                      onClick={sortingColumn}
                    >
                      Security:
                    </th>
                    <th
                      className="d-none d-md-table-cell sorting td-6"
                      tabIndex="0"
                      aria-controls="sold-tickets"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Date sold:: activate to sort column ascending"
                      style={{ width: "186.984px" }}
                      onClick={sortingColumn}
                    >
                      Date sold:
                    </th>
                    <th
                      className="sorting td-7"
                      tabIndex="0"
                      aria-controls="sold-tickets"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Used:: activate to sort column ascending"
                      style={{ width: "72.4688px" }}
                      onClick={sortingColumn}
                    >
                      Used:
                    </th>
                    <th
                      className="sorting td-8"
                      tabIndex="0"
                      aria-controls="sold-tickets"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Date used:: activate to sort column ascending"
                      style={{ width: "127.828px" }}
                      aria-sort="descending"
                      onClick={sortingColumn}
                    >
                      Date used:
                    </th>
                  </tr>
                </thead>
                <tbody className="table-tickets">
                  {queryData.map((element) => (
                    <tr key={element._id} role="row" className="even">
                      <td className="d-none d-sm-table-cell td-1">{element.order}</td>
                      <td className="d-none d-sm-table-cell ticket-type padding td-2" data-name="eventTime">
                        <span className="color" style={{ background: "#005C11" }}></span>{" "}
                        <span className="ellipsis">{element.name}</span>
                        {isEmailShow ? (
                          <ul className="token-list">
                            <li>
                              <span>{element.email}</span>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="price d-none d-md-table-cell td-3" data-price="10.00">
                        {element.price}{" "}
                      </td>
                      <td className="td-4 ">{element.number}</td>
                      <td className="td-5">{element.code}</td>
                      <td className="d-none d-md-table-cell td-6" data-date="1663316702">
                        {element.dateSold}
                      </td>
                      <td className="check td-7">
                        <label>
                          <input
                            className="circular"
                            type="checkbox"
                            value="31"
                            defaultChecked={element.used ? "checked" : ""}
                            onChange={() => setDateUsed(element._id)}
                          />
                          <span></span>
                        </label>
                      </td>
                      <td className="date-checked td-8" data-date="0">
                        {element.usedAt === undefined || null ? "-" : element.usedAt}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              allPages
              <div className="d-flex justify justify-content-between align-items-center">
                <div className="dataTables_info" id="sold-tickets_info" role="status" aria-live="polite">
                  Page <strong>{currentPage}</strong> of <strong>{allPages}</strong>
                </div>
                <nav aria-label="...">
                  <ul className="pagination">
                    <li className="page-item previous-btn" onClick={lowerPage}>
                      <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">
                        Previous
                      </a>
                    </li>
                    {showPagesLength(allPages)}

                    <li className="page-item next-btn" onClick={hightPage}>
                      <a className="page-link" href="#">
                        Next
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
