import "./Testimonial.scss";

export const Testimonial = () => {
  return (
    <div className="flex-container testimonial middle fadefrombottom">
      <img
        src="/img/organizers/testimonial-circle-irina-1.png"
        loading="lazy"
        width="200"
        alt="Irina's Quote about Plentix &amp; Evedo"
        className="image-8"
      />
      <p className="paragraph-testimonial">
        Plentix helps us achieve our goals &amp; reach more people. I love it! It’s user-friendly, the support is very
        fast so Plentix makes it easier!
      </p>
      <div className="div-block-9">
        <div className="testimonial-name">Irina&nbsp;Altanova</div>
        <div className="testimonial-title">
          <p>Administrative </p>
          <p>coordinator @ &nbsp;MOVE.BG</p>
        </div>
      </div>
    </div>
  );
};
