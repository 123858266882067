import fakeFriends from "../../data/friends.json";
import fakeSuggestedFriends from "../../data/suggestedFriends.json";
import { useEffect, useState } from "react";
import { getUser } from "../../services/userService";
import { List } from "./List.jsx";
import { Menu } from "./Menu.jsx";
import { Search } from "./Search.jsx";
import { Suggested } from "./Suggested.jsx";

export const Friends = () => {
  const [friends, setFriends] = useState([]);
  const [suggested, setSuggested] = useState(fakeSuggestedFriends);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState("friends");

  useEffect(() => {
    getUserRequest();
  }, []);

  function getUserRequest() {
    getUser(localStorage.getItem("userId"))
      .then((result) => {
        if (result?.friends?.length > 0) {
          const allFriends = result.friends.map((el) => {
            return {
              id: el.friend._id,
              names: `${el.friend.firstName} ${el.friend.lastName ? el.friend.lastName : ""}`,
              location: `Sofia, Bulgaria`,
              image: el.friend.image ? el.friend.image : "https://local.evedo.co/img/web/profile-no-image-medium.jpg",
              status: el.status
            };
          });
          setFriends(allFriends);
        } else {
          setFriends([]);
        }
      })
      .catch((err) => console.error(err));
  }

  return (
    <>
      <div className="container text-dark-blue">
        <div className="row">
          <h1>Friends</h1>
        </div>
      </div>
      <section className="container pt-3" style={{ maxWidth: "1220px", background: "#F6F5FA" }}>
        <Menu
          friendsNumber={friends.filter((user) => user.status === "friend").length}
          tab={tab}
          setTab={setTab}
        />
        <Search search={search} setSearch={setSearch} hidden={tab !== "friends" || friends.length === 0} />
        {tab === "friends" ? (
          <List search={search} friends={friends} setFriends={setFriends} getUserRequest={getUserRequest} />
        ) : (
          <Suggested suggested={suggested} setSuggested={setSuggested} setFriends={setFriends} />
        )}
      </section>
    </>
  );
};
