import "./Message.scss";

const formatDate = (date) => {
  date = new Date(date);
  const month = date.toLocaleString("default", { month: "short" });
  const day = date.getDate();
  const hour = date.getHours();
  const minutes = date.getMinutes();

  return `${month} ${day} ${hour < 10 ? `0${hour}` : hour}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

export const Message = ({ message }) => {
  const userId = localStorage.getItem("userId");
  return (
    <div className={`msg-wrapper ${message.senderId === userId ? "out" : "in"}`}>
      {/* <strong>{message.user}</strong> */}
      <p>{message.text}</p>
      <time>
        <span>{formatDate(message.updatedAt)}</span>
      </time>
    </div>
  );
};
