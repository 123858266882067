import React from "react";
import { Form } from "./Form.jsx";

export const New = () => {
  return (
    <div className="container">
      <Form />
    </div>
  );
};
