import { Link } from "react-router-dom";

import ProfileImage from "../../assets/profile-image.jpg";

import "./Attendee.scss";

export const Attendee = ({ user }) => {
  return (
    <Link to={`/profile/${user._id}`} className="attender-card">
      <img className="object-cover" alt="names" src={user.image ? user.image : ProfileImage} />
    </Link>
  );
};
