import { get, post, patch, deleteReq } from "../helpers/fetcher";

// Query is array of object with one key and value property
export const getEvents = async (query) => {
  let endpoint;

  if (query?.length > 0) {
    endpoint = `/events`;
    query.forEach((obj, i) => {
      const keyArray = Object.keys(obj);
      let key = keyArray[0];

      if (obj[key] != undefined || null) {
        if (i == 0) {
          endpoint = endpoint.concat(`?${key}=${obj[key]}`);
        } else {
          endpoint = endpoint.concat(`&${key}=${obj[key]}`);
        }
      }
    });
  } else {
    endpoint = `/events`;
  }

  return get(endpoint);
};

export const getEvent = async (id) => {
  const endpoint = `/events/${id}`;

  return get(endpoint);
};

export const getEventDiscounts = async (id) => {
  const endpoint = `/events/${id}/discounts`;

  return get(endpoint);
};

export const getEventFeatured = async () => {
  const endpoint = `/events/featured`;

  return get(endpoint);
};

export const getEventOnline = async () => {
  const endpoint = `/events/online`;

  return get(endpoint);
};

export const postEvents = async (user, body) => {
  const endpoint = `/events`;
  // const header = {user};
  const header = {};
  return post(endpoint, header, body);
};

export const postCancelEvents= async (id, body) => {
  const endpoint = `/events/${id}/canceled`;
  const token = localStorage.getItem('accessToken')
  const header = { "Content-Type": "application/json", Authorization: token };

  return post(endpoint,header,body);
};

export const patchEvents = async (user, id, body) => {
  const endpoint = `/events/${id}`;
  const header = { user };
  // const header = { user, "Content-Type":"multipart/form-data" };

  return patch(endpoint, header, body);
};

export const patchEventsSettings = async (user, id, body) => {
  const endpoint = `/events/${id}/settings`;
  const header = { user };

  return patch(endpoint, header, body);
};

export const patchEventsCategory = async (user, event_id, category_id, body) => {
  const endpoint = `/events/${event_id}/categories/${category_id}`;
  const header = { user };

  return patch(endpoint, header, body);
};

export const deleteEvents = async (user, eventsId) => {
  const endpoint = `/events/${eventsId}`;
  const header = { user };

  return deleteReq(endpoint, header);
};

export const patchEventsRsvp = async (id, data) => {
  const endpoint = `/events/${id}/rsvp`;
  const header = { "Content-Type": "application/json" };

  return patch(endpoint, header, data);
};
