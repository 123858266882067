import "./ResultsPagination.scss";

const ResultsPagination = (props) => {
  return (
    <nav className={`results-pagination mt-3 ${props.totalPages <= 1 ? "d-none" : ""}`}>
      <ul className="pagination">
        <li className="d-flex mx-1">
          <a
            className={`pagination-button pagination-button--prev px-3 py-2 rounded-1 text-mode-dark ${
              props.currentPage === 1 ? "pagination-button--disabled" : ""
            }`}
            href="#"
            aria-label="Previous"
            onClick={
              props.currentPage > 1 ? (event) => props.setPage(props.currentPage - 1) : (e) => e.preventDefault()
            }
          >
            <i className="fal fa-angle-left te" aria-hidden="true"></i>
          </a>
        </li>
        {props.isMobile ? (
          <>
            {/* current page is last or second/third last */}
            {props.currentPage > 3 && props.currentPage + 1 >= props.totalPages ? (
              <>
                <li className={`d-flex mx-1`}>
                  <a
                    className={`pagination-button px-3 py-2 rounded-1 text-mode-dark`}
                    href="#"
                    onClick={(event) => props.setPage(1)}
                  >
                    {1}
                  </a>
                </li>
                <span className="d-flex align-items-center"> ... </span>
              </>
            ) : (
              ""
            )}
            {/* current page is not first */}
            {props.currentPage !== 1 ? (
              <li className={`d-flex mx-1`}>
                <a
                  className={`pagination-button px-3 py-2 rounded-1 text-mode-dark`}
                  href="#"
                  onClick={(event) => props.setPage(props.currentPage - 1)}
                >
                  {props.currentPage - 1}
                </a>
              </li>
            ) : (
              ""
            )}
            <li className={`d-flex mx-1`}>
              <a
                className={`pagination-button px-3 py-2 rounded-1 pagination-button--current`}
                href="#"
                onClick={(event) => props.setPage(props.currentPage)}
              >
                {props.currentPage}
              </a>
            </li>
            {/* current page is not last */}
            {props.totalPages !== props.currentPage ? (
              <li className={`d-flex mx-1`}>
                <a
                  className={`pagination-button px-3 py-2 rounded-1 text-mode-dark`}
                  href="#"
                  onClick={(event) => props.setPage(props.currentPage + 1)}
                >
                  {props.currentPage + 1}
                </a>
              </li>
            ) : (
              ""
            )}
            {/* current page is first but not last */}
            {props.currentPage === 1 && props.currentPage !== props.totalPages ? (
              <li className={`d-flex mx-1`}>
                <a
                  className={`pagination-button px-3 py-2 rounded-1 text-mode-dark`}
                  href="#"
                  onClick={(event) => props.setPage(props.currentPage + 2)}
                >
                  {props.currentPage + 2}
                </a>
              </li>
            ) : (
              ""
            )}
            {/* current page is not last or second/third last */}
            {props.currentPage + 2 < props.totalPages && props.currentPage !== 1 ? (
              <>
                <span className="d-flex align-items-center"> ... </span>
                <li className={`d-flex mx-1`}>
                  <a
                    className={`pagination-button px-3 py-2 rounded-1 text-mode-dark`}
                    href="#"
                    onClick={(event) => props.setPage(props.totalPages)}
                  >
                    {props.totalPages}
                  </a>
                </li>
              </>
            ) : props.currentPage + 2 == props.totalPages ? (
              <li className={`d-flex mx-1`}>
                <a
                  className={`pagination-button px-3 py-2 rounded-1 text-mode-dark`}
                  href="#"
                  onClick={(event) => props.setPage(props.totalPages)}
                >
                  {props.totalPages}
                </a>
              </li>
            ) : (
              ""
            )}
          </>
        ) : (
          [...Array(props.totalPages)].map((e, i) => (
            <li key={"pagination-item-" + i} className={`d-flex mx-1`}>
              <a
                className={`pagination-button px-3 py-2 rounded-1 ${
                  props.currentPage == i + 1 ? "pagination-button--current" : "text-mode-dark"
                }`}
                href="#"
                onClick={(event) => props.setPage(i + 1)}
              >
                {i + 1}
              </a>
            </li>
          ))
        )}
        <li className="d-flex mx-1">
          <a
            className={`pagination-button pagination-button--next px-3 py-2 rounded-1 text-mode-dark ${
              props.currentPage === props.totalPages ? "pagination-button--disabled" : ""
            }`}
            href="#"
            aria-label="Next"
            onClick={
              props.currentPage < props.totalPages
                ? (event) => props.setPage(props.currentPage + 1)
                : (e) => e.preventDefault()
            }
          >
            <i className="fal fa-angle-right" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default ResultsPagination;
