import React from "react";
import Card from "./EventCard";

import "./NearbyEvents.scss";

const NearbyEvents = (props) => {

  let currentCategory = props.currentCategory;
  let cardsList = props.cards.length
    ? props.cards.map((card, id) => (
        <Card
          key={id}
          cardTexts={props.cardTexts}
          dateShort={card.shortdate}
          picture={card.picture}
          url={card.url}
          type={card.category_id}
          dateStart={`${new Date(card.startAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
          dateEnd={`${new Date(card.endAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
          title={card.what}
          going={card.going}
          featured={card.featured}
          where={card.where}
          price={card.price}
          categories={Object.values(props.categories)}
        />
      ))
    : "";

  return (
    <div className="row">
      <div className="col-12 mt-5 mb-5">
        <div className="row justify-content-center justify-content-md-start">
          <h2 className="title-bilo-m mb-4 col-auto text-dark">{props.content.title}</h2>
        </div>
        <div className="row justify-content-between flex-md-row flex-column align-items-center">
          <div className="location-container d-flex align-items-center flex-column flex-md-row col">
            <div className="my-2 my-lg-0 navbar-main h6 col-12 col-sm-8 col-md-6">
              <div className="input-group overflow-hidden align-items-center location-wrap border-0 w-100">
                <span className="h6 position-absolute location-icon" id="location-icon">
                  <img src="/img/svg/icon-location.svg" alt="Search on Plentix" />
                </span>
                <input
                  onChange={props.change}
                  className="border-0 location-input px-5 w-100"
                  id="location-autocomplete"
                  defaultValue={props.locationDefault}
                  name="location-query"
                  type="location"
                  placeholder={props.content.location_placeholder}
                  aria-describedby="location-icon"
                  aria-label="Location"
                />
              </div>
            </div>
            {props.locationDefault == "" && props.isLogged ? (
              <div className="location-action ms-2 h6 text-dark mb-0">
                {props.content.or}{" "}
                <a href="#" onClick={props.getLocation} className="link-normal link-transition">
                  {props.content.enable_location}
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="more-events col-auto mt-4 mt-md-0">
            <a
              href={`/search/?&categories=${props.catId}`}
              className="link-pink fw-500 d-flex align-items-center mb-0 link-transition"
            >
              {props.content.more} {currentCategory}
              <i className="fal fa-angle-right mb-0 ms-2"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-sm-0">
            <div className="row my-3 flex-wrap">
              {props.cards.length > 0 ? (
                cardsList
              ) : (
                <div className="col-12 h6 text-center py-5">{props.content.no_results}</div>
              )}
              {props.cardsLength !== props.cards.length && props.cards.length !== 0 ? (
                <SkeletonCard content={props.content} loadMore={props.loadCards} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NearbyEvents;

const SkeletonCard = (props) => (
  <div className="card border-0 bg-transparent col-md-4 col-sm-6 mt-4">
    <div className="nearby-card overflow-hidden pb-3">
      <div className="card-bg w-100 d-flex pb-3 align-items-center justify-content-center position-relative overflow-hidden skeleton">
        <a href="#" className="button-ghost link-transition" onClick={props.loadMore}>
          {props.content.load_more}
        </a>
      </div>
      <div className="card-body p-2 d-flex flex-column align-items-start">
        <p className="card-type text-uppercase text-secondary h6 skeleton">skeleton content</p>
        <p className="card-text h6 text-dark d-flex justify-content-between skeleton w-100">skeleton title content</p>
      </div>
    </div>
  </div>
);
