import { GiveATry } from "./GiveATry";
import { Partners } from "./Partners";

import "./Pricing.scss";

export const Pricing = () => {
  const body = document.querySelector("body");
  body.style["background-image"] = "url(https://local.evedo.co/img/organizers/Hero-image.svg)";

  return (
    <div className="container-my-page">
      <div className="hero pricing wf-section">
        <div className="container-main pricing-top">
          <div className="hero-text">
            <div className="label-l highlight-1">simple &amp; transparent pricing</div>
            <h1 className="page-title">
              Simple pricing for organizers!
              <br />
              No Plans. No Fees.
            </h1>
            <h2 className="page-subtitle">
              We have everything you need for free. You only pay a commission if you’re doing paid events. We handle all
              the other taxes and fees.
            </h2>
          </div>
        </div>
      </div>
      <div className="container-main mb-4 ">
        <div className="container-medium flexed d-flex">
          <div
            className="fadefrombottom"
            // style="opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
          >
            <div className="label-m">what you get for free?</div>
            <ul role="list" className="w-list-unstyled">
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-publiclisting.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">Public Listing</div>
              </li>
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-unlimitedtypes.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">Unlimited ticket types</div>
              </li>
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-share.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">Share Functionalities</div>
              </li>
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-embed.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">Embed ticket sales to your website</div>
              </li>
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-customize.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">Customizable checkout forms</div>
              </li>
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-trackanalytics.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">Track live sales analytics</div>
              </li>
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-unlimitedpromoters.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">Unlimited Promoters</div>
              </li>
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-exportcsv.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">Export audiences to CSV</div>
              </li>
              <li className="list-item bigger">
                <img
                  src="https://local.evedo.co/img/organizers/icn-rsvpinfo.svg"
                  loading="lazy"
                  alt=""
                  className="feature-icn"
                />
                <div className="text-block-2">RSVP information on the attendees</div>
              </li>
              <li className="list-item bigger">
                <a
                  data-w-id="48a48529-3a38-fa89-5b22-afcbeec57a22"
                  href="/features"
                  className="link-with-arrow w-inline-block"
                >
                  <div className="text-block-6">View all features</div>
                  <img src="/img/organizers/icn-arrow.svg" loading="lazy" alt="" className="link-arrow" />
                </a>
              </li>
            </ul>
          </div>
          <div className="commision-wrap fadefrombottom">
            <div className="comission-box">
              <h4 className="heading-2">Low commission for paid events only</h4>
              <div className="commission-percent">5%</div>
              <div className="text-block">Including all the fees.</div>
              <div className="example-wrap">
                <div className="text-block-3">i.e. each ticket sold at $100 gets you $95</div>
              </div>
            </div>
            <div className="paragraph-s">* No comission for free events</div>
          </div>
        </div>
      </div>
      {/* 33333333 */}sty
      <div className="container-main fadefrombottom">
        <div className="box-bordered">
          <div className="container-medium move-up">
            <img
              src="/img/organizers/young-business-man-and-woman-with-laptop-working-t-D6HMGWA-1.jpg"
              loading="lazy"
              sizes="(max-width: 479px) 86vw, (max-width: 767px) 91vw, (max-width: 991px) 93vw, 960px"
              srcSet="/img/organizers/young-business-man-and-woman-with-laptop-working-t-D6HMGWA-1-p-500.jpeg 500w, /img/organizers/young-business-man-and-woman-with-laptop-working-t-D6HMGWA-1-p-800.jpeg 800w, /img/organizers/young-business-man-and-woman-with-laptop-working-t-D6HMGWA-1.jpg 1011w"
              alt="young event organizer choosing plentix"
              className="image-header"
            />
            <h2 className="heading-8">
              Get more.
              <br />
              Become a partner
            </h2>
            <div className="flex-container">
              <div className="left-col">
                <p className="paragraph paragraph-l">
                  We are more than just a tool for our partners. Trust starts with understanding. And understanding
                  comes from conversation, not statistics. Become a partner and get the following:
                </p>
                <ul role="list" className="list w-list-unstyled">
                  <li className="list-item-2">
                    <div className="checkmark-outl-ined">
                      <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                    </div>
                    <div>Free Consultation</div>
                  </li>
                  <li className="list-item-2">
                    <div className="checkmark-outl-ined">
                      <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                    </div>
                    <div>Marketing Strategy</div>
                  </li>
                  <li className="list-item-2">
                    <div className="checkmark-outl-ined">
                      <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                    </div>
                    <div>Design, Legal and other services...</div>
                  </li>
                  <li className="list-item-2">
                    <div className="checkmark-outl-ined">
                      <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                    </div>
                    <div>Custom commission</div>
                  </li>
                </ul>
              </div>
              <div className="right-col bg-solid pale-bg">
                <img src="/img/organizers/icn-salesteam.svg" loading="lazy" alt="" />
                <h4 className="heading-4">
                  Any further questions?
                  <br />
                  Contact our sales team at:
                </h4>
                <a href="mailto:sales@plentix.co?subject=Pricing%20Page%20Sales" className="btn-m pale w-inline-block">
                  <div>sales@plentix.co</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-main pt-0 fadefrombottom">
        <div className="container-medium">
          <h3 className="heading-7">Soon to be added paid in-platform features</h3>
          <div className="w-layout-grid grid-5 desktop-3col">
            <div className="box-1 vertical">
              <div className="label-comingsoon move-up">
                <div className="text-block-7">coming soon</div>
              </div>
              <h4>Advertisement in the platform</h4>
              <p className="paragraph mt-0">
                Boost your events visibility and improve your sales with our advertisement tools.
              </p>
            </div>
            <div className="box-1 vertical">
              <div className="label-comingsoon move-up">
                <div className="text-block-7">coming soon</div>
              </div>
              <h4>Crypto Payments</h4>
              <p className="paragraph mt-0">
                Pay for events and get paid from events with any of the most popular crypto currencies out there -
                Bitcoin, Ethereum, EVED and more.
              </p>
            </div>
            <div className="box-1 vertical">
              <div className="label-comingsoon move-up">
                <div>coming soon</div>
              </div>
              <h4>Scheduled Payouts</h4>
              <p className="paragraph mt-0">Transfer your earnings and revenue from events on a regular basis.</p>
            </div>
          </div>
          <Partners />
        </div>
      </div>
      <div className="full-width bg-pale1">
        <h3 className="centered fadefrombottom">Frequently Asked Questions</h3>
        <div className="container-small centered">
          <div className="flex-container">
            <div className="fadefrombottom">
              <div className="w-layout-grid grid">
                <div className="left-aligned">
                  <h4>
                    <strong>Is the 5% commission final or can it be negotiated ?</strong>
                  </h4>
                  <p className="left-aligned">
                    Yes. but we also offer additional marketing packages that will increase the commission 3-8%. For
                    more on the value and services we bring please contact us at&nbsp;
                    <a href="mailto:info@plentix.co?subject=Pricing%20Plentix" className="link-4">
                      info@plentix.co
                    </a>
                    <br />
                  </p>
                </div>
                <div className="left-aligned">
                  <h4>
                    <strong>Will the pricing model change in the future ?</strong>
                  </h4>
                  <p className="left-aligned">
                    With the blockchain implementation that's planned in the near future the commission will be reduced
                    significantly. Stay tuned for that by following Plentix on social media.
                    <br />
                  </p>
                </div>
                <div className="left-aligned">
                  <h4>
                    <strong>What is your refund policy?</strong>
                  </h4>
                  <p className="left-aligned">
                    Plentix can guarantee the refund of most of the events and it's executed within 3-5 business days if
                    the event is cancelled. For some events the organizers set their own refund policies and you can
                    check with them on each event page.
                    <br />
                  </p>
                </div>
                <div className="left-aligned">
                  <h4>
                    <strong>Can I use Plentix for free events? </strong>
                  </h4>
                  <p className="left-aligned">
                    Yes, you can use the full functionality of Plentix for your FREE events. It will be absolutely FREE
                    for you as an organizer.
                    <br />
                  </p>
                </div>
                <div className="left-aligned">
                  <h4>
                    <strong>How do I sell tickets on Plentix?</strong>
                  </h4>
                  <p className="left-aligned">
                    Everyone can register in a few easy steps on the platform. Then create an event for in less than 5
                    minutes and start selling tickets through the platform.
                    <br />
                  </p>
                </div>
                <div className="left-aligned">
                  <h4>
                    <strong>How can I use blockchain to sell tickets? </strong>
                  </h4>
                  <p className="left-aligned">
                    Soon payment methods such as Bitcoin, Ethereum, Litecoin &amp; our own token - EVED will be added.
                    Every organizer will be able to choose which payment methods he wants to add to the checkout. For
                    more questions please contact our team&nbsp;
                    <a href="mailto:blockchain@plentix.co" target="_blank" className="link-5">
                      blockchain@plentix.co
                    </a>
                    <br />
                  </p>
                </div>
              </div>
              <div className="div-block">
                <p className="left-aligned">
                  Don't find your answers here?
                  <br />
                </p>
                <a
                  href="mailto:info@evedo.co?subject=Plentix%20Pricing"
                  className="btn-s secondary left-m w-inline-block"
                >
                  <div>Contact us</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GiveATry />
    </div>
  );
};
