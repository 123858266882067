import fakeEvent from "../../data/eventDetails.json";
import { useState } from "react";
import { Details } from "./Details.jsx";
import { Host } from "./Host.jsx";
import { Image } from "./Image.jsx";
import { RSVP } from "./RSVP.jsx";
import { GetTickets } from "./GetTickets.jsx";
import { SimilarEvents } from "./SimilarEvents.jsx";

export const Events = () => {
  const [event, setEvent] = useState(fakeEvent);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-12">
          {event.image && <Image image={event.image} />}
          <RSVP event={event} setEvent={setEvent} />
          <Details event={event} />

          <div className="row">
            <Host host={event.host} />
            <SimilarEvents similarEvents={event.similarEvents} />
          </div>
        </div>

        <GetTickets event={event} />
      </div>
    </div>
  );
};
