exports.tags = [
  { title: "Academics" },
  { title: "Accounting" },
  { title: "Adventure" },
  { title: "Advertising" },
  { title: "After-party" },
  { title: "Alternative" },
  { title: "Animals" },
  { title: "Art" },
  { title: "Arts" },
  { title: "Autobiographical" },
  { title: "Awareness" },
  { title: "Ballet & Opera" },
  { title: "Basketball" },
  { title: "Beach Sports" },
  { title: "Beauty & Aesthetics" },
  { title: "Beer" },
  { title: "Birthday" },
  { title: "Blues" },
  { title: "Brunch" },
  { title: "Burgers" },
  { title: "Business" },
  { title: "Cars" },
  { title: "Cause" },
  { title: "Charity" },
  { title: "Christmas" },
  { title: "Classical" },
  { title: "Closing" },
  { title: "Cocktail" },
  { title: "Comedy" },
  { title: "Commercial" },
  { title: "Community" },
  { title: "Consumer" },
  { title: "Country" },
  { title: "Craft" },
  { title: "Crafts" },
  { title: "Crowdfunding" },
  { title: "Culture" },
  { title: "Design" },
  { title: "Design & Art" },
  { title: "Dance" },
  { title: "Dinner" },
  { title: "DnB" },
  { title: "Drinks" },
  { title: "Drum & Bass" },
  { title: "Dubstep" },
  { title: "E-sports" },
  { title: "EDM" },
  { title: "Education" },
  { title: "Electronic" },
  { title: "Electronica" },
  { title: "Entertainment" },
  { title: "Event" },
  { title: "Excursion" },
  { title: "Expedition" },
  { title: "Expo" },
  { title: "Extreme" },
  { title: "Fair" },
  { title: "Fashion" },
  { title: "Film" },
  { title: "Finance" },
  { title: "Fitness" },
  { title: "Food" },
  { title: "Food & Drinks" },
  { title: "Football" },
  { title: "Fundraising" },
  { title: "Funk" },
  { title: "Gourmet" },
  { title: "Health" },
  { title: "Health & Wellness" },
  { title: "Helloween" },
  { title: "Hip-hop" },
  { title: "Historical" },
  { title: "Hobby" },
  { title: "Holiday" },
  { title: "House" },
  { title: "Immersive" },
  { title: "Improv" },
  { title: "Indoor" },
  { title: "Indoors" },
  { title: "Information" },
  { title: "Information Technology" },
  { title: "IT" },
  { title: "IT & Technology" },
  { title: "Jazz" },
  { title: "Jungle" },
  { title: "Lifestyle" },
  { title: "Lunch" },
  { title: "Magic & Illusion" },
  { title: "Maraton" },
  { title: "Marketing" },
  { title: "Marketing & Advertising" },
  { title: "Melodrama" },
  { title: "Metal" },
  { title: "Monologue" },
  { title: "Moto & Auto Sports" },
  { title: "Music" },
  { title: "Music & Dance" },
  { title: "Musical" },
  { title: "New Year" },
  { title: "Non-commercial" },
  { title: "Nonprofit" },
  { title: "Olympic" },
  { title: "Online" },
  { title: "Opening" },
  { title: "Outdoor" },
  { title: "Outdoors" },
  { title: "Personal Development" },
  { title: "Pop" },
  { title: "Promotion" },
  { title: "Public Speaking" },
  { title: "R&B" },
  { title: "Race" },
  { title: "Rap" },
  { title: "Rave" },
  { title: "Reception" },
  { title: "Reggae" },
  { title: "Running" },
  { title: "Rythum & Blues" },
  { title: "Science" },
  { title: "Seminar" },
  { title: "Sightseeing" },
  { title: "Soccer" },
  { title: "Software Development" },
  { title: "Solo Sports" },
  { title: "Soul" },
  { title: "Soul & Jazz" },
  { title: "Spirits" },
  { title: "Spiritual" },
  { title: "Sports" },
  { title: "Street" },
  { title: "Strength & Conditioning" },
  { title: "Talent" },
  { title: "Tasting" },
  { title: "Teaching" },
  { title: "Team Sports" },
  { title: "Techno" },
  { title: "Technology" },
  { title: "Tennis" },
  { title: "Theatre & Film Arts" },
  { title: "Trade Show" },
  { title: "Tragedy" },
  { title: "Trance" },
  { title: "Trap" },
  { title: "Triathlon" },
  { title: "Vegan" },
  { title: "Vegetarian" },
  { title: "Volunteer" },
  { title: "Wellness" },
  { title: "Wine" },
  { title: "Workshop" }
];
