import { useState, useEffect } from "react";

import { Welcome } from "./components/Welcome";
import { Preference } from "./components/Preference";
import { Elements } from "./components/Elements";
import { Tags } from "./components/Tags";
import { Success } from "./components/Success";

import { updateUser } from "../../services/userService";

import "./Setup.scss";

export const Setup = ({ userFirstName }) => {
  const [city, setCity] = useState("");
  const [tags, setTags] = useState("");
  const [sliderPages, setSliderPages] = useState(1);
  const [preferenceCategory, setPreferenceCategory] = useState({});
  const body = document.querySelector("body");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (sliderPages === 5) {
      body.style.background = null;
      body.style.background = "#fff";
    } else {
      body.style.background = null;
      body.style.background = "#FEE7F4 ";
    }
  }, [sliderPages]);

  const newPage = () => {
    setSliderPages(sliderPages + 1);
  };

  const viewPage = () => {
    if (sliderPages === 1) {
      return <Welcome newPage={newPage} />;
    } else if (sliderPages === 2) {
      return <Preference newPage={newPage} city={city} setCity={setCity} userFirstName={userFirstName} />;
    } else if (sliderPages === 3) {
      return (
        <Elements
          newPage={newPage}
          setPreferenceCategory={setPreferenceCategory}
          userFirstName={userFirstName}
        />
      );
    } else if (sliderPages === 4) {
      return (
        <Tags
          newPage={newPage}
          tags={tags}
          setTags={setTags}
          updateProfile={updateProfile}
          userFirstName={userFirstName}
        />
      );
    } else if (sliderPages === 5) {
      return <Success />;
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    if (userId) {
      try {
        const data = {
          homeCityId: city,
          tags: tags.split(" "),
          categories: Object.keys(preferenceCategory)
        };
        await updateUser(userId, data);
      } catch (err) {
        console.err(err);
      }
    }
  };

  return (
    <section className="profile-setup bg-transparent mx-auto d-flex h-100 flex-column align-items-center justify-content-center">
      {viewPage()}
    </section>
  );
};
