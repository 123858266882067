import { useState } from "react";
import { Button } from "./Button";
import { Input } from "./Input";
import "./DiscountForm.scss";

export const DiscountForm = ({ setShowModal }) => {
  const [discountCode, setDiscountCode] = useState("");
  const [error, setError] = useState("");

  const applyCode = () => {
    if (discountCode) {
      setError("Discount code is invalid");
    }
  };

  return (
    <div className="p-6 checkout-discount-form">
      <h6 className="form-heading">Add a discount code</h6>
      {/* <div className="p-4 checkout-discount-form modal-content">
      <button className="close-btn" onClick={() => setShowModal(false)}>
        <i className="fa-solid fa-xmark" />
      </button>

      <p className="form-heading">Add a discount code</p> */}
      <Input
        placeholder={"Discount code"}
        value={discountCode}
        onChange={(e) => setDiscountCode(e.target.value)}
      />

      {error && <p className="mt-2 invalid-p">{error}</p>}

      <Button text={"Apply"} className={"mt-2"} onClick={applyCode} />
    </div>
  );
};
