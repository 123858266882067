import { useEffect, useState } from "react";
import { getEvent } from "../../services/eventService";
import { TicketCategoryCard } from "./TicketCategoryCard";
// import ticketCategories from "../../../data/checkoutTicketCategories.json";
import "./TicketsList.scss";

export const TicketsList = ({ selectedTickets, setSelectedTickets }) => {
  const [ticketCategories, setTicketCategories] = useState([]);

  const locationPathSplitted = window.location.pathname.split("/");
  const eventId = locationPathSplitted[2];

  useEffect(() => {
    getEvent(eventId)
      .then((result) => {
        setTicketCategories(result.categories);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="checkout-tickets-list mb-2">
      {ticketCategories.map((ticket) => (
        <TicketCategoryCard
          key={ticket.name}
          ticket={ticket}
          selectedTickets={selectedTickets}
          setSelectedTickets={setSelectedTickets}
        />
      ))}
    </div>
  );
};
