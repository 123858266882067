import { useEffect, useState } from "react";
import { FavouritePromoterCard } from "./FavouritePromoterCard";
import "./FavouritePromoters.scss";

import "./FavouritePromoters.scss";

export const FavouritePromoters = ({ user }) => {
  const [showAll, setShowAll] = useState(false);
  const [promoters, setPromoters] = useState([]);

  useEffect(() => {
    if (user.hosts) {
      setPromoters(user.hosts);
    }
  }, [user]);

  return (
    <div className="col-12 favourite-promoters-wrapper">
      <h1 className="box-header">
        Favourite Promoters
        {promoters.length > 5 && (
          <button type="button" className="all-hosts" onClick={() => setShowAll(!showAll)}>
            {showAll ? "« back to the full profile" : "show all promoters"}
          </button>
        )}
      </h1>

      <ul className="items-list">
        {showAll
          ? promoters.map((promoter) => <FavouritePromoterCard key={promoter._id} promoter={promoter} />)
          : promoters.slice(0, 5).map((promoter) => <FavouritePromoterCard key={promoter._id} promoter={promoter} />)}
        <br className="clear"></br>
      </ul>
    </div>
  );
};
