import React from "react";

import allCategories from "../../../data/categories.json";

import "./Header.scss";

const Header = (props) => {
  let categoriesArr = [];
  let currentCategory = props.currentCategory;
  let headerCategory;
  let title;
  let description;
  let headerImage;
  for (const [key, value] of Object.entries(allCategories)) {
    categoriesArr.push(value);
    if (value.name_plural == currentCategory) {
      title = value.metadata.header_title_translated;
      description = value.metadata.header_text_translated;
      headerImage = value.metadata.headerImage;
      headerCategory = value.name_plural_translated;
    }
  }
  /* This is a dropdown that contains all of the available categories kept in case of future need */
  let categories = (
    <div className="dropdown show">
      <a
        className="dropdown-toggle dropdown py-3 px-4 bg-light text-primary rounded-left"
        href="#"
        role="button"
        id="dropdownMenuLink"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <u>{currentCategory}</u>
      </a>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        {categoriesArr.map((cat, id) => (
          <a className="dropdown-item" key={cat.id} href="#">
            {cat.name}
          </a>
        ))}
      </div>
    </div>
  );
  /* End of category dropdown */
  return (
    <div className="w-100 position-relative py-5 header-section">
      <div className="col-12 col-lg-6 position-absolute header-overlay-color"></div>
      <div
        className="col-6 d-none d-lg-flex position-absolute header-overlay-image"
        style={{ backgroundImage: `url(${headerImage ? headerImage : "/img/Hero-image.png"})` }}
      ></div>
      <div className="container flex-nowrap overflow-hidden py-sm-4">
        <div className="row">
          <div className="col-lg-6 col-12 pe-md-5">
            <div className="text-light flex-nowrap flex-column h-100 pe-md-5">
              <div className="header-category text-uppercase">
                {currentCategory === undefined ? "Events" : currentCategory}
              </div>
              <div className="d-flex flex-column">
                <h1 className="header-title text-dark-blue">{title}</h1>
                <div className="mb-4 mt-4 header-text">{description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
