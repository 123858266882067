import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Avatar } from "../Common/Form/Avatar.jsx";
import { WhatIsPromoter } from "../Common/Form/WhatIsPromoter.jsx";
import { BasicInfo } from "../Common/Form/BasicInfo.jsx";
import { LocationInfo } from "../Common/Form/LocationInfo.jsx";
import { ContactInfo } from "../Common/Form/ContactInfo.jsx";
import { SocialNetowrksInfo } from "./SocialNetworksInfo.jsx";
import { Button } from "../Common/Form/Button.jsx";

import { getHost, patchHost, postHost } from "../../services/hostService";
import { postUserHosts } from "../../services/userService.js";

export const Form = () => {
  let location = useLocation();
  const [eventImage, setEventImage] = useState(null);
  const [formData, setFormData] = useState({
    image: "",
    name: "",
    descriptionShort: "",
    description: "",
    location: {
      lat: "",
      lng: ""
    },
    phone: "",
    email: "",
    websiteUrl: "",
    facebook: "",
    twitter: "",
    mixcloud: "",
    soundcloud: "",
    fbpixel: ""
  });

  useEffect(() => {
    if (location.search.includes("?id")) {
      const hostId = location.search.split("?id=")[1];
      getHost(hostId)
        .then((result) => {
          if (result) {
            setFormData({
              image: result.image ? result.image : "",
              name: result.name,
              descriptionShort: result.descriptionShort ? result.descriptionShort : "",
              description: result.description ? result.description : "",
              location: {
                lat: "",
                lng: ""
              },
              phone: result.phone ? result.phone : "",
              email: result.email ? result.email : "",
              websiteUrl: result.websiteUrl ? result.websiteUrl : "",
              facebook: result.facebook ? result.facebook : "",
              twitter: result.twitter ? result.twitter : "",
              mixcloud: result.mixcloud ? result.mixcloud : "",
              soundcloud: result.soundcloud ? result.soundcloud : "",
              fbpixel: result.fbpixel ? result.fbpixel : ""
            });
          }
        })
        .catch((err) => console.error(err));
    }
  }, [location.search]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    const updatedFormData = { ...formData };
    updatedFormData[name] = value;
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add("was-validated");
    } else {
      form.classList.add("was-validated");

      const json = JSON.stringify(formData);
      let formDataHost = new FormData();

      formDataHost.append("image", eventImage);
      formDataHost.append("document", json);

      try {
        if (location.pathname.includes("edit")) {
          const hostId = location.search.split("?id=")[1];

          await patchHost(hostId, formDataHost);
        } else {
          const userId = localStorage.getItem("userId");
          const host = await postHost({}, formDataHost);
          await postUserHosts(userId, { host: host._id });
        }
        window.location.href = `${window.location.origin}/`;
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <form className="row mw-100" onSubmit={handleSubmit} noValidate>
      <div className={"col-md-3"}>
        <center>
          <Avatar formData={formData} setEventImage={setEventImage} />
        </center>
      </div>
      <div className="col-md-1"></div>
      <div className={"col-md-8"}>
        <div className={"col"}>
          <WhatIsPromoter />
        </div>

        <div className={"col"}>
          <BasicInfo formData={formData} handleInputChange={handleInputChange} />
        </div>

        <div className={"col"}>
          <LocationInfo formData={formData} handleInputChange={handleInputChange} />
        </div>

        <div className={"col"}>
          <ContactInfo formData={formData} handleInputChange={handleInputChange} />
        </div>

        <div className={"col"}>
          <SocialNetowrksInfo formData={formData} handleInputChange={handleInputChange} />
        </div>

        <div className={"row"}>
          <div className={"container"}>
            <Button text={location.pathname.includes("edit") ? "Edit" : "Create"} type="submit" className={"h-12"} />
            <Button
              text={"Cancel"}
              className={"ml-7 bg-white border-[#10345D] border-2 h-12"}
              textClassName={"text-[#10345D]"}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
