import { OrganizerPagesHeader } from "./OrganizerPagesHeader";
import { GiveATry } from "./GiveATry";
import { Partners } from "./Partners";
import { Testimonial } from "./Testimonial";

import "./Feature.scss";

export const Feature = () => {
  const HeaderFeaturePage = {
    "header-title": "PLENTIX FEATURES",
    header: "Improve Sales Using An Arsenal Of Great Features",
    title: `We strive to reshape the event industry to serve your core goal - connecting people through shared experiences.`,
    "background-image": "url(https://local.evedo.co/img/organizers/plentix-featuresimage.jpeg)"
  };
  return (
    <div className="container-my-page">
      <OrganizerPagesHeader props={HeaderFeaturePage} />
      <div className="full-width bg-pale1 centered">
        <div className="container-medium left-aligned">
          <h2 className="heading left-aligned fadefrombottom">How does Plentix help organizers?</h2>
          <div className="div-block-4 fadefrombottom">
            <p className="_w-50 paragraph-xl">
              Plentix is an event platform that knows organizers and their pains. The tool-chest of an organizers
              consists of many scattered things across different online and offline platforms we make the process
              smoother.
            </p>
            <div>
              <ul role="list" className="list-2 w-list-unstyled">
                <li className="list-item-2">
                  <img src="/img/organizers/bullet-check.svg" loading="lazy" alt="" className="bullet-checkbox" />
                  <div>Free Consultation</div>
                </li>
                <li className="list-item-2">
                  <img src="/img/organizers/bullet-check.svg" loading="lazy" alt="" className="bullet-checkbox" />
                  <div>Marketing Strategy</div>
                </li>
                <li className="list-item-2">
                  <img src="/img/organizers/bullet-check.svg" loading="lazy" alt="" className="bullet-checkbox" />
                  <div>Design, Legal and other services</div>
                </li>
                <li className="list-item-2">
                  <img src="/img/organizers/bullet-check.svg" loading="lazy" alt="" className="bullet-checkbox" />
                  <div>Custom commissions</div>
                </li>
                <li className="list-item-2">
                  <img src="/img/organizers/bullet-check.svg" loading="lazy" alt="" className="bullet-checkbox" />
                  <div>And more...</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          data-poster-url="/videos/tickets-test-poster-00001.jpg"
          data-video-urls="/videos/tickets-test-transcode.mp4,/videos/tickets-test-transcode.webm"
          data-autoplay="true"
          data-loop="true"
          data-wf-ignore="true"
          className="background-video fadefrombottom w-background-video w-background-video-atom"
          muted=""
          playsInline=""
          data-object-fit="cover"
        >
          <video
            autoPlay
            loop
            style={{ backgroundImage: "url(https://local.evedo.co/videos/tickets-test-poster-00001.jpg)" }}
            muted
            playsInline
            data-wf-ignore="true"
            data-object-fit="cover"
          >
            <source src="https://local.evedo.co/videos/tickets-test-transcode.mp4" data-wf-ignore="true" />
            <source src="https://local.evedo.co/videos/tickets-test-transcode.webm" data-wf-ignore="true" />
          </video>
        </div>
      </div>
      <div className="container-main mt-6 features">
        <div className="container-medium">
          <h2 className="heading fadefrombottom">everything you need to execute a memorable event in one place.</h2>
        </div>
        <div className="features-row fadefrombottom">
          <img
            src="https://local.evedo.co/img/organizers/img-tickets.jpg"
            loading="lazy"
            width="492"
            sizes="(max-width: 767px) 93vw, (max-width: 991px) 492px, 41vw"
            srcSet="/img/organizers/img-tickets-p-800.jpeg 800w, /img/organizers/img-tickets.jpg 984w"
            alt=""
          />
          <div className="features-content">
            <div>
              <div className="label-s">payments</div>
              <h3 className="mb-custom-1">Never pay upfront or risk losing money</h3>
            </div>
            <p className="paragraph-l">
              Plentix is an event platform that knows organizers and their pains. The toolchest of an organizers
              consists of many scattered things across different online and offline platforms! But We make the process
              smoother. And to top it all off - it's free for free events and very affordable for paid ones.
            </p>
            <a
              data-w-id="51ca9e61-38e8-e5c8-96d9-ad03b59efc66"
              href="/pricing"
              className="link-with-arrow w-inline-block"
            >
              <div className="text-block-6">View pricing</div>
              <img src="/img/organizers/icn-arrow_1.svg" loading="lazy" alt="" className="link-arrow" />
            </a>
          </div>
        </div>
        <div className="features-row flipped fadefrombottom">
          <img
            src="/img/organizers/img-checkout.jpeg"
            loading="lazy"
            width="492"
            sizes="(max-width: 767px) 93vw, (max-width: 991px) 492px, 41vw"
            srcSet="/img/organizers/img-checkout-p-500.jpeg 500w, /img/organizers/img-checkout-p-800.jpeg 800w, /img/organizers/img-checkout.jpeg 1008w"
            alt=""
          />
          <div className="features-content flipped">
            <div>
              <div className="label-s">Tickets</div>
              <h3 className="mb-custom-1">Custom check-out forms</h3>
            </div>
            <p className="paragraph-l">
              Know your attendees and any information you’d like to ask them by adding it to their ticket purchasing
              process.
            </p>
          </div>
        </div>
        <div className="features-row fadefrombottom">
          <img
            src="/img/organizers/img-venue.jpg"
            loading="lazy"
            width="416"
            sizes="(max-width: 479px) 92vw, (max-width: 991px) 416px, 41vw"
            srcSet="/img/organizers/img-venue-p-800.jpeg 800w, /img/organizers/img-venue.jpg 832w"
            alt=""
          />
          <div className="features-content">
            <div>
              <div className="label-s">Tickets</div>
              <h3 className="mb-custom-1">Venue Sitting Chart</h3>
            </div>
            <p className="paragraph-l">
              With Venue sitting functionality you can make it easy for ticket categories to be assigned to particular
              areas in the venue
            </p>
          </div>
        </div>
        <div className="features-row flipped fadefrombottom">
          <img
            src="/img/organizers/img-organizers.jpg"
            loading="lazy"
            width="493"
            sizes="(max-width: 767px) 93vw, (max-width: 991px) 493px, 41vw"
            srcSet="/img/organizers/img-organizers-p-500.jpeg 500w, /img/organizers/img-organizers-p-800.jpeg 800w, /img/organizers/img-organizers.jpg 986w"
            alt=""
          />
          <div className="features-content flipped">
            <div>
              <div className="label-s">organization</div>
              <h3 className="mb-custom-1">Multiple promoter accounts</h3>
            </div>
            <p className="paragraph-l">
              Stop managing hundreds of different accounts. You can have one master profile and as many promoter
              profiles as you’d like
            </p>
          </div>
        </div>
        <div className="features-row fadefrombottom">
          <img
            src="/img/organizers/img-embed.jpg"
            loading="lazy"
            width="508"
            sizes="(max-width: 767px) 93vw, (max-width: 991px) 508px, 41vw"
            srcSet="/img/organizers/img-embed-p-800.jpeg 800w, /img/organizers/img-embed.jpg 1016w"
            alt=""
          />
          <div className="features-content">
            <div>
              <div className="label-s">sales</div>
              <h3 className="mb-custom-1">Embed tickets on any website</h3>
            </div>
            <p className="paragraph-l">
              Get more sales by embedding tickets seamlessly into any website. Use our one of a kind feature to embed
              the ticket sales form into your website with just copy and paste
            </p>
          </div>
        </div>
      </div>
      <div className="full-width-with-image fadefrombottom wf-section">
        <div className="div-block-13"></div>
        <div className="hero-contrnt ">
          <div className="div-block-3">
            <div className="content-text">
              <h2 className="page-title left-aligned">
                No pricing plans!
                <br />
                just awesome free Possibilities.
              </h2>
              <p className="paragraph-l">
                Flat commission fee for paid events only. Our specialists can also consult you on other services like
                design, marketing, legal etc.
              </p>
              <a href="/pricing" className="btn-m secondary w-inline-block">
                <div>Learn more</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-main morefeatures">
        <div className="bg-solid pale fadefrombottom">
          <div className="container-medium more-features">
            <div className="more-features">
              <h3 className="centered">Not enough?&nbsp;We get it... here are many more possibilities</h3>
              <div className="w-layout-grid grid-2">
                <div className="box-1 no-border">
                  <div>
                    <h4>Create Free &amp; Paid events</h4>
                    <p className="paragraph-s">Easily publish events and start sharing them in minutes.</p>
                  </div>
                </div>
                <div className="box-1 no-border">
                  <div>
                    <h4>Export attendees data at anytime</h4>
                    <p className="paragraph-s">
                      Access the date you collected and use it to elevate your skills and drive more sales.
                    </p>
                  </div>
                </div>
                <div className="box-1 no-border">
                  <div>
                    <h4>NFT Tickets</h4>
                    <p className="paragraph-s">
                      Use blockchain and NFTs to offer your audience more than just great experience at your events.
                    </p>
                    <div className="label-comingsoon">
                      <div>coming soon</div>
                    </div>
                  </div>
                </div>
                <div className="box-1 no-border">
                  <div>
                    <h4>Edit events any time</h4>
                    <p className="paragraph-s">
                      Attendees will be notified of date &amp; time, location and other changes.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="box-1 no-border">
                    <div>
                      <h4>Give variety of payment options</h4>
                      <p className="paragraph-s">
                        Your attendees might want to pay with card or crypto... we give them the choice.
                      </p>
                      <div className="label-comingsoon">
                        <div>coming soon</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="box-1 no-border">
                  <div>
                    <h4>Use RSVP to drive more sales</h4>
                    <p className="paragraph-s">
                      RSVP&nbsp;functionality let's you know if somebody likes the event but can't go for some reason.
                    </p>
                  </div>
                </div>
                <div className="box-1 no-border">
                  <div>
                    <h4>Create / track promo codes</h4>
                    <p className="paragraph-s">Create as many promo codes as you want with a few clicks.</p>
                  </div>
                </div>
                <div className="box-1 no-border">
                  <div>
                    <h4>24/7 access to your event dashboard</h4>
                    <p className="paragraph-s">
                      Stay on top of your events performance at all times and tweak your strategy accordingly
                      <br />
                    </p>
                  </div>
                </div>
                <div className="box-1 no-border">
                  <div>
                    <h4>Create online events</h4>
                    <p className="paragraph-s">Tag your event as online ones so people can attend from anywhere.</p>
                  </div>
                </div>
              </div>
              <div className="cta-block centered">
                <a href="/event/create" className="btn-main w-inline-block logged-action-items">
                  <div>Get Started</div>
                </a>
                <div className="or-text">or</div>
                <a
                  data-w-id="c2daad0d-bddf-08bd-e18e-e3e81b23839e"
                  href="/why-plentix"
                  className="link-with-arrow w-inline-block"
                >
                  <div className="text-block-6">Why Choose Plentix</div>
                  <img
                    src="/img/organizers/icn-arrow_1.svg"
                    loading="lazy"
                    // style="-webkit-transform:translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0"
                    alt=""
                    className="link-arrow"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partners />
      <Testimonial />
      <GiveATry />
    </div>
  );
};
