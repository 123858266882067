import React from "react";

export const DateSelector = ({ value, onChange, label, menuItems, sx }) => {
  return (
    <div>
      <p>{label}</p>
      <select defaultValue={value} onChange={onChange}>
        {menuItems.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};
