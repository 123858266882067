import React from "react";
import { Input } from "../Form/Input.jsx";

export const BasicInfo = ({ handleInputChange, formData }) => {
  return (
    <div>
      <h3>Basic Information</h3>
      <Input placeholder={"Name"} name={"name"} value={formData?.name} onChange={handleInputChange} required={true} />
      <Input
        placeholder={"Short description (optional)"}
        name={"descriptionShort"}
        value={formData?.descriptionShort}
        onChange={handleInputChange}
      />
      <Input
        textArea
        placeholder={"Full description (optional)"}
        name={"description"}
        value={formData?.description}
        onChange={handleInputChange}
      />
    </div>
  );
};
