import standardSettings from "../../data/notificationStandardSettings.json";
import eventSettings from "../../data/notificationEventSettings.json";
import { useState } from "react";
import { NotificationSettingSwitch } from "./NotificationSettingSwitch";
import "./NotificationSettingsList.scss";
import { useEffect } from "react";

export const NotificationSettingsList = ({ user, setUser }) => {
  const [emailSettings, setEmailSettings] = useState([]);

  useEffect(() => {
    if (user?.emailNotification) {
      let settings = [];
      for (const key in user.emailNotification) {
        if (user.emailNotification[key] === true) {
          settings.push(key);
        }
      }
      setEmailSettings(settings);
    }
  }, [user]);

  return (
    <div className="notification-settings-list box py-3">
      <div className="container-fluid">
        <div className="section row mt-5 mb-3">
          <div className="col-12">
            <strong>Standard notifications</strong>
            <br />
            Keep being informed about events, promoters and friends
          </div>
        </div>

        <div className="group row">
          {standardSettings.map((x) => (
            <NotificationSettingSwitch
              key={x.id}
              setting={x}
              checked={emailSettings.includes(x.id)}
              setUser={setUser}
            />
          ))}
        </div>

        <div className="section row mt-5 mb-3">
          <div className="col-12">
            <strong>Notifications about your events and promoters</strong>
            <br />
            Get news about your events and your promoters' performance
          </div>
        </div>

        <div className="group row">
          {eventSettings.map((x) => (
            <NotificationSettingSwitch
              key={x.id}
              setting={x}
              checked={emailSettings.includes(x.id)}
              setUser={setUser}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
