import { Link, Outlet } from "react-router-dom";

import "./DiscountsNavbar.scss";

export const DiscountsNavbar = () => {
  return (
    <div className="manage-discount d-flex flex-column ">
      <ul className="nav mb-5  nav-tabs">
        <li className="nav-item">
          <Link className="nav-link " to="discounts">
            Form
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="multi-use">
            Multi-use
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="single-use">
            Single-use
          </Link>
        </li>
      </ul>
      <Outlet />
    </div>
  );
};
