import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";

const drawerWidth = "240px";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end"
}));

const pages = [
  { route: "/", name: "QA Scaffold" },
  { route: "/venues", name: "Venue Form Demo" },
  { route: "/promoter/create", name: "Create promoter" },
  { route: "/event/create", name: "Create event" },
  { route: "/dashboard", name: "Dashboard" },
  { route: "/profile", name: "Profile" },
  { route: "/tickets", name: "Tickets" },
  { route: "/my-events", name: "My events (Calendar)" },
  { route: "/friends", name: "Friends" },
  { route: "/messages", name: "Messages" },
  { route: "/settings", name: "Settings" },
  { route: "/event", name: "Event" }
];

export const TOC = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        onClick={handleDrawerOpen}
        className="bg-white w-10 h-10 flex items-center justify-center border-2 fixed top-[40%] ml-1 cursor-pointer"
        style={{ zIndex: 11 }}
      >
        <i className="fa fa-ellipsis p-5 border-2 w-5 opacity-70">...</i>
      </div>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box"
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map((page) => (
            <Link to={page.route} key={page.route} onClick={handleDrawerClose}>
              <ListItem button>
                <ListItemText primary={page.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </>
  );
};
