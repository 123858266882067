import { useEffect, useState } from "react";

import "./TicketsTable.scss";

export const TicketsTable = ({ categories }) => {
  const [totalSold, setTotalSold] = useState(0);
  const [pendingTickets, setPendingTickets] = useState(0);
  const [availableTickets, setAvailableTickets] = useState(0);
  const [grossIncome, setGrossIncome] = useState(0);
  const [netIncome, setNetIncome] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (categories?.length > 0) {
      let currentTotalTickets = 0;
      let currentAvailableTickets = 0;
      let currentGrossIncome = 0;
      categories.forEach((el) => {
        currentTotalTickets += el.sold;
        currentAvailableTickets += el.total;
        currentGrossIncome += el.sold * el.price;
      });

      setTotalSold(currentTotalTickets);
      // setPendingTickets((prev) => prev + el.sold);
      setAvailableTickets(currentAvailableTickets);
      setGrossIncome(currentGrossIncome);
      setNetIncome(currentGrossIncome * 0.9033);
      setTotal(currentGrossIncome * 0.9033);
    }
  }, [categories]);

  return (
    <div className="col-12 col-md-4">
      <div className="table-responsive pt-5 statistics">
        <table className="table">
          <tbody>
            <tr>
              <td>Sold tickets:</td>
              <td>{totalSold}</td>
            </tr>

            <tr>
              <td>Pending tickets:</td>
              <td>{pendingTickets}</td>
            </tr>

            <tr>
              <td>Available tickets:</td>
              <td>{availableTickets}</td>
            </tr>

            <tr>
              <td>Gross income:</td>
              <td>US${grossIncome.toFixed(2)}</td>
            </tr>

            <tr>
              <td>Net income:</td>
              <td>US${netIncome.toFixed(2)}</td>
            </tr>

            <tr>
              <td>Total:</td>
              <td>US${total.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
