import { TicketsTable } from "./TicketsTable";
import { TicketsSales } from "./TicketsSales";
import { TicketsSold } from "./TicketsSold";
// import { TicketsNavbar } from "./TicketsNavbar";

import "./EditTickets.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEvent } from "../../../services/eventService";

export const EditTickets = () => {
  const [event, setEvent] = useState([]);
  const [eventStartData, setEventStartData] = useState("");
  const locationParams = useParams();

  useEffect(() => {
    getDataEvents();
  }, []);

  function getDataEvents() {
    getEvent(locationParams.event_id)
      .then((result) => {
        if (result.startAt) {
          let date = new Date(result.startAt).toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
          });

          setEventStartData(date);
        }
        setEvent(result);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {}, [event]);

  return (
    <div className="mx-3">
      <div className="row">
        <div className="col-12">
          <a className="back" href={`/events/${event.category}/${event.name}/${event._id}/`}>
            <span className="icon"></span>
            <strong className="me-2">{event.name}</strong>
            <span className="details">
              {event.category} <span>·</span> Sofia, Bulgaria <span>·</span> {eventStartData}
            </span>
          </a>
        </div>
      </div>
      <div className="row sales-summary">
        <header className="col-12">
          <h1>Sales summary</h1>
          <div className="buttons">
            <a className="btn btn-inverse rounded" href={`/events/${event.category}/${event.name}/${event._id}edit/`}>
              <strong>Edit tickets</strong>
            </a>
          </div>
        </header>
      </div>
      <div className="row">
        <TicketsTable categories={event.categories} />
        <TicketsSales categories={event.categories} />
      </div>
      <div className="row">
        <div className="notice col-12">
          <span className="star">*</span> The data on this page is <strong>approximate</strong> and it may slightly
          vary.{" "}
        </div>
      </div>
      <TicketsSold tickets={event.tickets} getDataEvents={getDataEvents} />
    </div>
  );
};
