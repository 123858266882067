import { Tag } from "./Tag";
import { KeywordCard } from "./KeywordCard";
import { Map } from "../Common/Map";
import "./Details.scss";

export const Details = ({ event }) => {
  return (
    <div className="event-details row">
      <div className="col-12">
        <div className="white-bg mt-md-4 p-5 row">
          <div className="col-md-10">
            <h4 className="mb-5 mt-3">Event Details</h4>
            <p className="d-none d-sm-none d-md-none d-lg-block my-3">
              {event.tags?.map((tag, i) => (
                <Tag key={i} tag={tag} />
              ))}
            </p>

            <p className="ev-description">{event.pageTextBody}</p>
          </div>

          <div className="col-md-12">
            <div className="row">
              <h5 className="section-heading col-12 col-sm-2 mt-2">When</h5>
              <div className="col-12 col-sm-9 pb-2 d-flex align-items-start mt-2">
                <img alt="date" src="https://plentix.co/img/event/date-icn.svg" width="18" />
                <p className="meta color ps-2">
                  {`${new Date(event.startAt).toLocaleString("default", {
                    month: "long",
                    day: "numeric",
                    year: "numeric"
                  })}
                  to 
                  ${new Date(event.endAt).toLocaleString("default", {
                    month: "long",
                    day: "numeric",
                    year: "numeric"
                  })}`}
                  <br />
                  {/* {event.startAt?.split(' ')[1]} to {event.endAt?.split(' ')[1]} */}
                </p>
              </div>
            </div>
            <div className="row">
              <h5 className="section-heading col-12 col-sm-2 mt-2">Where</h5>
              <div className="col-12 col-sm-9 pb-2 d-flex align-items-start mt-2">
                <img alt="location" src="https://plentix.co/img/event/location-icn.svg" width="18" />
                <div>
                  <p className="meta color ps-2">
                    <a href="#map" className="jump-to-map">
                      {/* {event.location} */}
                    </a>
                  </p>
                  <p>
                    <a href="#map" className="link sm jump-to-map">
                      View on map
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-2 mt-2">
            <h5 className="section-heading">Refunds</h5>
          </div>
          <div className="col-12 col-sm-9 mt-2">
            <p className="meta pt-0 mt-0">
              {event.refundInformation ? event.refundInformation : "Please contact the organizer for more information"}
            </p>
          </div>

          <div className="col-12 col-sm-2">
            <h5 className="section-heading mt-2">Keywords</h5>
          </div>
          <div className="col-12 col-sm-10 mt-2">
            <p>
              {event.keywords?.map((keyword, i) => (
                <KeywordCard key={i} keyword={keyword} />
              ))}
            </p>
          </div>
        </div>
      </div>

      {/* <Map location={event.location} /> */}
      <Map location={"Romexpo, Bulevardul Mărăști, Bucharest, Romania"} />
    </div>
  );
};
