import { useState } from "react";
import floorMap from "../../data/floorMap.json";
import { SittingChart } from "./SittingChart";
import { Modal } from "../Common/Modal";
import SittingChartImg from "./assets/sitting-chart.png";
import "./SittingChartPreview.scss";

export const SittingChartPreview = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="col-12 col-md-3 col-lg-3 sitting-chart-preview-container">
      <img alt="sitting-chart" src={SittingChartImg} className="w-100" />
      <button className="text-center mx-auto d-block mt-3" onClick={() => setShowModal(true)}>
        View sitting chart
      </button>

      {showModal && (
        <Modal setShowModal={setShowModal}>
          <SittingChart floorMap={floorMap} section={0} quantity={10} />
        </Modal>
      )}
    </div>
  );
};
