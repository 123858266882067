import { useState } from "react";
import { useOuterClick } from "../../hooks";
import {
  getMonthArr,
  getYear,
  getMonth,
  getDay,
  getMonthString,
  checkIfToday,
  compareDates,
  checkIfBeforeToday,
  betweenDates
} from "../../helpers/dateHelpers";
import { CalendarCellEvent } from "./CalendarCellEvent";
import { CalendarEventCard } from "./CalendarEventCard";
import { CalendarCellTooltip } from "./CalendarCellTooltip";
import "./Calendar.scss";

export const Calendar = ({ events, filters, date, setDate }) => {
  const [tooltipDay, setTooltipDay] = useState(0);
  const innerRef = useOuterClick((e) => {
    if (tooltipDay && !e.target.classList.contains("featured")) {
      setTooltipDay(0);
    }
  });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const monthArr = getMonthArr(date);

  const previousMonth = () => {
    const updatedDate = new Date(getYear(date), getMonth(date) - 2, 1);
    setDate(updatedDate);
    setSelectedEvent(null);
  };

  const nextMonth = () => {
    const updatedDate = new Date(getYear(date), getMonth(date), 1);
    setDate(updatedDate);
    setSelectedEvent(null);
  };

  const selectEvent = (event) => {
    if (event.id === selectedEvent?.id) {
      setSelectedEvent(null);
    } else {
      setSelectedEvent(event);
    }
  };

  return (
    <section className="row box calendar-wrapper">
      <div className={`col-${selectedEvent ? "8 compact" : "12"} cal position-relative`}>
        <h1 className="box-header">
          {getMonthString(date)}, {getYear(date)}
        </h1>
        <button className="change-month prev" type="button" onClick={previousMonth}>
          <span>Previous</span>
        </button>
        <button className="change-month next" type="button" onClick={nextMonth}>
          <span>Next</span>
        </button>

        <table>
          <thead>
            <tr>
              <td>Mon</td>
              <td>Tue</td>
              <td>Wed</td>
              <td>Thu</td>
              <td>Fri</td>
              <td>Sat</td>
              <td>Sun</td>
            </tr>
          </thead>

          <tbody>
            {monthArr.map((week, weekIndex) => (
              <tr key={`week-${weekIndex + 1}`}>
                {week.map((day) => {
                  const inactive = (weekIndex === 0 && day > 20) || (weekIndex === monthArr.length - 1 && day < 10);

                  const filteredEvents = events.filter(
                    (event) =>
                      filters[event?.response] &&
                      !inactive &&
                      compareDates(new Date(getYear(date), getMonth(date) - 1, day), new Date(event.startAt))
                  );

                  return (
                    <td
                      key={`week-${weekIndex + 1}-${day}`}
                      className={`${inactive ? "inactive" : ""} 
                  ${checkIfToday(day, getMonth(date), getYear(date)) ? "today" : ""}`}
                    >
                      <div className={`cell ${getDay(date)}-${getMonth(date)}-${getYear(date)}`}>
                        <span>{day}</span>
                        {filteredEvents.map((event) => (
                          <CalendarCellEvent
                            key={event.id}
                            event={event}
                            selectedEvent={selectedEvent}
                            onClick={() => selectEvent(event)}
                          />
                        ))}

                        {!checkIfBeforeToday(day, date) && !filteredEvents.length && !inactive ? (
                          <>
                            <div
                              className={`featured ${day === tooltipDay ? "featured-selected" : ""}`}
                              onClick={() => setTooltipDay(day)}
                            ></div>
                            {day === tooltipDay && (
                              <CalendarCellTooltip
                                innerRef={innerRef}
                                date={new Date(getYear(date), getMonth(date) - 1, day)}
                                events={events.filter((event) =>
                                  betweenDates(
                                    new Date(event.startAt),
                                    new Date(event.endAt),
                                    new Date(getYear(date), getMonth(date) - 1, day)
                                  )
                                )}
                              />
                            )}
                          </>
                        ) : null}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedEvent && <CalendarEventCard event={selectedEvent} close={() => setSelectedEvent(null)} />}
    </section>
  );
};
