import "./Input.scss";

export const Input = ({
  placeholder,
  className,
  type,
  name,
  htmlFor,
  value = "",
  onChange,
  labelText,
  error
}) => {
  return (
    <label className={`w-100 checkout-input-label ${error ? "error" : ""}`} htmlFor={htmlFor}>
      {labelText}

      <div className="position-relative checkout-input-container">
        <input
          className={`w-100 checkout-input ${className || ""}`}
          placeholder={placeholder}
          type={type}
          name={name}
          htmlFor={htmlFor}
          value={value}
          onChange={onChange}
        />
      </div>
    </label>
  );
};
