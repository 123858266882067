import { useState, useEffect } from "react";

import "./Profile.scss";

const data = [
  {
    img: "https://plentixbucket.s3.eu-central-1.amazonaws.com/images/events/ba4ce1990451878034870e24f5c02c9c-small.jpg ",
    name: "1The importance of building a community",
    date: " 11.11.2021",
    location: "Sofia, Bulgaria"
  },
  {
    img: "https://plentixbucket.s3.eu-central-1.amazonaws.com/images/events/ba4ce1990451878034870e24f5c02c9c-small.jpg ",
    name: "2The importance of building a community",
    date: " 11.11.2021",
    location: "Sofia, Bulgaria"
  },
  {
    img: "https://plentixbucket.s3.eu-central-1.amazonaws.com/images/events/ba4ce1990451878034870e24f5c02c9c-small.jpg ",
    name: "3The importance of building a community",
    date: " 11.11.2021",
    location: "Sofia, Bulgaria"
  },
  {
    img: "https://plentixbucket.s3.eu-central-1.amazonaws.com/images/events/ba4ce1990451878034870e24f5c02c9c-small.jpg ",
    name: "4The importance of building a community",
    date: " 11.11.2021",
    location: "Sofia, Bulgaria"
  },
  {
    img: "https://plentixbucket.s3.eu-central-1.amazonaws.com/images/events/ba4ce1990451878034870e24f5c02c9c-small.jpg ",
    name: "5The importance of building a community",
    date: " 11.11.2021",
    location: "Sofia, Bulgaria"
  },
  {
    img: "https://plentixbucket.s3.eu-central-1.amazonaws.com/images/events/ba4ce1990451878034870e24f5c02c9c-small.jpg ",
    name: "6The importance of building a community",
    date: " 11.11.2021",
    location: "Sofia, Bulgaria"
  }
];

export const Profile = () => {
  const [queryData, setQueryData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [allPages, setAllPages] = useState(1);
  const rows = 3;
  const windowPageButtons = 3;

  useEffect(() => {
    dataForShow(data);
  }, [currentPage, rows]);

  const dataForShow = (dataArray) => {
    let data = pagination(dataArray, currentPage, rows);
    setQueryData(data.querySet);
    setAllPages(data.pages);
  };

  const pagination = (querySet, page, rows) => {
    let trimStart = (page - 1) * rows;
    let trimEnd = page * rows;
    let trimmedData = querySet.slice(trimStart, trimEnd);
    let pages = Math.ceil(querySet.length / rows);

    return {
      querySet: trimmedData,
      pages: pages
    };
  };
  const showPagesLength = (pages) => {
    let maxLeft = currentPage - Math.floor(windowPageButtons / 2);
    let maxRight = currentPage + Math.floor(windowPageButtons / 2);

    if (maxLeft < 1) {
      maxLeft = 1;
      maxRight = windowPageButtons;
    }
    if (maxRight > pages) {
      maxLeft = pages - (windowPageButtons - 1);
      maxRight = pages;

      if (maxLeft < 1) {
        maxLeft = 1;
      }
    }

    let numbOfButtons = new Array(allPages).fill(0).map((_, index) => {
      if (maxLeft <= index + 1 && maxRight >= index + 1) {
        if (currentPage === index + 1) {
          return (
            <button
              key={index + 1}
              className="page-item active"
              onClick={(e) => {
                e.preventDefault();
                return setCurrentPage(index + 1);
              }}
            ></button>
          );
        } else {
          return (
            <button
              key={index + 1}
              className="page-item"
              onClick={(e) => {
                e.preventDefault();
                return setCurrentPage(index + 1);
              }}
            ></button>
          );
        }
      }
    });
    return numbOfButtons;
  };

  return (
    <aside className="widgets d-block">
      <section className="people-widget box p-3 text-center col-12">
        <h3>Followers</h3>

        <div className="body col-12 d-flex flex-wrap justify-content-center">
          <a className="item" href="#" rel="nofollow">
            <img
              alt=""
              src="https://plentixbucket.s3.eu-central-1.amazonaws.com/images/users/a2d96c2f78ded025e50fc884239c8cd4-small.jpg"
            />
            <span>Rick Graham</span>
          </a>
          <a className="item" href="#" rel="nofollow">
            <img
              alt=""
              src="https://plentixbucket.s3.eu-central-1.amazonaws.com/images/users/profile-no-image-small.jpg"
            />
            <span>Rumiana</span>
          </a>
          <br className="clear" />
        </div>
      </section>

      <section className="past-events-widget box p-3 text-center col-12">
        <h3>Past events</h3>

        <div className="past-events">
          <ul>
            {queryData.map((element, index) => (
              <li key={index}>
                <img alt="" src={element.img} />
                <a href="/events/conferences/the-importance-of-building-a-community/810/">{element.name}</a>
                <span className="details">
                  {element.date} <span>·</span> {element.location}
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="paging inner-box">{allPages === 1 ? "" : showPagesLength(allPages)}</div>
      </section>
    </aside>
  );
};
