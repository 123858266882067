import React from "react";

import "./CtaSection.scss";

const CallToAction = (props) => {
  return (
    <>
      <div className="container-fluid cta-section position-relative mt-5">
        <div className="col-12 col-lg-6 position-absolute header-overlay-color cta-bg d-flex align-items-end">
          <img src="/img/svg/bg-dots.svg" alt="Plentix" />
        </div>
        <div
          className="col-6 d-none d-lg-flex position-absolute cta-overlay-image"
          style={{ backgroundImage: `url('/img/affiliates/zzzzapp/irina.png')` }}
        ></div>
        <div className="container position-relative">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-12 cta-content">
              <h2 className="h3 mb-3 text-dark about-title">{props.content.title}</h2>
              <div className="text-l cta-text mb-4 text-dark">{props.content.content}</div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12 col-sm-6 d-flex">
                    <a
                      href="/event/create"
                      // onClick={() =>
                      //   !props.isLogged ? window.evedo.openRegistration() : (window.location.href = "/event/create")
                      // }
                      className="button-main link-transition text-l bg-pink flex-fill justify-content-center d-flex align-items-center px-0"
                    >
                      {props.isLogged ? props.content.create_event : props.content.get_started}
                    </a>
                  </div>
                  <div className="col-12 col-sm-6 d-flex">
                    <a href="/pricing" className="button-ghost link-transition flex-fill">
                      {props.content.view_pricing}
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="trusted d-flex pb-3 pt-5 px-4 flex-column">
                  <div className="text-s mb-3">{props.content.trusted}</div>
                  <div className="trusted-logos d-flex w-100">
                    <img className="trusted-logo" src="/img/svg/tmrwland_logo_1.svg" />
                    <img className="trusted-logo" src="/img/svg/lovefest-logo-drk_1.svg" />
                    <img className="trusted-logo" src="/img/svg/drooble-logo-dark_1.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex col-lg-6 col-12 align-items-end px-0 ms-auto">
              <div className="cta-tooltip">
                <p className="text-s mb-2">{props.content.testimonial_content}</p>
                <p className="text-s fw-600 mb-0">
                  <small>{props.content.testimonial_author}</small>
                </p>
                <p className="text-s mb-0">
                  <small>{props.content.testimonial_author_position}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CallToAction;
