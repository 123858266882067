import React from "react";

import "./Footer.scss";

import { footerText } from "../../constants/props";

export class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      selectedLanguage: "en_UK",
      messagesLength: 0
    };
    this.languageSwitch = this.languageSwitch.bind(this);
  }
  componentDidMount() {
    if (!this.state.isLoaded) {
      if (this.props.isLogged) {
        fetch("/messages/check/")
          .then((res) => res.json())
          .then((result) => this.setState({ messagesLength: Object.keys(result.messages).length }))
          .catch((error) => console.log(error));
      }
      this.setState({
        selectedLanguage: this.props.selectedLanguage ? this.props.selectedLanguage : "en_UK",
        isLoaded: true
      });
    }
  }
  languageSwitch(e) {
    e.preventDefault();
    let el = e.target;
    fetch(`/change-language/?language=${el.getAttribute("data-lang")}`)
      .then((res) => res.json())
      .then((res) => {
        this.setState(
          {
            selectedLanguage: el.getAttribute("data-lang")
          },
          () => (window.location = window.location.href)
        );
      });
  }
  render() {
    let languageKeys = Object.keys(this.props.languages);
    let languageValues = Object.values(this.props.languages);
    return (
      <div className="col-12 footer-wrap bg-white">
        <div className="container pt-5">
          <div className="row align-items-start">
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-10 pe-0">
                  <div className="row">
                    <div className="col-10">
                      <img
                        src="/img/svg/plentix-logo-footer.svg"
                        width="140"
                        alt="Plentix"
                        className="footer-logo mb-3"
                      />
                    </div>
                  </div>
                  <div className="text-s mb-3 text-grey">{footerText.texts.description}</div>
                  <div className="footer-widgets d-flex flex-column">
                    <div className="btn-group dropright footer-language">
                      <a
                        href="#"
                        className="trigger-dropdown d-flex align-items-center mb-3 text-dark"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="footer-icon d-flex justify-content-center text-pink me-1">
                          <img src={`/img/svg/icon-globe.svg`} alt="Choose language" />
                        </div>
                        &nbsp;
                        {languageValues[languageKeys.indexOf(this.props.selectedLanguage)].name.substring(0, 2)}
                      </a>
                      <div className="dropdown-menu widget-dropdown px-0">
                        <div className="d-flex flex-column">
                          {languageKeys.map((lang, id) => (
                            <a
                              href="#"
                              onClick={this.languageSwitch}
                              data-lang={lang}
                              className={`language-link text-grey d-flex align-items-center py-2 px-4 text-s rounded-0 ${
                                this.props.selectedLanguage === lang ? "current" : ""
                              }`}
                              key={`language-${id}`}
                            >
                              <div className={`language-flag me-2 ${lang}`}></div>
                              {languageValues[id].name.substring(0, 2)}&nbsp;
                              {this.props.selectedLanguage === lang ? (
                                <i className="fad fa-check-circle text-pink ms-auto"></i>
                              ) : (
                                ""
                              )}
                            </a>
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* COUNTRY SELECTOR BELOW */}
                    {/* <div className="btn-group dropright footer-country">
                           <a href="#" className="trigger-dropdown d-flex align-items-center mb-3 text-dark">
                               <div className="footer-icon d-flex justify-content-center text-pink me-1"> <i className="far fa-location-arrow"></i> </div>{this.props.selectedCountry}
                           </a>
                           <div className="dropdown-menu widget-dropdown">
                               <div className="d-flex flex-column">
                                   <a href="#" className="language-link text-grey" data-country="UK">UK</a>
                                   <a href="#" className="language-link text-grey" data-country="BG">Bulgaria</a>
                                   <a href="#" className="language-link text-grey" data-country="FR">France</a>
                               </div>
                           </div>
                       </div> */}
                    <div className="text-grey ps-2 ps-sm-0">
                      &copy; <small>{new Date().getFullYear()} Plentix.com</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 mt-3">
              <div className="row">
                <div className="d-flex col-12 col-md-6 flex-column align-items-start">
                  <h3 className="footer-title text-dark mb-3 px-2">{footerText.texts.for_organizers}</h3>
                  <div className="footer-menu align-items-start d-flex flex-column mb-3">
                    <a href="/features" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      {footerText.texts.features}
                    </a>
                    <a href="/pricing" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      {footerText.texts.pricing}
                    </a>
                    <a href="/why-plentix" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      {footerText.texts.why_plentix}
                    </a>
                  </div>
                </div>
                <div className="d-flex flex-column col-12 col-md-6 align-items-start">
                  <h3 className="footer-title text-dark mb-3 px-2">{footerText.texts.help_center}</h3>
                  <div className="footer-menu align-items-start d-flex flex-column mb-3">
                    <a href="mailto:info@plentix.co" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      <img src={"/img/svg/icon-mail.svg"} className="me-1" alt="email" />
                      <b>info@plentix.co</b>
                    </a>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className="footer-menu-item text-grey mb-1 py-1 px-2 d-sm-flex d-none"
                    >
                      <img src={"/img/svg/icon-chat.svg"} className="me-1" alt="chat" />
                      {footerText.texts.live_chat}
                    </a>
                    <a
                      href="https://www.facebook.com/plentix.co/"
                      target="_blank"
                      className="footer-menu-item text-grey mb-1 py-1 px-2"
                    >
                      <img src={"/img/svg/icon-fb.svg"} className="me-1" alt="facebook" />
                      Facebook
                    </a>
                  </div>
                </div>
                <div className="d-flex col-12 col-md-6 flex-column align-items-start">
                  <h3 className="footer-title text-dark mb-3 px-2">{footerText.texts.legal}</h3>
                  <div className="footer-menu align-items-start d-flex flex-column mb-3">
                    <a href="/legal/terms-of-use" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      {footerText.texts.terms_and_conditions}
                    </a>
                    <a href="/legal/privacy-policy" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      {footerText.texts.privacy_policy}
                    </a>
                    <a href="/legal/cookie-policy" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      {footerText.texts.cookie_policy}
                    </a>
                    <a href="#" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      {footerText.texts.sitemap}
                    </a>
                    <a href="https://evedo.co/webinars" className="footer-menu-item text-grey mb-1 py-1 px-2">
                      {footerText.texts.covid}
                    </a>
                  </div>
                </div>
                <div className="d-flex col-12 col-md-6 flex-column align-items-start">
                  <h3 className="footer-title text-dark mb-3 px-2">{footerText.texts.resources}</h3>
                  <div className="footer-menu align-items-start d-flex flex-column mb-3">
                    <a
                      href="https://blog.plentix.co/"
                      target="_blank"
                      className="footer-menu-item text-grey mb-1 py-1 px-2"
                    >
                      {footerText.texts.our_blog}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {this.props.isLogged ? (
              <div className="col-10 col-sm-6 ms-auto me-auto me-lg-0 col-lg-3 my-3 footer-profile-menu p-4">
                <div className="footer-menu-header menu-left-header d-flex align-items-center mb-3 pb-3">
                  <div className="label-l text-dark fw-500">
                    {footerText.texts.hey}, {this.props.userFirstName}
                  </div>
                  <a
                    href="/settings"
                    className="d-flex align-items-center ms-auto text-dark circle-gray justify-content-center"
                  >
                    <img src="/img/svg/icon-settings.svg" alt="Settings" />
                  </a>
                </div>
                <div className="footer-menu-body">
                  <a
                    className="footer-profile-link text-grey px-2 text-start d-flex align-items-center mb-1"
                    href="/profile/"
                  >
                    {footerText.texts.admin.profile}
                  </a>
                  <a
                    className="footer-profile-link text-grey px-2 text-start d-flex align-items-center mb-1"
                    href="/profile/tickets/"
                  >
                    {footerText.texts.admin.my_tickets}
                  </a>
                  <a
                    className="footer-profile-link text-grey px-2 text-start d-flex align-items-center mb-1"
                    href="/calendar/"
                  >
                    {footerText.texts.admin.my_calendar}
                  </a>
                  <a
                    className="footer-profile-link text-grey px-2 text-start d-flex align-items-center mb-1"
                    href="/profile/friends/"
                  >
                    {footerText.texts.admin.friends}
                  </a>
                  <a
                    className="footer-profile-link text-grey px-2 text-start d-flex align-items-center mb-1"
                    href="/messages/"
                  >
                    {footerText.texts.admin.messages}
                    <div className="d-flex align-items-center ms-1 text-dark circle-gray circle-gray-sm justify-content-center">
                      {this.state.messagesLength}
                    </div>
                  </a>
                </div>
                <div className="footer-menu-footer d-flex py-3 mt-3">
                  <a href="/logout" className="footer-profile-link text-grey px-2 d-flex align-items-center">
                    <div>
                      <img src={`/img/svg/icon-logout.svg`} alt={footerText.texts.admin.logout} />
                    </div>
                    <div className="ms-2 cta-text">
                      <small>
                        {footerText.texts.admin.logout} {this.props.userFirstName}
                      </small>
                    </div>
                  </a>
                </div>
              </div>
            ) : (
              <div className="col-12 ms-auto me-auto me-lg-0 col-lg-3 mt-3 footer-card">
                <div className="row">
                  <div className="footer-card-action my-2 mx-auto text-center">
                    <img src="/img/svg/icon-hand.svg" alt="hey" />
                  </div>
                  <div className="text-s text-grey text-center">{footerText.texts.not_signed_yet}</div>
                  <div className="d-flex justify-content-center">
                    <a
                      id="footer-login-mobile"
                      data-bs-toggle="offcanvas"
                      href="#mobile-offcanvas"
                      role="button"
                      aria-controls="mobile-offcanvas"
                      className="nav-button-primary nav-link-normal text-white px-5 mx-auto my-3 text-center"
                    >
                      {footerText.texts.signIn}
                    </a>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    {footerText.texts.no_acc}&nbsp;
                    <a
                      id="footer-register-mobile"
                      data-bs-toggle="offcanvas"
                      href="#mobile-offcanvas"
                      role="button"
                      aria-controls="mobile-offcanvas"
                      className={`nav-link-normal text-dark ms-1`}
                    >
                      {footerText.texts.signUp}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="container-fluid bg-pink-light py-4">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-0"></div>
                <div className="col-md-6 col-12 col-sm-9 d-flex align-items-center flex-wrap mb-3 mb-sm-0">
                  <div className="footer-ribbon-text me-2">{footerText.texts.looking_for_partners}</div>
                  <a href="https://alpha.evedo.co/" target="_blank" className="link-pink d-flex align-items-center">
                    {footerText.texts.browse_marketplace}&nbsp;
                    <span className="icon">
                      <img src={`/img/svg/diagonal-arrow-violet.svg`} alt="Evedo B2B" />
                    </span>
                  </a>
                </div>
                <div className="col-3 d-flex">
                  <img className="ms-auto" src={`/img/svg/alpha-logo.svg`} width="75" alt="Evedo Alpha" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
