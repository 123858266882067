import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { NotFoundPage } from "./Screens/NotFoundPage.jsx";
import { TOC } from "./app/Common/TOC.jsx";
import { LANGUAGES } from "./constants/props";
import { Navigation } from "./app/Common/header/navigation.js";
import { Footer } from "./app/Common/Footer";

import { Legal } from "./app/Legal";
import { MyPlentix } from "./app/Marketing/MyPlentix";
import { Feature } from "./app/Marketing/Feature";
import { Pricing } from "./app/Marketing/Pricing";

import { Register } from "./app/Register/Register";
import { Setup } from "./app/Setup";
import { Login } from "./app/Login/Login";
import { ForgotPassword } from "./app/Login/ForgotPassword";
import { Logout } from "./app/Logout/Logout";
import { ProtectedRoutes } from "./app/Common/ProtectedRoutes";

import { Homepage } from "./app/Homepage";
import { Discover } from "./app/Discover";
import { Search } from "./app/Search";
import { Dashboard } from "./app/Dashboard";

import { Events } from "./app/Events";

import { New as NewHost } from "./app/Hosts/New.jsx";
import { New as NewEvent } from "./app/Events/New.jsx";

import { Edit as EditHost } from "./app/Hosts/Edit.jsx";
import { Edit as EditEvent } from "./app/Events/Edit.jsx";

import { ManageDiscount } from "./app/Events/Manage/ManageDiscount";
import { MultiUseCode } from "./app/Events/Manage/MultiUseCode";
import { ManageDiscountCodes } from "./app/Events/Manage/ManageCodes/ManageMultiCodes";
import { ManageSingleCodes } from "./app/Events/Manage/ManageCodes/ManageSingleCodes";
import { EditTickets } from "./app/Events/Manage/EditTickets";
import { DiscountsNavbar } from "./app/Events/Manage/DiscountsNavbar";
import { BillingInformation } from "./app/Events/Manage/BillingInformation";
import { EmbedTickets } from "./app/Events/Manage/EmbedTickets";
import { TicketsSold } from "./app/Events/Manage/TicketsSold";

import { Tickets } from "./app/Tickets";
import { Profile } from "./app/Profile";
import { Friends } from "./app/Friends";
import { Messages } from "./app/Messages";
import { Settings } from "./app/Settings";
import { Calendar } from "./app/Calendar";


import { PromoterView } from "./app/Hosts/PromoterView";
import { Event } from "./app/Events/Event";
import { Host } from "./app/Hosts/Host";


import { Orders } from "./app/Orders";
import { RequiredInfo } from "./app/Orders/RequiredInfo";
import { Pay } from "./app/Orders/Pay";
import { TicketsView } from "./app/Orders/TicketsView";
import { PaymentSuccess } from "./app/Orders/PaymentSuccess";

import { getUser } from "./services/userService.js";
import { getHost } from "./services/hostService.js";

const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

const appearance = {
  theme: "stripe",
  rules: {
    ".Input": {
      fontSize: "14px",
      color: "#23232e",
      padding: "19px 12px"
    },
    ".Label": {
      fontSize: "14px",
      marginBottom: "16px",
      color: "#6E6D76"
    }
  }
};
const languages = LANGUAGES;

export const App = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [mode, setMode] = useState("light");
  const [selectedLanguage, setSelectedLanguage] = useState("en_UK");
  const [currentCategory, setCurrentCategory] = useState("Events");
  const [currentEvent, setCurrentEvent] = useState();
  const [events, setEvents] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [userFirstName, setUserFirstName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [notifications, setNotifications] = useState("");
  const [hostsCount, setHostsCount] = useState("");
  const [eventsCount, setEventsCount] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      setUserFirstName(localStorage.getItem("name"));
      setUserImage(localStorage.getItem("image"));
      setIsLogged(true);

      getUser(localStorage.getItem("userId"))
        .then((result) => updateStateOfUser(result))
        .catch((err) => console.error(err));
    }
  }, []);

  function updateStateOfUser(user) {
    setUserFirstName(user.firstName);
    setUserImage(user.image);
    localStorage.setItem("name", user.firstName);
    localStorage.setItem("image", user.image);

    if (user.events?.length > 0) {
      setEvents(user.events);
    }

    if (user.hosts?.length > 0) {
      Promise.all(
        user.hosts.map((element) => {
          return getHost(element);
        })
      )
        .then((result) => {
          if(result.length > 0 ){
            let allHosts = [];
            result.forEach((el) => {
              if(el !== null ){
                allHosts.push(el);
              }
            })
            setHosts(allHosts);
          }
        })
        .catch((err) => console.error(err));
    }
  }

  function disconnect() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("image");
    localStorage.removeItem("name");
    localStorage.removeItem("userId");
  }

  return (
    <BrowserRouter>
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: "pi_1DpzYU2eZvKYlo2Cl8AsYeyF_secret_1xu1AlPEQqRRbZ1Ca74h9L1Zo", appearance }}
      >
        <Navigation
          isLogged={isLogged}
          mode={mode}
          languages={languages}
          selectedLanguage={selectedLanguage}
          currentCategory={currentCategory}
          events={events}
          hosts={hosts}
          userFirstName={userFirstName}
          userImage={userImage}
          notifications={notifications}
          hostsCount={hostsCount}
        />
        <TOC />

        <Routes>
          <Route path={"/"} element={<Homepage isLogged={isLogged} currentCategory={currentCategory} />} />
          <Route
            path={"/login"}
            element={
              <Login setIsLogged={setIsLogged} setUserFirstName={setUserFirstName} setUserImage={setUserImage} />
            }
          />
          <Route
            path={"/register"}
            element={
              <Register setIsLogged={setIsLogged} setUserFirstName={setUserFirstName} setUserImage={setUserImage} />
            }
          />
          <Route element={<ProtectedRoutes condition={localStorage.getItem("accessToken")} navigateAddress={"/"} />}>
            <Route path={"/logout"} element={<Logout disconnect={disconnect} />} />
          </Route>
          <Route path={"/setup"} element={<Setup userFirstName={userFirstName} />} />
          <Route path={"/forgot-password"} element={<ForgotPassword />} />
          
          <Route path={"/events/:categories/:name/:id"} element={<Event />} />
          <Route path={"/events/:categories/:name/:id/edit"} element={<EditEvent />} />
          
          <Route path={"/events"} element={<Events />} />
          <Route path={"/events/new"} element={<NewEvent />} />
          <Route path={"/events/:event_id"} element={<DiscountsNavbar />} />
          <Route path={"/events/:event_id/manage"} element={<EditTickets />} />
          <Route path={"/events/:event_id/manage/discounts"} element={<MultiUseCode />} />
          <Route path={"/events/:event_id/manage/multi-use"} element={<ManageDiscountCodes />} />
          <Route path={"/events/:event_id/manage/single-use"} element={<ManageDiscountCodes />} />
          <Route path={"/events/:event_id/manage/embed-tickets"} element={<EmbedTickets />} />
          <Route path={"/events/:event_id/manage/billing-information"} element={<BillingInformation />} />
          <Route
            path={"/events/conferences"}
            element={
              <Discover
                isLogged={isLogged}
                languages={languages}
                currentCategory={currentCategory}
                selectedLanguage={selectedLanguage}
              />
            }
          />
          <Route path='*' element={<NotFoundPage />} />
          
          <Route path={"/hosts/:name/:id"} element={<PromoterView />} />
          <Route path={"/hosts/new"} element={<NewHost />} />
          <Route path={"/hosts/edit"} element={<EditHost />} />
          <Route path={"/hosts/:id"} element={<Host />} />

          <Route path={"/orders/new"} element={<Orders />} />
          <Route path={"/orders/:id/required"} element={<RequiredInfo />} />
          <Route path={"/orders/:id/pay"} element={<Pay />} />
          <Route path={"/orders/:id/finish"} element={<PaymentSuccess />} />

          <Route path={"/search"} element={<Search />} />
          <Route path={"/dashboard"} element={<Dashboard />} />
          <Route path={"/profile"} element={<Profile />} />
          <Route path={"/tickets"} element={<Tickets />} />
          <Route path={"/friends"} element={<Friends />} />
          <Route path={"/messages"} element={<Messages />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path={"/calendar"} element={<Calendar />} />

          <Route path={"/legal/*"} element={<Legal />} />
          <Route path={"/why-plentix"} element={<MyPlentix />} />
          <Route path={"/features"} element={<Feature />} />
          <Route path={"/pricing"} element={<Pricing />} />
        </Routes>

        <Footer
          isLogged={isLogged}
          languages={languages}
          selectedLanguage={selectedLanguage}
          currentCategory={currentCategory}
          userFirstName={userFirstName}
          notifications={notifications}
          hostsCount={hostsCount}
          eventsCount={eventsCount}
        />
      </Elements>
    </BrowserRouter>
  );
};
