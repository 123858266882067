import React from "react";
import { Input } from "../Form/Input.jsx";

export const DateTime = ({ formData, handleInputChange }) => {
  const validEndDate = (startAt, endAt) => {
    const startDate = new Date(startAt);
    const endDate = new Date(endAt);
    if (startDate.toString() === "Invalid Date" || endDate.toString() === "Invalid Date") return true;
    return startDate <= endDate;
  };

  const validEndTime = (startAt, startTime, endAt, endTime) => {
    if (!startTime || !endTime || !startAt || !endAt) return true;
    const startDate = new Date(`${startAt} ${startTime}`);
    const endDate = new Date(`${endAt} ${endTime}`);
    return startDate < endDate;
  };

  return (
    <div className="row">
      <div className="col-12 my-5">
        <h2>When?</h2>
        <div className="row">
          <div className="col-md-3">
            <Input
              placeholder="Start date"
              type="date"
              name="startAt"
              value={formData?.startAt}
              onChange={(e) => {
                e.target.setCustomValidity(
                  validEndDate(
                    new Date().toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric"
                    }),
                    e.target.value
                  )
                    ? ""
                    : "Error"
                );
                document.querySelector(".start-time-input").setCustomValidity(
                  validEndTime(
                    new Date().toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric"
                    }),
                    new Date().toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric"
                    }),
                    e.target.value,
                    formData.startTime
                  )
                    ? ""
                    : "Error"
                );
                document
                  .querySelector(".end-date-input")
                  .setCustomValidity(validEndDate(e.target.value, formData.endAt) ? "" : "Error");
                document
                  .querySelector(".end-time-input")
                  .setCustomValidity(
                    validEndTime(e.target.value, formData.startTime, formData.endAt, formData.endTime) ? "" : "Error"
                  );
                document.querySelectorAll(".ticket-start-date-input").forEach((el, i) => {
                  el.setCustomValidity(validEndDate(formData["ticket-start-date"][i], e.target.value) ? "" : "Error");
                });
                document.querySelectorAll(".ticket-start-time-input").forEach((el, i) => {
                  el.setCustomValidity(
                    validEndTime(
                      formData["ticket-start-date"][i],
                      formData["ticket-start-time"][i],
                      e.target.value,
                      formData.startTime
                    )
                      ? ""
                      : "Error"
                  );
                });
                handleInputChange(e);
              }}
              required={true}
              style={{ backgroundImage: "none", paddingRight: "inherit" }}
              className="start-date-input"
            />
            <div className="invalid-feedback">End date can't be before today's date </div>
          </div>
          <div className="col-md-3">
            <Input
              placeholder="Start time"
              type="time"
              name="startTime"
              value={formData?.startTime}
              onChange={(e) => {
                e.target.setCustomValidity(
                  validEndTime(
                    new Date().toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric"
                    }),
                    new Date().toLocaleString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric"
                    }),
                    formData.startAt,
                    e.target.value
                  )
                    ? ""
                    : "Error"
                );
                handleInputChange(e);
              }}
              required={true}
              style={{ backgroundImage: "none", paddingRight: "inherit" }}
              className="start-time-input"
            />
            <div className="invalid-feedback">Start time must be after current time.</div>
          </div>
          <div className="col-md-3">
            <Input
              placeholder="End date"
              type="date"
              name="endAt"
              value={formData?.endAt}
              onChange={(e) => {
                e.target.setCustomValidity(validEndDate(formData.startAt, e.target.value) ? "" : "Error");
                document
                  .querySelector(".end-time-input")
                  .setCustomValidity(
                    validEndTime(formData.startAt, formData.startTime, e.target.value, formData.endTime) ? "" : "Error"
                  );
                handleInputChange(e);
              }}
              style={{ backgroundImage: "none", paddingRight: "inherit" }}
              required={true}
              className={(validEndDate(formData.startAt, formData.endAt) ? "" : "is-invalid") + " end-date-input"}
            />
            <div className="invalid-feedback">End date can't be before start date.</div>
          </div>
          <div className="col-md-3">
            <Input
              placeholder="End time"
              type="time"
              name="endTime"
              value={formData?.endTime}
              onChange={(e) => {
                e.target.setCustomValidity(
                  validEndTime(formData.startAt, formData.startTime, formData.endAt, e.target.value) ? "" : "Error"
                );
                handleInputChange(e);
              }}
              style={{ backgroundImage: "none", paddingRight: "inherit" }}
              required={true}
              className={
                (validEndTime(formData.startAt, formData.startTime, formData.endAt, formData.endTime)
                  ? ""
                  : "is-invalid") + " end-time-input"
              }
            />
            <div className="invalid-feedback">End date must be after start date.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
