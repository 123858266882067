export const props = {
  isLogged: "1",
  mode: "light",
  languages: {
    en_UK: {
      name: "English",
      country: "United Kingdom",
      rtl: false
    },
    es_MX: {
      name: "Español",
      country: "Mexico",
      rtl: false
    },
    ru_RU: {
      name: "Russian",
      country: "Russia",
      rtl: false
    },
    ro_RO: {
      name: "Romanian",
      country: "Romania",
      rtl: false
    }
  },
  selectedLanguage: "en_UK",
  currentCategory: "Events",
  events: [],
  hosts: [
    {
      url: "/promoter/rick/1/",
      name: "Rick",
      attendance: 0,
      avatar: "https://s3.eu-central-1.amazonaws.com/localbucket.evedo.co/images/hosts/host-no-image-small.gif",
      events: [],
      new: "/event/create/?host=1"
    }
  ],
  userFirstName: "Rick",
  userImage:
    "https://s3.eu-central-1.amazonaws.com/localbucket.evedo.co/images/users/a2d96c2f78ded025e50fc884239c8cd4-small.jpg",
  notifications: "4",
  hostsCount: "1",
  eventsCount: "0"
};

export const propsNoLogin = {
  isLogged: "",
  mode: "light",
  languages: {
    en_UK: {
      name: "English",
      country: "United Kingdom",
      rtl: false
    },
    es_MX: {
      name: "Español",
      country: "Mexico",
      rtl: false
    },
    ru_RU: {
      name: "Russian",
      country: "Russia",
      rtl: false
    },
    ro_RO: {
      name: "Romanian",
      country: "Romania",
      rtl: false
    }
  },
  selectedLanguage: "en_UK",
  currentCategory: "Events",
  events: [],
  hosts: [],
  texts: {
    admin: {
      title: "Settings",
      profile: "Profile",
      my_events: "My events",
      my_promoters: "My promoters",
      create_event: "Create event",
      create_promoter: "Create promoter",
      messages: "Messages",
      my_tickets: "My tickets",
      my_calendar: "My events",
      friends: "Friends",
      logout: "Log out as"
    },
    searchPlaceholder: "Search for events",
    searchTargetNotFound: "Don’t see what you’re looking for?",
    searchSeeMore: "See more results",
    events: "Events",
    promoters: "Promoters",
    discover: "Discover",
    forOrganizers: "For organizers",
    learnAboutPlentix: "Learn about Plentix",
    whyPlentix: "Why Plentix?",
    howItWorks: "How it works?",
    eventPartnersLookFor: "Looking for partners to build your event?",
    browseOnB2B: "Browse on Evedo B2B",
    hey: "Hey",
    education: "Education",
    music: "Music & Entertainment",
    hobbies: "Hobbies",
    other: "Other",
    features: "Features",
    pricing: "Pricing",
    signIn: "Sign in",
    signUp: "Sign up",
    youHave: "You have",
    notifications: "notifications",
    notification: "notification",
    new: "new",
    hey_stranger: "Hey, Stranger!"
  },
  userFirstName: "",
  userImage: "",
  notifications: "",
  hostsCount: "",
  eventsCount: ""
};

export const propsHome = {
  currentCityId: 234821,
  domain: "https://staging.evedo.co",
  latestEvedoSection: {
    title: "Latest on evedo b2b",
    text: "Evedo B2b is a marketplace for anybody that takes part in event organization and execution",
    link: {
      title: "evedo.co/alpha",
      url: "https://alpha.evedo.co/"
    },
    button_names: {
      venues: "Venues",
      performers: "Performers"
    }
  },
  texts: {
    header: {
      title: "The Global Event Platform For Social Explorers",
      description: "Connect with like-minded people by discovering, booking, and sharing events.",
      organizer: "Are you an organizer?",
      view_features: "View Features",
      events_search: "Search for events",
      city_or_country: "City or country",
      go: "Go! Go! Go!"
    },
    featured_events: {
      title: "Featured Events on Plentix",
      description:
        "Find the Best Events at your fingertips. Our staff knows what is hot right now and is featuring these events for you.",
      help: "Want your event here?",
      no_results: "There are no featured events.",
      dropdown_content: "We are always happy to feature great events! Contact us at sales@evedo.co to find out more."
    },
    events: {
      title: "Happening",
      today: "Today",
      weekend: "This Weekend",
      week: "This Week",
      month: "This Month",
      more: "More",
      all_events: "All Events",
      load_more: "Load more",
      view_all: "View All",
      education: "Education",
      music: "Music & Entertainment",
      hobbies: "Hobbies",
      other: "Other",
      no_results: "There are no events near you."
    },
    online_events: {
      title: "Online Events",
      sub_title: "Praesent commodo cursus magna",
      description:
        "Stay in touch with the rest of the world even if you’re far away. Don’t miss out on great opportunities just because of location.",
      view_all: "View All"
    },
    about: {
      title: "Plentix is a one-stop shop for any event.",
      description:
        "With Plentix you can do everything you need for organizing events right in the platform. Create events, sell tickets, track progress and save time and money from using multiple tools.",
      view_all: "View all features",
      first_section: {
        title: "Easy & free event creation process",
        description: "Set up your events in a heartbeat with no upfront cost, no matter if they are paid or free."
      },
      second_section: {
        title: "Full access to relevant customer data",
        description: "Stay up-to-date with event attendants’ data and give them the information they need."
      },
      third_section: {
        title: "Multiple promoter profiles in a single user",
        description: "Manage multiple promoter profiles seamlessly and without wasting time."
      },
      fourth_section: {
        title: "Embed ticket sales on your website",
        description: "Get more sales for your events by embedding tickets seamlessly on your website."
      }
    },
    marketplace: {
      title: "Are you an organizer?",
      content: "Make use of our powerful platform that will take care of all your needs as an organizer.",
      get_started: "Get Started",
      view_pricing: "View pricing",
      create_event: "Create event",
      testimonial_content:
        "Plentix helps us achieve our goals & reach more people. I love it! It’s user-friendly, the support is very fast so Plentix makes it easier!",
      testimonial_author: "Irina Altanova",
      testimonial_author_position: "Administrative coordinator @ MOVE.BG ",
      trusted: "Trusted by"
    },
    trust: {
      title: "Trusted by"
    },
    footer: {
      title: "Latest on evedo b2b",
      description: "Evedo B2b is a marketplace for anybody that takes part in event organization and execution",
      view_all_on: "View all on",
      performers: "Performances",
      venues: "Venues"
    },
    event_card: {
      get_tickets: "Get Tickets",
      tickets: "Tickets",
      free: "Free",
      online: "Online"
    }
  }
};

export const propsDiscover = {
  currentLocation: {
    country: "Bulgaria",
    city: "Sofia",
    lat: "42.69751",
    lng: "23.32415"
  },
  aboutContent: {
    title: "Why are conferences so important?",
    image: "/img/web/host-no-image-thumb.gif",
    text: [
      "Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Vestibulum id ligula porta felis euismod semper. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Aenean lacinia bibendum nulla sed consectetur.",
      " Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Nullam id dolor id nibh ultricies vehicula ut id elit. Donec sed odio dui. Sed posuere consectetur est at lobortis. Integer posuere erat a ante venenatis dapibus posuere velit aliquet."
    ]
  },
  texts: {
    events: {
      title: "Happening Soon Near You",
      location_placeholder: "Enter your location",
      or: "or",
      enable_location: "enable location",
      more: "More",
      near_you: "near you",
      load_more: "Load more",
      no_results: "There are no events near you."
    },
    promoters: {
      title: "Notable Promoters for"
    },
    marketplace: {
      title: "Are you an organizer?",
      content: "Make use of our powerful platform that will take care of all your needs as an organizer.",
      get_started: "Get Started",
      view_pricing: "View pricing",
      create_event: "Create event",
      testimonial_content:
        "Plentix helps us achieve our goals & reach more people. I love it! It’s user-friendly, the support is very fast so Plentix makes it easier!",
      testimonial_author: "Irina Altanova",
      testimonial_author_position: "Administrative coordinator @ MOVE.BG ",
      trusted: "Trusted by"
    },
    footer: {
      plentix:
        "Plentix is a platfrom created by evedo.co. Nulla vitae elit libero, a pharetra augue. Maecenas faucibus mollis interdum. Maecenas sed diam eget risus varius blandit sit amet non magna.",
      organizers: {
        title: "For Organizers",
        features: "Features",
        pricing: "Pricing",
        why_Plentix: "Why Plentix",
        how_it_works: "How it works?"
      },
      legal: {
        title: "Legal",
        terms_and_conditions: "Terms & Conditions",
        privacy_policy: "Privacy Policy",
        cookie_policy: "Cookie Policy",
        sitemap: "Sitemap",
        covid_support: "Covid-19 Support"
      },
      help_center: {
        title: "Help center",
        faqs: "FAQs",
        help: "Help",
        contact_support: "Contact Support",
        connect_with_us: "Connect with us",
        for_investors: "For Investors",
        blockchain: "Blockchain 101"
      },
      marketplace: {
        title: "Looking for partners to build your event?",
        browse_marketplace: "Browse Marketplace"
      }
    },
    event_card: {
      get_tickets: "Get Tickets",
      tickets: "Tickets",
      free: "Free",
      online: "Online"
    }
  }
};

export const footerText = {
  texts: {
    admin: {
      title: "Settings",
      profile: "Profile",
      my_events: "My events",
      my_promoters: "My promoters",
      create_event: "Create event",
      create_promoter: "Create promoter",
      messages: "Messages",
      my_tickets: "My tickets",
      my_calendar: "My calendar",
      friends: "Friends",
      logout: "Log out as"
    },
    description:
      "Plentix is a product of Evedo - a blockchain based platform consisting of B2B & B2C Marketplaces aimed at unifying all businesses and participants involved in organizing events.",
    for_organizers: "For Organizers",
    features: "Features",
    pricing: "Pricing",
    why_plentix: "Why Plentix",
    how_it_works: "How it works?",
    terms_and_conditions: "Terms & Conditions",
    privacy_policy: "Privacy Policy",
    cookie_policy: "Cookie Policy",
    sitemap: "Sitemap",
    covid: "Covid-19 Support",
    help_center: "Help Center",
    help: "Help",
    faqs: "FAQs",
    legal: "Legal",
    blockchain: "Blockchain 101",
    contact_support: "Contact Support",
    connect_with_us: "Connect with us",
    for_investors: "For Investors",
    looking_for_partners: "Looking for partners to build your event?",
    browse_marketplace: "Browse Evedo B2b Marketplace",
    not_signed_yet: "Hey! You're not signed in yet!",
    signIn: "Sign in now",
    signUp: "Sign up",
    no_acc: "No account?",
    resources: "Resources",
    our_blog: "Our Blog",
    live_chat: "Live Chat",
    hey: "Hey"
  }
};

export const LANGUAGES = {
  en_UK: {
    name: "English",
    country: "United Kingdom",
    rtl: false
  },
  es_MX: {
    name: "Español",
    country: "Mexico",
    rtl: false
  },
  ru_RU: {
    name: "Russian",
    country: "Russia",
    rtl: false
  },
  ro_RO: {
    name: "Romanian",
    country: "Romania",
    rtl: false
  }
};
