import { Link } from "react-router-dom";
import "./Tag.scss";

export const Tag = ({ tag }) => {
  return (
    <Link to={`/events/tags/${tag}`} className="event-tag tag-small accent-blue">
      {tag}
    </Link>
  );
};
