import React, { useRef } from "react";

export const Input = ({
  placeholder,
  type = "text",
  label,
  className,
  mt,
  noLabel,
  disabled = false,
  value,
  onChange = () => null,
  textArea,
  button,
  name,
  maxLength,
  hint,
  required,
  min,
  max,
  style
}) => {
  const ref = useRef();
  const v = value ? value : "";
  return (
    <>
      {noLabel ? null : type === "date" || type === "time" || label ? (
        <label htmlFor={`${label}Input`} className="form-label d-flex align-items-center">
          {label}
          {hint && <span className="hint" title={hint} style={{ marginLeft: "5px" }}></span>}
        </label>
      ) : null}
      {textArea ? (
        <textarea
          onChange={onChange}
          value={v}
          disabled={disabled}
          type={type}
          className={`form-control form-control-lg ${className}`}
          id={`${label}Input`}
          placeholder={placeholder}
          name={name}
          maxLength={maxLength}
          required={required}
          style={style}
        ></textarea>
      ) : button ? (
        <>
          <button onClick={() => ref.current.click()} className="form-control form-control-lg">
            select color
          </button>
          <input
            ref={ref}
            onChange={onChange}
            value={v}
            disabled={disabled}
            type={type}
            id={`${label}Input`}
            placeholder={placeholder}
            style={{ opacity: 0, height: 2 }}
            name={name}
            maxLength={maxLength}
            required={required}
            min={min}
            max={max}
            style={style}
            className={className}
          />
        </>
      ) : (
        <input
          ref={ref}
          onChange={onChange}
          value={v}
          disabled={disabled}
          type={type}
          className={`form-control form-control-lg ${className}`}
          id={`${label}Input`}
          placeholder={placeholder}
          name={name}
          maxLength={maxLength}
          required={required}
          min={min}
          max={max}
          style={style}
        />
      )}
    </>
  );
};
