import React from "react";

export const TextArea = ({ placeholder, name, value, onChange, required }) => {
  return (
    <textarea
      className="form-control mt-3 form-control-lg"
      id="exampleFormControlTextarea1"
      rows="5"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      required={required}
    ></textarea>
  );
};
