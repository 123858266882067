import React from "react";

export const CheckBox = ({ labelText, name, value, onChange }) => {
  return (
    <div className="form-check mt-1">
      <input
        className="form-check-input"
        type="checkbox"
        id={name}
        name={name}
        value={value}
        checked={value}
        onChange={(e) => onChange({ target: { name, value: e.target.checked } })}
      />
      <label className="form-check-label" htmlFor={name}>
        {labelText}
      </label>
    </div>
  );
};
