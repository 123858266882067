import React, { useState } from "react";

import "./Header.scss";

const Header = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [locationQuery, setLocationQuery] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    let locationSearchQuery = "search";

    if (searchQuery.length > 0) {
      locationSearchQuery += `?name=${searchQuery}`;
    }
    window.location = window.location.href + locationSearchQuery;
  }

  return (
    <div
      className={`container-fluid position-relative py-5 ${
        props.mode == "light" ? "text-white bg-dark-violet" : "header-section"
      }`}
    >
      <div className="col-12 col-lg-6 position-absolute header-overlay-color d-flex align-items-end">
        <img src="/img/svg/bg-dots-2.svg" alt="Plentix" />
      </div>
      <div
        className="col-6 d-none d-lg-flex position-absolute header-overlay-image"
        style={{ backgroundImage: `url(${props.content.image ? props.contnent.image : "/img/Hero-image.png"})` }}
      ></div>
      <div className="row">
        <div className="container flex-nowrap overflow-hidden py-sm-4 position-relative">
          <div className="row justify-content-between">
            <div className="col-lg-5 col-12">
              <div className="text-light flex-nowrap flex-column h-100">
                <div className="d-flex flex-column">
                  <h1 className={`header-title ${props.mode == "light" ? "text-white" : "text-mode-dark"}`}>
                    {props.content.title}
                  </h1>
                  <div
                    className={`mb-4 mt-4 header-text col-12 ${
                      props.mode == "light" ? "text-white" : "text-mode-dark"
                    }`}
                  >
                    <div className="d-flex">{props.content.description}</div>
                    <div className="d-flex pt-sm-5 pt-3">
                      {props.content.organizer}{" "}
                      <a className="link-normal link-white link-transition ms-2" href="/features">
                        {props.content.view_features}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12 d-flex align-items-center">
              <div className="header-filter w-100">
                <div className="d-flex flex-column header-search-wrap bg-white p-0">
                  <div className={`position-relative d-flex align-items-center py-4 px-4`}>
                    <span className="d-flex align-items-center search-icon" id="header-search-icon">
                      <img src="/img/svg/icon-search.svg" alt="Search on Plentix" />
                    </span>
                    <input
                      type="text"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      name="header-event-input"
                      className={`border-0 px-3 text-m text-mode-dark h-100 bg-transparent w-100 py-1`}
                      placeholder={props.content.events_search}
                      aria-describedby="header-search-icon"
                      aria-label="Search"
                      onKeyDown={(e) => (e.key == "Enter" ? handleSubmit(e) : "")}
                    />
                  </div>
                  <div className={`position-relative d-flex align-items-center py-4 px-4 location-borders`}>
                    <span className="d-flex align-items-center Location-icon" id="header-Location-icon">
                      <img src="/img/svg/icon-location.svg" alt="Search on Plentix" />
                    </span>
                    <input
                      type="text"
                      name="header-location-input"
                      onChange={(e) => setLocationQuery(e.target.value)}
                      id="location-autocomplete"
                      className={`border-0 px-3 text-m text-mode-dark h-100 bg-transparent w-100 py-1`}
                      placeholder={props.content.city_or_country}
                      aria-describedby="header-Location-icon"
                      aria-label="Location"
                      autoComplete="nope"
                      onKeyDown={(e) => (e.key == "Enter" ? handleSubmit(e) : "")}
                    />
                  </div>
                  <a
                    href="#"
                    onClick={handleSubmit}
                    className="header-button mx-auto text-white nav-link-normal border-0 px-5 my-4"
                    id="header-search-button"
                  >
                    {props.content.go}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

const searchIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4089 24.8895C5.74008 24.8895 0.306641 19.3067 0.306641 12.4428C0.306641 5.57886 5.74008 0.0078125 12.4089 0.0078125C19.0778 0.0078125 24.5112 5.59062 24.5112 12.4428C24.5112 19.2949 19.0892 24.8895 12.4089 24.8895ZM12.4089 3.34574C7.52454 3.34574 3.54383 7.42413 3.54383 12.4545C3.54383 17.4849 7.5131 21.5633 12.4089 21.5633C17.3047 21.5633 21.274 17.4849 21.274 12.4545C21.274 7.42413 17.2933 3.34574 12.4089 3.34574Z"
      fill="#D6D3DF"
    />
    <path
      d="M24.7942 22.2623C25.6068 24.2581 22.8514 24.6391 21.3485 23.0949L19.8684 21.5741C19.2459 20.9344 19.2459 19.9154 19.8684 19.2757C20.5153 18.6111 21.5831 18.6111 22.2299 19.2757L24.1033 21.2006C24.4069 21.5154 24.6373 21.8768 24.7942 22.2623Z"
      fill="#D6D3DF"
    />
  </svg>
);
const locationIcon = () => (
  <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2222 25.6125L24.2677 1.50781L1.30664 12.0536L12.0266 14.3435L14.2222 25.6125Z"
      stroke="#D6D3DF"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
