import { useState } from "react";
import ReactDOM from "react-dom";
import { postMessages } from "../../services/messageService";

import "./MessageModal.scss";

export const MessageModal = ({ setShowModal, friend, chatId }) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message && chatId && localStorage.getItem("userId")) {
      try {
        await postMessages({
          chatId: chatId,
          senderId: localStorage.getItem("userId"),
          text: message
        });
        setShowModal(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
      }
    }
  };

  return ReactDOM.createPortal(
    <div className="message-friend-modal-wrapper modal">
      <div className="message-friend-modal modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div>
              <h3 className="title">Message to {friend.names}</h3>
              {error ? <p className='text-danger m-0 p-0'>{error}</p> : ""}
            </div>

            <button className="close" type="button" onClick={() => setShowModal(false)}>
              x
            </button>
          </div>

          <form className=" message-form" onSubmit={handleSubmit}>
            <div className="modal-body p-3">
              <div className="block">
                <textarea className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} />
              </div>
            </div>

            <div className="modal-footer actions">
              <button className="btn btn-primary" type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};
