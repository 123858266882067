import { useState } from "react";
import { getOrder, completeOrder, patchRequired } from "../../services/orderService";

import "./TicketsReserved.scss";

//This is for the input fields about the request information about the ticket creations

export const TicketsReserved = () => {
  const selectedTickets = JSON.parse(localStorage.getItem("tickets")).filter((ticket) => 'required' in ticket.information);

  const [fieldsInformation, setFieldsInformation] = useState({});
  const numberOfTickets = selectedTickets.reduce((a, c) => (a += c.amount), 0);

  const onSelect = (e) => {
    const parent = e.target.parentNode.parentNode;
    const nameOfGroup = parent.attributes.name.textContent;
    const nameOfField = e.target.dataset.fieldType;

    setFieldsInformation((element) => {
      let copyElement = Object.assign({}, { ...element });
      if (copyElement[nameOfGroup] === undefined) {
        copyElement[nameOfGroup] = {};
      }
      copyElement[nameOfGroup][nameOfField] = e.target.value;
      return copyElement;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const locationPathSplitted = window.location.pathname.split("/");
    const orderId = locationPathSplitted[2];

    selectedTickets.forEach(async (ticket) => {
      const requiredTicketInformation = [];

      Array(ticket.amount).fill().forEach((el) => {
        const requiredFields = [];
        ticket.information.required.forEach((obj) => {
          requiredFields.push({ ...obj });
        });
        requiredTicketInformation.push(requiredFields);
      });

      requiredTicketInformation.forEach((element, index) => {
        element.forEach((info) => {
          info.field_value = fieldsInformation[`${ticket.information.name.replace(/\s/g, '')}${index}`][info.field_type];
        });
      });
      
      await patchRequired(orderId, { categoryId: ticket.information._id, required_information: requiredTicketInformation });
    });

    const order = getOrder(orderId);

    if (order.price > 0) {
      window.location.href = `${window.location.origin}/orders/${orderId}/pay`;
    } else {
      await completeOrder(orderId, {});
      window.location.href = `${window.location.origin}/orders/${orderId}/finish`;
    }
  };

  return (
    <div className="col-12 col-lg-9 col-xl-8 text-end white-bg ">
      <div className="row my-5">
        <div className="col-12 text-center">
          <form onSubmit={handleSubmit}>
            <h2>
              <span>{numberOfTickets} tickets</span> were reserved!
            </h2>
            <p>
              You have <span className="countdown" data-seconds="900" data-start-time="2022-10-04 12:14:34"></span>15
              minutes to fill in the required information and pay
            </p>
            {selectedTickets.map(ticket => (
              <div className="category row" data-id="1634">
                <div className="col-4 text-end mt-2">
                  <span className="color" style={{ borderLeft: `12px solid ${ticket.information.color}` }}>
                    &nbsp;
                  </span>
                  <strong>{ticket.information.name}</strong>
                </div>
                <div className="mb-3 col-6 text-start">
                  {Array(ticket.amount).fill().map((el, index) => (
                    <div key={index} className="mb-3 info-group text-start" name={`${ticket.information.name.replace(/\s/g, '')}${index}`}>
                      {ticket.information.required.some((e) => e.field_type === "name") && (
                        <div className="input-group">
                          <input
                            name={`name-online-${index}`}
                            className="form-control required"
                            data-type="default"
                            data-field-type="full_name"
                            placeholder="Full name"
                            type="text"
                            required
                            onChange={onSelect}
                          />
                        </div>
                      )}

                      {ticket.information.required.some((e) => e.field_type === "email") && (
                        <div className="input-group">
                          <input
                            name={`email-online-${index}`}
                            className="form-control required"
                            data-type="default"
                            data-field-type="email"
                            placeholder="Email"
                            type="text"
                            onChange={onSelect}
                          />
                        </div>
                      )}
                      {ticket.information.required.some((e) => e.field_type === "tshirt") && (
                        <div className="input-group">
                          <select 
                            name={`tshirt-online-${index}`} 
                            data-type="default" 
                            data-field-type="tshirt" 
                            onChange={onSelect} 
                            className="tickets-select w-100"
                          >
                            <option value="Size" de>
                              Size
                            </option>
                            <option value="XXS">XXS</option>
                            <option value="XS">XS</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="2XL">2XL</option>
                            <option value="3XL">3XL</option>
                          </select>
                        </div>
                      )}
                      {ticket.information.required.some((e) => e.field_type === "age") && (
                        <div className="input-group">
                          <input
                            name={`age-online-${index}`}
                            className="form-control required"
                            data-type="default"
                            data-field-type="age"
                            placeholder="Age"
                            type="number"
                            min="1"
                            max="80"
                            onChange={onSelect}
                          />
                        </div>
                      )}
                      {ticket.information.required.some((e) => e.field_type === "gender") && (
                        <div className="input-group">
                          <select 
                            name={`gender-online-${index}`} 
                            data-type="default" 
                            data-field-type="gender" 
                            onChange={onSelect} 
                            className="tickets-select w-100"
                          >
                            <option value="Gender">Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      )}
                      {ticket.information.required.some((e) => e.field_type === "phone") && (
                        <div className="input-group">
                          <input
                            name={`phone-online-${index}`}
                            className="form-control"
                            data-type="default"
                            data-field-type="phone"
                            placeholder="Phone"
                            type="text"
                            onChange={onSelect}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <input type="hidden" name="order[required]" id="order-info" />
            <button id="submit" className="btn btn-primary rounded active mt-3 mr-3">
              <strong>Continue</strong>
              <span></span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
