import { useState } from "react";

import { patchUserPassword } from "../../services/userService.js";
import { changePasswordFormValidator } from "../../validators/changePasswordFormValidator.js";
import { GeneralSettingsForm } from "./GeneralSettingsForm.jsx";

export const ChangePasswordForm = () => {
  const [message, setMessage] = useState({ type: "", text: "" });
  const [data, setData] = useState({
    newPassword: "",
    newPasswordRe: "",
    oldPassword: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...data };
    updatedData[name] = value;
    setData(updatedData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = changePasswordFormValidator(data);
    if (error) {
      setMessage({ type: "error", text: error });
      return;
    } else {
      const userId = localStorage.getItem("userId");

      await patchUserPassword(userId, { oldPassword: data.oldPassword, newPassword: data.newPassword })
        .then(() => {
          setMessage({ type: "success", text: "You have successfully changed your password." });
          setData({
            newPassword: "",
            newPasswordRe: "",
            oldPassword: ""
          });
        })
        .catch((err) => {
          setMessage({ type: "error", text: err.message });
        });
    }

    const timeout = setTimeout(() => {
      setMessage({ type: "", text: "" });
      clearTimeout(timeout);
    }, 3000);
  };

  return (
    <GeneralSettingsForm>
      <form className="box py-3" onSubmit={handleSubmit}>
        <div className="container-fluid">
          <h2>Change password</h2>
          <div className="col-12">
            <div className="row g-1">
              <div className=" col-12 col-md-6">
                <input
                  className="form-control form-control-lg"
                  autoComplete="off"
                  autoCorrect="off"
                  name="newPassword"
                  placeholder="New password"
                  type="password"
                  value={data.newPassword}
                  onChange={handleChange}
                />
              </div>
              <div className=" col-12 col-md-6">
                <input
                  className="form-control form-control-lg"
                  autoComplete="off"
                  autoCorrect="off"
                  name="newPasswordRe"
                  placeholder="New password again"
                  type="password"
                  value={data.newPasswordRe}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="row g-1 my-1">
            <div className=" col-12 col-md-6">
              <input
                className="form-control form-control-lg"
                autoComplete="off"
                autoCorrect="off"
                name="oldPassword"
                placeholder="Old password"
                type="password"
                value={data.oldPassword}
                onChange={handleChange}
              />
            </div>
          </div>
          {message.type && <div className={`message ${message.type}`}>{message.text}</div>}
          <button className="btn btn-primary" type="submit">
            Save changes
          </button>
        </div>
      </form>
    </GeneralSettingsForm>
  );
};
