import { useEffect } from "react";
import allAchievements from "../../data/userAchievements.json";
import { AchievementCard } from "./AchievementCard";
import "./Achievements.scss";

export const Achievements = ({ userAchievements }) => {
  let achievements = [];

  useEffect(() => {
    if (userAchievements) {
      achievements = allAchievements.filter((achievement) => userAchievements.includes(achievement));
    }
  }, [userAchievements]);

  return (
    <section className="box gained-achievements row my-3">
      <div className="col-12">
        <h1 className="box-header">Achievements</h1>
        <ul className="achievements-list">
          {achievements.map((achievement) => (
            <AchievementCard key={achievement.id} achievement={achievement} />
          ))}
        </ul>
      </div>
    </section>
  );
};
