import { useDebugValue, useEffect, useState } from "react";
import { ConversationBody } from "./ConversationBody";
import { getMessages, postMessages } from "../../services/messageService";
import { getAllChat } from "../../services/chatService";

import ProfileImage from "../../../src/assets/profile-image.jpg";

import "./Conversation.scss";

export const Conversation = ({ friend, setFriends, friends, setSendMessage, receiveMessage }) => {
  const [newMsg, setNewMsg] = useState("");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (friend?.chatId) {
      getMessages(friend.chatId)
        .then((result) => {
          setMessages(result);
        })
        .catch((err) => console.error(err));
    }
  }, [friend]);

  useEffect(() => {
    if (receiveMessage !== null && receiveMessage.chatId === friend?.chatId) {
      setMessages([...messages, receiveMessage]);
    }
    getAllChat(localStorage.getItem("userId"))
      .then((result) => setFriends(result))
      .catch((err) => console.error(err));
  }, [receiveMessage]);

  const sendMessage = async (e) => {
    const userId = localStorage.getItem("userId");
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (friend.chatId && userId && newMsg.trim()) {
        try {
          const sendedMessage = await postMessages({
            chatId: friend.chatId,
            senderId: userId,
            text: newMsg.trim()
          });
          setNewMsg("");

          const result = await getAllChat(userId);
          setFriends(result);
          setSendMessage({ ...sendedMessage, receiverId: friend._id });
        } catch (err) {
          console.error(err);
        }
      }
    }
  };

  return (
    <section className="box msg-conversation col-12 col-md-8 col-lg-9">
      {friend ? (
        <>
          <div className="conversation-header col-12">
            <button type="button" className="image-container">
              <img alt="user" className="profile-image" src={friend.image ? friend.image : ProfileImage} />
            </button>
            <span>Conversation with</span>
            <button className="name" type="button">
              {friend.names}
            </button>
          </div>

          <ConversationBody messages={messages} />

          <form className="col-12 new-msg-form">
            <textarea
              name="new-msg"
              placeholder="Write your message and press enter"
              className="form-control"
              onKeyPress={sendMessage}
              onChange={(e) => setNewMsg(e.target.value)}
              value={newMsg}
            />
          </form>
        </>
      ) : (
        <div className="start-conversation-msg">
          To start a new conversation
          <br />
          find a friend via the search box and press enter.
        </div>
      )}
    </section>
  );
};
