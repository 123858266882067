import { useEffect, useState } from "react";

import { Image } from "./Image";
import { RSVP } from "./RSVP";
import { Details } from "./Details";
import { Information } from "./Information";
import { RightMenu } from "./RightMenu";

import { getEvent } from "../../services/eventService";

import "./Event.scss";
import { useParams } from "react-router-dom";

export const Event = () => {
  const [events, setEvents] = useState([]);
  const locationParams = useParams();

  useEffect(() => {
    getEvent(locationParams.id)
      .then((result) => {
        setEvents(result);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-7 col-md-12 my-4">
          <Image image={events.image} showUploadForm={false}/>
          <RSVP event={events} setEvent={setEvents} />
          <Details event={events} />
        </div>
        <div className="col-lg-4 col-md-12 d-none d-sm-none d-md-none d-lg-block sidebar">
          <div className="sticky-top">
            <Information />
            <RightMenu event={events} />
          </div>
        </div>
      </div>
    </div>
  );
};
