import { useEffect, useState } from "react";
import { Calendar as Cal } from "./Calendar.jsx";
import { Filters } from "./Filters.jsx";
import { UpcomingEvents } from "./UpcomingEvents.jsx";
import { PastEvents } from "./PastEvents.jsx";

import imagePlaceholder from "../../assets/placeholder.png";

import "./Calendar.scss";
import { getUserRsvp } from "../../services/userService";

export const Calendar = () => {
  const [filters, setFilters] = useState({
    attending: true,
    interested: true,
    "love-it": true
  });
  const [events, setEvents] = useState([]);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      getUserRsvp(userId)
        .then((result) => {
          const newArrayOfEvents = result.map((element) => {
            let event = {
              id: element.event._id,
              title: element.event.name,
              startAt: element.event.startAt,
              endAt: element.event.endAt,
              // location: element.event.location,
              location: "Sofia, Bulgaria",
              price:
                element.event?.categories[0]?.settings?.priceType?.toLowerCase() === "other"
                  ? element.event?.categories[0]?.settings?.priceDescription
                  : element.event.categories[0]?.settings?.priceType,
              category: element.event.name,
              response: element.response,
              image: element.event?.image ? element.event.image : imagePlaceholder
            };
            return event;
          });

          setEvents(newArrayOfEvents);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  return (
    <div className="container">
      <div className="col-12 ">
        <h1>Calendar</h1>
      </div>

      <div className="row">
        <aside className="col-12 hidden-events">
          <Filters filters={filters} setFilters={setFilters} />
        </aside>

        <section className="col-12 col-md-9">
          <Cal events={events} filters={filters} date={date} setDate={setDate} />
          <UpcomingEvents events={events} setEvents={setEvents} filters={filters} date={date} />
          <PastEvents events={events} filters={filters} date={date} />
        </section>

        <aside className="col-3 hidden-events-calendar">
          <Filters box={"box"} filters={filters} setFilters={setFilters} />
        </aside>
      </div>
    </div>
  );
};
