import "./Preference.scss";

export const Preference = ({ newPage, city, setCity, userFirstName }) => {
  return (
    <>
      <div className="mask steps mb-5">
        <div className="wrapper">
          <div className="slide h-100 justify-content-center location d-flex flex-column align-items-center">
            <img className="mx-auto my-5" src="/img/svg/icon-welcome.svg" alt="Welcome to Plentix" />
            <h1 className="h4 text-dark-blue">Welcome aboard {userFirstName}</h1>
            <p className="text-m text-dark-grey">Lets set your likes and preferences</p>

            <input
              className="search-input py-4 px-5 text-s text-mode-dark bg-white rounded-pill border-0 w-100 mx-auto pac-target-input"
              name="search-city"
              placeholder="Select Your City (Optional)"
              type="text"
              defaultValue={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <input name="city-id" type="hidden" value="" />
          </div>
        </div>
      </div>
      <div className="mask navigation">
        <div className="wrapper">
          <div className="slide steps d-flex justify-content-center align-items-center">
            <button className="nav-button-primary text-white d-flex align-items-center py-3 px-5" onClick={newPage}>
              Next <img className="ms-3" src="/img/svg/icon-arrow-right-long.svg" alt="Next" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
