import { Link } from "react-router-dom";
import "./InteractionCard.scss";

import Placeholder from "../../assets/placeholder.png";

export const InteractionCard = ({ event }) => {
  const answerTexts = {
    attending: "Attending",
    interested: "Interested",
    "love-it": "Love it but can't"
  };

  return (
    <li className="interaction-card col-12 col-sm-6 col-lg-4">
      <Link to={`/events/${event.category?.toLowerCase()}/${event.name}/${event._id}`}>
        <button type="button" className="mask">
          <img alt="event" src={event.image ? event.image : Placeholder} className="w-100 object-cover" />
        </button>
      </Link>

      <Link to={`/events/${event.category?.toLowerCase()}`}>
        <button type="button" className="category">
          {event.category}
        </button>
      </Link>

      <Link to={`/events/${event.id}`}>
        <button type="button" className="title">
          {event.name}
        </button>
      </Link>

      {/* <span>{event.location}</span> */}
      <time>{event.startAt.split(" ")[0]}</time>

      {/* <div className={`marker-${event.answer} marker`}>{answerTexts[event.answer]}</div> */}
    </li>
  );
};
