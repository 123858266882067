export const OrganizerPagesHeader = ({ props }) => {
  return (
    <div className="hero why-plentix wf-section" style={{ backgroundImage: props["background-image"] }}>
      <div className="hero-contrnt">
        <div className="div-block-3">
          <div className="hero-text features me-auto me-lg-0">
            <div className="label-l highlight-1 left-aligned">{props["header-title"]}</div>
            <h1 className="page-title left-aligned">{props.header}</h1>
            <h2 className="page-subtitle left-aligned">{props.title}</h2>
            <div className="cta-block mt-5">
              <a href="/event/create" className="btn-main w-inline-block logged-action-items">
                <div>Get Started</div>
              </a>
              <div className="or-text">or</div>
              <a
                data-w-id="6efb6908-333d-bb10-8461-ce101b468cb7"
                href="/pricing"
                className="link-with-arrow w-inline-block"
              >
                <div className="text-block-6">View pricing</div>
                <img src="/img/organizers/icn-arrow_1.svg" loading="lazy" alt="" className="link-arrow" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
