import React from "react";

const NoResults = (props) => (
  <div className="col-12 py-5 my-5">
    <div className="row justify-content-center">
      <div className="text-center">
        <img src="/img/svg/icon-no-results.svg" alt={props.paragraph1} />
        <div className="text-m cta-text mt-4">
          <p className="mb-0">{props.paragraph1}</p>
          <p className="mb-0">{props.paragraph2}</p>
        </div>
      </div>
    </div>
  </div>
);
export default NoResults;
