import { useEffect } from "react";
import { Popover } from "bootstrap";

import "./EmbedTickets.scss";

export const EmbedTickets = () => {
  useEffect(() => {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl);
    });
  }, []);

  const copyText = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(e.target.value);
  };
  return (
    <div className="d-flex flex-column align-items-center embed-wrapper py-4">
      <div className="modal-header d-flex flex-column align-items-center w-100 py-3">
        <h2>Embed tickets into your website</h2>
        <p>
          Select a layout, choose options
          <br />
          and insert the generated HTML into your website.
        </p>
      </div>
      <div className="modal-body embed-ticket-body w-100">
        <div className="row form-wrapper">
          <div className="col-6 button text-center">
            <h2>Embed button</h2>
            <div className="layout button-normal"></div>

            <select className="form-control form-control-lg">
              <option value="button-small">Small button: 120 × 30px</option>
              <option selected="selected" value="button-normal">
                Normal button: 180 × 50px
              </option>
              <option value="button-big">Big button: 240 × 70px</option>
            </select>
            <input
              className="form-control form-control-lg"
              maxLength="32"
              placeholder="Custom button label"
              type="text"
            />
            <span
              className="popover-textarea"
              data-bs-container="body"
              data-bs-toggle="popover"
              data-bs-trigger="focus"
              data-bs-placement="left"
              data-bs-html="true"
              title="<p><strong>Paste this code </strong> into your website</p>"
            >
              <textarea className="rounded form-control form-control-lg" readOnly="readonly">
                &lt;script src="https://plentix.co/js/api/embed-tickets.js"
                type="text/javascript"&gt;&lt;/script&gt;&lt;div style="height: 50px; width: 180px;"&gt;&lt;iframe
                data-ey="true" data-event-id="30" data-key="7b11f4acfa" data-layout="button-normal" data-label=""
                id="t26gxfwy" style="visibility: hidden;"&gt;&lt;/iframe&gt;&lt;/div&gt;
              </textarea>
            </span>
          </div>
          <div className="col-6 inline text-center">
            <h2>Embed form</h2>
            <div className="layout inline"></div>
            <div className="size">
              Form size: <strong>730 × 550px</strong>
            </div>
            <span
              className="popover-textarea"
              data-bs-container="body"
              data-bs-toggle="popover"
              data-bs-trigger="focus"
              data-bs-placement="right"
              data-bs-html="true"
              title="<p><strong>Paste this code </strong>into your website</p>"
            >
              <textarea className="rounded form-control form-control-lg" readOnly="readonly" onClick={copyText}>
                &lt;script src="https://plentix.co/js/api/embed-tickets.js"
                type="text/javascript"&gt;&lt;/script&gt;&lt;div style="height: 650px; width: 750px;"&gt;&lt;iframe
                data-ey="true" data-event-id="30" data-key="7b11f4acfa" data-layout="inline" data-label="" id="snqfic3g"
                style="visibility: hidden;"&gt;&lt;/iframe&gt;&lt;/div&gt;
              </textarea>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
