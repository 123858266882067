import React, { useEffect, useRef, useState } from "react";
import placeholder from "../../../assets/placeholder.png";

export const Avatar = ({ formData, setEventImage }) => {
  const [image, setImage] = useState("");
  const documentSelecterRef = useRef();
  const openSelecter = () => documentSelecterRef?.current?.click();
  const supportedTypes = ["image/png", "image/jpeg"];

  useEffect(() => {
    if (formData.image) {
      setImage(formData.image);
    }
  }, [formData]);

  const selectedImage = (e) => {
    const file = e.target.files[0];
    if (supportedTypes.find((type) => type === file.type)) {
      const image = {
        path: URL?.createObjectURL(file),
        file: file
      };

      const formData = new FormData();
      formData.append("image", file);
      setEventImage(file);

      documentSelecterRef.current.value = "";
      setImage(image.path);
    }
  };

  const dragOver = (e) => {};

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;

    const image = {
      path: URL?.createObjectURL(files[0]),
      file: files[0]
    };

    documentSelecterRef.current.value = "";
    setImage(image.path);
  };

  return (
    <div className="card border-0 avatar">
      <input
        onChange={selectedImage}
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        type="file"
        ref={documentSelecterRef}
      />
      <div
        onDrop={fileDrop}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDragOver={dragOver}
        id="openSelecter"
        onClick={openSelecter}
      >
        <img src={image.length > 0 ? image : placeholder} className="card-img-top h-48 object-cover" alt="..." />
        <div>
          <center>
            <p>browse images</p>
            <p>or drag-n-drop one here</p>
          </center>
        </div>
      </div>

      <div className={"mt-4"}>
        <center>
          <p className={"font-extrabold text-xl"}>Uploading image is optional.</p>
          <p className={"text-xl text-gray-400"}>Maximum file size: 4 MB</p>
        </center>
      </div>
    </div>
  );
};
