import { ReactComponent as NextArrow } from "./assets/next-arrow.svg";
import "./Navigation.scss";

export const Navigation = ({ page }) => {
  return (
    <nav className="checkout-navigation d-flex justify-content-center align-items-center">
      <button className="checkout-nav-link completed">Required Information</button>
      <NextArrow />
      <button className={`checkout-nav-link ${page === "payment" ? "completed" : ""}`}>Payment</button>
    </nav>
  );
};
