import React from "react";

export const Button = ({ style, text, icon, className, textClassName, ...props }) => {
  // let styles=['primary','secondary','success','danger','warning','info','light','dark','link'];
  return (
    <button
      type="button"
      {...props}
      className={`btn ${style ? `btn-${style}` : "btn-default"}${className ? ` ${className}` : ""}`}
    >
      <i className={`fa fa-${icon} fa-lg`}></i> {text}
    </button>
  );
};
