import React, { useEffect, useState } from "react";
import { Button } from "./Button.jsx";
import { Input } from "./Input.jsx";
import { Radio } from "./Radio.jsx";
import { Select } from "./Select.jsx";
import { CheckBox } from "./CheckBox.jsx";
import { Category } from "./Category.jsx";
// import currencies from "../../data/currencies.json";
import { getCurrencies } from "../../../services/currencyService";

export const Categories = ({
  onChange,
  selected,
  tickets,
  onCreateTicket,
  onDeleteTicket,
  onAddInfo,
  onDeleteInfo,
  formData,
  handleInputChange,
  colours
}) => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    getCurrencies()
      .then((result) => {
        setCurrencies(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="row my-5 categories">
      <div className="col-12">
        <h2>Tickets</h2>

        <Radio
          onChange={handleInputChange}
          label={"Not available"}
          value="Not available"
          selected={formData?.priceType === "Not available"}
          name="priceType"
          required={true}
        />
        <Radio
          onChange={handleInputChange}
          label={"Free"}
          value="Free"
          selected={formData?.priceType === "Free"}
          name="priceType"
          required={true}
        />
        <Radio
          onChange={handleInputChange}
          label={"Other"}
          value="Other"
          selected={formData?.priceType === "Other"}
          name="priceType"
          required={true}
        />

        {formData?.priceType === "Other" && (
          <Input
            placeholder={"Price description: 20$ to 50$"}
            name={"priceDescription"}
            value={formData?.priceDescription}
            onChange={handleInputChange}
            maxLength={80}
            required={true}
          />
        )}

        {tickets?.length > 0 ? (
          <div className="row">
            {tickets.length > 0 && (
              <div className="col-12 my-5">
                <Select
                  placeholder='Choose currency'
                  required={true}
                  mt={0}
                  label="Currency"
                  options={currencies.map((item) => ({ _id: item._id, name: `${item.name} (${item.symbol})` }))}
                  name="tickets-currency"
                  onSelect={(e) => {
                    const currencyId = currencies.find((x) => {
                      return x._id === e.target.value;
                    })?._id;
                    handleInputChange({ target: { name: "tickets-currency", value: currencyId } });
                  }}
                  selected={formData["tickets-currency"]}
                  // selected={(() => {
                  //   const currency = currencies.find((x) => x.id === formData["tickets-currency"]);
                  //   return currency ? `${currency.name} (${currency.symbol})` : "";
                  // })()}
                />
                <div className="row mt-2">
                  <div className="col-md-4">
                    <CheckBox
                      name="tickets-age-restriction"
                      labelText="Age restricted event (18+)"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-8">
                    <CheckBox
                      name="tickets-require-registration"
                      labelText="Allow only registered users to buy tickets"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {tickets.map((item, i) => (
              <div className="row" key={item.color + i}>
                <div className="col-12 my-2">
                  <Category
                    onDeleteTicket={() => onDeleteTicket(i)}
                    data={item}
                    onAddInfo={(val) => onAddInfo(val, i)}
                    onDeleteInfo={(val) => onDeleteInfo(val, i)}
                    formData={formData}
                    handleInputChange={handleInputChange}
                    index={i}
                    minPrice={currencies.find((x) => x.id === formData["tickets-currency"])?.min}
                    maxPrice={currencies.find((x) => x.id === formData["tickets-currency"])?.max}
                    colours={colours}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          <div className="col-12 text-center mt-5">
            <h2>Add tickets to your event</h2>
            <h3>using Plentix ticket system</h3>

            <div className="m-5">
              <Button
                onClick={() => onCreateTicket({ free: true })}
                text="Free tickets"
                icon="plus"
                style="success"
                className="mx-2"
              />
              <Button
                onClick={() => onCreateTicket({ free: false })}
                text="Paid tickets"
                icon="plus"
                style="success"
                className="mx-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
