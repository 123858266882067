import { get, post, patch, deleteReq } from "../helpers/fetcher";

export const registerUser = async (userData) => {
  const endpoint = `/register`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, userData);
};

export const loginUser = async (userData) => {
  const endpoint = `/login`;
  const header = { "Content-Type": "application/json" };
  return await post(endpoint, header, userData);
};

export const getUser = async (id) => {
  const endpoint = `/users/${id}`;
  const header = { "Content-Type": "application/json" };

  return await get(endpoint, header);
};

export const updateUser = async (id, userData) => {
  const endpoint = `/users/${id}`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, userData);
};

export const updateUserSettings = async (id, emailQuery, data) => {
  const endpoint = `/users/${id}/settings?email=${emailQuery}`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, data);
};

export const getUserEvents = async (id) => {
  const endpoint = `/users/${id}/events`;
  const header = { "Content-Type": "application/json" };

  return await get(endpoint, header);
};

export const getUserRsvp = async (id) => {
  const endpoint = `/users/${id}/rsvp`;
  const header = { "Content-Type": "application/json" };

  return await get(endpoint, header);
};
export const getUserFollowers = async (id) => {
  const endpoint = `/users/${id}/followers`;
  const header = { "Content-Type": "application/json" };

  return await get(endpoint, header);
};

export const postUserEvents = async (id, userData) => {
  const endpoint = `/users/${id}/events`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, userData);
};

export const postUserHosts = async (id, userData) => {
  const endpoint = `/users/${id}/hosts`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, userData);
};

export const postUserFollowing = async (id, data) => {
  const endpoint = `/users/${id}/following`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, data);
};

export const postUserImages = async (id, body) => {
  const endpoint = `/users/${id}/images`;
  const header = {};

  return post(endpoint, header, body);
};

export const postUserFriendRequest = async (id, data) => {
  const endpoint = `/users/${id}/friend/request`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, data);
};

export const postUserCategories = async (id, data) => {
  const endpoint = `/users/${id}/categories`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, data);
};

export const postUserTags = async (id, data) => {
  const endpoint = `/users/${id}/tags`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, data);
};

export const patchUserFollowing = async (id, data) => {
  const endpoint = `/users/${id}/following`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, data);
};

export const patchUserPassword = async (id, userData) => {
  const endpoint = `/users/${id}/password`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, userData);
};

export const patchUserCancelRequest = async (id, data) => {
  const endpoint = `/users/${id}/friend/decline`;
  const header = { "Content-Type": "application/json" };
  return await patch(endpoint, header, data);
};

export const patchRemoveFriend = async (id, data) => {
  const endpoint = `/users/${id}/friend/not-friends`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, data);
};

export const patchAcceptFriend = async (id, data) => {
  const endpoint = `/users/${id}/friend/accept`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, data);
};
