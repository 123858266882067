import { get, post, patch, deleteReq } from "../helpers/fetcher";

export const postDiscount = async (user,data) => {
  const endpoint = `/discounts`;
  const header = { "Content-Type": "application/json" };

  return await post(endpoint, header, data);
};

export const patchDiscount = async (user,id,data) => {
  const endpoint = `/discounts/${id}/active`;
  const header = { "Content-Type": "application/json" };

  return await patch(endpoint, header, data);
};

export const deleteDiscount = async (user,id) => {
  const endpoint = `/discounts/${id}`;
  const header = { "Content-Type": "application/json" };

  return await deleteReq(endpoint, header);
};