import { ReactComponent as CheckIcon } from "./assets/check.svg";
import "./Checkbox.scss";

export const Checkbox = ({ checked, setChecked }) => {
  return (
    <button type="button" className="checkout-checkbox" onClick={() => setChecked(!checked)}>
      {checked && <CheckIcon />}
    </button>
  );
};
