import { useState, useEffect } from "react";

import "./Welcome.scss";

export const Welcome = ({ newPage }) => {
  return (
    <div className="mask steps mb-5">
      <div className="wrapper">
        <div className="slide h-100 justify-content-center welcome d-flex flex-column align-items-center">
          <h1>Welcome to Plentix!</h1>
          <p>
            Plentix helps you discover exciting events happening around you,
            <br />
            follow your favourite promoters and stay tuned with their next gigs.
          </p>
          <button
            className="start-setup nav-button-primary text-white d-flex align-items-center py-3 px-5"
            // href="#"
            onClick={newPage}
          >
            Set up your profile&nbsp;
            <img className="ms-3" src="/img/svg/icon-arrow-right-long.svg" alt="Set up your profile" />
          </button>
        </div>
      </div>
    </div>
  );
};
