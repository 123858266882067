import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTicket,
  faTags,
  faChartLine,
  faArrowUpRightFromSquare,
  faFileInvoiceDollar,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { postCancelEvents } from "../../services/eventService";
import { getUser } from "../../services/userService";

import "./RightMenu.scss";

export const RightMenu = ({ event }) => {
  const [user, setUser] = useState();
  const [cancelEventBtn, setCancelEventBtn] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    getUser(userId).then((result) => {
      setUser(result);
    });
  }, []);

  useEffect(() => {
    user?.events?.forEach((el) => {
      if (el._id == event._id) {
        setCancelEventBtn(true);
      }
    });
  }, [event]);

  function cancelEventHandler(eventId) {
    if (window.confirm("Are you sure ypu want to cancel this event! You cannot undo this action.")) {
      const userId = localStorage.getItem("userId");
      postCancelEvents(eventId);
      window.location.href = window.location.origin;
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-12 text-center">
          <span className="type-of-event">
            <img src="/img/event/public-icn.svg" width="14" />
            &nbsp;
            <span className="me-2 public">Public</span>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <ul className="event-edit-list d-inline-block p-0">
            <li className="mt-1">
              <a className="primary " href={`/events/${event.category}/${event.name}/${event._id}/edit`}>
                <FontAwesomeIcon className="font-awesome me-2" icon={faPenToSquare} /> Edit event
              </a>
            </li>
            <li>
              <a className="event-statistics" href={`/dashboard/${event._id}`}>
                <FontAwesomeIcon className="font-awesome me-2" icon={faChartLine} /> Event insights
              </a>
            </li>
            {event?.categories?.length > 0 ? (
              <>
                <li>
                  <a className="tickets" href={`/events/${event._id}/manage`}>
                    <FontAwesomeIcon className="font-awesome me-2" icon={faTicket} /> Manage tickets
                  </a>
                </li>
                <li>
                  <a className="discount-codes" href={`/events/${event._id}/discounts`}>
                    <FontAwesomeIcon className="font-awesome me-2" icon={faTags} /> Manage discount codes
                  </a>
                </li>
                <li>
                  <a className="embed" href="#">
                    <FontAwesomeIcon className="font-awesome me-2" icon={faArrowUpRightFromSquare} /> Embed tickets
                  </a>
                </li>
                <li>
                  <a className="billing" href="#">
                    <FontAwesomeIcon className="font-awesome me-2" icon={faFileInvoiceDollar} /> Billing information
                  </a>
                </li>
              </>
            ) : cancelEventBtn ? (
              <li>
                <button className="billing p-0" onClick={() => cancelEventHandler(event._id)}>
                  <FontAwesomeIcon className="font-awesome me-2" icon={faTrash} /> Cancel event
                </button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
