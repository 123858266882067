import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "../../../../slick-theme.css";
import "./LatestOnEvedo.scss";

class LatestOnEvedo extends Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    // let currentCategory = props.props.currentCategory;
    const settings = {
      dots: false,
      arrows: false,
      speed: 1000,
      slidesToShow: 5,
      autoplay: false,
      pauseOnFocus: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
    return (
      <div className="row">
        <div className="container mx-auto latest-evedo py-5">
          <div className="row">
            <div className="container pb-4">
              <div className="row flex-md-nowrap">
                <div className="col-xl-3 col-md-5 col-12 me-5">
                  <div className="row flex-column">
                    <h3 className="h5 text-white">
                      <b>{this.props.content.title}</b>
                    </h3>
                    <div className="text-s mb-5 mt-4 text-white">{this.props.content.text}</div>
                    <a
                      href={this.props.content.link.url}
                      target="_blank"
                      className="text-pink label-l fw-500 d-flex align-items-start"
                    >
                      {this.props.content.link.title} <span className="arrowIcon ms-1 d-flex">{pinkArrow()}</span>{" "}
                    </a>
                  </div>
                </div>
                {this.props.slides.length > 5 ? (
                  <div className="mt-5 mt-md-0 px-0">
                    <div className="container col-lg-8 col-md-6 col-12 m-0">
                      <div className="row">
                        <div className="col-12">
                          <div className="latest-actions d-flex justify-content-between">
                            <div className="latest-switcher">
                              <a
                                href="#"
                                data-latest="performers"
                                className={`cta-text latest-button link-transition text-l fw-500 ${
                                  this.props.selected == "performers" ? "selected" : ""
                                }`}
                                onClick={this.props.changeSlides}
                              >
                                {this.props.content.button_names.performers}
                              </a>
                              <a
                                href="#"
                                data-latest="venues"
                                className={`cta-text latest-button link-transition text-l fw-500 ms-2 ${
                                  this.props.selected == "venues" ? "selected" : ""
                                }`}
                                onClick={this.props.changeSlides}
                              >
                                {this.props.content.button_names.venues}
                              </a>
                            </div>
                            <div className="d-flex align-items-center">
                              <div
                                className="custom-prev-arrow custom-arrow"
                                id="custom-prev-arrow"
                                onClick={this.previous}
                              >
                                {prevArrowIcon()}
                              </div>
                              <div
                                className="custom-next-arrow ms-1 custom-arrow"
                                id="custom-next-arrow"
                                onClick={this.next}
                              >
                                {nextArrowIcon()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="position-relative latest-slider-wrap">
                      <div className="latest-slider position-md-absolute">
                        <Slider {...settings} ref={(c) => (this.slider = c)}>
                          {this.props.slides.map((slide, i) => (
                            <a
                              href={slide.url.replace(".json", "")}
                              key={i}
                              className="promoter-card slide-rounded overflow-hidden me-3 position-relative link-transition"
                            >
                              <div
                                className="promoter-content position-absolute link-transition"
                                style={{
                                  backgroundImage: `url(${
                                    slide.user
                                      ? this.props.props.domain + slide.user.avatar.url
                                      : this.props.props.domain + slide.banner.url
                                  })`
                                }}
                              ></div>
                              <div className="slide-item-title bg-white w-100 position-absolute m-0 link-transition d-flex justify-content-between align-items-end">
                                {slide.full_name ? slide.full_name : slide.name}
                                <span className="d-flex">{greyArrow()}</span>
                              </div>
                            </a>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="latest-actions d-flex justify-content-start">
                          <div className="latest-switcher">
                            <a
                              href="#"
                              data-latest="performers"
                              className={`cta-text latest-button link-transition text-l fw-500 ${
                                this.props.selected == "performers" ? "selected" : ""
                              }`}
                              onClick={this.props.changeSlides}
                            >
                              {this.props.content.button_names.performers}
                            </a>
                            <a
                              href="#"
                              data-latest="venues"
                              className={`cta-text latest-button link-transition text-l fw-500 ms-2 ${
                                this.props.selected == "venues" ? "selected" : ""
                              }`}
                              onClick={this.props.changeSlides}
                            >
                              {this.props.content.button_names.venues}
                            </a>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 mt-5">
                        <div className="row">
                          {this.props.slides.map((slide, i) => (
                            <div key={"slide-" + i} className={"col-lg-2 col-sm-3 col-6 position-relative d-flex"}>
                              <a
                                href={slide.url.replace(".json", "")}
                                key={i}
                                className="promoter-card slide-rounded overflow-hidden me-3 position-relative link-transition w-100"
                              >
                                <div
                                  className="promoter-content position-absolute link-transition"
                                  style={{
                                    backgroundImage: `url(${
                                      slide.user.avatar.url
                                        ? this.props.props.domain + slide.user.avatar.url
                                        : this.props.props.domain + slide.banner.url
                                    })`
                                  }}
                                ></div>
                                <div className="slide-item-title bg-white w-100 position-absolute m-0 link-transition d-flex justify-content-between align-items-end">
                                  {slide.full_name ? slide.full_name : slide.name}
                                  <span className="d-flex">{greyArrow()}</span>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LatestOnEvedo;

const prevArrowIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.626003 15.6584C0.626003 24.0028 7.36171 30.7652 15.668 30.7652C23.9744 30.7652 30.7101 24.0028 30.7101 15.6584C30.7101 7.31401 23.9744 0.55163 15.668 0.55163C7.36171 0.55163 0.626003 7.31401 0.626003 15.6584Z"
      fill="white"
      stroke="#D6D3DF"
      strokeWidth="0.583729"
    />
    <path
      d="M16.1113 12.4404L12.9291 15.6589L16.1113 18.8774"
      stroke="#F347BA"
      strokeWidth="1.16746"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const nextArrowIcon = () => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.5509 20.812C40.5509 31.7337 31.7348 40.5847 20.863 40.5847C9.99111 40.5847 1.17498 31.7337 1.17498 20.812C1.17498 9.89027 9.99111 1.03924 20.863 1.03924C31.7348 1.03924 40.5509 9.89027 40.5509 20.812Z"
      fill="white"
      stroke="#D6D3DF"
      strokeWidth="0.764022"
    />
    <path
      d="M20.2832 16.5996L24.4483 20.8121L20.2832 25.0247"
      stroke="#F347BA"
      strokeWidth="1.52804"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const pinkArrow = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#F347BA"
      d="M11.679 0.232422H6.63383C6.14253 0.232422 5.74572 0.61969 5.74572 1.09916C5.74572 1.57864 6.14253 1.96591 6.63383 1.96591H9.506L1.15403 10.1354C0.79501 10.4858 0.79501 11.0206 1.15403 11.371C1.32409 11.537 1.55084 11.6292 1.77759 11.6292C2.00434 11.6292 2.23109 11.537 2.40116 11.371L10.772 3.21992V6.023C10.772 6.50247 11.1688 6.88974 11.6601 6.88974C12.1514 6.88974 12.5482 6.50247 12.5482 6.023V1.09916C12.5671 0.61969 12.1703 0.232422 11.679 0.232422Z"
    />
  </svg>
);
const greyArrow = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.16314 0.182617H3.9872C3.67793 0.182617 3.42813 0.426402 3.42813 0.728231C3.42813 1.03006 3.67793 1.27384 3.9872 1.27384H5.79523L0.537667 6.41655C0.311663 6.63711 0.311663 6.97377 0.537667 7.19434C0.644721 7.29882 0.78746 7.35686 0.9302 7.35686C1.07294 7.35686 1.21568 7.29882 1.32273 7.19434L6.59219 2.06324V3.82778C6.59219 4.12961 6.84198 4.3734 7.15125 4.3734C7.46052 4.3734 7.71031 4.12961 7.71031 3.82778V0.728231C7.72221 0.426402 7.47241 0.182617 7.16314 0.182617Z"
      fill="#5232AE"
    />
  </svg>
);
