import "./Partners.scss";

export const Partners = () => {
  return (
    <div className="trusted-by-box fadefrombottom">
      <img src="/img/organizers/lovefest-logo-drk-1.jpg" loading="lazy" alt="lovefest logo" className="trusted-logo" />
      <img
        src="/img/organizers/tmrwland_logo-1.jpg"
        loading="lazy"
        width="100"
        alt="tomorrow land around the world logo"
        className="trusted-logo"
      />
      <img
        src="/img/organizers/drooble-logo-dark-1.jpg"
        loading="lazy"
        width="100"
        alt="drooble logo"
        className="trusted-logo"
      />
      <img
        src="/img/organizers/djanemag-1.png"
        loading="lazy"
        width="100"
        alt="djanemag logo"
        className="trusted-logo"
      />
      <img src="/img/organizers/Stagecast-Lg-1.png" loading="lazy" width="100" alt="stagecast logo" />
    </div>
  );
};
