import "./TicketCard.scss";

export const TicketCard = ({ ticket }) => {
  return (
    <div className="ticket-card m-2">
      <a className="event-details" href={ticket.eventLink} target="_blank" rel="noreferrer">
        <strong>{ticket.name}</strong>
        <span>
          <strong>When:</strong> {ticket.date}
        </span>
        <span>
          <strong>Where:</strong> {ticket.location}
        </span>
      </a>

      <div className="ticket-details">
        <strong className="label">Price</strong>
        <span className="value">{+ticket.price > 0 ? `$${(+ticket.price).toFixed(2)}` : "FREE"}</span>

        <strong className="label">Category</strong>
        <span className="color" style={{ background: "#429506" }}></span>
        <span className="value">{ticket.category}</span>
      </div>

      <div className="ticket-codes">
        <img alt="qr code" src={ticket.qr} />

        <div className="number">
          <strong className="label">Ticket</strong>
          <span className="value">{ticket.amount}</span>
        </div>

        <div className="code">
          <strong className="label">Security code</strong>
          <span className="value">{ticket.code}</span>
        </div>
      </div>

      <div className="signature">
        <span>powered by</span>
        <img
          alt="evedo"
          src="https://local.evedo.co/img/mail/logo.png"
          className="logo"
          style={{ marginLeft: "15px" }}
        />
        <img alt="evedo" src="https://local.evedo.co/img/mail/ticket-logo-white.jpg" className="white-logo" />
      </div>
    </div>
  );
};
