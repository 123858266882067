import { updateUserSettings } from "../../services/userService";
import "./NotificationSettingSwitch.scss";

export const NotificationSettingSwitch = ({ setting, checked, setUser }) => {
  const handleSelect = async (e, settingId) => {
    let status = true;

    if (e.target.className == "on") {
      status = false;
    } else {
      status = true;
    }

    try {
      const user = await updateUserSettings(localStorage.getItem("userId"), settingId, { status: status });
      setUser(user);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="notification-setting-switch block col-12 justify-content-between d-flex col-lg-4 col-md-6">
      <div className="title-container">
        <strong>{setting.title}</strong>
        {setting.description}
      </div>

      <label className={`switch ${!checked ? "unchecked" : ""}`} onClick={(e) => handleSelect(e, setting.id)}>
        <span className="on">on</span>
        <span className="thumb"></span>
        <span className="off">off</span>
      </label>
    </div>
  );
};
