import React from "react";

import Header from "./components/Header";
import NearbyEvents from "./components/NearbyEvents";
import PromoterSlider from "./components/PromoterSlider";
import CallToAction from "./components/CtaSection";
import About from "./components/About";
import initLocationAutocomplete from "../Homepage/initAutocomplete";

import allCategories from "../../data/categories.json";
import { propsDiscover } from "../../constants/props";

import "./Discover.scss";

export class Discover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      isLoaded: false,
      selectedLanguage: props.selectedLanguage,
      cardsLength: 0,
      promoters: [],
      aboutContent: {},
      cityData: {},
      categoryId: 0,
      initialSearch: false
    };
    this.loadMore = this.loadMore.bind(this);
    this.languageSwitch = this.languageSwitch.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.loadNearbyEvents = this.loadNearbyEvents.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  languageSwitch(e) {
    e.preventDefault();
    let el = e.target;
    this.setState({
      selectedLanguage: el.getAttribute("data-lang")
    });
  }
  getLocation(e) {
    let t = this;
    e.preventDefault();
    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(`Latitude: ${latitude} °, Longitude: ${longitude} °`);
      console.log(position);
      fetch(`profile/change-location-geoip?accept=1&latitude=${latitude}&longitude=${longitude}`)
        .then((res) => res.json())
        .then((result) => console.log(result))
        .catch((err) => console.log("Error: " + err));
    }
    function error() {
      console.log("Unable to retrieve your location");
    }
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
    } else {
      console.log("Locating…");
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }
  onInputChange() {
    this.setState({ initialSearch: false });
  }
  componentDidMount() {
    if (!this.state.isLoaded) {
      // load the first two event cards initially
      let cl = propsDiscover.currentLocation;
      let currentLocation = cl.city + ", " + cl.country;
      let currentLocationQuery =
        cl.city && cl.country && cl.lat && cl.lng ? `&location=${currentLocation}&lat=${cl.lat}&lng=${cl.lng}` : "";
      let currentCat = this.props.currentCategory.toLowerCase();

      this.loadNearbyEvents(`pageId=1${currentLocationQuery}`);
      fetch(
        `/hosts-load/?featured=true&pageId=1&category=${
          currentCat.includes("&") ? currentCat.replace("&", "%26") : currentCat
        }`
      )
        .then((res) => res.json())
        .then((result) =>
          this.setState({
            promoters: Object.values(result.hosts)
          })
        );
      let about = {};
      let catId = 0;
      Object.values(allCategories).map((cat) => {
        if (cat.name_plural == this.props.currentCategory) {
          about = cat.metadata;
          catId = cat.id;
        }
      });
      this.setState({
        isLoaded: true,
        aboutContent: about,
        categoryId: catId
      });
      initLocationAutocomplete(document.querySelector("#location-autocomplete"), this, () => {
        let cd = this.state.cityData;
        let loc = `/?location=${cd.city}, ${cd.country}&city-name=${cd.city}&country-code=${cd.countryCode}&lat=${cd.lat}&lng=${cd.lng}`;
        fetch(`/events-loader/${this.props.currentCategory.toLowerCase()}${loc}`)
          .then((res) => res.json())
          .then((result) => {
            this.setState({
              events: Object.values(result.events).filter((event) =>
                event.where.includes(`${cd.city}`) && event.country_code.includes(`${cd.countryCode.toLowerCase()}`)
                  ? event
                  : null
              ),
              cardsLength: Object.values(result.events).filter((event) => event.where.includes(`${cd.city}`)).length,
              initialSearch: true
            });
          })
          .catch((error) => console.log(error));
      });
    }
  }
  loadMore(e) {
    // load more nearby event cards
    e.preventDefault();

    let newCards = [];
    this.state.events.map((card, id) =>
      id + 1 > this.state.events.length && id <= this.state.events.length + 2 ? newCards.push(card) : null
    );
    this.setState({ events: [...this.state.events, ...newCards] });
  }
  loadNearbyEvents(params) {
    fetch(`/events-loader/${this.props.currentCategory.toLowerCase()}/?${params}`)
      .then((res) => res.json())
      .then((result) =>
        this.setState({
          events: Object.values(result.events).filter((event) =>
            event.where.includes(`${propsDiscover.currentLocation.city}`) &&
            event.where.includes(`${propsDiscover.currentLocation.country}`)
              ? event
              : null
          ),
          cardsLength: result.eventsToLoad <= result.totalCount ? result.eventsToLoad : result.totalCount
        })
      );
  }
  render() {
    return (
      <div>
        <Header texts={propsDiscover.texts} currentCategory={this.props.currentCategory} />
        <div className="container">
          <NearbyEvents
            locationDefault={
              propsDiscover.currentLocation.city && propsDiscover.currentLocation.country
                ? propsDiscover.currentLocation.city + ", " + propsDiscover.currentLocation.country
                : ""
            }
            isLogged={this.props.isLogged}
            catId={this.state.categoryId}
            change={this.onInputChange}
            getLocation={this.getLocation}
            content={propsDiscover.texts.events}
            cardTexts={propsDiscover.texts.event_card}
            categories={Object.values(allCategories)}
            currentCategory={this.props.currentCategory}
            cardsLength={this.state.cardsLength}
            cards={this.state.events}
            loadCards={this.loadMore}
          />
        </div>
        {this.state.promoters.length ? (
          <div className="col-12 col-sm-11 ms-auto notable-promoters py-5 ps-md-0">
            <PromoterSlider
              content={propsDiscover.texts.promoters}
              currentCategory={this.props.currentCategory}
              slides={this.state.promoters}
            />
          </div>
        ) : (
          ""
        )}
        <CallToAction content={propsDiscover.texts.marketplace} isLogged={this.props.isLogged} />
        <div className="container">
          <About content={this.state.aboutContent} />
        </div>
      </div>
    );
  }
}
