import React from "react";
import CONFIG from "../../../config/config";
import Autocomplete from "react-google-autocomplete";

export default function Maps({ formData, handleInputChange }) {
  return (
    <div className="row">
      <div className="col-12">
        <h2 className="">Where?</h2>
        <div className="mt-3">
          <iframe
            width="100%"
            height="400"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe>
          <Autocomplete
            className="location-input form-control form-control-lg"
            apiKey={CONFIG.googlePlacesAPI}
            onPlaceSelected={(place) => {
              const lat = place.geometry?.location.lat();
              const lng = place.geometry?.location.lng();
              handleInputChange({ target: { name: "location", value: { lat, lng } } });
            }}
            placeholder="Place, Venue, etc."
            autoComplete="off"
            type="text"
            defaultValue={""}
            language="en"
            onChange={(e) => {
              if (!e.target.value) {
                handleInputChange({ target: { name: "location", value: { lat: "", lng: "" } } });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
