import { Link } from "react-router-dom";
import "./TicketsMobile.scss";

export const TicketsMobile = ({ event }) => {
  return (
    <div className="event-tickets-mobile d-sm-block d-md-none cta-box-sm p-3">
      <p className="price-line col-12">
        <img alt="ticket" src="https://plentix.co/img/event/icn-ticket.svg" width="28" />
        <span className="date-highlight pe-2 me-2">
          {event.startAt?.split(" ").slice(1, 3).join(" ")} - {event.endAt?.split(" ").slice(1, 3).join(" ")}
        </span>
        {event.settings?.priceType}
      </p>

      <Link to={`/events/${event.id}/orders/new`} className="buy btn btn-primary col-12 mb-3">
        Get Tickets
      </Link>
    </div>
  );
};
