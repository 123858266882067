import { useState } from "react";
import { Modal } from "../Common/Modal";
import { DiscountForm } from "./DiscountForm";
import { TicketCard } from "./TicketCard";
import "./Pricing.scss";

export const Pricing = ({ selectedTickets, isNew }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="mx-4 pt-3 checkout-event-pricing">
      <h6 className="title">Summary</h6>

      <div className="chosen-tickets-list pt-3">
        {selectedTickets.map((ticket) => (
          <TicketCard key={ticket.information.name} ticket={ticket} />
        ))}
      </div>

      <div className="total-prices-container pt-3">
        <p className="fees">Fees: €22.99</p>
        <p className="total">Total: €{selectedTickets.reduce((a, c) => (a += c.information.price * c.amount), 0).toFixed(2)}</p>
        {isNew && 
          <p className="discount">
            Have a discount code?{" "}
            <button onClick={() => setShowModal(true)} className="discount-link">
              Apply
            </button>
          </p>
        }
      </div>
      {showModal && (
        <Modal setShowModal={setShowModal}>
          <DiscountForm setShowModal={setShowModal} />
        </Modal>
      )}
    </div>
  );
};
