const initLocationAutocomplete = (input, that, callback) => {
  // Prepare location info object.
  let locationInfo = {
    lat: null,
    lng: null,
    country: null,
    state: null,
    city: null,
    postalCode: null,
    street: null,
    streetNumber: null,
    sublocality: null,
    countryCode: null,
    reset: function () {
      that.lat = null;
      that.lng = null;
      that.country = null;
      that.state = null;
      that.city = null;
      that.postalCode = null;
      that.street = null;
      that.streetNumber = null;
      that.sublocality = null;
      that.countryCode = null;
    }
  };
  var observerHack = new MutationObserver(function () {
    observerHack.disconnect();
    input.setAttribute("autocomplete", "off");
  });

  observerHack.observe(input, {
    attributes: true,
    attributeFilter: ["autocomplete"]
  });
  // let autocomplete;
  // autocomplete = new google.maps.places.Autocomplete(input, {
  //     fields: ["address_components", "geometry"],
  //     types: ['(regions)'],
  // });
  // google.maps.event.addListener(autocomplete, "place_changed", function() {
  //     // Segment results into usable parts.
  //     var place = autocomplete.getPlace(),
  //       address = place.address_components,
  //       lat = place.geometry.location.lat(),
  //       lng = place.geometry.location.lng();

  //     // Reset location object.
  //     locationInfo.reset();

  //     // Save the individual address components.
  //     locationInfo.lat = lat;
  //     locationInfo.lng = lng;
  //     for (var i = 0; i < address.length; i++) {
  //       var component = address[i].types[0];
  //       switch (component) {
  //         case "country":
  //           locationInfo.country = address[i]["long_name"];
  //           locationInfo.countryCode = address[i]["short_name"];
  //           break;
  //         case "administrative_area_level_1":
  //           locationInfo.state = address[i]["long_name"];
  //           break;
  //         case "locality":
  //           locationInfo.city = address[i]["long_name"];
  //           break;
  //         case "sublocality":
  //           locationInfo.sublocality = address[i]["long_name"];
  //           break;
  //         case "postal_code":
  //           locationInfo.postalCode = address[i]["long_name"];
  //           break;
  //         case "route":
  //           locationInfo.street = address[i]["long_name"];
  //           break;
  //         case "street_number":
  //           locationInfo.streetNumber = address[i]["long_name"];
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //     that.setState({
  //         cityData: locationInfo
  //     }, callback);
  // })
  // input.focus();
};
export default initLocationAutocomplete;
