import React, { useState } from "react";
import { Form } from "./Manage/Form.jsx";

export const Edit = () => {
  return (
    <div className="container">
      <Form
        categories={[
          "Campaign",
          "Competition",
          "Concert",
          "Conference",
          "Course",
          "Exhibition",
          "Festival",
          "Meetup",
          "Party",
          "Performance",
          "Theatre",
          "Travel",
          "Workshop"
        ]}
      />
    </div>
  );
};
<select class="form-control form-control-lg col-12" name="category">
  <option value="0">Choose category</option>
  <option value="1">Campaign</option>
  <option value="2">Competition</option>
  <option value="3">Concert</option>
  <option value="4">Conference</option>
  <option value="5">Course</option>
  <option value="6">Exhibition</option>
  <option value="7">Festival</option>
  <option value="9">Meetup</option>
  <option value="10">Party</option>
  <option value="11">Performance</option>
  <option value="13">Theatre</option>
  <option value="14">Travel</option>
  <option value="15">Workshop</option>
</select>;
