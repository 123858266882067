import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Description } from "../../Common/Form/Description.jsx";
import { Promoter } from "../../Common/Form/Promoter.jsx";
import { DateTime } from "../../Common/Form/DateTime.jsx";
import { Avatar } from "../../Common/Form/Avatar";
import Maps from "../../Common/Form/Maps.jsx";
import { Categories } from "../../Common/Form/Categories.jsx";

import {
  postEvents,
  patchEvents,
  getEvent,
  patchEventsSettings,
  patchEventsCategory,
  postEventsImages
} from "../../../services/eventService.js";
import { getCurrency } from "../../../services/currencyService.js";
import { postSettings } from "../../../services/settingServices.js";
import { postCategories } from "../../../services/categoriesService.js";
import { getUser, postUserEvents } from "../../../services/userService.js";
import { postHostEvents } from "../../../services/hostService.js";

export const Form = ({ categories }) => {
  const [selectedCategories, setSelectedCategories] = useState("");
  const [tickets, setTickets] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [categoriesIds, setCategoriesIds] = useState([]);
  const [eventImage, setEventImage] = useState(null);
  let location = useLocation();
  let locationParams = useParams();
  const isEditForm = location.pathname.includes("edit");

  const colours = [
    "#005C11",
    "#429506",
    "#69CE22",
    "#FFD800",
    "#FF9D00",
    "#FF5722",
    "#F23123",
    "#AE1D11",
    "#9C1F80",
    "#7E1F9D",
    "#1E399C",
    "#2284D1",
    "#23C3D0",
    "#4FEAFF",
    "#009688",
    "#26D094",
    "#795548",
    "#617D8B",
    "#9E9E9E",
    "#113242"
  ];
  const [formData, setFormData] = useState({
    selectedPromoter: "",
    image: "",
    title: "",
    subtitle: "",
    category: "",
    topic: "",
    tags: [],
    description: "",
    private: "",
    online: "",
    startAt: "",
    startTime: "",
    endAt: "",
    endTime: "",
    priceType: "",
    location: {
      lat: "",
      lng: ""
    },
    priceDescription: "",
    "tickets-currency": "",
    "tickets-age-restriction": false,
    "tickets-require-registration": false,
    "ticket-category-name": [],
    "ticket-category-quantity": [],
    "ticket-category-active": [],
    "ticket-start-date": [],
    "ticket-start-time": [],
    "ticket-end-date": [],
    "ticket-end-time": [],
    "ticket-category-description": [],
    "ticket-category-limit": [],
    "ticket-category-information": [],
    "ticket-category-price": [],
    "ticket-category-color": []
  });

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (location.search.includes("?host")) {
      const hostId = location.search.split("?host=")[1];
      setFormData((prev) => ({ ...prev, selectedPromoter: hostId }));
    }

    if (isEditForm) {
      setInformationOnEvent();
    }

    getUser(userId)
      .then((result) => setPromoters(result.hosts))
      .catch((err) => console.error(err));
  }, []);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    const updatedFormData = { ...formData };
    updatedFormData[name] = value;
    setFormData(updatedFormData);
  };

  const onCreateTicket = ({ free = false }) => {
    const tik = [...tickets];
    if (free) {
      let ticketsPrice = [...formData["ticket-category-price"]];
      ticketsPrice.push(0);
      setFormData({ ...formData, "ticket-category-price": [...ticketsPrice] });
    }

    tik.push({ free, selectedInfo: [], color: colours[tik.length] });
    const updatedColors = [...formData["ticket-category-color"]];
    setFormData({ ...formData, "ticket-category-color": [...updatedColors, colours[tik.length - 1]] });
    setTickets(tik);
  };

  const onDeleteTicket = (index) => {
    const tik = [...tickets];

    tik.splice(index, 1);

    setTickets(tik);

    if (isEditForm) {
      const categoriesIdsCopy = [...categoriesIds];

      categoriesIdsCopy.splice(index, 1);
      setCategoriesIds(categoriesIdsCopy);
    }

    const updatedFormData = { ...formData };
    Object.keys(updatedFormData).forEach((key) => {
      if (Array.isArray(updatedFormData[key])) {
        updatedFormData[key].splice(index, 1);
      }
    });
    setFormData(updatedFormData);
  };

  const onAddInfo = (val, i) => {
    if (val === "not") return;

    const itemIndex = tickets.findIndex((item, index) => i === index);
    const item = tickets.find((item, index) => i === index);

    if (!item.selectedInfo.some((x) => x.field_type === val)) {
      item["selectedInfo"] = [...item?.selectedInfo, { type: "default", field_type: val }];
    }

    const tik = [...tickets];

    tik.splice(itemIndex, 1, item);
    setTickets(tik);

    const requiredFields = JSON.stringify(tik[i].selectedInfo);
    const currentData = formData["ticket-category-information"];
    currentData[i] = requiredFields.substring(1, requiredFields.length - 1);
    handleInputChange({ target: { name: "ticket-category-information", value: currentData } });
  };

  const onDeleteInfo = (val, i) => {
    const itemIndex = tickets.findIndex((item, index) => i === index);
    const item = tickets.find((item, index) => i === index);

    item["selectedInfo"] = item?.selectedInfo?.filter((data) => data !== val);

    const tik = [...tickets];

    tik.splice(itemIndex, 1, item);
    setTickets(tik);

    const requiredFields = JSON.stringify(tik[i].selectedInfo);
    const currentData = formData["ticket-category-information"];
    currentData[i] = requiredFields.substring(1, requiredFields.length - 1);
    handleInputChange({ target: { name: "ticket-category-information", value: currentData } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    let numberOfCategories = [];

    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add("was-validated");
    } else {
      const userId = localStorage.getItem("userId");
      if (userId) {
        form.classList.add("was-validated");

        const tagsData = [];

        if (formData.tags.length > 0) {
          formData.tags.forEach((el) => {
            tagsData.push(el.title);
          });
        }

        try {
          let currencyInfo = "";
          if (formData["tickets-currency"]) {
            currencyInfo = await getCurrency(formData["tickets-currency"]);
          }

          const settingsData = {
            currency: currencyInfo._id,
            requireRegistration: formData["tickets-require-registration"],
            ageRestriction: formData["tickets-age-restriction"],
            priceType: formData.priceType
          };
          const settingsInfo = isEditForm
            ? await patchEventsSettings({}, locationParams.id, settingsData)
            : await postSettings(settingsData);

          let eventImageURL = undefined;

          const eventData = {
            image: formData.image,
            // image:eventImageURL? eventImageURL: formData.image,
            name: formData.title,
            category: formData.category,
            startAt: `${formData.startAt} ${formData.startTime}`,
            endAt: `${formData.endAt} ${formData.endTime}`,
            pageTextHeader: formData.topic,
            pageTextBody: formData.subtitle,
            location: formData.location,
            description: formData.description,
            tags: tagsData,
            private: formData.private.length === 0 ? false : true,
            online: formData.online.length === 0 ? false : true,
            priceDescription: formData.priceDescription,
            settings: settingsInfo._id,
            host: formData.selectedPromoter.length > 0 ? formData.selectedPromoter : userId
          };

          const json = JSON.stringify(eventData);

          var formDataEvent = new FormData();

          formDataEvent.append("image", eventImage);
          formDataEvent.append("document", json);

          const postedEvent = isEditForm
            ? (await patchEvents({}, locationParams.id, formDataEvent)).event
            : await postEvents({}, formDataEvent);

          if (formData["ticket-category-name"]?.length > 0) {
            for (let i = 0; i < formData["ticket-category-name"].length; i++) {
              const categoriesData = {
                name: formData["ticket-category-name"][i],
                description: formData["ticket-category-description"][i],
                sold: 0,
                price: Number(formData["ticket-category-price"][i]) || 0,
                total: Number(formData["ticket-category-quantity"][i]) || 10000,
                active: formData["ticket-category-active"][i],
                color: formData["ticket-category-color"][i],
                limit: Number(formData["ticket-category-limit"][i]) || 10000,
                startAt: `${formData["ticket-start-date"][i]} ${formData["ticket-start-time"][i]}`,
                endAt: `${formData["ticket-end-date"][i]} ${formData["ticket-end-time"][i]}`,
                settings: settingsInfo._id
              };
              numberOfCategories.push(categoriesData);
            }
          }

          Promise.all(
            numberOfCategories.map((element, i) => {
              if (isEditForm) {
                if (categoriesIds[i]) {
                  return patchEventsCategory({}, locationParams.id, categoriesIds[i], element);
                }
              }
              return postCategories({}, element);
            })
          ).then(async (result) => {
            let allCategoriesID = result.map((el) => el._id);
            let formDataCategories = new FormData();
            formDataCategories.append("document", JSON.stringify({ categories: allCategoriesID }));

            await patchEvents({}, postedEvent._id, formDataCategories);
            if (formData.selectedPromoter.length > 0) {
              await postHostEvents(formData.selectedPromoter, { event: postedEvent._id });
            } else {
              await postUserEvents(userId, { event: postedEvent._id });
            }
            window.location.href = `${window.location.origin}/`;
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  function setInformationOnEvent() {
    const eventId = locationParams.id;

    getEvent(eventId)
      .then((result) => {
        if (result.settings._id) {
          setFormData((prev) => ({
            ...prev,
            "tickets-currency": result.settings?.currency ? result.settings?.currency : ""
          }));
        }

        if (result.categories?.length > 0) {
          let categoriesData = [];

          for (let i = 0; i < result.categories.length; i++) {
            if (result.categories[i].price > 0) {
              categoriesData.push({ color: result.categories[i].color, free: false });
            } else {
              categoriesData.push({ color: result.categories[i].color, free: true });
            }
            setCategoriesIds((prev) => [...prev, result.categories[i]._id]);

            setFormData((prev) => ({
              ...prev,
              "ticket-category-name": [...prev["ticket-category-name"], result.categories[i].name || ""],
              "ticket-category-description": [
                ...prev["ticket-category-description"],
                result.categories[i].description || ""
              ],
              "ticket-category-price": [...prev["ticket-category-price"], result.categories[i].price || ""],
              "ticket-category-quantity": [...prev["ticket-category-quantity"], result.categories[i].total || ""],
              "ticket-category-active": [...prev["ticket-category-active"], result.categories[i].active || false],
              "ticket-category-color": [...prev["ticket-category-color"], result.categories[i].color || ""],
              "ticket-category-limit": [...prev["ticket-category-limit"], result.categories[i].limit || ""],
              "ticket-start-date": [...prev["ticket-start-date"], result.categories[i].startAt.split(" ")[0] || ""],
              "ticket-start-time": [...prev["ticket-start-time"], result.categories[i].startAt.split(" ")[1] || ""],
              "ticket-end-date": [...prev["ticket-end-date"], result.categories[i].endAt.split(" ")[0] || ""],
              "ticket-end-time": [...prev["ticket-end-time"], result.categories[i].endAt.split(" ")[1] || ""]
            }));
          }
          setTickets(categoriesData);
        }

        setFormData((prev) => ({
          ...prev,
          selectedPromoter: result.host ? result.host : "",
          image: result.image ? result.image : "",
          title: result.name ? result.name : "",
          subtitle: result.pageTextBody ? result.pageTextBody : "",
          category: result.category ? result.category : "",
          topic: result.pageTextHeader ? result.pageTextHeader : "",
          tags: result.tags ? result.tags.map((el) => ({ title: el })) : [],
          description: result.description ? result.description : "",
          private: result.private ? result.private : "",
          online: result.online ? result.online : "",
          startAt: result.startAt ? result.startAt.split(" ")[0] : "",
          startTime: result.startAt ? result.startAt.split(" ")[1] : "",
          endAt: result.endAt ? result.endAt.split(" ")[0] : "",
          endTime: result.endAt ? result.endAt.split(" ")[1] : "",
          priceType: result.settings?.priceType ? result.settings.priceType : "",
          priceDescription: result.settings?.priceDescription ? result.settings.priceDescription : "",
          location: {
            lat: "",
            lng: ""
          }
        }));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <form className="row my-5 needs-validation mw-100" onSubmit={handleSubmit} noValidate>
      <div className="col-12 col-md-3 text-center">
        <Avatar formData={formData} setEventImage={setEventImage} />
      </div>
      <div className="col-md-1"></div>
      <div className="col-12 col-md-8 card">
        <Promoter promoters={promoters} selectedPromoter={formData.selectedPromoter} selectPromoter={setFormData} />
        <Description categories={categories} formData={formData} handleInputChange={handleInputChange} />
        <DateTime formData={formData} handleInputChange={handleInputChange} />

        <Maps formData={formData} handleInputChange={handleInputChange} />

        <Categories
          selected={selectedCategories}
          onChange={(val) => setSelectedCategories(val)}
          tickets={tickets}
          onCreateTicket={onCreateTicket}
          onDeleteTicket={onDeleteTicket}
          onAddInfo={onAddInfo}
          onDeleteInfo={onDeleteInfo}
          formData={formData}
          handleInputChange={handleInputChange}
          colours={colours}
        />
        <div className="row">
          <div className="col-12 text-center">
            <div className="actions">
              <button className="btn btn-primary mx-2" type="submit">
                Create
              </button>
              <button
                className="btn btn-outline-primary mx-2"
                onClick={() => window.location.href = window.location.origin}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
