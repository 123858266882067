import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { INFURA_ID } from "../config/index";

import MetamaskLogo from "../images/wallets/Metamask.svg";
import WalletConnectLogo from "../images/wallets/WalletConnect.svg";

export function createWeb3Modal() {
  const modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: getProviderOptions()
  });

  if (window.ethereum) {
    const metamaskIndex = modal.userOptions.findIndex((element) => element.id === "custom-metamask");
    if (metamaskIndex !== -1) {
      modal.userOptions.splice(0, 1, modal.userOptions[metamaskIndex]);
      modal.userOptions.splice(metamaskIndex, 1);
    }
  }

  return modal;
}

function getProviderOptions() {
  const providerOptions = {};

  providerOptions["custom-metamask"] = {
    id: "injected",
    display: {
      logo: MetamaskLogo,
      name: "MetaMask",
      description: "Connect to your Metamask Wallet"
    },
    package: {},
    connector: async () => {
      const provider = checkingForMetaMaskProvider();

      if (!provider) {
        window.open(`https://metamask.app.link/dapp/${window.location.hostname}`);
        throw new Error("MetaMask not installed");
      }

      await provider.request({ method: "eth_requestAccounts" });

      return provider;
    }
  };

  providerOptions.walletconnect = {
    display: {
      logo: WalletConnectLogo
    },
    package: WalletConnectProvider,
    options: {
      infuraId: INFURA_ID
    }
  };
  return providerOptions;
}

export async function connectWallet(web3Modal) {
  let provider = "";

  try {
    provider = await web3Modal.connect();
  } catch (err) {
    if (err === "Modal closed by user") {
      console.error(err);
    } else if (
      err &&
      (err.message === "User rejected the request." ||
        err.message.includes("not installed") ||
        err.message.includes("accounts received is empty") ||
        err.message.includes("User closed modal") ||
        err.message.includes("Already processing") ||
        err.message.includes("User denied account authorization"))
    ) {
      throw new Error("User rejected the request.");
    }

    throw new Error("Cannot connect!");
  }

  return provider;
}

function checkingForMetaMaskProvider() {
  let metamaskProvider = null;

  if (window.ethereum && window.ethereum.providers && window.ethereum.providers.length > 1) {
    metamaskProvider = window.ethereum.providers.find((e) => e.isMetaMask === true && e.isBitKeep === undefined);
  } else if (window.ethereum && window.ethereum.isMetaMask === true && window.ethereum.isBitKeep === undefined) {
    metamaskProvider = window.ethereum;
  }
  return metamaskProvider;
}
