import { Host } from "./Host";
import { TicketView } from "./TicketView";
import { Profile } from "./Profile";

import "./PromoterView.scss";

export const PromoterView = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-xl-9 col-12 mt-5">
          <div className="row">
            <Host />
            <TicketView />
          </div>
        </div>
        <div className="col-lg-4 col-xl-3 col-12 mt-5">
          <Profile />
        </div>
      </div>
    </div>
  );
};
