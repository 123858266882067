import React from "react";

const About = (props) => (
  <div className="row align-items-center mt-5 py-5">
    <div className="col-md-6 col-12">
      <h2 className="h3 mb-4 text-dark-blue about-title">{props.content.about_section_title_translated}</h2>
      <div
        className="text-s cta-text mb-4 text-dark"
        dangerouslySetInnerHTML={{ __html: props.content.about_section_text_translated }}
      ></div>
    </div>
    <div className="col-md-5 ms-auto col-12">
      <img
        src={props.content.about_image ? props.content.about_image : "/img/web/host-no-image-thumb.gif"}
        alt="About Plentix"
        className="about-image w-100"
      />
    </div>
  </div>
);
export default About;
