import React from "react";
import { Input } from "../Form/Input.jsx";

export const ContactInfo = ({ handleInputChange, formData }) => {
  return (
    <div className={"bg-white p-4"}>
      <p className={"text-xl font-bold"}>Contact</p>
      <Input placeholder={"Phone (optional)"} name={"phone"} value={formData?.phone} onChange={handleInputChange} />
      <Input placeholder={"Email (optional)"} name={"email"} value={formData?.email} onChange={handleInputChange} />
      <Input
        placeholder={"Website (optional)"}
        name={"websiteUrl"}
        value={formData?.websiteUrl}
        onChange={handleInputChange}
      />
    </div>
  );
};
