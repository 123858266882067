import "./TicketView.scss";

export const TicketView = () => {
  return (
    <div className="host col-12" data-host="111">
      <section className="host-events my-3 row">
        <div className="events-list-horizontal col-12 text-center">
          <div className="row events-list-horizontal-row g-4">
            <div className="col-6 col-md-4 col-xl-3">
              <div className="card w-100" data-event="1023">
                <img
                  className="card-img-top"
                  src="https://plentixbucket.s3.eu-central-1.amazonaws.com/images/events/b52a98d09ba22adc6142327971ca1e8c-medium.jpg"
                  alt="Card image cap"
                />
                <div className="card-body text-start">
                  <h5
                    className="card-title"
                    //  style="text-indent: 0px; overflow: hidden; white-space: nowrap;"
                  >
                    <a className="title" href="/events/conferences/sheleaderdigital-conference-2022/1023/">
                      SHEleader@digital conference 2022
                    </a>
                  </h5>

                  <div className="card-text row">
                    <div className="d-flex justify-content-between col-12">
                      <strong>Conference</strong>
                      <em>Sofia, Bulgaria</em>

                      <strong>Join us </strong>
                    </div>
                    <div className="col-12">
                      <time className="d-flex flex-column align-items-center">
                        <strong className="event-ticket-date w-100">06.10.2022 10:00</strong>
                        Thursday
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
