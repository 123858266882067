import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Tooltip } from "bootstrap";

import { deleteDiscount, patchDiscount } from "../../../../services/discountServices";
import { getEventDiscounts } from "../../../../services/eventService";

import "./ManageCodes.scss";

export const ManageDiscountCodes = () => {
  const [allDiscountCodes, setAllDiscountCodes] = useState([]);
  const [singleCodesType, setSingleCodesType] = useState([]);
  const [ticketsArr, setTicketsArr] = useState([]);
  const [eventSettings, setEventSettings] = useState([]);
  const locationParams = useParams();
  const locationPath = useLocation();
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

  useEffect(() => {
    fetchEventDiscounts(locationParams["event_id"]);
  }, []);

  useEffect(() => {
    if (ticketsArr) {
      const discountCodes = filterCodes(allDiscountCodes);

      setSingleCodesType(discountCodes);
      setTicketsArr(discountCodes);
    }
  }, [locationPath]);

  function fetchEventDiscounts(eventId) {
    getEventDiscounts(eventId)
      .then((result) => {
        if (result) {
          setAllDiscountCodes([...result.discounts]);
          const discountCodes = filterCodes(result.discounts);

          setSingleCodesType(discountCodes);
          setTicketsArr(discountCodes);
          setEventSettings(result.settings);
        }
      })
      .catch((err) => console.error(err));
  }
  function filterCodes(codes) {
    let discounts = [];

    if (locationPath.pathname.includes("multi-use")) {
      discounts = codes.filter((el) => el.ticketCodeType == "many");
    } else {
      discounts = codes.filter((el) => el.ticketCodeType == "one");
    }
    return discounts;
  }

  const arrowClick = (e) => {
    e.preventDefault();
    const parent = e.target.parentNode;
    const details = parent.parentNode.querySelector(".details");
    const arrow = parent.querySelector(".arrow");

    if (Array.from(arrow.classList).includes("arrow-right")) {
      arrow.classList.remove("arrow-right");
      arrow.classList.add("arrow-down");
      details.style.height = "150px";
    } else {
      arrow.classList.add("arrow-right");
      arrow.classList.remove("arrow-down");
      details.style.height = "0px";
    }
  };

  const setChecked = async (e, discountId) => {
    e.preventDefault();
    const element = e.target.parentNode;
    const parent = e.target.parentNode.parentNode;

    try {
      if (Array.from(element.classList).includes("unchecked")) {
        await patchDiscount({}, discountId, { active: true });
        element.classList.remove("unchecked");
      } else {
        await patchDiscount({}, discountId, { active: false });
        element.classList.add("unchecked");
      }

      const tooltips = parent.querySelector(".highlight-tooltip");
      tooltips.style.display = "inline";
      element.addEventListener("mouseleave", () => {
        tooltips.style.display = "none";
      });

      fetchEventDiscounts(locationParams["event_id"]);
    } catch (err) {
      console.error(err);
    }
  };

  const searchCodes = (e) => {
    let newArray = [];
    const searchText = e.target.value.toLowerCase();

    singleCodesType.forEach((element) => {
      if (element.code.toLowerCase().includes(searchText)) {
        newArray.push(element);
      }
    });
    if (newArray) {
      setTicketsArr(newArray);
    }
    const listOfName = document.querySelectorAll(".categories-name");

    for (let i = 0; i < listOfName.length; i++) {
      const element = listOfName[i];
      const regex = new RegExp(searchText, "gi");
      let text = element.innerText.toLowerCase();

      text = text.replace(<mark className="highlight">|</mark>, "");

      const newText = text.replace(regex, `<mark class="highlight">${searchText}</mark>`);
      element.innerHTML = newText;
    }
  };

  function showCategories(categories) {
    const categoriesComponent = [];

    for (let i = 0; i < 6; i++) {
      const category = categories[i];

      if (category) {
        categoriesComponent.push({ name: category.name, color: category.color });
      } else {
        categoriesComponent.push(null);
      }
    }
    return categoriesComponent;
  }

  function deleteCode(e, codeId) {
    deleteDiscount({}, codeId);
  }
  return (
    <div className="d-flex flex-column codes-wrapper align-items-center m-auto ">
      <div className="d-flex w-100 mt-2 mb-4 justify-content-around align-items-center">
        <p className="m-0">
          You have created <strong> {ticketsArr.length} codes</strong> so far
        </p>
        <Link
          className="btn-create"
          to={`/events/${locationParams["event_id"]}/discounts?${
            locationPath.pathname.includes("multi-use") ? "discount-type=many" : "discount-type=one"
          }
   
          `}
        >
          Create now
        </Link>
        <input type="text" placeholder="search discount codes" onChange={searchCodes} />
      </div>

      <ul>
        {ticketsArr.map((code, i) => {
          return (
            <li
              key={i}
              className="code"
              data-code={code.code}
              data-code-id={code.event}
              data-discount={code.ticketUsageType == "one" ? `$${code.discountFixed}` : `${code.discountPercentage}%`}
            >
              <div className="code">
              {/* <button type="button" class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
  Tooltip on top
</button> */}
                <div className="arrow arrow-right" onClick={arrowClick}></div>
                <a
                  className="name categories-name"
                  href="#"
                  data-bs-toggle="tooltip"
                  title={code.code}
                  onClick={arrowClick}
                >
                  {code.code}
                </a>

                <div className="pair discount">
                  <span className="label">Discount:</span>
                  <span className="value">
                    {code.discountFixed ? `$${code.discountFixed.toFixed(2)}` : `${code.discountPercentage}%`}
                  </span>
                </div>

                <div className="pair categories">
                  <span className="label">Categories:</span>
                  {showCategories(code.categories).map((el) =>
                    el ? (
                      <div
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        style={{ background: el.color }}
                        title=""
                        data-bs-original-title={el.name}
                        aria-label={el.name}
                      ></div>
                    ) : (
                      <div className="placeholder"></div>
                    )
                  )}
                </div>

                <div className="pair uses">
                  <span className="label">Uses (Tickets):</span>
                  <span className="value">{code.numberUses}</span>
                </div>

                <div className="pair limit">
                  <span className="label">Limit per user:</span>
                  <span className="value">{code.numberUsed}</span>
                </div>

                <label
                  className={`new-switch ${code.active ? "" : "unchecked"} mt-3`}
                  onClick={(e) => setChecked(e, code._id)}
                >
                  <span className="on">on</span>
                  <span className="thumb"></span>
                  <span className="off">off</span>
                </label>

                <div className="highlight-tooltip" id="omajlbn4" style={{ left: "640px", top: "29px", display: "none" }}>
                  <div className={`${code.active ? "yellow" : "red"} left`} style={{ top: "1px" }}>
                    {code.active ? "The code is now active" : "The code is now disabled"}
                  </div>
                </div>
              </div>

              <div className="details d-flex flex-column align-items-center">
                <p className="period">
                  This discount code can be used between{" "}
                  <strong>
                    {new Date(code.startAt)
                      .toLocaleString("default", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false
                      })
                      .split(",")
                      .join("")}
                  </strong>{" "}
                  and{" "}
                  <strong>
                    {new Date(code.endAt)
                      .toLocaleString("default", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false
                      })
                      .split(",")
                      .join("")}
                  </strong>
                  .
                </p>

                <div className="actions">
                  <button className="btn btn-inverse delete" onClick={(e) => deleteCode(e, code._id)}>
                    Delete this code
                  </button>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
