import { WindowSharp } from "@mui/icons-material";
import { useState } from "react";

import { registerUser } from "../../services/userService";

import "../Login/Login.scss";

export const Register = ({ setIsLogged, setUserFirstName, setUserImage }) => {
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");

  const registerHandle = async (e) => {
    e.preventDefault();

    if (checked) {
      if (!regexEmail.test(email)) {
        setError("Please enter correct email!");
      } else if (password === confirmPassword) {
        const data = {
          email,
          password1: password,
          name
        };

        try {
          const result = await registerUser(data);
          localStorage.setItem("accessToken", result.accessToken);
          localStorage.setItem("name", result.user.firstName);
          localStorage.setItem("image", result.user?.image);
          setIsLogged(true);
          setUserFirstName(result.user.firstName);
          setUserImage(result.user?.image);

          localStorage.setItem("userId", result.user._id);
          window.location.href = `${window.location.origin}/profile/setup`;
        } catch (err) {
          setError(err);
        }
      } else {
        setError("The password is not match");
      }
    } else {
      setError("You need to accept the terms!");
    }
  };

  return (
    <>
      <div className="container d-flex flex-column justify-content-md-center">
        <div className="modal-actions col-md-11 col-lg-9 col-11 mx-auto">
          <div className="row gx-5">
            <div className="col-12 col-md-6 sign-wrapper">
              {/* <!-- REGISTER --> */}
              <form
                className="column standard w-100"
                id="register"
                onSubmit={registerHandle}
                // style={{"display": "none"}}
              >
                <div className="row">
                  <h3 className="title-bilo-m px-0 mb-1">Sign Up</h3>
                  {error.length > 0 ? <p className="error">{error}</p> : ""}
                  <label className="text-xs mt-2" htmlFor="reg-email">
                    Email
                  </label>
                  <input
                    className="mt-0"
                    name="email"
                    id="reg-email"
                    placeholder="email@evedo.co"
                    type="text"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label className="text-xs mt-2" htmlFor="reg-name">
                    Your Name
                  </label>
                  <input
                    className="mt-0"
                    name="name"
                    id="reg-name"
                    placeholder="Johnny Bravo"
                    type="text"
                    defaultValue={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <label className="text-xs mt-2" htmlFor="reg-pass1">
                    Password
                  </label>
                  <input
                    className="mt-0"
                    name="password1"
                    id="reg-pass1"
                    placeholder="*****"
                    type="password"
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label className="text-xs mt-2" htmlFor="reg-pass2">
                    Confirm password
                  </label>
                  <input
                    className="mt-0"
                    name="password2"
                    id="reg-pass2"
                    placeholder="*****"
                    type="password"
                    defaultValue={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <label className="d-flex align-items-center text-s">
                    <input
                      className="form-check-input my-0 me-1"
                      name="terms"
                      type="checkbox"
                      defaultChecked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    I agree to&nbsp;
                    <a href="/legal/terms-of-use/" target="_blank" className="text-dark fw-700 ms-1">
                      Terms of use
                    </a>
                  </label>

                  <label className="warning alert alert-warning" style={{ display: "none" }}></label>
                  <div id="g-recaptcha-container"></div>
                  <div className="form-actions row mt-2">
                    <button
                      type="submit"
                      className="nav-button-primary nav-link-normal text-white text-nowrap border-0"
                    >
                      Sign up
                    </button>
                  </div>
                  <div className="pt-3 mb-3 row gx-0">
                    <div className="col d-flex align-items-center">
                      <div className="grey-separator w-100"></div>
                    </div>
                    <div className="col d-flex align-items-center justify-content-center">
                      <small className="text-nowrap cta-text">Or continue with</small>
                    </div>
                    <div className="col d-flex align-items-center">
                      <div className="grey-separator w-100"></div>
                    </div>
                  </div>
                  <div className="row mt-3 gx-1">
                    <div className="col-4">
                      <div
                        className="button-ghost-square py-1 px-0"
                        style={{ cursor: "pointer" }}
                        // onClick='window.open("/common/facebook-url", "fbwindow", "width=800,height=410,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + ((screen.width - 800) /2) + ",top=" + ((screen.height - 410) / 2))'
                      >
                        <img src="/img/svg/facebook-button.svg" alt="Facebook" />
                        <span className="text-xs">Facebook</span>
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        className="button-ghost-square py-1 px-0"
                        style={{ cursor: "pointer" }}
                        // onClick='window.open("/common/google-url", "fbwindow", "width=800,height=410,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + ((screen.width - 800) /2) + ",top=" + ((screen.height - 410) / 2))'
                      >
                        <img src="/img/svg/google-button.svg" alt="Google" />
                        <span className="text-xs">Google</span>
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        className="button-ghost-square py-1 px-0"
                        style={{ cursor: "pointer" }}
                        // onClick='window.open("/common/twitter-url", "fbwindow", "width=800,height=410,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,left=" + ((screen.width - 800) /2) + ",top=" + ((screen.height - 410) / 2))'
                      >
                        <img src="/img/svg/twitter-button.svg" alt="Twitter" />
                        <span className="text-xs">Twitter</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              {/* <!-- FORGOTTEN PASSWORD --> */}
              <form
                action="#"
                className="column standard"
                id="forgotten"
                method="post"
                // onSubmit="window.evedo.forgottenPasswordRequest(); return false;"
                style={{ display: "none" }}
              >
                <div className="row">
                  <h3 className="title-bilo-m px-0 mb-1">Recover your password</h3>
                  <fieldset className="row">
                    <label className="text-xs mt-2" htmlFor="forgotten-email">
                      Email
                    </label>
                    <input className="mt-0" name="email" id="forgotten-email" placeholder="Email" type="text" />
                  </fieldset>
                  <label className="warning alert alert-warning" style={{ display: "none" }}></label>
                  <div className="form-actions row mt-2">
                    <button className="nav-button-primary nav-link-normal text-white text-nowrap border-0">
                      Recover
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6 col-lg-5 col-sm-10 col-12 mx-auto mx-md-0 bg-pink-pale d-flex flex-column pt-5 pb-4 px-4 ms-md-auto align-items-center rounded-4">
              <img
                className="mt-4 mb-2 rounded-circle"
                src="/img/affiliates/zzzzapp/irina-small.png"
                width="92"
                height="92"
                alt="Irina Altanova"
              />
              <div className="mb-5 text-m text-center px-4">
                Plentix helps us achieve our goals and reach more people. I love it! It’s user-friendly, the support is
                very fast so Plentix makes it easier!
              </div>
              <div className="mb-2 text-m">Irina Altanova</div>
              <div className="mb-2 text-m">
                <small>Administrative coordinator @ MOVE.BG</small>
              </div>
              <div className="d-flex mt-5 flex-wrap justify-content-center">
                <img src="/img/svg/tomorrowland-dark-pale.svg" className="me-1" alt="Tomorrowland" />
                <img src="/img/svg/lovefest-dark-pale.svg" className="me-1" alt="Lovefest" />
                <img src="/img/svg/drooble-dark-pale.svg" className="me-1" alt="Drooble" />
                <img src="/img/svg/logo-stagecast.svg" alt="Stagecast" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
