import "../Login/Login.scss";

export const ForgotPassword = () => {
  return (
    <>
      <div className="container d-flex flex-column justify-content-md-center">
        <div className="modal-actions col-md-11 col-lg-9 col-11 mx-auto">
          <div className="row gx-5">
            <div className="col-12 col-md-6 sign-wrapper">
              {/* <!-- FORGOTTEN PASSWORD --> */}
              <form
                action="#"
                className="column standard w-100"
                id="forgotten"
                method="post"
                // onSubmit="window.evedo.forgottenPasswordRequest(); return false;"
                // style={{"display": "none"}}
              >
                <div className="row">
                  <h3 className="title-bilo-m px-0 mb-1">Recover your password</h3>
                  <fieldset className="row">
                    <label className="text-xs mt-2" htmlFor="forgotten-email">
                      Email
                    </label>
                    <input className="mt-0" name="email" id="forgotten-email" placeholder="Email" type="text" />
                  </fieldset>
                  <label className="warning alert alert-warning" style={{ display: "none" }}></label>
                  <div className="form-actions row mt-2">
                    <button className="nav-button-primary nav-link-normal text-white text-nowrap border-0">
                      Recover
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6 col-lg-5 col-sm-10 col-12 mx-auto mx-md-0 bg-pink-pale d-flex flex-column pt-5 pb-4 px-4 ms-md-auto align-items-center rounded-4">
              <img
                className="mt-4 mb-2 rounded-circle"
                src="/img/affiliates/zzzzapp/irina-small.png"
                width="92"
                height="92"
                alt="Irina Altanova"
              />
              <div className="mb-5 text-m text-center px-4">
                Plentix helps us achieve our goals and reach more people. I love it! It’s user-friendly, the support is
                very fast so Plentix makes it easier!
              </div>
              <div className="mb-2 text-m">Irina Altanova</div>
              <div className="mb-2 text-m">
                <small>Administrative coordinator @ MOVE.BG</small>
              </div>
              <div className="d-flex mt-5 flex-wrap justify-content-center">
                <img src="/img/svg/tomorrowland-dark-pale.svg" className="me-1" alt="Tomorrowland" />
                <img src="/img/svg/lovefest-dark-pale.svg" className="me-1" alt="Lovefest" />
                <img src="/img/svg/drooble-dark-pale.svg" className="me-1" alt="Drooble" />
                <img src="/img/svg/logo-stagecast.svg" alt="Stagecast" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
