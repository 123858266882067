
export const Cookies = () => {
  return (
    <div className="box">
      <h1 className="text-center h5">
        <p>COOKIE POLICY</p>
        <p className="mt-0">of https://plentix.co/</p>
      </h1>
      <p>
        <u>Version signature: </u>
        <b>1.0</b>
      </p>
      <p>
        <u>Effective from: </u>
        <b>August 26 2021</b>
      </p>
      <p>
        <u>Last amended: </u>
        <b>August 26 2021</b>
      </p>
      <h3>1. What are Cookies?</h3>
      <p>
        Cookies are small pieces of information that are stored by your web browser on your computer’s hard drive. They
        are often used as a mechanism by websites to remember information about the web browser and the history of its
        activity of its users, such as clicking various buttons, information on entering the site and using it in recent
        months or years.
      </p>
      <h3>2. How does the https://plentix.co/ website use Cookies and what types of Cookies can you find on it?</h3>
      <p>
        The website uses them to provide the best possible user experience while using the website https://plentix.co/
        and its functionalities. We also use our cookies to develop the website to meet the requirements of our users
        and their interests, taking into account how users interact with our website, which pages, directories and ads
        they have visited; how often they visit specific pages and which pages on the site are most popular among our
        consumers. We also use cookies to show the most relevant ads to our users.
      </p>
      <p>
        Our site uses the following types of cookies, some of which require your explicit consent, as set out below:
      </p>
      <p></p>
      <h6>A) Strictly Necessary Cookies:</h6>
      <p></p>
      <p>
        These are cookies that are necessary for the successful operation and functionality of our website
        https://plentix.co/. These include, for example, cookies, which allow users to enter their data in connection
        with the ordering procedure and make full use of the platform. They help make the website work and deliver
        services that the website visitor expects, like navigating around web pages or accessing secure areas of the
        website.
      </p>
      <p>
        The preferences cookies are also strictly necessary for the functionality of the website. This helps Plentix.co
        to remember information that changes the way the site behaves or looks, such as your preferred language or the
        region you are in. For instance, by remembering your region, a website may be able to provide you with precise
        local event suggestions. These cookies can also assist you in further personalizations of the website.
      </p>
      <p>
        Plentix.co also uses security cookies – cookies used to authenticate users, prevent fraudulent use of login
        credentials, and protect user data from unauthorized parties.
      </p>
      <div style={{ "overflow-y": "auto" }}>
        <table className="border-table my-4">
          <tbody>
            <tr>
              <th>№</th>
              <th>Cookie name</th>
              <th>Provider</th>
              <th>Type</th>
              <th>Purpose</th>
              <th>Term of expiry</th>
            </tr>
            <tr>
              <td>1.</td>
              <td>__stripe_mid</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                This cookie is necessary for making credit card transactions on tde web site. The service is provided by
                Stripe.com which allows online transactions witdout storing any credit card information.
              </td>
              <td>1 year</td>
            </tr>
            <tr>
              <td>2.</td>
              <td>__stripe_sid</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                This cookie is necessary for making credit card transactions on tde web site. The service is provided by
                Stripe.com which allows online transactions witdout storing any credit card information.
              </td>
              <td>1 day</td>
            </tr>
            <tr>
              <td>3.</td>
              <td>m</td>
              <td>m.stripe.com</td>
              <td>HTTP</td>
              <td>
                Determines tde device used to access tde web site. This allows tde web site to be formatted accordingly.
              </td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>4.</td>
              <td>PHPSESSID</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>Preserves user session state across page requests.</td>
              <td>Session</td>
            </tr>
            <tr>
              <td>5.</td>
              <td>ss</td>
              <td>va.tawk.to</td>
              <td>HTTP</td>
              <td>Necessary for tde functionality of tde web site’s chat-box function.</td>
              <td>Session</td>
            </tr>
            <tr>
              <td>6.</td>
              <td>_lang</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>Remembers tde language chosen on the website</td>
              <td>1 year</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p></p>
      <h6>B) Analytical cookies:</h6>
      <p></p>
      <p>
        These cookies allow us to recognize and count the number of users and see how users move in it and how they use
        it. This helps us improve the way our websites work. By using these cookies, we do not collect or process any
        personal data and identifiers about you or your device. Data gathered with the help of these cookies is sent to
        the USA.
      </p>
      <div style={{ "overflow-y": "auto" }}>
        <table className="border-table my-4">
          <tbody>
            <tr>
              <th>№</th>
              <th>Cookie name</th>
              <th>Provider</th>
              <th>Type</th>
              <th>Purpose</th>
              <th>Term of expiry</th>
            </tr>
            <tr>
              <td>1.</td>
              <td>_ga</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                Registers a unique ID that is used to generate statistical data on how the visitor uses the web site.
              </td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>2.</td>
              <td>_ga_#</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                Used by Google Analytics to collect data on the number of times a user has visited the web site as well
                as dates for the first and most recent visit.
              </td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>3.</td>
              <td>_hjAbsoluteSessionInProgress</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                This cookie is used to count how many times a web site has been visited by different visitors - this is
                done by assigning the visitor an ID, so the visitor does not get registered twice.
              </td>
              <td>1 day</td>
            </tr>
            <tr>
              <td>4.</td>
              <td>_hjFirstSeen</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                This cookie is used to determine if the visitor has visited the web site before, or if it is a new
                visitor on the web site.
              </td>
              <td>1 day</td>
            </tr>
            <tr>
              <td>5.</td>
              <td>_hjid</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                Sets a unique ID for the session. This allows the web site to obtain data on visitor behaviour for
                statistical purposes.
              </td>
              <td>1 year</td>
            </tr>
            <tr>
              <td>6.</td>
              <td>_hjid</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                Sets a unique ID for the session. This allows the web site to obtain data on visitor behaviour for
                statistical purposes.
              </td>
              <td>Persistent</td>
            </tr>
            <tr>
              <td>7.</td>
              <td>_hjIncludedInPageviewSample</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                Used to detect whether the user navigation and interactions are included in the web site’s data
                analytics.
              </td>
              <td>1 day</td>
            </tr>
            <tr>
              <td>8.</td>
              <td>_hjTLDTest</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                Registers statistical data on users’ behaviour on the web site. Used for internal analytics by the web
                site operator.
              </td>
              <td>Session</td>
            </tr>
            <tr>
              <td>9.</td>
              <td>TawkConnectionTime</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>Allows the web site to recognize the visitor, in order to optimize the chat-box functionality.</td>
              <td>Session</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p></p>
      <h6>C) Marketing cookies:</h6>
      <p></p>
      <p>
        These cookies record the visits of users and the links they have gone through. They are used to deliver
        advertising messages that are relevant to the interests of different users and can also limit the number of ad
        impressions.
      </p>
      <div style={{ "overflow-y": "auto" }}>
        <table className="border-table my-4">
          <tbody>
            <tr>
              <th>№</th>
              <th>Cookie name</th>
              <th>Provider</th>
              <th>Type</th>
              <th>Purpose</th>
              <th>Term of expiry</th>
            </tr>
            <tr>
              <td>1.</td>
              <td>_fbp</td>
              <td>plentix.co</td>
              <td>HTTP</td>
              <td>
                Used by Facebook to deliver a series of advertisement products such as real time bidding from third
                party advertisers.
              </td>
              <td>3 months</td>
            </tr>
            <tr>
              <td>2.</td>
              <td>tr</td>
              <td>facebook.com</td>
              <td>Pixel</td>
              <td>
                Used by Facebook to deliver a series of advertisement products such as real time bidding from third
                party advertisers.&nbsp;
              </td>
              <td>Session</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3>3. Managing Cookies in Your Browser</h3>
      <p>
        In addition to the settings above, you can change your browser’s privacy settings to block all cookies; however,
        this can seriously affect the functionality of the website and its use by you, as many websites may not function
        properly. Your browser may allow you to delete all cookies when you close it. However, this option also deletes
        persistent cookies, which can store your preferences and custom settings. It is possible to save the cookies you
        want as your browser may allow you to specify which sites you always allow or which sites you do not allow the
        use of cookies. What this means is that you can dismiss cookies from all sites except those that you trust.
      </p>
      <p>
        In the Google Chrome browser, the Tools menu contains an option to Clear Browsing Data. You can use this option
        to delete cookies and other site and plug-in data, including data stored on your device by the Adobe Flash
        Player (commonly known as Flash cookies). See our instructions for managing cookies in Chrome.
      </p>
      <p>
        If you are concerned about cookies in Safari, you can set how you want Safari to handle them. Open Safari if it
        is not already open. Choose Safari &gt; Preferences, and then click Privacy. In the “Block cookies” section,
        specify if and when Safari should accept cookies from websites. To see an explanation of the options, click the
        Help button (question mark). If you want to see which websites store cookies on your computer, click Details. If
        you set Safari to block cookies, you may need to temporarily accept cookies to open a page. Repeat the above
        steps, selecting Never. When you’re done with the page, block cookies again, and then remove the page’s cookies.
      </p>
      <p>
        If you are concerned about cookies in Mozilla Firefox, you can set how you want Mozilla Firefox to handle them.
      </p>
      <p>To check or change your settings:</p>
      <ol>
        <li>On the menu bar, click on the Firefox menu and select Preferences.</li>
        <li>Select the Privacy panel.</li>
        <li>Set Firefox will: to Use custom settings for history.</li>
        <li>
          Check mark Accept cookies from sites to enable Cookies, and uncheck it to disable them.
          <ul>
            <li>
              If you are troubleshooting problems with cookies, make sure Accept third party cookies is also not set to
              Never. For more information, see Disable third-party cookies in Firefox to stop some types of tracking by
              advertisers.
            </li>
          </ul>
        </li>
        <li>
          Choose how long cookies are allowed to be stored:
          <ul>
            <li>
              Keep until: they expire: Each cookie will be removed when it reaches its expiration date, which is set by
              the site that sent the cookie.
            </li>
            <li>
              Keep until: I close Firefox: The cookies that are stored on your computer will be removed when Firefox is
              closed.
            </li>
            <li>
              Keep until: ask me every time: Displays an alert every time a website tries to send a cookie, and asks you
              whether or not you want to store it.
            </li>
          </ul>
        </li>
        <li>Close the Preferences window.</li>
      </ol>
      <h3>4. Changes to our cookie policy:</h3>
      <p>
        Any future changes to our Cookie Policy will be posted on this page. All changes take effect immediately, except
        for existing (registered) users for whom these changes take effect 30 (thirty) days after their publication,
        unless otherwise stated.
      </p>
      <h3>5. Where can I find more information about cookies?</h3>
      <p>Detailed information on how organizations use cookies can be found at: www.allaboutcookies.org.</p>&nbsp;
    </div>
  );
};
