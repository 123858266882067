import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material";
import { Menu, Dashboard, AirplaneTicket, AdminPanelSettings } from "@mui/icons-material";

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => {
    setIsOpen(open);
  };

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => toggleDrawer(true)}
            >
              <Menu />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Evedo Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer anchor={"left"} open={isOpen} onClose={() => toggleDrawer(false)}>
        <List>
          <ListItem button>
            <ListItemIcon>
              <AdminPanelSettings />
            </ListItemIcon>
            <ListItemText primary={"Admin"} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <AirplaneTicket />
            </ListItemIcon>
            <ListItemText primary={"Tickets"} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};
