import "./ActivityCard.scss";

export const ActivityCard = ({ activity }) => {
  return (
    <div className="activity-card item item-rsvp text-start">
      <button className="mask" type="button">
        <img alt="event" src={activity.image} />
      </button>

      <span>{activity.details}</span>
      <button className="title">{activity.title}</button>
      <div className={`marker marker-${activity.answer}`}>{activity.answer}</div>
    </div>
  );
};
