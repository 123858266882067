import { useState, useEffect } from "react";
import { postUserCategories } from "../../../services/userService";

import "./CategoryListElement.scss";

export const CategoryListElement = ({ setPreferenceCategory, user }) => {
  const [checkedElements, setCheckedElements] = useState({});
  const profileSetup = window.location.href.includes("profile/setup");

  useEffect(() => {
    if (setPreferenceCategory && profileSetup) {
      setPreferenceCategory(checkedElements);
    }
  }, [checkedElements]);

  useEffect(() => {
    if (user?.categories?.length > 0) {
      checkCategories(user.categories);
    }
  }, [user]);

  const clickCategories = async (e) => {
    e.preventDefault();
    if (Array.from(e.target.classList).includes("unchecked")) {
      try {
        await postUserCategories(user._id, { category: e.target.children[0].name, status: 1 });
        e.target.classList.remove("unchecked");
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await postUserCategories(user._id, { category: e.target.children[0].name, status: 0 });
        e.target.classList.add("unchecked");
      } catch (error) {
        console.error(error);
      }
    }
  };

  function checkCategories(categories) {
    if (categories.length > 0) {
      const elements = document.querySelectorAll("input[type=checkbox]");

      Array.from(elements).forEach((el) => {
        if (categories.includes(el.name)) {
          const parent = el.parentNode;
          parent.classList.remove("unchecked");
          parent.classList.add("checked");
        }
      });
    }
  }

  const clickFields = (e) => {
    e.preventDefault();
    if (Array.from(e.target.classList).includes("unchecked")) {
      e.target.classList.remove("unchecked");

      setCheckedElements((el) => {
        const copyEl = { ...el };
        copyEl[e.target.children[0].name] = "checked";
        return copyEl;
      });
    } else {
      e.target.classList.add("unchecked");

      setCheckedElements((el) => {
        const copyEl = { ...el };
        delete copyEl[e.target.children[0].name];
        return copyEl;
      });
    }
  };

  return (
    <div className="categories-list text-center  py-5 g-2 bg-white my-3 pb-5">
      <label className="c1 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="campaings" type="checkbox" value="1" /> Campaigns
      </label>
      <label className="c2  unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="competitions" type="checkbox" value="2" /> Competitions
      </label>
      <label className="c3 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="concerts" type="checkbox" value="3" /> Concerts
      </label>
      <label className="c4 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="conferences" type="checkbox" value="4" /> Conferences
      </label>
      <label className="c5  unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="courses" type="checkbox" value="5" /> Courses
      </label>
      <label className="c6 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="exhibitions" type="checkbox" value="6" /> Exhibitions
      </label>
      <label className="c7 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="festivals" type="checkbox" value="7" /> Festivals
      </label>
      <label className="c9  unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="meetups" type="checkbox" value="9" /> Meetups
      </label>
      <label className="c10 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="parties" type="checkbox" value="10" /> Parties
      </label>
      <label className="c11 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="performances" type="checkbox" value="11" /> Performances
      </label>
      <label className="c13 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="theatres" type="checkbox" value="13" /> Theatres
      </label>
      <label className="c14 unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="travel" type="checkbox" value="14" /> Travel
      </label>
      <label className="c15  unchecked" onClick={profileSetup ? clickFields : clickCategories}>
        <input name="workshops" type="checkbox" value="15" /> Workshops
      </label>
      <div className="empty"></div>
      <br className="clear" />
    </div>
  );
};
