import "./Search.scss";

export const Search = ({ search, setSearch, hidden }) => {
  return (
    <div className="row search-friends">
      <div
        className={`my-3 col-12 col-sm-6 ms-auto d-flex justify-content-right search-friends-input-container  ${
          hidden ? "hidden" : ""
        }`}
      >
        <input
          className={`col-12 search-friends-input`}
          name="search"
          placeholder="Search among Plentix friends"
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
};
