import allCategories from "../../../data/categories.json";

import "./Sidebar.scss";

const Sidebar = (props) => {
  let selectedDate = props.currentFilters.date.selectedDate;
  let triggerStyle = props.currentFilters.date.startAt !== "" ? { position: "absolute", opacity: "0" } : "";
  return (
    <div className="offcanvas offcanvas-end p-4" tabIndex="-1" id="filters-sidebar">
      <div className="offcanvas-header">
        <h6 className="filters-title">{props.texts.filter_results}</h6>
        {props.currentFilters.category ||
        props.currentFilters.date.startAt ||
        props.currentFilters.checkboxes.online ||
        props.currentFilters.checkboxes.free ||
        props.currentFilters.tags.length ? (
          <a href="#" className="text-s ms-3 online-event" data-type={"all"} onClick={props.clearFilter}>
            {props.texts.clear_all}
          </a>
        ) : (
          ""
        )}
      </div>
      <div className="container-fluid mt-3">
        <div className="sb-item p-3 dropdown">
          <span className="text-m label-m font-weight-light">{props.texts.category}</span>
          <div className="d-flex justify-content-between">
            <span className="sb-bold-title">
              {props.currentFilters.category ? props.currentFilters.category : "All Events"}
            </span>
            {props.currentFilters.categoryId == "" ? (
              <>
                <a
                  href="#"
                  role="button"
                  id="category-filter"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="true"
                >
                  <i className="fas fa-plus fa-sm text-light-grey"></i>
                </a>
                <ul
                  className="dropdown-menu widget-dropdown filters-drop-width dropdown-menu-end px-0 category-filter-dropdown"
                  aria-labelledby="category-filter"
                >
                  {Object.values(allCategories).map((cat, id) => {
                    return (
                      <li key={`c-${cat.name}`}>
                        <a
                          className={`dropdown-item py-2 px-4 ${
                            props.currentFilters.category == cat.name_plural ? "selected-filter" : ""
                          }`}
                          data-cat={cat.name_plural}
                          data-type="category"
                          data-cat-id={cat.id}
                          href="#"
                          onClick={(e) => props.filter(e)}
                        >
                          {cat.name_plural_translated}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <a href="#" data-type={"category"} onClick={props.clearFilter}>
                <i className="fas fa-times fa-sm text-pink"></i>
              </a>
            )}
          </div>
        </div>
        <div className="sb-item p-3 mt-2" id="date-filter">
          <span className="text-m label-m font-weight-light">{props.texts.date}</span>
          <div className="d-flex justify-content-between dropdown">
            <span className="sb-bold-title">{selectedDate}</span>
            {props.currentFilters.date.startAt !== "" ? (
              <a href="#" data-type="date" onClick={props.clearFilter}>
                <i className={`fas fa-sm fa-times text-pink`}></i>
              </a>
            ) : (
              ""
            )}
            <a
              href="#"
              style={{ ...triggerStyle }}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="true"
              data-type="date"
              id="date-dropdown-trigger"
            >
              <i className={`fas fa-sm fa-plus text-light-grey`}></i>
            </a>
            <div
              className="dropdown-menu widget-dropdown filters-drop-width dropdown-menu-end text-s w-100"
              aria-labelledby="date-filter"
            >
              <div className="input-group flex-nowrap mb-3">
                <span className="input-group-text col-5" id="calendar-start-date-icon">
                  {props.texts.start_date}
                </span>
                <input
                  type="date"
                  className="form-control p-2 text-s"
                  data-type="date"
                  data-date="start"
                  onChange={(e) => props.filter(e)}
                  // defaultValue={new Date().toISOString().substring(0, 10)}
                  name="start-date"
                  aria-describedby="calendar-start-date-icon"
                  id="start-date"
                />
              </div>
              <div className="input-group flex-nowrap">
                <span className="input-group-text col-5" id="calendar-end-date-icon">
                  {props.texts.end_date}
                </span>
                {props.currentFilters.date.startAt !== "" ? (
                  <input
                    type="date"
                    className="form-control p-2 text-s"
                    data-type="date"
                    data-date="end"
                    onChange={(e) => props.filter(e)}
                    defaultValue={props.currentFilters.date.startAt ? props.currentFilters.date.startAt : ""}
                    min={props.currentFilters.date.startAt ? props.currentFilters.date.startAt : ""}
                    name="end-date"
                    aria-describedby="calendar-end-date-icon"
                    id="end-date"
                  />
                ) : (
                  <input
                    type="date"
                    className="form-control p-2 text-s"
                    name="end-date"
                    aria-describedby="calendar-end-date-icon"
                    id="end-date"
                    disabled
                  />
                )}
              </div>
              <div className="d-flex justify-content-center mt-2">
                <a href="#" className="text-s py-2 px-3 online-event" data-type="date" onClick={props.clearFilter}>
                  {props.texts.clear}
                </a>
                <a
                  href="#"
                  className="text-s py-2 px-3 online-event"
                  onClick={() => document.getElementById("date-dropdown-trigger").click()}
                >
                  Ok
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={`sb-item p-3 mt-2 ${props.tags.length ? "" : "bg-white"}`}>
          <span className="text-m label-m font-weight-light d-flex justify-content-between">
            {props.texts.tags}{" "}
            {props.tags.length ? (
              <a href="#" className="text-s py-2 px-3 link-pink" data-type="tags" onClick={props.clearFilter}>
                Clear
              </a>
            ) : (
              ""
            )}
          </span>
          <div className="input-tag bg-white d-flex flex-wrap">
            <ul className="input-tag__tags w-100 d-flex flex-wrap p-0 m-0">
              {props.currentFilters.tags.map((tag, i) => (
                <li key={tag} className="badge d-flex align-items-center fw-500 me-1 mb-1">
                  {tag}
                  <button
                    className="ms-2 border-0 bg-transparent"
                    type="button"
                    data-tag={tag}
                    onClick={(e) => {
                      props.removeTag(e);
                    }}
                  >
                    <img src={`/img/svg/icon-remove-tag.svg`} alt="Remove tag" />
                  </button>
                </li>
              ))}
              <li className="input-tag__tags__input bg-transparent p-0 w-100">
                <input
                  type="text"
                  name="tags"
                  id="tags-input"
                  className="border-0 w-100"
                  onChange={props.inputKeyDown}
                  autoComplete={"off"}
                />
                <div className="dropdown h-0">
                  <button
                    className="dropdown-toggle overflow-hidden p-0 border-0"
                    id="tag-suggestions-trigger"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    {" "}
                  </button>
                  <ul className="dropdown-menu w-100 p-0">
                    {props.tagSuggestions.map((sug) => (
                      <li key={sug.id}>
                        <a
                          data-id={sug.id}
                          data-value={sug.name}
                          className="dropdown-item"
                          href="#"
                          onClick={props.addTagSuggestion}
                        >
                          {sug.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {props.showCheckboxes ? (
          <div className="event-types-container p-3">
            <div className="event-type">
              <label
                htmlFor={"online-only"}
                className="text-s sb-cbox-label d-flex justify-content-between align-items-center flex-fill"
              >
                {props.texts.online_only}
                <img
                  src={`/img/svg/icon-checkbox-${props.currentFilters.checkboxes.online ? "checked" : "unchecked"}.svg`}
                  className="custom-arrow"
                  alt="checkbox"
                />
              </label>
              <input
                id={"online-only"}
                name={"online-only"}
                className="sb-cbox-control d-none"
                type="checkbox"
                data-type={"checkbox"}
                data-cb={"online"}
                onChange={(e) => props.filter(e)}
              />
            </div>
            <div className="event-type mt-3">
              <label
                htmlFor={"free-events"}
                className="text-s sb-cbox-label d-flex justify-content-between align-items-center flex-fill"
              >
                {props.texts.free_events}
                <img
                  src={`/img/svg/icon-checkbox-${props.currentFilters.checkboxes.free ? "checked" : "unchecked"}.svg`}
                  className="custom-arrow"
                  alt="checkbox"
                />
              </label>
              <input
                id={"free-events"}
                name={"free-events"}
                className="sb-cbox-control d-none"
                type="checkbox"
                data-type={"checkbox"}
                data-cb={"free"}
                onChange={(e) => props.filter(e)}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="d-flex p-3">
        <button
          className="btn btn-sm btn-primary me-2"
          onClick={props.filterResults}
          data-bs-toggle="offcanvas"
          data-bs-target="#filters-sidebar"
          aria-controls="filters-sidebar"
        >
          {props.texts.filter_results}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-default text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          {props.texts.cancel}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
