import { useState } from "react";

import "./Image.scss";

export const Image = ({ image, showUploadForm = true }) => {
  const [selectedFile, setSelectedFile] = useState();
  return (
    <div className="row">
      <div className="col-12 p-0">
        {image ? <img src={image} className="w-100" alt="event" /> : ""}
        {showUploadForm ? (
          <form action="" method="post" enctype="multipart/form-data" id="pictureForm">
            <label for="imageupload" className="w-100">
              <div className="custom-browse">
                <input
                  type="file"
                  name="img"
                  id="imageupload"
                  value={selectedFile}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />
                <strong className="me-2">browse images</strong>
                click here to upload a new image
              </div>
            </label>
          </form>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
