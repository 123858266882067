import "./InterestTag.scss";

export const InterestTag = ({ tag, removeTag }) => {
  return (
    <li className="interest-tag">
      {tag}
      <button type="button" className="remove-tag ms-1" onClick={() => removeTag(tag)}></button>
    </li>
  );
};
