import "./Empty.scss";

export const Empty = ({ tab }) => {
  return (
    <li className="col-12 no-friends-container">
      <div className="dashed-border">
        <div className="inner">
          <h3>
            {tab === "friends"
              ? "It seems you have no friends yet."
              : "Sorry, we cannot find any people you may know yet."}
          </h3>
        </div>
      </div>
    </li>
  );
};
