import React from "react";
import { Link } from "react-router-dom";

import Autocomplete from "./autocomplete";

/* TO-DO ADD USER MENU WHEN LOGGED */

class MobileNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      submenuOpen: false
    };
    // this.toggleBurger = this.toggleBurger.bind(this);
    this.toggleSubmenu = this.toggleSubmenu.bind(this);
  }
  // toggleBurger(e){
  //     e.preventDefault();
  //     this.setState({
  //         menuOpen: !this.state.menuOpen
  //     }, () => {this.state.menuOpen ? document.getElementsByTagName('body')[0].style.overflow = 'hidden' : document.getElementsByTagName('body')[0].style.overflow = 'auto'} )
  // }
  toggleSubmenu(e) {
    e.preventDefault();
    var target = e.target;
    if (target.closest(".sublevel")) {
      [...target.closest(".sublevel").parentNode.querySelectorAll(".submenuOpened")]
        .filter((el) => el != target.closest(".sublevel"))
        .map((el) => el.classList.remove("submenuOpened"));
      target.closest(".has-sub").classList.toggle("submenuOpened");
    } else {
      target.closest(".has-sub").classList.toggle("submenuOpened");
    }
  }
  render() {
    let languageKeys = Object.keys(this.props.languages);
    let languageValues = Object.values(this.props.languages);

    let languageBar = (
      <div className="btn-group dropright header-language ms-1">
        <a
          href="#"
          className={`trigger-dropdown d-flex align-items-center cta-text text-s`}
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="header-icon d-flex justify-content-center cta-text me-1">
            <img src="/img/svg/icon-globe.svg" alt="Choose language" />
          </div>
          &nbsp;
          {languageValues[languageKeys.indexOf(this.props.selectedLanguage)].name.substring(0, 2)}
        </a>
        <div className="dropdown-menu mt-3 transition-200 widget-dropdown px-0">
          <div className="d-flex flex-column">
            {languageKeys.map((lang, id) => (
              <a
                href="#"
                onClick={this.props.languageSwitch}
                data-lang={lang}
                className={`language-link text-grey d-flex align-items-center py-2 px-4 rounded-0 text-s ${
                  this.props.selectedLanguage === lang ? "current" : ""
                }`}
                key={`language-${id}`}
              >
                <div className={`language-flag me-2 ${lang}`}></div>
                {languageValues[id].name.substring(0, 2)}&nbsp;
                {this.props.selectedLanguage === lang ? <i className="fad fa-check-circle text-pink ms-auto"></i> : ""}
              </a>
            ))}
          </div>
        </div>
      </div>
    );
    let fontColor = "text-mode-dark";
    let education = [];
    let music = [];
    let hobbies = [];
    let other = [];

    this.props.categories.map((cat, id) => {
      return cat.parent_category == "Education"
        ? education.push(
            <a
              key={`${cat}-${id}`}
              className="dropdown-item my-3 px-0"
              href={`/events/${cat.name_plural.toLowerCase()}`}
            >
              {cat.name_plural_translated}
            </a>
          )
        : cat.parent_category == "Music & Entertainment"
        ? music.push(
            <a
              key={`${cat}-${id}`}
              className="dropdown-item my-3 px-0"
              href={`/events/${cat.name_plural.toLowerCase()}`}
            >
              {cat.name_plural_translated}
            </a>
          )
        : cat.parent_category == "Hobbies"
        ? hobbies.push(
            <a
              key={`${cat}-${id}`}
              className="dropdown-item my-3 px-0"
              href={`/events/${cat.name_plural.toLowerCase()}`}
            >
              {cat.name_plural_translated}
            </a>
          )
        : cat.parent_category == "Other"
        ? other.push(
            <a
              key={`${cat}-${id}`}
              className="dropdown-item my-3 px-0"
              href={`/events/${cat.name_plural.toLowerCase()}`}
            >
              {cat.name_plural_translated}
            </a>
          )
        : null;
    });
    return (
      <div className="container-fluid mobile-navigation">
        <div className="row flex-nowrap">
          {this.props.openSearch ? (
            <Autocomplete
              openSearch={this.props.openSearch}
              toggleDynamicSearch={this.props.toggleDynamicSearch}
              suggestions={this.props.searchParams}
              texts={this.props.texts}
            />
          ) : (
            ""
          )}
          <div className="d-flex align-items-center col-12 py-3 mobile-nav-header bg-white">
            <a className="navbar-brand logo p-0" href="/">
              <img src={"/img/Plentix.svg"} width="110" className="d-inline-block align-top mt-1" alt="Plentix logo" />
            </a>
            <div
              onClick={this.props.toggleDynamicSearch}
              className="d-flex align-items-center ms-4 search-icon ms-auto"
              id="search-icon"
            >
              <img src="/img/svg/icon-search.svg" alt="Search" />
              &nbsp;
            </div>
            {this.props.isLogged ? (
              <div className="bg-white d-flex align-items-center justify-content-between">
                <div className="logged-notification custom-drop dropleft text-center ms-3 position-relative">
                  <a
                    href="#"
                    className={`h6 mb-0 link-transition d-flex ${fontColor}`}
                    data-bs-toggle="dropdown"
                    role="button"
                    id="notificationDropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        this.props.notifications == 0 ? "/img/svg/icon-bell-gray.svg" : "/img/svg/icon-bell-violet.svg"
                      }
                      alt="Notificaitons"
                    />
                  </a>
                  <div
                    className="dropdown-menu menu-transition-to-bottom transition-200 widget-dropdown notifications-dropdown px-0 py-4"
                    aria-labelledby="notificationDropdown"
                  >
                    <p className="label-m cta-text text-uppercase letter-spacing-5 mb-2 px-4">
                      {this.props.texts.notifications}
                    </p>
                    <a className="dropdown-item px-4 text-s" href="/notifications">
                      {this.props.texts.youHave} {this.props.notifications} <b>{this.props.texts.new}</b>&nbsp;
                      {this.props.notifications > 1 ? this.props.texts.notifications : this.props.texts.notification}.
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <a
                    className={`h6 mb-0 link-transition d-flex ms-3 ${fontColor}`}
                    data-bs-toggle="offcanvas"
                    href="#user-menu"
                    role="button"
                    aria-controls="user-menu"
                  >
                    <div className="username-letter d-flex align-items-center justify-content-center">
                      {this.props.name?.charAt(0)}
                    </div>
                  </a>
                  <div
                    className="offcanvas offcanvas-end px-0"
                    tabIndex="-1"
                    id="user-menu"
                    aria-labelledby="offcanvasLabel"
                    data-bs-backdrop="false"
                  >
                    <div className="offcanvas-header menu-left-header pe-0">
                      <div className="label-l ps-3">
                        {this.props.texts.hey}, {this.props.name}
                      </div>
                      <a
                        href="/settings"
                        className="d-flex align-items-center ms-4 text-dark circle-gray justify-content-center me-auto"
                      >
                        <img src="/img/svg/icon-settings.svg" alt="Settings" />
                      </a>
                      <div className=" px-3 py-2 d-flex justify-content-between">
                        <a href="#" className="d-flex" role="button" data-bs-dismiss="offcanvas" aria-label="Close">
                          <img
                            className={`submenu-trigger-icon`}
                            alt="Previous menu"
                            src="/img/svg/icon-arrow-left.svg"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="offcanvas-body">
                      <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/profile">
                        {this.props.texts.admin.profile}
                      </a>
                      <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/tickets">
                        {this.props.texts.admin.my_tickets}
                      </a>
                      <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/my-events">
                        {this.props.texts.admin.my_calendar}
                      </a>
                      <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/friends">
                        {this.props.texts.admin.friends}
                      </a>
                      <a className="dropdown-item text-start d-flex align-items-center mb-2" href="/messages">
                        {this.props.texts.admin.messages}
                        <div className="d-flex align-items-center ms-1 text-dark circle-gray circle-gray-sm justify-content-center">
                          {this.props.messages !== 0 ? this.props.messages : 0}
                        </div>
                      </a>
                    </div>
                    <div className="offcanvas-footer d-flex py-3 mt-3">
                      <a href="/logout" className="dropdown-item d-flex align-items-center">
                        <div>
                          <img src="/img/svg/icon-logout.svg" alt={this.props.texts.admin.logout} />
                        </div>
                        <div className="ms-2 cta-text">
                          <small>
                            {this.props.texts.admin.logout} {this.props.name}
                          </small>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <a
              href="#mobile-offcanvas"
              className="mobile-nav-trigger ms-3"
              data-bs-toggle="offcanvas"
              role="button"
              aria-controls="mobile-offcanvas"
            >
              <img
                className={`menu-trigger-icon`}
                alt="hamburger"
                src={this.state.menuOpen ? "/img/svg/icon-close.svg" : "/img/svg/icon-hamburger.svg"}
              />
            </a>
          </div>
          <div
            className="offcanvas offcanvas-end px-0"
            tabIndex="-1"
            id="mobile-offcanvas"
            aria-labelledby="mobile-offcanvasLabel"
          >
            <div className="offcanvas-header px-0 menu-left-header">
              <div className="px-3 py-2 ms-auto">
                <a href="#" className="d-flex" role="button" data-bs-dismiss="offcanvas" aria-label="Close">
                  <img className={`submenu-trigger-icon`} alt="Close" src="/img/svg/icon-arrow-left.svg" />
                </a>
              </div>
            </div>
            <div className="has-sub transition-500 my-4">
              <a
                href="#"
                onClick={this.toggleSubmenu}
                className="nav-link nav-link-normal dropdown-angle-pink-mobile d-flex justify-content-between align-items-center h6 mb-0 link-transition text-mode-dark"
                role="button"
              >
                {this.props.texts.discover}
              </a>
              <div className="d-flex menu-left transition-500 bg-white flex-column w-100">
                <div className="menu-left-header px-3 py-2 d-flex justify-content-between">
                  <span className="nav-link-normal h6 mb-0 link-transition text-mode-dark">
                    {this.props.texts.discover}
                  </span>
                  <a href="#" onClick={this.toggleSubmenu} className="d-flex">
                    <img className={`submenu-trigger-icon`} alt="Previous menu" src="/img/svg/icon-arrow-left.svg" />
                  </a>
                </div>
                <div className="has-sub sublevel transition-500 p-3 my-2">
                  <a
                    href="#"
                    className="label-m mb-2 text-uppercase cta-text mobile-category d-flex justify-content-between align-items-center"
                    onClick={this.toggleSubmenu}
                  >
                    {this.props.texts.education}
                  </a>
                  <div className="submenu-mobile">{education.map((el) => el)}</div>
                </div>
                <div className="has-sub sublevel transition-500 p-3 my-2">
                  <a
                    href="#"
                    className="label-m mb-2 text-uppercase cta-text mobile-category d-flex justify-content-between align-items-center"
                    onClick={this.toggleSubmenu}
                  >
                    {this.props.texts.music}
                  </a>
                  <div className="submenu-mobile">{music.map((el) => el)}</div>
                </div>
                <div className="has-sub sublevel transition-500 p-3 my-2">
                  <a
                    href="#"
                    className="label-m mb-2 text-uppercase cta-text mobile-category d-flex justify-content-between align-items-center"
                    onClick={this.toggleSubmenu}
                  >
                    {this.props.texts.hobbies}
                  </a>
                  <div className="submenu-mobile">{hobbies.map((el) => el)}</div>
                </div>
                <div className="has-sub sublevel transition-500 p-3 my-2">
                  <a
                    href="#"
                    className="label-m mb-2 text-uppercase cta-text mobile-category d-flex justify-content-between align-items-center"
                    onClick={this.toggleSubmenu}
                  >
                    {this.props.texts.other}
                  </a>
                  <div className="submenu-mobile">{other.map((el) => el)}</div>
                </div>
              </div>
            </div>
            <div className="has-sub transition-500 my-4">
              <a
                href="#"
                onClick={this.toggleSubmenu}
                className={`nav-link nav-link-normal dropdown-angle-pink-mobile d-flex justify-content-between align-items-center h6 mb-0 link-transition text-mode-dark`}
                role="button"
              >
                {this.props.texts.forOrganizers}
              </a>
              <div className="d-flex menu-left transition-500 bg-white flex-column w-100">
                <div className="menu-left-header px-3 py-2 d-flex justify-content-between">
                  <span className="nav-link-normal h6 mb-0 link-transition text-mode-dark">
                    {this.props.texts.forOrganizers}
                  </span>
                  <a href="#" onClick={this.toggleSubmenu} className="d-flex">
                    <img className={`submenu-trigger-icon`} alt="Previous menu" src="/img/svg/icon-arrow-left.svg" />
                  </a>
                </div>
                <div className="d-flex flex-column h-100 justify-content-between overflow-auto">
                  <div className="d-flex flex-column">
                    <div className="dropdown-col col-5 p-0 d-flex align-items-start flex-column">
                      <a className="dropdown-item mt-3" href="/manage-discount">
                        {this.props.texts.features}
                      </a>
                      <a className="dropdown-item mt-3" href="/pricing">
                        {this.props.texts.pricing}
                      </a>
                      <a className="dropdown-item mt-3" href="/why-plentix">
                        {this.props.texts.whyPlentix}
                      </a>
                      {/* <a className="dropdown-item mt-3" href="#">{this.props.texts.howItWorks}</a> */}
                    </div>
                  </div>
                  {this.props.isLogged ? (
                    <>
                      {/* events */}
                      <div className="col-12 mt-3">
                        <div className="label-m mb-2 text-uppercase d-flex align-items-center px-3">
                          {this.props.texts.admin.my_events}
                          <div className="d-flex align-items-center ms-1 text-dark circle-gray circle-gray-sm justify-content-center fw-500">
                            {this.props.myEvents?.length > 0 ? this.props.myEvents.length : 0}
                          </div>
                          <a href="/event/create/" className="ms-auto mb-1">
                            <img alt={this.props.texts.admin.my_events} src="/img/svg/add-button-icon.svg" />
                          </a>
                        </div>
                        <div className="col-12 px-3">
                          <div className="dropdown-col col-12 p-0 d-flex align-items-start flex-column">
                            {this.props.myEvents.map((event) => (
                              <Link
                                key={"event-" + event.id}
                                className="dropdown-item mt-2"
                                to={`/events/${event.category}/${event.name}/${event._id}`}
                              >
                                {event.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* promoters */}
                      <div className="col-12 mt-3">
                        <div className="label-m mb-2 text-uppercase d-flex align-items-center px-3">
                          {this.props.texts.admin.my_promoters}
                          <div className="d-flex align-items-center ms-1 text-dark circle-gray circle-gray-sm justify-content-center fw-500">
                            {this.props.myHosts?.length > 0 ? this.props.myHosts.length : 0}
                          </div>
                          <a href="/promoter/create/" className="ms-auto mb-1">
                            <img alt={this.props.texts.admin.create_promoter} src="/img/svg/add-button-icon.svg" />
                          </a>
                        </div>
                        <div className="col-12 px-3">
                          <div className={`dropdown-col p-0 d-flex flex-column`}>
                            {this.props.myHosts?.map((host, id) => (
                              <div
                                key={"host-" + id}
                                className={`d-flex flex-wrap justify-content-between align-items-center ${
                                  host.events?.length ? "mb-3" : ""
                                }`}
                              >
                                <div className="col-12 d-flex mt-2">
                                  <a className="dropdown-item" href={`/promoter/${host._id}`}>
                                    {host.name}
                                  </a>
                                  <a href={host.new} className="ms-auto mb-1">
                                    <img alt={host.name} width="20" height="20" src="/img/svg/add-button-icon.svg" />
                                  </a>
                                </div>
                                {host.events?.length ? (
                                  <div className="col-12 ps-4 d-flex flex-column">
                                    {host.events?.map((event, evId) => (
                                      <Link
                                        key={event._id}
                                        className="link-pink py-1 my-1"
                                        to={`/events/${event.category}/${event.name}/${event._id}`}
                                      >
                                        {event.name}
                                      </Link>
                                    ))}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="d-flex flex-column align-items-center m mb-5 mb-5 pt-4">
                    <div className="d-flex justify-content-center col-8">
                      <img
                        src="/img/svg/icon-organizer.svg"
                        alt={this.props.texts.eventPartnersLookFor}
                        className="w-100"
                      />
                    </div>
                    <div className="d-flex flex-column align-items-center text-center mt-2">
                      <div className="mobile-nav-title h6 col-8">{this.props.texts?.eventPartnersLookFor}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!this.props.isLogged ? (
              <div className="menu-left-footer col-12 d-flex align-items-end justify-content-center mb-5 flex-fill">
                <div className="d-flex flex-column align-items-center justify-content-center w-100 mobile-footer-content pt-4">
                  <img className="mobile-footer-icon" alt="Hey, Stranger" src="/img/svg/icon-hand.svg" />
                  <div className="mobile-nav-title h6">{this.props.texts?.hey_stranger}</div>
                  <div className="mobile-footer-actions py-4">
                    <a
                      id="register"
                      data-bs-dismiss="offcanvas"
                      href="/register"
                      role="button"
                      aria-controls="mobile-offcanvas"
                      className={`button-ghost nav-link-normal px-5 text-dark`}
                    >
                      {this.props.texts.signUp}
                    </a>
                    <a
                      id="login"
                      data-bs-dismiss="offcanvas"
                      href="/login"
                      role="button"
                      aria-controls="mobile-offcanvas"
                      // onClick={() => window.evedo.openLogin()}
                      className="nav-button-primary nav-link-normal text-white px-5 ms-2"
                    >
                      {this.props.texts?.signIn}
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mt-auto mb-4 ms-3 cta-text">
              <small>Language:</small>
              {languageBar}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MobileNav;
