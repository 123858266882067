import React from "react";
import { Form } from "./Form.jsx";

export const Edit = ({host}) => {
  return (
    <div className="container">
      <Form host={host}/>
    </div>
  );
};
