import "./TicketOptions.scss";

export const TicketOptions = ({ ticket }) => {
  return (
    <div className="ticket-options-wrapper">
      <strong>
        {ticket.amount} ticket{ticket.amount > 1 ? "s" : ""}
      </strong>
      <br />
      <span>Order #{ticket.order}</span>
      <div className="buttons">
        <a href={ticket.printLink} target="_blank" rel="noreferrer">
          Print
        </a>

        <div className="more">
          <div className="dropdown">
            <a href={ticket.downloadLink} target="_blank" rel="noreferrer">
              Download ticket{ticket.amount > 1 ? "s" : ""}
            </a>
            <div></div>
            {+ticket.price > 0 ? (
              <>
                <a href={ticket.printReceiptLink} target="_blank" rel="noreferrer">
                  Print receipt
                </a>
                <div></div>
                <a href={ticket.downloadReceiptLink} target="_blank" rel="noreferrer">
                  Download receipt
                </a>
              </>
            ) : null}
            <a href={ticket.addGoogleCalendar} target="_blank" rel="noreferrer">
              Add to Google Calendar
            </a>
            <a href={ticket.addOutlookCalendar} target="_blank" rel="noreferrer">
              Add to Outlook Calendar
            </a>
            <a href={ticket.downloadIcsLink} target="_blank" rel="noreferrer">
              Download.ics
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
