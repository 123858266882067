import { Details } from "./Details";
import { Navigation } from "./Navigation";
import { PaymentForm } from "./PaymentForm";

export const Pay = () => {
  return (
    <div style={{ background: "#fff" }}>
      <header style={{ boxShadow: "inset 0px -1px 0px #ECEAF0" }}>
        <Navigation page={"payment"} />
      </header>
      <div className="container" style={{ marginTop: "67px" }}>
        <div className="row justify-content-center">
          <Details
            selectedTickets={[
              { amount: 1, name: "General Pass", price: 369 },
              { amount: 1, name: "Pro business pass", price: 599 },
              { amount: 1, name: "Ultimate pass+", price: 1499 }
            ]}
            email={"anton.aladzhov@gmail.com"}
          />
          <PaymentForm />
        </div>
      </div>
    </div>
  );
};
