import { Link } from "react-router-dom";
import { Tag } from "./Tag.jsx";
import { TicketsMobile } from "./TicketsMobile.jsx";
import "./TicketsResponsive.scss";

export const TicketsResponsive = ({ event }) => {
  return (
    <div className="event-tickets-responsive d-block d-sm-block d-md-block d-lg-none cta-box row">
      <div className="col-12">
        <div className="p-4">
          <p className="my-2">
            {event.tags?.map((tag, i) => (
              <Tag key={i} tag={tag} />
            ))}
          </p>

          <h1 className="maintitle mt-3">{event.pageTextHeader}</h1>
          <h2 className="subtitle my-2">{event.pageTextBody}</h2>

          <span className="type-of-event my-2">
            <i className="fas fa-globe" width="14"></i>&nbsp;
            <span className="me-2 public">public event</span>
          </span>

          <div className="pt-4 d-none d-md-block d-lg-none">
            <p className="priceline xs mt-2">
              <img alt="ticket" src="https://plentix.co/img/event/icn-ticket.svg" width="20" />
              <span className="date-highlight pe-2 me-2">
                {event.startAt?.split(" ").slice(1, 3).join(" ")} - {event.endAt?.split(" ").slice(1, 3).join(" ")}
              </span>
              {event.settings?.priceType}
            </p>
            <Link to={`/orders/${event._id}/new`} className="btn btn-primary w-50 mb-0">
              Get Tickets
            </Link>
          </div>

          <TicketsMobile event={event} />
        </div>
      </div>
    </div>
  );
};
