import { GiveATry } from "./GiveATry";
import { Partners } from "./Partners";
import { Testimonial } from "./Testimonial";
import { OrganizerPagesHeader } from "./OrganizerPagesHeader";

import "./MyPlentix.scss";

export const MyPlentix = () => {
  const HeaderMyPage = {
    "header-title": "why choose us",
    header: "most streamlined event organisation Process",
    title: `Plentix is made for organizers but loved by attendees, as well. If this is not enough for you to give it a
    try read more… oh and it's also free!`,
    "background-image": "url(https://local.evedo.co/img/organizers/buddies-at-the-summer-festival-RW2DB23.jpg)"
  };
  return (
    <div className="container-my-page">
      <OrganizerPagesHeader props={HeaderMyPage} />
      <div className="container-main pt-0 pb-0">
        <div className="w-layout-grid grid-5 desktop-3col">
          <div className="box-image-text fadefrombottom">
            <img
              src="/img/organizers/illy-44.png"
              loading="lazy"
              width="390"
              alt="Reshaping the event industry illustration"
              className="image-header"
            />
            <div className="box-1 no-border vertical">
              <h4>Reshaping the event industry</h4>
              <p className="paragraph mt-0">
                We offer you the most streamlined event organization process. And strive to reshape the event industry
                to serve your core goal - connecting people through shared experiences.
              </p>
            </div>
          </div>
          <div className="box-image-text fadefrombottom">
            <img
              src="/img/organizers/illy-45.png"
              loading="lazy"
              width="390"
              alt="Transparent pricing illustration"
              className="image-header"
            />
            <div className="box-1 no-border vertical">
              <h4>Transparent pricing</h4>
              <p className="paragraph mt-0">
                We, more than anyone understand that doing what you love sometimes comes at a price. That's why you can
                use everything on <strong className="bold-text">Plentix™</strong> without paying a dime... for free
                events.
              </p>
            </div>
          </div>
          <div className="box-image-text fadefrombottom">
            <img
              src="/img/organizers/illy-46.png"
              loading="lazy"
              width="390"
              alt="Passionate team illustration"
              className="image-header"
            />
            <div className="box-1 no-border vertical">
              <h4>Passionate team</h4>
              <p className="paragraph mt-0">
                Each of our team members has great stories and involvement with the event industry. We all know how it
                gives joy, changes lives and expands human connection.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="full-width border-bottom">
        <div className="container-main">
          <div>
            <h2 className="heading left-aligned fadefrombottom">Plentix is a one-stop Shop for any event.</h2>
            <p className="paragraph-xl fadefrombottom">
              With Plentix you can do everything you need to organize events right in the platform. Create events,
              Market &amp; share with your audience, sell tickets, track progress and save time and money from using
              multiple tools. But plentix is also great for event goers.
            </p>
            <div data-duration-in="300" data-duration-out="100" className="tabs fadefrombottom w-tabs">
              <div className="tabs-menu w-tab-menu" role="tablist">
                <a
                  data-w-tab="For Organizers"
                  className="tab-link-for-organizers w-inline-block w-tab-link w--current"
                  id="w-tabs-0-data-w-tab-0"
                  href="#w-tabs-0-data-w-pane-0"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-0"
                  aria-selected="true"
                >
                  <div className="text-block-4">For Organizers</div>
                </a>
                <a
                  data-w-tab="For Event Goers"
                  className="tab-link-for-event-goers w-inline-block w-tab-link"
                  tabIndex="-1"
                  id="w-tabs-0-data-w-tab-1"
                  href="#w-tabs-0-data-w-pane-1"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-1"
                  aria-selected="false"
                >
                  <div className="text-block-5">For Event Goers</div>
                </a>
              </div>
              <div className="tabs-content w-tab-content">
                <div
                  data-w-tab="For Organizers"
                  className="tab-pane-for-organizers w-tab-pane w--tab-active"
                  id="w-tabs-0-data-w-pane-0"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-0"
                >
                  <div className="flex-container">
                    <div className="div-block-6">
                      <div className="flex-container top-aligned">
                        <div className="checkmark-outl-ined">
                          <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                        </div>
                        <div className="box-1 no-border vertical">
                          <h4 className="heading-10">Supporting each partner</h4>
                          <p className="paragraph mt-0">
                            Trust starts with understanding. And understanding comes from conversation, not statistics.
                            We strive to give our partners a say in the development of the platform. This dialogue
                            allows us to provide you with the tools and expertise you need.
                          </p>
                        </div>
                      </div>
                      <div className="flex-container top-aligned">
                        <div className="checkmark-outl-ined">
                          <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                        </div>
                        <div className="box-1 no-border vertical">
                          <h4 className="heading-10">Removing barriers of entry</h4>
                          <p className="paragraph mt-0">
                            Technology allows each and every one of us to gather people together. Our company's role at
                            this stage is to make this connection as easy as possible. We provide the education and
                            inspiration you need in order to become the best event organizer you can be.
                          </p>
                        </div>
                      </div>
                      <div className="flex-container top-aligned">
                        <div className="checkmark-outl-ined">
                          <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                        </div>
                        <div className="box-1 no-border vertical">
                          <h4 className="heading-10">Reach broader audience</h4>
                          <p className="paragraph mt-0">
                            Doing sales is not easy, we know that from the hundreds of conversations we have each day
                            with organizers. If nothing else plentix's users are another set of eyeballs that might be
                            interested in your event.&nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        src="/img/organizers/urban-happy-business-woman-using-tablet-computer-a-C6TCZDR.jpeg"
                        loading="lazy"
                        width="400"
                        sizes="(max-width: 479px) 92vw, (max-width: 767px) 400px, (max-width: 991px) 32vw, 36vw"
                        srcSet="/img/organizers/urban-happy-business-woman-using-tablet-computer-a-C6TCZDR-p-500.jpeg 500w, /img/organizers/urban-happy-business-woman-using-tablet-computer-a-C6TCZDR-p-800.jpeg 800w, /img/organizers/urban-happy-business-woman-using-tablet-computer-a-C6TCZDR.jpeg 900w"
                        alt="Happy event organizer woman"
                        className="image-7"
                      />
                    </div>
                  </div>
                </div>
                <div
                  data-w-tab="For Event Goers"
                  className="tab-pane-for-event-goers w-tab-pane d-none"
                  id="w-tabs-0-data-w-pane-1"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-1"
                >
                  <div className="flex-container">
                    <div className="div-block-6">
                      <div className="flex-container top-aligned">
                        <div className="checkmark-outl-ined">
                          <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                        </div>
                        <div className="box-1 no-border vertical">
                          <h4 className="heading-10">Feel secure about your ticket purchases</h4>
                          <p className="paragraph mt-0">
                            Buying tickets for events is sometimes painful because of the uncertainties around the
                            validity of the ticket or the happening of the event.&nbsp;The pandemic situation has shown
                            us how hard it is to get your money back or any kind of assistance if the event gets
                            cancelled. Using Plentix the refund is automatic and usually fast for 90% of the events.
                          </p>
                        </div>
                      </div>
                      <div className="flex-container top-aligned">
                        <div className="checkmark-outl-ined">
                          <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                        </div>
                        <div className="box-1 no-border vertical">
                          <h4 className="heading-10">Curated list of event options catered to your taste</h4>
                          <p className="paragraph mt-0">
                            Plentix is a place to find like-minded people and find what you prefer. Not everybody likes
                            rock, hip-hop... educational content or cultural events like exhibitions and live opera
                            performances. So let the platform give u suggestions and discover great events with it.
                          </p>
                        </div>
                      </div>
                      <div className="flex-container top-aligned">
                        <div className="checkmark-outl-ined">
                          <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                        </div>
                        <div className="box-1 no-border vertical">
                          <h4 className="heading-10">Everything you need in one platform</h4>
                          <p className="paragraph mt-0">
                            Plentix is your home for events. A way to find out about, share, and book events, as well as
                            coordinate with your friends. And find people with common interests to share experiences
                            that change lives.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        src="/img/organizers/happy-female-friends-standing-by-railing-at-music--RCFSEWG-1.jpeg"
                        loading="lazy"
                        width="400"
                        sizes="(max-width: 479px) 92vw, (max-width: 767px) 400px, (max-width: 991px) 32vw, 36vw"
                        srcSet="/img/organizers/happy-female-friends-standing-by-railing-at-music--RCFSEWG-1-p-500.jpeg 500w, /img/organizers/happy-female-friends-standing-by-railing-at-music--RCFSEWG-1-p-800.jpeg 800w, /img/organizers/happy-female-friends-standing-by-railing-at-music--RCFSEWG-1.jpeg 900w"
                        alt="Happy group of girls at a concert"
                        className="image-7"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-main">
        <div className="flex-container fadefrombottom">
          <div className="left-aligned">
            <img
              src="/img/organizers/nft-tickets-50.png"
              loading="lazy"
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 440px, (max-width: 991px) 36vw, 42vw"
              width="440"
              srcSet="/img/organizers/nft-tickets-50.png 500w, /img/organizers/nft-tickets-50.png 814w"
              alt=""
              className="image-7"
            />
            <div>
              <div className="cta-block mb-1">
                <div className="or-text">Learn more:</div>
                <a
                  data-w-id="81a06435-0ae6-87e8-4ce1-6bbed920aefd"
                  href="mailto:info@plentix.co?subject=Plentix%20NFT%20Tickets"
                  className="link-with-arrow w-inline-block"
                >
                  <div className="text-block-6">contact us</div>
                  <img src="/img/organizers/icn-arrow_1.svg" loading="lazy" alt="" className="link-arrow" />
                </a>
              </div>
            </div>
          </div>
          <div className="div-block-12">
            <div className="label-comingsoon mb-0">
              <div>coming soon</div>
            </div>
            <h3 className="mt-0 mb-1">NFT&nbsp;Tickets - Modern tech is here!</h3>
            <p className="paragraph-l mt-0">
              Use blockchain and NFTs to offer your audience more than just great experience at your events.
            </p>
            <ul role="list" className="list w-list-unstyled">
              <li className="list-item-2">
                <div className="checkmark-outl-ined">
                  <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                </div>
                <div>Unique collectible from the event</div>
              </li>
              <li className="list-item-2">
                <div className="checkmark-outl-ined">
                  <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                </div>
                <div>More special perks for the NFT&nbsp;ticket holder</div>
              </li>
              <li className="list-item-2">
                <div className="checkmark-outl-ined">
                  <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                </div>
                <div>Invest / trade on secondary markets</div>
              </li>
              <li className="list-item-2">
                <div className="checkmark-outl-ined">
                  <img src="/img/organizers/check-icn.svg" loading="lazy" alt="" className="image-11" />
                </div>
                <div>Many more NFT&nbsp;related perks</div>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex-container fadefrombottom">
          <div className="div-block-12">
            <h2 className="mb-custom-1">Loaded with the tools you need</h2>
            <p className="paragraph-xl">The beaten path will not always get you there.</p>
            <p className="mb-6">
              We give you tools that add new ways you can approach your work and remove the strain from organizing
              events. This attitude of pushing forward allows you to have the most versatile event toolset out there at
              all times.
            </p>
          </div>
          <div className="div-block-11">
            <img
              src="/img/organizers/cheerful-young-female-entrepreneur-DKCR8V9-2.jpeg"
              loading="lazy"
              sizes="(max-width: 479px) 93vw, (max-width: 767px) 94vw, (max-width: 991px) 48vw, 40vw"
              width="545"
              srcSet="/img/organizers/cheerful-young-female-entrepreneur-DKCR8V9-2-p-800.jpeg 800w, /img/organizers/cheerful-young-female-entrepreneur-DKCR8V9-2-p-1080.jpeg 1080w, /img/organizers/cheerful-young-female-entrepreneur-DKCR8V9-2.jpeg 1090w"
              alt="cheerful young woman looking at opened laptop"
              className="image-7"
            />
          </div>
        </div>
        <div className="bg-solid pale fadefrombottom">
          <div className="container-medium">
            <h3>What we have so far ?</h3>
            <div className="w-layout-grid stats-grid">
              <div className="stat-container">
                <div className="stats-number">$100K+</div>
                <div>Revenue send to organizers</div>
              </div>
              <div className="stat-container">
                <div className="stats-number">3K+</div>
                <div>Events executed</div>
              </div>
              <div className="stat-container">
                <div className="stats-number">5k+</div>
                <div>Tickets sold</div>
              </div>
              <div className="stat-container">
                <div className="stats-number">50k+</div>
                <div>Free tickets generated</div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-solid pale fadefrombottom">
          <div className="container-medium">
            <div className="div-block-7">
              <h3 className="heading-9">So what can you do on Plentix?</h3>
            </div>
            <div className="w-layout-grid grid-3">
              <ul role="list" className="w-list-unstyled">
                <li className="list-item bigger">
                  <div className="label-m">For Organizers</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-publiclisting.svg" loading="lazy" alt="" className="feature-icn" />
                  <div>Public Listing</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-unlimitedtypes.svg" loading="lazy" alt="" className="feature-icn" />
                  <div>Unlimited ticket types</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-share.svg" loading="lazy" alt="" className="feature-icn" />
                  <div>Share Functionalities</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-embed.svg" loading="lazy" alt="" className="feature-icn" />
                  <div>Embed ticket sales to your website</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-customize.svg" loading="lazy" alt="" className="feature-icn" />
                  <div>Customizable checkout forms</div>
                </li>
              </ul>
              <ul role="list" className="w-list-unstyled">
                <li className="list-item bigger">
                  <div className="label-m">For Event Goers</div>
                </li>
                <li className="list-item bigger">
                  <img
                    src="/img/organizers/icn-bookingprocess.svg"
                    loading="lazy"
                    width="10.5"
                    alt=""
                    className="feature-icn"
                  />
                  <div>Simple &amp;&nbsp;quick booking process</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-calendar.svg" loading="lazy" alt="" className="feature-icn" />
                  <div>Events Calendar</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-share.svg" loading="lazy" alt="" className="feature-icn" />
                  <div>Share with friends</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-friendsfeed.svg" loading="lazy" alt="" className="feature-icn" />
                  <div>Friends' events feed</div>
                </li>
                <li className="list-item bigger">
                  <img src="/img/organizers/icn-privacy.svg" loading="lazy" width="9" alt="" className="feature-icn" />
                  <div>Control your personal data</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="cta-block centered">
            <div className="or-text">And that is just a fraction! View</div>
            <a
              data-w-id="42682afa-8514-d314-b238-a0a6877136a4"
              href="/features"
              className="link-with-arrow w-inline-block"
            >
              <div className="text-block-6">the features</div>
              <img src="/img/organizers/icn-arrow_1.svg" loading="lazy" alt="" className="link-arrow" />
            </a>
          </div>
        </div>
        <Partners />
        <Testimonial />
      </div>
      <div className="full-width palebg">
        <h3 className="heading centered fadefrombottom">4.5 Review on trustpilot</h3>
        <div className="container-medium">
          <div className="w-layout-grid grid-4 fadefrombottom">
            <img
              src="/img/organizers/trustpilot-review.png"
              loading="lazy"
              sizes="100vw"
              srcSet="/img/organizers/trustpilot-review-p-500.png 500w, /img/organizers/trustpilot-review.png 501w"
              alt="Trust pilot review"
              className="trustpilot-review"
            />
            <img
              src="/img/organizers/trustpilot-review-2.png"
              loading="lazy"
              sizes="100vw"
              srcSet="/img/organizers/trustpilot-review-2-p-500.png 500w, /img/organizers/trustpilot-review-2.png 607w"
              alt="Trust pilot review"
              className="trustpilot-review"
            />
          </div>
          <div className="cta-block centered mt-4 fadefrombottom">
            <div className="or-text">These are just two of many!</div>
            <a
              data-w-id="6f753e03-e097-213c-571d-4426bde1e984"
              href="https://www.trustpilot.com/review/evedo.co"
              target="_blank"
              className="link-with-arrow w-inline-block"
            >
              <div className="text-block-6">View all on trustpilot</div>
              <img src="/img/organizers/icn-arrow_1.svg" loading="lazy" alt="" className="link-arrow" />
            </a>
          </div>
        </div>
      </div>
      <GiveATry />
    </div>
  );
};
