import { useState } from "react";
import { Route, Routes, Navigate, Outlet, Link } from "react-router-dom";

import { Privacy } from "./components/Privacy";
import { Terms } from "./components/Terms";
import { Cookies } from "./components/Cookies";
import { Attendees } from "./components/Attendees";
import { EventOrganizer } from "./components/EventOrganizer";

import "./Legal.scss";

export const Legal = () => {
  const [pathname, setPathname] = useState(window.location.pathname);

  const clickHandler = (e) => {
    setPathname(e);
  };
  return (
    <div className="container">
      <div className="row">
        <section className="col-12 legal">
          <aside>
            <div className="sidebar-menu sticky">
              <Link
                className={`indent ${pathname.includes("terms-of-use") ? "active" : ""}`}
                to="terms-of-use/"
                onClick={() => clickHandler("terms-of-use/")}
              >
                <span>{"Terms and Conditions".slice(0, 12) + "..."}</span>
              </Link>
              <Link
                className={`indent ${pathname.includes("privacy-policy") ? "active" : ""}`}
                to="privacy-policy/"
                onClick={() => clickHandler("privacy-policy/")}
              >
                <span>{"Privacy Policy"}</span>
              </Link>

              <Link
                className={`indent ${pathname.includes("cookie-policy") ? "active" : ""}`}
                to={`cookie-policy/`}
                onClick={() => clickHandler("cookie-policy/")}
              >
                <span>{"Cookie Policy"}</span>
              </Link>
              <Link
                className={`indent ${pathname.includes("buyers-agreement") ? "active" : ""}`}
                to={`buyers-agreement/`}
                onClick={() => clickHandler("buyers-agreement/")}
              >
                <span>{"Attendees Agreement".slice(0, 12) + "..."}</span>
              </Link>
              <Link
                className={`indent ${pathname.includes("organizers-agreement") ? "active" : ""}`}
                to={`organizers-agreement/`}
                onClick={() => clickHandler("organizers-agreement/")}
              >
                <span>{"Event Organizers Agreement".slice(0, 12) + "..."}</span>
              </Link>
            </div>
          </aside>
          {pathname === ("/legal" || "/legal/") ? <Navigate to="terms-of-use" /> : <Outlet />}
          <Routes>
            <Route exact path={"terms-of-use"} element={<Terms />} />
            <Route exact path={"privacy-policy"} element={<Privacy />} />
            <Route exact path={"cookie-policy"} element={<Cookies />} />
            <Route exact path={"buyers-agreement"} element={<Attendees />} />
            <Route exact path={"organizers-agreement"} element={<EventOrganizer />} />
          </Routes>
        </section>
      </div>
    </div>
  );
};
