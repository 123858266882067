import { useState } from "react";

import "./Tags.scss";

export const Tags = ({ newPage, tags, setTags, updateProfile, userFirstName }) => {
  return (
    <>
      <div className="mask steps mb-5">
        <div className="wrapper">
          <div className="slide h-100 justify-content-center tags d-flex flex-column align-items-center">
            <img className="mx-auto my-5" src="/img/svg/icon-welcome.svg" alt="Welcome to Plentix" />
            <h2 className="h4 text-dark-blue">Welcome aboard {userFirstName}</h2>
            <div className="dropdown d-flex">
              <div className="text-m text-dark-grey">
                Choose at least <b>3 tags</b> that you might like
              </div>
              <a
                href="/why-plentix"
                className="link-transition ms-2 d-flex"
                type="button"
                id="whatsThis"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img className="col" src="/img/svg/icon-questionmark-grey.svg" alt="What’s this?" />
                <div className="ms-1 featured-link">
                  <span className="cta-text">What’s this?</span>
                </div>
              </a>
              <ul className="dropdown-menu transition-200 widget-dropdown text-xs" aria-labelledby="whatsThis">
                <li>Predefined tags help us Plentix understand better what events you migh prefer.</li>
              </ul>
            </div>
            <form action="#" className="tags w-100 mt-4 d-flex justify-content-center">
              <input
                name="add-tag"
                id="add-tag-input"
                placeholder="Add your interests (e.g. live music, rock, hip hop, jazz, art, comedy, etc.)"
                type="text"
                className="search-input py-4 px-5 text-s text-mode-dark bg-white rounded-3 border-0 flex-fill mx-4 ui-autocomplete-input"
                autoComplete="off"
                defaultValue={tags}
                onChange={(e) => setTags(e.target.value)}
              />
            </form>
            <div id="tags-wrapper" className="col-12 mt-3">
              <ul className="tags d-flex flex-wrap" id="setup-tags"></ul>
            </div>
          </div>
        </div>
      </div>

      <div className="mask navigation">
        <div className="wrapper">
          <div className="slide skip p-1"></div>
          <div className="slide steps d-flex justify-content-center align-items-center">
            <button
              className="nav-button-primary text-white d-flex align-items-center py-3 px-5"
              onClick={(e) => {
                e.preventDefault();
                updateProfile(e);
                newPage();
              }}
            >
              Next <img className="ms-3" src="/img/svg/icon-arrow-right-long.svg" alt="Next" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
