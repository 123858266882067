import { Card } from "./Card";
import "./Friends.scss";

export const Friends = ({ friendId, friends, setFriendId, setFriends }) => {
  return (
    <ul className="messaged-friends-wrapper">
      {friends.map(
        (f) =>
          f.messages.length > 0 && (
            <Card
              key={f.id}
              friend={f}
              active={f.id === friendId}
              setFriendId={setFriendId}
              setFriends={setFriends}
              friends={friends}
              lastMessage={f.messages[f.messages.length - 1].text}
            />
          )
      )}
    </ul>
  );
};
