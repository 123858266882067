import { useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { ReactComponent as BackArrow } from "./assets/back-arrow.svg";
import { Button } from "./Button";
import { Checkbox } from "./Checkbox";
import "./PaymentForm.scss";

export const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [termsAgreed, setTermsAgreed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (termsAgreed) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/orders/:id/finish`
        }
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error.type === "card_error" || error.type === "validation_error") {
        console.log(error.message);
      } else {
        console.log(error);
      }
    }
    window.location.href = `${window.location.origin}/orders/:id/finish`;
  };

  return (
    <form className="checkout-payment-form col-12 col-lg-5 col-md-5" onSubmit={handleSubmit}>
      <h3 className="form-heading">Fill in the required information</h3>
      <p className="finish-payment-text">
        Please finish the payment below
        <span className="ml-2 timer">Time left: 14:52</span>
      </p>

      <div className="payment-fields">
        <PaymentElement />

        <div className="d-flex terms-container">
          <Checkbox checked={termsAgreed} setChecked={setTermsAgreed} />
          <p className="ml-4 terms-p ms-2">
            By submiting the payment you agree to <span className="term-link">Terms of Use</span> and{" "}
            <span className="term-link">Attendees Agreement</span>.
          </p>
        </div>
      </div>

      <Button text={"Submit Payment"} type={"submit"} onClick={handleSubmit} />
      <button className="mx-auto mt-2 d-flex align-items-center go-back-btn">
        <BackArrow /> Go Back
      </button>
    </form>
  );
};
