import React from "react";

import "./InfoSection.scss";

const InfoSection = (props) => (
  <>
    <div className="row flex-nowrap align-items-center">
      <div className="col-lg-6 col-md-8 col-12">
        <h2 className="h2 text-dark">{props.content.title}</h2>
      </div>
      <div className="col-lg-6 col-md-4 d-none d-md-flex pink-hr"></div>
    </div>
    <div className="row">
      <div className="text-l cta-text my-4 col-12">{props.content.description}</div>
    </div>
    <div className="row px-5 px-sm-0">
      <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-column section-blocks mt-3">
        {heartIcon()}
        <p className="card-text text-dark d-flex justify-content-between text-l my-3">
          <b>{props.content.first_section.title}</b>
        </p>
        <div className="text-s mb-3">{props.content.first_section.description}</div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-column section-blocks mt-3">
        {heartIcon()}
        <p className="card-text text-dark d-flex justify-content-between text-l my-3">
          <b>{props.content.second_section.title}</b>
        </p>
        <div className="text-s mb-3">{props.content.second_section.description}</div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-column section-blocks mt-3">
        {heartIcon()}
        <p className="card-text text-dark d-flex justify-content-between text-l my-3">
          <b>{props.content.third_section.title}</b>
        </p>
        <div className="text-s mb-3">{props.content.third_section.description}</div>
      </div>
    </div>
  </>
);
export default InfoSection;

const heartIcon = () => (
  <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.6777 28.749L14.2325 32.3038L10.6777 35.8585"
      stroke="#F347BA"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14.2326 32.3047H3.56836" stroke="#F347BA" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21.3425 33.6728C29.9628 31.7177 35.3482 23.133 33.3931 14.5305C31.438 5.91026 22.8533 0.524825 14.2508 2.47993C5.63059 4.45281 0.227386 13.0197 2.20027 21.64C2.48465 22.8664 2.91122 24.075 3.47997 25.2125"
      stroke="#F347BA"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.992 24.0924C19.4054 24.6789 18.6056 25.0166 17.788 25.0166C16.9527 25.0166 16.1706 24.6789 15.5841 24.0924L11.8338 20.3599C10.2875 18.8136 10.2875 16.3253 11.8161 14.7967C13.3446 13.2504 15.8329 13.2504 17.3792 14.779C17.3792 14.779 17.3792 14.779 17.397 14.7967L17.788 15.1878L18.1791 14.7967C19.7076 13.2504 22.1959 13.2504 23.7422 14.779C25.2885 16.3075 25.2885 18.7958 23.76 20.3421C23.76 20.3421 23.76 20.3421 23.7422 20.3599L19.992 24.0924Z"
      stroke="#F347BA"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
