import React, { useEffect, useState } from "react";
import { ethers } from "ethers";

import { createWeb3Modal, connectWallet } from "../../helpers/web3Modal";
// import './WalletConnect.scss';

let web3Modal;
export const WalletConnect = ({}) => {
  const [library, setLibrary] = useState(null);
  const [address, setAddress] = useState(null);
  const [provider, setProvider] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [changedAddress, setChangedAddress] = useState(null);

  const [showChangeAddressModal, setShowChangeAddressModal] = useState(false);

  useEffect(() => {
    web3Modal = createWeb3Modal();
    if (web3Modal.cachedProvider && !window.location.pathname.includes("logout")) {
      onConnect().catch(() => {
        resetApp();
      });
    }
  }, []);

  async function onConnect() {
    const provider = await connectWallet(web3Modal);
    const address = await getAddress(provider);
    await subscribeToProviderEvents(provider);

    checkingAddress(address);

    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    setLibrary(library);

    setProvider(provider);
    setAddress(address);
    setChainId(network.chainId);

    if (window.location.search.includes("?changed_acc=true ") && provider) {
      const accounts = await provider.request({ method: "eth_accounts" });
      await changedAccounts(accounts);
      return;
    }

    localStorage.setItem("address", address);

    const body = document.querySelector("body");
    body.style.background = "white";
  }

  async function resetApp() {
    await disposeCashedProvider();

    if (window.coinbaseWalletExtension) {
      if (window.coinbaseWalletExtension._addresses.length > 0 === false) {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  }

  async function getAddress(provider) {
    let address = "";
    if (!provider.selectedAddress && !provider.accounts) {
      const addressArray = await provider.request({ method: "eth_accounts" });
      address = addressArray[0];
    } else {
      address = provider.selectedAddress ? provider.selectedAddress : provider.accounts[0];
    }

    return address;
  }

  async function subscribeToProviderEvents(provider) {
    if (!provider.on) {
      return;
    }

    provider.on("accountsChanged", changedAccounts);
    provider.on("chainChanged", chainChanged);
    provider.on("disconnect", resetApp);
  }

  function checkingAddress(address) {
    if (address.slice(0, 2) !== "0x") {
      throw Error("Incorrect address!");
    }
  }

  async function changedAccounts(accounts) {
    if (!accounts.length) {
      // Metamask Lock fire an empty accounts array
      await resetApp();
      return;
    }

    // This check is because walletConnect fire this event when we sign a new transaction
    const currentAddress = localStorage.getItem("address");
    if (currentAddress.toLocaleLowerCase() !== accounts[0].toLocaleLowerCase()) {
      setShowChangeAddressModal(true);
      setChangedAddress(accounts[0]);
      // localStorage.removeItem('bt');
    }
  }

  const disposeCashedProvider = async () => {
    await web3Modal.clearCachedProvider();
    localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("address");

    await unSubscribe(provider);
  };

  const unSubscribe = async (provider) => {
    if (!provider || !provider.removeListener) {
      return;
    }

    provider.removeListener("accountsChanged", changedAccounts);
    provider.removeListener("chainChanged", chainChanged);
    provider.removeListener("disconnect", resetApp);
  };

  async function chainChanged(_chainId) {
    setChainId(parseInt(_chainId.toString(10)));
  }

  return (
    <div className="col-12 col-md-6 general-settings-form-wrapper">
      <div className="wallet-wrapper box py-3">
        <div className="container-fluid">
          <h2>Connect with your crypto wallet</h2>
          <div className="col-12">
            <div className="row g-1">
              <div className="col-12 col-md-6">
                <h1>Address</h1>
                <p>{address}</p>
              </div>
            </div>
          </div>
          {address ? (
            <button className="btn btn-primary" onClick={() => resetApp()}>
              Disconnect Wallet
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => {
                onConnect().catch((err) => {
                  console.log(err);
                });
              }}
            >
              Connect Wallet
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
