import { Link, useLocation, useSearchParams } from "react-router-dom";
import { TicketsResponsive } from "./TicketsResponsive";
import { Attendee } from "./Attendee";
import "./RSVP.scss";
import { useEffect, useState } from "react";
import { patchEventsRsvp } from "../../services/eventService";

export const RSVP = ({ event, setEvent }) => {
  const [usersGoing, setUsersGoing] = useState([]);
  const [response, setResponse] = useState("");
  const locationPath = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const actions = searchParams.get("action");
    if (localStorage.getItem("accessToken")) {
      if (searchParams.has("action") && ["interested", "attending", "love-it", "not"].includes(actions)) {
        handleRespond(actions);
      }
    } else {
      window.location.href = `${window.location.origin}/login`;
    }
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (event?.rsvp) {
      if (userId && event.rsvp?.length > 0) {
        event.rsvp.forEach((el) => {
          if (el.user === userId) {
            setResponse(el.response.toLowerCase());
          }
        });

        setUsersGoing(() => event.rsvp.filter((el) => el.response === "attending"));
      }
    }
  }, [event]);

  const handleRespond = async (response) => {
    const data = {
      rsvp: localStorage.getItem("userId"),
      response: response
    };

    patchEventsRsvp(event._id, data)
      .then((result) => {
        setEvent(result.event);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="row event-rsvp">
      <div className="col-12">
        <TicketsResponsive event={event} />

        <div className="justify-content-between white-bg row">
          <div className="col-12 col-md-6">
            <div className="p-4">
              <h5 className="heading-l mb-2">
                {usersGoing.length} others are going!&nbsp;
                <Link to={`/events/${event._id}/guests`} className="link sm">
                  See all
                </Link>
              </h5>
              <section className="people-widget">
                <div className="body">
                  {usersGoing.map((user) => (
                    <Attendee key={user._id} user={user} />
                  ))}
                </div>
              </section>
            </div>
          </div>

          <div className="col-12 col-md-6 text-sm-left">
            <div className="p-4">
              <h5 className="heading-l mb-3">Are you going? RSVP now</h5>
              <div className="rsvp-container mt-1 d-flex align-items-center justify-content-md-end justify-content-sm-start w-100">
                <button
                  className={`marker-green rsvp-btn ${response === "attending" ? "selected" : ""}`}
                  onClick={() => handleRespond("attending")}
                >
                  <i className="fas fa-check"></i>
                  <span className="marker-title">Attending</span>
                </button>

                <button
                  className={`marker-yellow rsvp-btn ${response === "interested" ? "selected" : ""}`}
                  onClick={() => handleRespond("interested")}
                >
                  <i className="fas fa-star"></i>
                  <span className="marker-title">Interested</span>
                </button>

                <button
                  className={`marker-pink rsvp-btn ${response === "love-it" ? "selected" : ""}`}
                  onClick={() => handleRespond("love-it")}
                >
                  <i className="fas fa-heart"></i>
                  <span className="marker-title">Love it but can't</span>
                </button>

                <button
                  className={`marker-blue rsvp-btn ${response === "not" ? "selected" : ""}`}
                  onClick={() => handleRespond("not")}
                >
                  <i className="fas fa-times"></i>
                  <span className="marker-title">Not interested</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
