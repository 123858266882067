import "./AchievementCard.scss";

export const AchievementCard = ({ achievement }) => {
  return (
    <li className="achievement-card">
      <img src={achievement.image} alt="achievement" />
      <strong>{achievement.name}</strong>
      {achievement.date}
      {achievement.target && <span className="target">{achievement.target}</span>}
    </li>
  );
};
