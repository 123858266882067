import React from "react";
import { DateSelector } from "../DateSelector/DateSelector.jsx";
import styles from "./styles.module.scss";

export const NumberBox = ({ heading, subheading, number, value, onChange, menuItems = [], label }) => {
  return (
    <div className={styles.box}>
      <div className={styles.container}>
        <div>
          <p className={styles.heading}>{heading}</p>
          <p className={styles.subHeading}>{subheading}</p>
        </div>
        {menuItems.length > 0 && (
          <DateSelector value={value} onChange={onChange} label={label} menuItems={menuItems} sx={{ ml: 2 }} />
        )}
      </div>

      <div className={styles.numberBox}>
        <p className={styles.number}>{number}</p>
      </div>
    </div>
  );
};
