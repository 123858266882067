import React, { useState } from "react";
import { Form } from "./Manage/Form.jsx";

export const New = () => {
  return (
    <div className="container">
      <Form categories={["Concert", "Conference", "Party", "Travel"]} />
    </div>
  );
};
