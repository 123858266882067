import { Link } from "react-router-dom";
import "./SimilarEventCard.scss";

export const SimilarEventCard = ({ similarEvent }) => {
  return (
    <div className="similar-event-card">
      <div className="title-container">
        <Link to={`/events/${similarEvent.id}`}>
          <h3>{similarEvent.title}</h3>
        </Link>
      </div>

      <div className="card-content">
        <button className="mask">
          <Link to={`/events/${similarEvent.id}`}>
            <img
              className="object-cover"
              alt="event"
              src="https://plentix.co/img/content/events/3947ca9dde8f7bb9188857a6f78989ae-thumb.jpg?1643901670"
            />
          </Link>
          <Link to={`/events/${similarEvent.category.toLowerCase()}`} className="category">
            {similarEvent.category}
          </Link>
        </button>
        <span>{similarEvent.location}</span>
        <time>{new Date(similarEvent.startAt).toLocaleDateString("uk")}</time>
      </div>
    </div>
  );
};
