import { Link } from "react-router-dom";

import ProfileImage from "../../../src/assets/profile-image.jpg";

import "./FavouritePromoterCard.scss";

export const FavouritePromoterCard = ({ promoter }) => {
  return (
    <li className="favourite-promoter-card">
      <Link to={`/promoter/${promoter._id}`}>
        <button type="button" className="mask">
          <img alt="promoter" src={promoter.image ? promoter.image : ProfileImage} />
        </button>
        <button type="button" className="title">
          {promoter.name}
        </button>
      </Link>
      {/* <span>{promoter.location ? promoter.location : ''}</span> */}
      <span>Bulgaria Sofia </span>
    </li>
  );
};
