import { Friends } from "./Friends.jsx";
import { Search } from "./Search.jsx";
import "./Panel.scss";

export const Panel = ({ friendId, setFriendId, friends, setFriends }) => {
  return (
    <section className="box message-friend-panel col-12 col-md-4 col-lg-3 relative overflow-hidden">
      <Search setFriendId={setFriendId} friends={friends} setFriends={setFriends} />
      <Friends friendId={friendId} friends={friends} setFriendId={setFriendId} setFriends={setFriends} />
    </section>
  );
};
