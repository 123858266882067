import "./PrivacySettingSwitch.scss";

export const PrivacySettingSwitch = ({ setting, state, settings, setSettings }) => {
  const handleSelect = (newState) => {
    const newSettings = { ...settings };
    newSettings[setting.id] = newState;
    setSettings(newSettings);
  };

  return (
    <div className="privacy-setting-switch block col-12 d-md-flex justify-content-between">
      <div className="title-container me-auto">
        <strong>{setting.title}</strong>
        {setting.description}
      </div>

      <label className={`${state !== "public" ? "unchecked" : ""}`} onClick={() => handleSelect("public")}>
        Public
      </label>
      <label className={`${state !== "friends" ? "unchecked" : ""}`} onClick={() => handleSelect("friends")}>
        Friends only
      </label>
      <label className={`${state !== "private" ? "unchecked" : ""}`} onClick={() => handleSelect("private")}>
        Private
      </label>
    </div>
  );
};
