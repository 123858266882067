import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import Card from "../../../Discover/components/EventCard";

import "./NearbyEvents.scss";

const NearbyEvents = (props) => {
  let cardsList = props.cards.length
    ? props.cards.slice(0, props.cardsToShow).map((card, id) => {
        return (
          <Card
            key={id}
            cardTexts={props.description}
            picture={card.image}
            url={`/events/${card.category?.toLowerCase()}/${card.name}/${card._id}`}
            type={card.category}
            dateStart={`${new Date(card.startAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
            dateEnd={`${new Date(card.endAt).toLocaleString("default", { month: "short", day: "numeric" })}`}
            title={card.name}
            going={card.going}
            featured={card.featured}
            where={card.where}
            price={card.price}
            categories={Object.values(props.categories)}
          />
        );
      })
    : "";
  let education = [];
  let music = [];
  let hobbies = [];
  let other = [];
  const [eventsWhenSelected, setEventsWhenSelected] = useState("Whenever");
  const eventsWhen = ["Whenever", "Today", "This Weekend", "This Week", "This Month"];
  const sortingCategories = [
    {
      name: "All Events",
      key: "all"
    },
    {
      name: "Concerts",
      key: "concert"
    },
    {
      name: "Conferences",
      key: "conference"
    },
    {
      name: "Festivals",
      key: "festival"
    },
    {
      name: "Parties",
      key: "party"
    },
    {
      name: "Meetups",
      key: "meetup"
    }
  ];

  props.categories.map((cat, id) => {
    return cat.parent_category == "Education"
      ? education.push(
          <a
            key={`${cat}-${id}`}
            className="dropdown-item mb-2"
            href={`/events/${cat.name_plural.toLowerCase()}`}
            data-cat={cat.name}
            onClick={props.switchEventCategory}
          >
            {cat.name_plural_translated}
          </a>
        )
      : cat.parent_category == "Music & Entertainment"
      ? music.push(
          <a key={`${cat}-${id}`} className="dropdown-item mb-2" href={`/events/${cat.name_plural.toLowerCase()}`}>
            {cat.name_plural_translated}
          </a>
        )
      : cat.parent_category == "Hobbies"
      ? hobbies.push(
          <a key={`${cat}-${id}`} className="dropdown-item mb-2" href={`/events/${cat.name_plural.toLowerCase()}`}>
            {cat.name_plural_translated}
          </a>
        )
      : cat.parent_category == "Other"
      ? other.push(
          <a key={`${cat}-${id}`} className="dropdown-item mb-2" href={`/events/${cat.name_plural.toLowerCase()}`}>
            {cat.name_plural_translated}
          </a>
        )
      : null;
  });

  return (
    <div className="row mt-5 mb-5">
      <div className="col-12">
        <div className="row">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="dropdown">
              <h2 className="title-bilo-m mb-4 px-3 px-sm-0 text-dark d-flex">
                {props.content.title}
                <a
                  href="#"
                  className={`link-pink mb-0 link-transition ms-2 position-relative d-flex align-items-center`}
                  data-bs-toggle="dropdown"
                  role="button"
                  id="whenDropdown"
                  aria-expanded="false"
                  data-when="Whenever"
                >
                  {eventsWhenSelected}
                  <FontAwesomeIcon className="font-awesome me-2" icon={faAngleDown} />
                </a>
                <div className="dropdown-menu widget-dropdown when-dropdown p-2" aria-labelledby="whenDropdown">
                  <div className="d-flex flex-column">
                    {eventsWhen.map((when, id) => (
                      <a
                        href="#"
                        onClick={(e) => {
                          setEventsWhenSelected(when);
                          props.switchEvents(e);
                        }}
                        data-when={when}
                        className={`d-flex align-items-center justify-content-between px-2 mb-1 nav-link-normal ${
                          eventsWhenSelected === when ? "current" : ""
                        }`}
                        key={`when-${id}`}
                      >
                        {when}{" "}
                        {eventsWhenSelected === when ? <i className="fad fa-check-circle text-pink ms-4"></i> : ""}
                      </a>
                    ))}
                  </div>
                </div>
              </h2>
            </div>
            <div className="filter-events d-flex ms-auto align-items-center flex-wrap">
              {sortingCategories.map((cat, id) => (
                <a
                  href="#"
                  onClick={props.switchEventCategory}
                  data-cat={cat.key}
                  className={`d-flex align-items-center justify-content-between mb-1 nav-link-normal cta-text link-transition ${
                    props.eventsCategorySelected === cat.key ? "current" : ""
                  }`}
                  key={`category-${id}`}
                >
                  {cat.name}
                </a>
              ))}
              <div className="dropstart">
                <a
                  href="#"
                  className="link-pink text-l d-flex align-items-center mb-1 link-transition"
                  data-bs-toggle="dropdown"
                  role="button"
                  id="moreCategoriesDropdown"
                  aria-expanded="false"
                >
                  : {props.content.more}
                </a>
                <div
                  className="dropdown-menu widget-dropdown category-dropdown more-categories-dropdown"
                  aria-labelledby="moreCategoriesDropdown"
                >
                  <div className="d-flex flex-wrap flex-lg-nowrap">
                    <div className="dropdown-col col-12 col-sm-6 col-lg-auto">
                      <p className="label-m mb-2 text-uppercase ps-3">{props.content.education}</p>
                      {education.map((el) => el)}
                    </div>
                    <div className="dropdown-col col-12 col-sm-6 col-lg-auto">
                      <p className="label-m mb-2 text-uppercase ps-3">{props.content.music}</p>
                      {music.map((el) => el)}
                    </div>
                    <div className="dropdown-col col-12 col-sm-6 col-lg-auto">
                      <p className="label-m mb-2 text-uppercase ps-3">{props.content.hobbies}</p>
                      {hobbies.map((el) => el)}
                    </div>
                    <div className="dropdown-col col-12 col-sm-6 col-lg-auto">
                      <p className="label-m mb-2 text-uppercase ps-3">{props.content.other}</p>
                      {other.map((el) => el)}
                    </div>
                    <div
                      className="dropdown-image ms-3 col-12 col-sm-6 col-lg-auto"
                      style={{
                        backgroundImage: `url(http://tickets.evedo.co/img/content/events/c871b1dd1d48a883bd75a101ba494243.jpg)`
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 p-sm-0">
            <div className="row my-3 flex-wrap">
              {props.cards.length > 0 ? (
                cardsList
              ) : (
                <div className="col-12 h6 text-center py-5">{props.content.no_results}</div>
              )}
              {props.cards.length !== 0 && props.moreEvents ? (
                <SkeletonCard
                  content={props.content}
                  loadMore={props.loadMore}
                  showAll={props.cardsLength == props.cardsToShow ? true : false}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NearbyEvents;

const SkeletonCard = (props) => (
  <div className="card border-0 bg-transparent col-md-4 col-sm-6 mt-4">
    <div className="nearby-card overflow-hidden pb-3">
      <div className="card-bg w-100 d-flex pb-3 align-items-center justify-content-center position-relative overflow-hidden skeleton">
        <a href="/search?show=all" className="button-ghost link-transition" onClick={props.loadMore}>
          {!props.showAll ? props.content.load_more : props.content.view_all}
        </a>
      </div>
      <div className="card-body p-2 d-flex flex-column align-items-start">
        <p className="card-type text-uppercase text-secondary skeleton mb-2">skeleton content</p>
        <p className="card-text h6 text-dark d-flex justify-content-between skeleton w-100">skeleton title content</p>
      </div>
    </div>
  </div>
);
