import { useEffect, useState } from "react";
import { Map } from "../Common/Map.jsx";

import "./Host.scss";

export const Host = () => {
  const [showDescription, setShowDescription] = useState(false);
  const [style, setStyle] = useState({ height: "100px" });
  useEffect(() => {
    showDescription ? setStyle({ height: "400px" }) : setStyle({ height: "100px" });
  }, [showDescription]);

  return (
    <section className="box summary row horizontal">
      <div className="information col-12 col-sm-6">
        <hgroup>
          <h1 id="host_name">BCWT</h1>
          <h2 id="host_shortdescription">Bulgarian Centre Women in Technology</h2>
        </hgroup>

        <table className="details col-12 table-borderless table">
          <tbody>
            <tr>
              <td>
                <strong>Place</strong>:
              </td>
              <td>
                <a className="jump-to-map" href="#">
                  {" "}
                  Club by MOVE.BG, ulitsa "Serdika", Sofia, Bulgaria{" "}
                </a>{" "}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Rating</strong>:
              </td>
              <td>
                <div className="rating">
                  <div
                  // style="width: 0%;"
                  ></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="actions">
          <div className="follow">
            <a id="follow-button" className="btn btn-primary mb-3" href="#">
              <span className="follow">Follow</span>
              <span className="unfollow">Unfollow</span>
              <span className="following">Following</span>
            </a>
          </div>
        </div>
      </div>

      <div className="description col-12 col-sm-6">
        <div className="text transition" id="host_description" style={style}>
          <p className="m-0 pt-3">
            The Bulgarian Center for Women in Technology (BCWT) is an organization that inspires, motivates and supports
            girls and women to find their place in the digital world. We work to support women's leadership and increase
            women's professional participation in the digital industry, science and entrepreneurship. BCWT initiates and
            cooperates in the implementation of regional innovative projects, organizes trainings and thematic events
            and stimulates research collaboration, exchange of good practices and resource mobilization in the ICT
            sector. The founders and the members of the Center believe that the more women in Bulgaria and in the world
            become part of the development of the digital industry, the more successful and diverse our products and
            companies will be in the future.
          </p>
        </div>
        <a
          href="#"
          id="full-description"
          onClick={() => setShowDescription(!showDescription)}
          // style="display: block;"
        >
          <span className="show">show full description</span>
          <span className="hide">hide full description</span>
        </a>
      </div>

      <div className="socials col-6 py-3">
        <a className="social facebook" href="https://www.facebook.com/bcwtbg" rel="nofllow" target="_blank"></a>{" "}
      </div>

      <ul className="contacts col-6">
        <li>
          <strong>Email:</strong> <a href="mailto:hello@bcwt.bg">hello@bcwt.bg</a>
        </li>
        <li>
          <strong>Website:</strong>{" "}
          <a href="http://bcwt.bg/en/" target="_blank">
            http://bcwt.bg/en/
          </a>
        </li>{" "}
      </ul>
      <br className="clear" />
      <Map />
    </section>
  );
};
