import { Link } from "react-router-dom";
import { getDay, getDayString, getMonth, getYear } from "../../helpers/dateHelpers";
import "./UpcomingEventCard.scss";

import Placeholder from "../../../src/assets/placeholder.png";

export const UpcomingEventCard = ({ event, updateResponse }) => {
  return (
    <div className="upcoming-event-card row mb-5">
      <div className="left-container col-5 col-sm-4 col-lg-2">
        <Link to={`/events/${event.category?.toLowerCase()}/${event.name}/${event.id}`} className="mask">
          <img alt="event" src={event.image ? event.image : Placeholder} />
        </Link>
        <time>
          <strong>{`${getDay(new Date(event.startAt))}.${getMonth(new Date(event.startAt))}.${getYear(
            new Date(event.startAt)
          )}`}</strong>
          {getDayString(new Date(event.startAt))}
        </time>
      </div>

      <div className="info col-7 col-sm-8 col-lg-10">
        <Link to={`/events/${event.category?.toLowerCase()}/${event.name}/${event.id}`} className="title">
          {event.title}
        </Link>
        {event.category}
        <span>·</span>
        {event.location}
        <span>·</span>
        <br />

        <button
          type="button"
          className={`marker marker-green ${event.response === "attending" ? "" : "disabled"}`}
          disabled={event.response === "attending"}
          onClick={() => updateResponse(event.id, "attending")}
        >
          Attending
        </button>

        <button
          type="button"
          className={`marker marker-yellow ${event.response === "interested" ? "" : "disabled"}`}
          disabled={event.response === "interested"}
          onClick={() => updateResponse(event.id, "interested")}
        >
          Interested
        </button>

        <button
          type="button"
          className={`marker marker-pink ${event.response === "love-it" ? "" : "disabled"}`}
          disabled={event.response === "love-it"}
          onClick={() => updateResponse(event.id, "love-it")}
        >
          Love it but can't
        </button>

        <button
          type="button"
          className={`marker marker-blue disabled`}
          onClick={() => updateResponse(event.id, "blue")}
        >
          Not interested
        </button>
      </div>
    </div>
  );
};
