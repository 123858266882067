import "./Menu.scss";

export const Menu = ({ friendsNumber, tab, setTab }) => {
  return (
    <div className="friends-menu">
      <nav className="button-tabs nav row">
        <button
          type="button"
          className={`col-6 ${tab === "friends" ? "active" : ""} friends-tab`}
          onClick={() => setTab("friends")}
        >
          <strong>Friends</strong>
          <span>{friendsNumber} friends</span>
        </button>

        <button
          type="button"
          className={`col-6 ${tab === "suggested" ? "active" : ""} suggested-tab`}
          onClick={() => setTab("suggested")}
        >
          <strong>People you may know</strong>
          <span>who have mutual friends with you</span>
        </button>
      </nav>
    </div>
  );
};
