import "./Email.scss";

export const Email = ({ email }) => {
  return (
    <div className="checkout-email-wrapper mx-4">
      <p className="checkout-email-info">Tickets will be sent to:</p>
      <div className="email-container d-flex justify-content-between flex-wrap">
        <p className="email">{email}</p>
        <button className="change-btn">change</button>
      </div>
    </div>
  );
};
