import { useEffect, useState } from "react";

import "./TicketsSales.scss";

export const TicketsSales = ({ categories }) => {
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    if (categories?.length > 0) {
      setTickets([]);
      categories.forEach((el) => {
        const ticket = {
          _id: el._id,
          color: el.color,
          name: el.name,
          price: el.price,
          total: el.total,
          sold: el.sold,
          gross: el.sold * el.price,
          net: el.sold * el.price * 0.9033
        };
        setTickets((prev) => {
          return [...prev, ticket];
        });
      });
    }
  }, [categories]);

  return (
    <div className="col-12 col-md-8">
      <div className="table-responsive categories" style={{ paddingTop: "5px" }}>
        <table className="table">
          <thead>
            <tr>
              <th colSpan="2">Tickets type:</th>
              <th>Price:</th>
              <th>Sold tickets:</th>
              <th>Gross / Net:</th>
            </tr>
          </thead>
          <tbody>
            {tickets.length > 0 ? (
              tickets.map((el) => (
                <tr key={el._id}>
                  <td>
                    <div style={{ background: el.color }}>&nbsp;&nbsp;&nbsp;</div>
                  </td>
                  <td>
                    <div>{el.name}</div>
                  </td>
                  <td>US${el.price == 0 ? "FREE" : el.price.toFixed(2)}</td>
                  <td>
                    <div className="progress">
                      {Number(el.sold) > 0 ? (
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: `${(el.total / 100) * el.sold}` }}
                          aria-valuenow="9"
                          aria-valuemin="10"
                          aria-valuemax="23"
                        >
                          {el.sold}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                  <td>
                    US${el.gross.toFixed(2)} / US${el.net.toFixed(2)}
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
