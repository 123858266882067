import { Email } from "./Email";
import { Info } from "./Info";
import { Pricing } from "./Pricing";
import checkoutEvent from "../../data/checkoutEvent.json";
import "./Details.scss";

export const Details = ({ event, email, selectedTickets, showPublicButton, isNew }) => {
  return (
    <div className="col-12 col-md-4 col-lg-4 checkout-event-details-wrapper">
      <div className="row">
        <div className="col-12 checkout-event-details-container">
          <Info event={event} />
          {email && <Email email={email} />}
          {selectedTickets.length > 0 && <Pricing selectedTickets={selectedTickets} isNew={isNew} />}
        </div>
        {showPublicButton ? (
          <div className="col-12 text-center my-5">
            <span className="type-of-event">
              <img src="/img/event/public-icn.svg" width="14" />
              &nbsp;
              <span className="mr-2 public">Public</span>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
