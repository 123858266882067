import { useState } from "react";
import { useOuterClick } from "../../hooks";
import "./Search.scss";

export const Search = ({ setFriendId, setFriends, friends }) => {
  const [filteredFriends, setFilteredFriends] = useState([]);
  const [search, setSearch] = useState("");
  const innerRef = useOuterClick(() => {
    setFilteredFriends([]);
  });

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) {
      setSearch(value);
      setFilteredFriends(friends.filter((x) => x.names.toLowerCase().includes(value.toLowerCase())));
    } else {
      setSearch("");
      setFilteredFriends([]);
    }
  };

  const handleChoice = (friendId) => {
    setFriendId(friendId);
    setSearch("");
    setFriends(
      friends.map((x) => {
        if (x._id === friendId) {
          x.unreadCount = 0;
        }
        return x;
      })
    );
    setFilteredFriends([]);
  };

  return (
    <div className="search-friends-wrapper">
      <input
        name="search-friends"
        autoComplete="off"
        className="search-friends-input"
        placeholder="Search friends"
        value={search}
        onChange={handleChange}
        ref={innerRef}
      />
      {filteredFriends.length > 0 && (
        <ul className="search-results">
          {filteredFriends.map((x) => (
            <li key={x._id} className="user-li" onClick={() => handleChoice(x._id)}>
              <div className="user-names">{x.names}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
