import { Empty } from "./Empty";
import { Card } from "./Card";
import "./Suggested.scss";

export const Suggested = ({ suggested, setSuggested, setFriends }) => {
  return (
    <ul className="row box suggested-friends-list">
      {suggested.length ? (
        suggested.map((friend) => (
          <Card
            key={friend.id}
            friend={friend}
            setSuggested={setSuggested}
            suggested={suggested}
            setFriends={setFriends}
          />
        ))
      ) : (
        <Empty tab={"suggested"} />
      )}
    </ul>
  );
};
