import tickets from "../../data/tickets.json";
import { OrderCard } from "./OrderCard";
import "./Tickets.scss";

export const Tickets = () => {
  return (
    <div className="tickets-list row mt-35">
      {tickets.map((ticket) => (
        <OrderCard key={ticket.id} ticket={ticket} />
      ))}
    </div>
  );
};
