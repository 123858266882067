import "./Filters.scss";

export const Filters = ({ filters, setFilters, box }) => {
  const toggleFilter = (filter) => {
    const newFilters = { ...filters };
    newFilters[filter] = !filters[filter];
    setFilters(newFilters);
  };

  return (
    <section className={`calendar-filters-widget text-center ${box ? box : ""} pt-3`}>
      <h3>Calendar Filters</h3>

      <section className="body calendar-filters-container p-3">
        <button
          type="button"
          className={`btn marker-green ${filters.attending ? "" : "unchecked"}`}
          onClick={() => toggleFilter("attending")}
        >
          Attending
        </button>

        <button
          type="button"
          className={`btn marker-yellow ${filters.interested ? "" : "unchecked"}`}
          onClick={() => toggleFilter("interested")}
        >
          Interested
        </button>

        <button
          type="button"
          className={`btn marker-pink ${filters['love-it'] ? "" : "unchecked"}`}
          onClick={() => toggleFilter("love-it")}
        >
          Love it but can't
        </button>
      </section>
    </section>
  );
};
