import { ReactComponent as TicketIcon } from "../../assets/ticket.svg";
import "./Info.scss";

export const Info = ({ event }) => {
  return (
    <div className="px-3 pt-3 checkout-event-info">
      <img src={event.image} className="w-100" alt="event" />

      <h6 className="title">{event.name}</h6>
      {/* <p className="info">
        <TicketIcon className="ticket-icon" />
        {event.info}
      </p> */}
    </div>
  );
};
