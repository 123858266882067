
export const Attendees = () => {
  return (
    <div className="box">
      <h2>ATTENDEES AGREEMENT</h2>
      <h4>
        as per ARTICLE 5 of the GENERAL TERMS AND CONDITIONS published on the website&nbsp;
        <a href="https://plentix.co/">https://plentix.co</a>
      </h4>
      <p>
        This Agreement (this <strong>“Agreement”</strong>) sets forth the agreement and understanding between:
      </p>
      <p>
        <strong>Evedo Bulgaria LTD</strong>, a limited liability company incorporated and exists under the law of
        Republic of Bulgaria, registered into the Commercial Register with the Registry Agency under UIN (Unique
        Identification Number): 201598848, having headquarters and registered office at: 2А Emiliyan Stanev Street,
        block 5, entrance D, 2nd Floor, Apt 3, Sofia, the Republic of Bulgaria, which is the operator of the internet
        platform on the website <a href="https://plentix.co/">https://plentix.co/</a>, hereinafter referred as to
        “Evedo”&nbsp;
      </p>
      <p>and</p>
      <p>
        <strong>each Attendee (registered visitor and non-registered visitor)</strong> who has intention to buy online
        tickets for a current event promoted via the website <a href="https://plentix.co/">https://plentix.co/</a> where
        online tickets can be bought, by agreeing with this Agreement and with the General Terms and Conditions of that
        website, hereinafter referred as to <strong>“the Attendee”</strong>
      </p>
      <h3>WHEREAS:</h3>
      <ol>
        <li>
          On the platform there are profiles of different type of Organizers that are promoting their events, organized
          by them, and that are offering paid or free online tickets for those events.
        </li>
        <li>Some of the Attendees would like to purchase online tickets for a current event.</li>
        <li>
          Evedo is acting as an intermediary platform ensuring communication and contact between Attendees and the
          Organizers. In event of financial transaction from the Attendee to the Organizer with reference to tickets
          buying, the account of Evedo shall be used by the Attendee as “a bridge” to the bank account of the Organizer.
        </li>
        <li>
          The relations between the Attendees and its Organizers do not concern, prejudice or bind Evedo and/or the
          platform itself, i.e. Evedo is a third non-bounded party.
        </li>
        <li>
          For the purposes of this Agreement:
          <ol>
            <li>
              the website <a href="https://plentix.co/">https://plentix.co/</a> shall be referred hereinafter to as “the
              website” and/or “the platform”;
            </li>
            <li>
              the term “Organizer” shall have the meaning as per Definition said in the General Terms and Conditions.
            </li>
            <li>
              “tickets price collected” shall means the amount that the Attendees have transferred to the account of
              Evedo for purchase of the online tickets, which amount Evedo received from the Attendees for the tickets
              sold on behalf and at the expense of the Organizer;&nbsp;
              <strong>That amount does not constitute any remuneration, fee or any similar charges of Evedo.</strong>
            </li>
          </ol>
        </li>
      </ol>
      <h3>EVEDO AND THE ATTENDEES CONCLUDED THE PRESENT AGREEMENT</h3>
      <h4>under the following terms and conditions:</h4>
      <p>
        <strong>Art. 1.</strong>
      </p>
      <ol>
        <li>
          The Attendee agrees that when he/she would like to buy an online ticket for a current event organized by an
          Organizer and promoted via the platform, the Attendee shall use the account of Evedo as “a bridge” to the
          account of the Organizer, i.e. the Attendee shall transfer the ticket’s price on the account of Evedo.
        </li>
        <li>
          Evedo shall be entitled but not obliged to hold on its bank account any payment made by a Attendee to an
          Organizer for up to 5 working days after the date of the event.
          <br />
          This period is a guarantee period so that if any User, both Attendees and Organizers, breaches the rules set
          out herein in this Agreement or in the General Terms and Conditions, the payment shall be returned to the
          non-infringing party.
        </li>
      </ol>
      <p></p>
      <p>
        <strong>Art. 2.</strong> Evedo and the Attendee confirm that the amount transferred to the account of Evedo is
        related solely to the relation between the Attendee and the Organizer and this amount does not constitute any
        remuneration, fee or any similar charges of Evedo paid by the Attendees to the Latter.
        <br /> This amount represents ticket’s price collected from the Attendees when they are using the platform of
        Evedo as a device for communication from distance for the online tickets buying.
        <br /> The sale of online tickets is made by the Eventyrad on behalf of and at the expense of the Organizer.
      </p>
      <p>
        <strong>Art. 3.</strong>
      </p>
      <ol>
        <li>
          The Attendee hereby confirms that he/she utilizes the platform only for the purposes of getting in virtual
          contact with the Organzier.
        </li>
        <li>
          The Attendee declares that in the event of any claims, complaints or other similar towards or on behalf of the
          Organizer, the Attendee shall be hеld responsible and all claims, complaints or other similar should be
          communicated directly between the Attendee and the Organizer.
        </li>
        <li>
          If you are a Attendee and you wish to request a refund in connection with an event listed on the Website, you
          should contact the respective Organizer directly.
        </li>
      </ol>
      <p></p>
      <p>
        <strong>Art. 4.</strong> Evedo is an operator of a platform for communication from distance, namely the web
        platform <a href="https://plentix.co/">https://plentix.co/</a> under the meaning of art. 54, subart. 3 and 4 of
        the Customer Protection Act of Bulgaria, therefore Evedo is not a party on the relation between the Organizer
        and the Attendee.
      </p>
      <p>
        <strong>Art. 5.</strong>
      </p>
      <ol>
        <li>
          The Attendee agrees that Evedo reserves the right to modify, complement or replace the provisions of this
          Agreement, without notice, that will take effect when displayed on&nbsp;
          <a href="https://plentix.co/">https://plentix.co/</a> or by any other form of notification. For example, Evedo
          displays a message on the site (pop-up) in case of modification of this Agreement so that the Attendee can see
          such changes before continuing to use the Services.
        </li>
        <li>
          The Attendee acknowledges that he/she is invited to review the most current version of this Agreement,
          available to all and accessible at all times on all pages of the Platform in the General Terms and Conditions
          page.
        </li>
      </ol>
      <p></p>
      <p>
        <strong>Art. 6.</strong>
      </p>
      <ol>
        <li>
          All other relations are between Evedo and the Attendees are regulated by the General Terms and Conditions,
          unless they are expressly regulated differently in this Agreement.
        </li>
        <li>
          This Agreement is concluded automatically with the acceptance of the General Terms and Conditions and the
          usage of the services of the website by the Attendees
        </li>
      </ol>
      <p></p>
      <p>This Agreement shall be governed by the laws of Republic of Bulgaria.</p>
      <p>
        THIS ATTENDEE AGREEMENT CONSTITUTES A CONTRACT BETWEEN EVEDO AND ANY ATTENDEE AND REGULATES THE TERMS AND
        CONDITIONS FOR RELATION BETWEEN EVEDO AND THE ANY ATTENDEE. BY PRESSING THE BUTTON <strong>“ORDER NOW”</strong>,
        THE ATTENDEE AGREES WITH THE PRESENT AGREEMENT AND DECLARES THAT HE/SHE UNDERSTANDS THESE TERMS AND AGREES
        THEREWITH AND UNDERTAKES TO COMPLY WITH THEM.
        <br />
        THIS AGREEMENT CONSTITUTES AN ELECTRONIC STATEMENT WITHIN THE MEANING OF THE ELECTRONIC DOCUMENT AND ELECTRONIC
        SIGNATURE ACT (EDESA) OF REPUBLIC OF BULGARIA. BY RECORDING THIS ELECTRONIC STATEMENT TO THE SERVER SYSTEM OF
        EVEDO THROUGH A COMMON STANDARD FOR CONVERSION IN A TECHNICAL WAY, MAKING POSSIBLE ITS REPRODUCTION, ELECTRONIC
        STATEMENT BECOMES AN ELECTRONIC DOCUMENT WHOSE AUTHOR AND TITLEHOLDER IS THE ATTENDEE WITHIN THE MEANING OF
        EDESA.
      </p>
      &nbsp;
    </div>
  );
};
