import React from "react";

import Tabs from "./components/Tabs";
import Sidebar from "./components/Sidebar";
import initLocationAutocomplete from "../Homepage/initAutocomplete";
import NoResults from "./components/NoResults";
import ResultsPagination from "./components/ResultsPagination";

import allCategories from "../../data/categories.json";

import "./Search.scss";
import { getEvents } from "../../services/eventService";

const texts = {
  title: "Search on Plentix",
  search_placeholder: "Search...",
  search: "Search",
  location: "Location",
  anywhere: "Anywhere",
  category: "Category",
  date: "Date",
  tags: "Tags",
  online_only: "Online only",
  online: "online",
  free_only: "Free only",
  free_events: "Free events",
  start_date: "Start Date",
  no_results: "No results",
  end_date: "End Date",
  events: "Events",
  cancel: "Cancel",
  promoters_and_users: "Promoters and Users",
  filter_results: "Filter Results",
  clear_all: "Clear all",
  clear: "Clear",
  sort_by: "Sort by",
  paragraph1: "Unfortunately we couldn’t locate what you’re looking for.",
  paragraph2: "Try another search terms"
};

export class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isMobile: false,
      searchParams: {
        name: "",
        location: ""
      },
      showLoader: true,
      initialResult: {
        events: {
          results: [],
          resultsCount: 0
        },
        usersAndPromoters: {
          results: [],
          resultsCount: 0
        }
      },
      searchQuery: "",
      locationQuery: "",
      sortBy: "Date",
      sortingOptions: ["Date", "Popularity"],
      tags: [],
      filterParams: {
        category: "",
        categoryId: "",
        date: {
          startAt: "",
          endAt: "",
          selectedDate: "Any date"
        },
        tags: [],
        checkboxes: {
          online: false,
          free: false
        }
      },
      cityData: {},
      categories: "",
      activeTab: "events",
      hoveredFilter: "",
      tagSuggestions: [],
      pagesTotal: 1,
      currentPage: 1,
      itemsPerPage: 5
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.inputKeyDown = this.inputKeyDown.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.searchNameLocation = this.searchNameLocation.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
    this.searchCategories = this.searchCategories.bind(this);
    this.handleActiveTab = this.handleActiveTab.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleFiltering = this.handleFiltering.bind(this);
    this.addTagSuggestion = this.addTagSuggestion.bind(this);
    this.setPage = this.setPage.bind(this);
    this.isMobile = this.isMobile.bind(this);
  }

  componentDidMount() {
    this.isMobile();

    if (!this.state.isLoaded) {
      let loc = window.location.href;
      let url = new URLSearchParams(window.location.search);

      if (url.has("name") || url.has("location") || url.has("show")) {
        let locationStr = url.get("location");
        this.setState(
          {
            searchQuery: url.has("name") ? url.get("name") : "",
            locationQuery: locationStr ? locationStr : ""
          },
          this.searchNameLocation(url.get("name"), url.get("location"),url.get("show"))
        );
        if (locationStr) {
          document.getElementById("location-search-field").value = locationStr;
        }
      } else if (loc.includes("&categories")) {
        let cats = url.get("categories");
        let online = loc.includes("&online-event=true") ? "&online-event=true" : "";
        let allCategories = Object.keys(allCategories).map((key) => key);
        this.setState(
          {
            categories: cats,
            filterParams: {
              ...this.state.filterParams,
              categoryId: cats,
              category: cats.includes(allCategories) ? "All" : allCategories[cats].name_plural_translated,
              //old
              // category: cats.includes(allCategories) ? 'All' :  this.props.props.categories[cats].name_plural_translated,
              checkboxes: {
                ...this.state.filterParams.checkboxes,
                online: loc.includes("&online-event=true") ? true : false
              }
            }
          },
          this.searchCategories(url.get("categories"), online)
        );
      } else {
        this.setState({
          showLoader: false
        });
      }
      this.setState({
        isLoaded: true
      });
      window.addEventListener("resize", this.isMobile);
    }
    initLocationAutocomplete(
      document.querySelector("#location-search-field"),
      this,
      this.state.cityData.length
        ? this.setState({ locationQuery: this.state.cityData.city + ", " + this.state.cityData.country })
        : null
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.isMobile);
  }

  isMobile() {
    this.setState({ isMobile: window.innerWidth < 768 });
  }

  searchNameLocation(name, location,showAll) {
    const arrayQuery = [];
    if (name && name.length > 0) {
      arrayQuery.push({ name: name });
    }

    if (location && location.length > 0) {
      arrayQuery.push({ location: location });
    }

    if ((name && name.length > 0) || (location && location.length > 0) || showAll == 'all') {
      getEvents(arrayQuery)
        .then((result) => {
          this.setState({
            initialResult: {
              ...this.state.initialResult,
              events: {
                resultsCount: result?.length > 0 ? result.length : 0,
                results: result ? result : []
              }
            }
          });
        })
        .catch((err) => console.error(err));
    }
  }
  searchCategories(query, online) {
    fetch(`/search/advanced/?categories=${query}${online}`)
      .then((res) => res.json())
      .then((result) =>
        this.setState(
          {
            initialResult: result ? result : []
          },
          this.setState({ showLoader: false })
        )
      )
      .catch((error) => console.log(error));
  }

  handleFilters(e) {
    // category
    let cat = e.currentTarget.getAttribute("data-cat");
    let id = e.currentTarget.getAttribute("data-cat-id");
    let type = e.currentTarget.getAttribute("data-type");
    if (type !== "checkbox") {
      e.preventDefault();
    }
    if (type == "category") {
      this.setState({ filterParams: { ...this.state.filterParams, category: cat, categoryId: id } });
    }
    // date
    let date = e.currentTarget.getAttribute("data-date");
    if (type == "date") {
      this.setState(
        {
          filterParams: {
            ...this.state.filterParams,
            date: {
              startAt: date == "start" ? e.currentTarget.value : this.state.filterParams.date.startAt,
              endAt: date == "end" ? e.currentTarget.value : this.state.filterParams.date.endAt
            }
          }
        },
        () => {
          let selectedDate;
          if (this.state.filterParams.date.startAt && this.state.filterParams.date.endAt) {
            selectedDate =
              new Date(this.state.filterParams.date.startAt).toLocaleString("default", { month: "short" }) +
              " " +
              new Date(this.state.filterParams.date.startAt).getDate() +
              " - " +
              (new Date(this.state.filterParams.date.endAt).toLocaleString("default", { month: "short" }) !==
              new Date(this.state.filterParams.date.startAt).toLocaleString("default", { month: "short" })
                ? new Date(this.state.filterParams.date.endAt).toLocaleString("default", { month: "short" }) +
                  " " +
                  new Date(this.state.filterParams.date.endAt).getDate()
                : new Date(this.state.filterParams.date.endAt).getDate());
          } else if (this.state.filterParams.date.startAt && !this.state.filterParams.date.endAt) {
            selectedDate =
              new Date(this.state.filterParams.date.startAt).toLocaleString("default", { month: "short" }) +
              " " +
              new Date(this.state.filterParams.date.startAt).getDate();
          } else {
            selectedDate = "Any time";
          }
          this.setState({
            filterParams: {
              ...this.state.filterParams,
              date: {
                ...this.state.filterParams.date,
                selectedDate: selectedDate
              }
            }
          });
        }
      );
    }
    // checkboxes
    let checkbox = e.currentTarget.getAttribute("data-cb");
    if (type == "checkbox") {
      this.setState({
        filterParams: {
          ...this.state.filterParams,
          checkboxes: {
            online:
              checkbox == "online"
                ? !this.state.filterParams.checkboxes.online
                : this.state.filterParams.checkboxes.online,
            free:
              checkbox == "free" ? !this.state.filterParams.checkboxes.free : this.state.filterParams.checkboxes.free
          }
        }
      });
    }
  }
  clearFilter(e) {
    e.preventDefault();
    let type = e.currentTarget.getAttribute("data-type");
    if (type == "category") {
      this.setState(
        { filterParams: { ...this.state.filterParams, category: "All Events", categoryId: "" } },
        this.handleFiltering
      );
    }
    if (type == "date") {
      this.setState({ filterParams: { ...this.state.filterParams, date: { startAt: "", endAt: "" } } }, () => {
        document.querySelectorAll("input[type=date]").forEach((el) => (el.value = ""));
        this.handleFiltering();
      });
    }
    if (type == "tags") {
      this.setState({ filterParams: { ...this.state.filterParams, tags: [] }, tags: [] }, () => {
        this.handleFiltering();
        document.querySelectorAll("input[type=date]").forEach((el) => (el.value = ""));
      });
    }
    if (type == "online") {
      this.setState(
        {
          filterParams: {
            ...this.state.filterParams,
            checkboxes: { ...this.state.filterParams.checkboxes, online: false }
          }
        },
        this.handleFiltering
      );
    }
    if (type == "free") {
      this.setState(
        {
          filterParams: {
            ...this.state.filterParams,
            checkboxes: { ...this.state.filterParams.checkboxes, free: false }
          }
        },
        this.handleFiltering
      );
    }

    if (type == "all") {
      this.setState(
        {
          filterParams: {
            category: "",
            categoryId: "",
            date: {
              startAt: "",
              endAt: "",
              selectedDate: "Any date"
            },
            tags: [],
            checkboxes: {
              online: false,
              free: false
            }
          },
          tags: []
        },
        () => {
          document.querySelectorAll("input[type=date]").forEach((el) => (el.value = ""));
          this.handleFiltering();
        }
      );
    }
  }

  removeTag(e) {
    let tagName = e.currentTarget.getAttribute("data-tag");
    const newTags = [...this.state.filterParams.tags];
    newTags.splice(tagName, 1);
    let tagIndex = this.state.filterParams.tags.indexOf(tagName);
    this.setState({
      tags: this.state.tags.filter((el, id) => id !== tagIndex),
      filterParams: {
        ...this.state.filterParams,
        tags: [...this.state.filterParams.tags].filter((el, id) => id !== tagIndex)
      }
    });
  }

  inputKeyDown(e) {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      if (this.state.tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      this.setState({
        tags: [...this.state.tags, val],
        filterParams: { ...this.state.filterParams, tags: [...this.state.tags, val] }
      });
      e.target.value = "";
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
      this.setState({
        tagSuggestions: []
      });
    } else {
      if (val) {
        fetch(`/new-event-autocomplete-tag/?q=${val}`)
          .then((res) => res.json())
          .then((result) => {
            this.setState(
              {
                tagSuggestions: result.sort((a, b) => a.similarity - b.similarity).reverse()
              },
              () => {
                document.getElementById("tag-suggestions-trigger").click();
                document.getElementById("tags-input").focus();
              }
            );
          });
      }
    }
  }

  addTagSuggestion(e) {
    e.preventDefault();
    let el = e.currentTarget;
    let id = el.getAttribute("data-id");
    let value = el.getAttribute("data-value");
    let newTagIds = this.state.tags.indexOf(id) === -1 ? [...this.state.tags, id] : this.state.tags;
    let newTagNames =
      this.state.filterParams.tags.indexOf(value) === -1
        ? [...this.state.filterParams.tags, value]
        : this.state.filterParams.tags;

    this.setState(
      {
        tags: newTagIds,
        filterParams: { ...this.state.filterParams, tags: newTagNames },
        tagSuggestions: []
      },
      () => (document.getElementById("tags-input").value = "")
    );
  }
  handleSearch(e) {
    let el = e.currentTarget;
    el.id == "name-search-field"
      ? this.setState({
          searchQuery: el.value
        })
      : this.setState({
          locationQuery: el.value
        });
    window.history.replaceState({}, "", "/search");
  }

  clearSearch(e) {
    e.preventDefault();
    let el = e.currentTarget;
    el.id == "clear-name"
      ? this.setState({
          searchQuery: ""
        })
      : this.setState(
          {
            locationQuery: "",
            cityData: {}
          },
          () => (document.getElementById("location-search-field").value = "")
        );
  }

  handleSorting(e) {
    e.preventDefault();
    let val = e.currentTarget.getAttribute("data-sort");
    switch (val) {
      case "Date":
        let dateSort = this.state.initialResult.events.results.sort(
          (a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime()
        );
        this.setState({
          initialResult: {
            ...this.state.initialResult,
            events: { ...this.state.initialResult.events, results: dateSort }
          }
        });
        break;
      case "Popularity":
        let popSort = this.state.initialResult.events.results.sort((a, b) => a.rating - b.rating).reverse();
        this.setState({
          initialResult: {
            ...this.state.initialResult,
            events: { ...this.state.initialResult.events, results: popSort }
          }
        });
        break;

      default:
        break;
    }
    this.setState({
      sortBy: val
    });
  }

  handleSearchSubmit(e) {
    e.preventDefault();
    this.searchNameLocation(this.state.searchQuery, this.state.locationQuery);
  }

  handleActiveTab(e) {
    let selected = e.currentTarget.getAttribute("aria-controls");
    this.setState({
      activeTab: selected,
      currentPage: 1
    });
  }

  handleEnter(e) {
    let filter = e.currentTarget.getAttribute("data-type");
    this.setState({
      hoveredFilter: filter
    });
  }
  handleLeave() {
    this.setState({
      hoveredFilter: ""
    });
  }
  handleFiltering() {
    let f = this.state.filterParams;
    let c = this.state.cityData;
    let city = c.city ? c.city + "," : "";
    let cityName = c.city ? "&city-name=" + c.city : "";
    let location = Object.values(c).length
      ? `&location=${city}${c.country}${cityName}&country-code=${c.countryCode}&lat=${c.lat}&lng=${c.lng}`
      : "";
    let additionalQuery = `${this.state.searchQuery ? "&name=" + this.state.searchQuery : ""}${location}${
      f.categoryId ? "&categories=" + f.categoryId : ""
    }${f.date.startAt ? "&start-date=" + f.date.startAt : ""}${
      f.date.endAt !== f.date.startAt ? "&end-date=" + f.date.endAt : ""
    }${this.state.tags.length ? "&tags=" + this.state.tags.filter(Number) : ""}${
      f.checkboxes.online ? "&online-event=" + f.checkboxes.online : ""
    }${f.checkboxes.free ? "&free-event=" + f.checkboxes.free : ""}`;
    additionalQuery !== ""
      ? setTimeout(() => {
          fetch(`/search/advanced/?${additionalQuery}`)
            .then((res) => res.json())
            .then((result) =>
              this.setState(
                {
                  initialResult: result ? result : [],
                  currentPage: 1
                },
                this.setState({ showLoader: false })
              )
            )
            .catch((error) => console.log(error));
        }, 1000)
      : this.setState({
          initialResult: { events: { resultsCount: 0 }, usersAndPromoters: { resultsCount: 0 } }
        });
  }
  setPage(page) {
    // event.preventDefault();
    this.setState({ currentPage: page }, () => document.getElementsByClassName("nav-tabs")[0].scrollIntoView());
  }
  render() {
    let resultsAvailable =
      this.state.initialResult.events.resultsCount > 0 || this.state.initialResult.usersAndPromoters.resultsCount > 0;
    let activeFilters =
      (this.state.filterParams.category !== "" && this.state.filterParams.category !== "All Events") ||
      this.state.filterParams.date.startAt ||
      this.state.filterParams.checkboxes.online ||
      this.state.filterParams.checkboxes.free ||
      this.state.filterParams.tags.length
        ? true
        : false;

    let eventsResLength = this.state.initialResult.events.resultsCount
      ? this.state.initialResult.events.resultsCount
      : 0;
    let usersResLength = this.state.initialResult.usersAndPromoters.resultsCount
      ? this.state.initialResult.usersAndPromoters.resultsCount
      : 0;
    let countPages =
      this.state.activeTab == "events"
        ? Math.ceil(eventsResLength / this.state.itemsPerPage)
        : Math.ceil(usersResLength / this.state.itemsPerPage);
    return (
      <div className="search-page-content bg-white">
        <div className="bg-dark-blue py-5">
          <div className="container position-relative align-items-center flex-column d-flex justify-content-center">
            <h3 className="header-title-search h2 text-white py-5">{texts.title}</h3>
            <form
              action=""
              onSubmit={this.handleSearchSubmit}
              className="searchbox bg-white d-flex input-group m-auto md-m-0 p-3 p-md-0"
              role="group"
            >
              <div className="d-flex flex-fill align-items-center ">
                <a
                  href="#"
                  className="d-flex ms-3 search-icon-action justify-cotnent-center"
                  onClick={this.clearSearch}
                  id="clear-name"
                >
                  {this.state.searchQuery !== "" ? (
                    <img src={`/img/svg/icon-close-search-grey.svg`} alt={`Search clear`} />
                  ) : (
                    <img src={`/img/svg/icon-search.svg`} alt={`Search`} />
                  )}
                </a>
                <input
                  type="text"
                  className="form-control search-input border-0 shadow-none flex-md-fill"
                  placeholder={texts.search_placeholder}
                  aria-label={texts.search_placeholder}
                  id="name-search-field"
                  onChange={this.handleSearch}
                  value={this.state.searchQuery}
                  autoComplete="off"
                  onKeyDown={(e) => (e.key == "Enter" ? this.handleSearchSubmit : "")}
                />
              </div>
              <div className="d-flex flex-fill bl-grey align-items-center">
                <a
                  href="#"
                  className="d-flex ms-3 search-icon-action justify-cotnent-center"
                  onClick={this.clearSearch}
                  id="clear-location"
                >
                  {this.state.locationQuery !== "" ? (
                    <img src={`/img/svg/icon-close-search-grey.svg`} alt={`Location clear`} />
                  ) : (
                    <img src={`/img/svg/icon-location.svg`} alt={`Location`} />
                  )}
                </a>
                <input
                  type="text"
                  className="form-control search-input border-0 shadow-none flex-md-fill"
                  placeholder={texts.anywhere}
                  aria-label={texts.anywhere}
                  id="location-search-field"
                  onChange={this.handleSearch}
                  // value={this.state.locationQuery}
                  autoComplete={"off"}
                />
              </div>
              <button
                type="submit"
                // onClick={this.handleSearchSubmit}
                className="btn text-white bg-violet d-flex align-items-center ps-5 pe-5 justify-content-center justify-content-md-start mx-auto"
              >
                <img src={`/img/svg/icon-search-white.svg`} className="me-2" alt={`Search`} />
                {texts.search}
              </button>
            </form>
          </div>
        </div>
        {/* {this.state.showLoader ?
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    : */}
        <div className="container mt-5">
          <div className="row pt-5 flex-column-reverse flex-lg-row">
            <div className="col-lg-8">
              {resultsAvailable ? (
                <>
                  <Tabs
                    tabSelect={this.handleActiveTab}
                    activeTab={this.state.activeTab}
                    sort={this.handleSorting}
                    sortedBy={this.state.sortBy}
                    sortingOptions={this.state.sortingOptions}
                    eventsCount={this.state.initialResult.events.resultsCount}
                    usersCount={this.state.initialResult.usersAndPromoters.resultsCount}
                    events={this.state.initialResult.events.results.slice(
                      (this.state.currentPage - 1) * this.state.itemsPerPage,
                      this.state.currentPage * this.state.itemsPerPage
                    )}
                    users={this.state.initialResult.usersAndPromoters.results.slice(
                      (this.state.currentPage - 1) * this.state.itemsPerPage,
                      this.state.currentPage * this.state.itemsPerPage
                    )}
                    texts={texts}
                    itemsPerPage={this.state.itemsPerPage}
                    currentPage={this.state.currentPage}
                    setPage={this.setPage}
                  />
                  <ResultsPagination
                    currentPage={this.state.currentPage}
                    totalPages={countPages}
                    setPage={this.setPage}
                    isMobile={this.state.isMobile}
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-lg-4">
              <div
                className={`card filter-card mb-2 pt-4 pb-0 px-0 ${activeFilters === false ? "border-radius-64" : ""}`}
              >
                <a
                  data-bs-toggle="offcanvas"
                  href="#filters-sidebar"
                  role="button"
                  aria-controls="filters-sidebar"
                  className="d-flex justify-content-between px-3 pb-4"
                >
                  <h6 className="filter-title featured-text text-dark">{texts.filter_results}</h6>
                  <i className="fas fa-arrow-right fa-sm"></i>
                </a>
                <div className="filter-category-list">
                  <ul className="list-group">
                    {this.state.filterParams.category !== "" && this.state.filterParams.category !== "All Events" ? (
                      <li
                        className="list-group-item bg-transparent px-3 featured-text text-s d-flex justify-content-between align-items-center"
                        data-type="category"
                        onClick={this.state.hoveredFilter == "category" ? this.clearFilter : null}
                        onMouseEnter={this.handleEnter}
                        onMouseLeave={this.handleLeave}
                      >
                        <div>
                          {texts.category}: <span className="category-name">{this.state.filterParams.category}</span>
                        </div>
                        {this.state.hoveredFilter == "category" ? (
                          <img src="/img/svg/icon-times.svg" alt="Remove filter" />
                        ) : (
                          ""
                        )}
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.filterParams.date.startAt ? (
                      <li
                        className="list-group-item bg-transparent px-3 featured-text text-s d-flex justify-content-between align-items-center"
                        data-type="date"
                        onClick={this.state.hoveredFilter == "date" ? this.clearFilter : null}
                        onMouseEnter={this.handleEnter}
                        onMouseLeave={this.handleLeave}
                      >
                        <div>
                          {texts.date}:{" "}
                          <span className="category-name">{this.state.filterParams.date.selectedDate}</span>
                        </div>
                        {this.state.hoveredFilter == "date" ? (
                          <img src="/img/svg/icon-times.svg" alt="Remove filter" />
                        ) : (
                          ""
                        )}
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.filterParams.tags.length ? (
                      <li
                        className="list-group-item bg-transparent px-3 featured-text text-s d-flex justify-content-between align-items-center"
                        data-type="tags"
                        onClick={this.state.hoveredFilter == "tags" ? this.clearFilter : null}
                        onMouseEnter={this.handleEnter}
                        onMouseLeave={this.handleLeave}
                      >
                        <div>
                          {texts.tags}: <span className="category-name">{this.state.filterParams.tags.length}</span>
                        </div>
                        {this.state.hoveredFilter == "tags" ? (
                          <img src="/img/svg/icon-times.svg" alt="Remove filter" />
                        ) : (
                          ""
                        )}
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.filterParams.checkboxes.online ? (
                      <li
                        className="list-group-item bg-transparent px-3 featured-text text-s d-flex justify-content-between align-items-center"
                        data-type="online"
                        onClick={this.state.hoveredFilter == "online" ? this.clearFilter : null}
                        onMouseEnter={this.handleEnter}
                        onMouseLeave={this.handleLeave}
                      >
                        {texts.online_only}
                        {this.state.hoveredFilter == "online" ? (
                          <img src="/img/svg/icon-times.svg" alt="Remove filter" />
                        ) : (
                          ""
                        )}
                      </li>
                    ) : (
                      ""
                    )}
                    {this.state.filterParams.checkboxes.free ? (
                      <li
                        className="list-group-item bg-transparent px-3 featured-text text-s d-flex justify-content-between align-items-center"
                        data-type="free"
                        onClick={this.state.hoveredFilter == "free" ? this.clearFilter : null}
                        onMouseEnter={this.handleEnter}
                        onMouseLeave={this.handleLeave}
                      >
                        {texts.free_only}
                        {this.state.hoveredFilter == "free" ? (
                          <img src="/img/svg/icon-times.svg" alt="Remove filter" />
                        ) : (
                          ""
                        )}
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  {activeFilters ? (
                    <>
                      {/* <hr className="dropdown-divider mt-0"/> */}
                      <a
                        className="text-s ms-3 online-event d-flex py-4"
                        data-type="all"
                        onClick={this.clearFilter}
                        href="#"
                      >
                        {texts.clear_all}
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {!resultsAvailable ? <NoResults paragraph1={texts.paragraph1} paragraph2={texts.paragraph2} /> : ""}
          </div>
        </div>
        {/* } */}
        <div
          className={`filters-container d-flex justify-content-end transition-500 ${
            this.state.filtersOpen ? "filters-open" : ""
          }`}
        >
          <Sidebar
            showCheckboxes={
              Object.values(this.state.cityData).length ||
              this.state.searchQuery ||
              this.state.tags.length ||
              this.state.filterParams.categoryId ||
              this.state.filterParams.date.startAt
                ? true
                : false
            }
            clearFilter={this.clearFilter}
            filter={this.handleFilters}
            filterResults={this.handleFiltering}
            currentFilters={this.state.filterParams}
            inputKeyDown={this.inputKeyDown}
            removeTag={this.removeTag}
            tags={this.state.tags}
            tagSuggestions={this.state.tagSuggestions}
            addTagSuggestion={this.addTagSuggestion}
            texts={texts}
          />
        </div>
      </div>
    );
  }
}
