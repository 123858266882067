import React from "react";
import { Input } from "../Common/Form/Input.jsx";

export const SocialNetowrksInfo = ({ handleInputChange, formData }) => {
  return (
    <div className={"container"}>
      <p>Social Networks</p>
      <Input
        placeholder={"Facebook page (optional)"}
        name={"facebook"}
        value={formData?.facebook}
        onChange={handleInputChange}
      />
      <Input
        placeholder={"Twitter profile (optional)"}
        name={"twitter"}
        value={formData?.twitter}
        onChange={handleInputChange}
      />
      <Input
        placeholder={"Mixcloud (optional)"}
        name={"mixcloud"}
        value={formData?.mixcloud}
        onChange={handleInputChange}
      />
      <Input
        placeholder={"Soundcloud (optional)"}
        name={"soundcloud"}
        value={formData?.soundcloud}
        onChange={handleInputChange}
      />
      <Input
        textArea
        placeholder={"Facebook pixel (optional)"}
        name={"fbpixel"}
        value={formData?.fbpixel}
        onChange={handleInputChange}
      />
    </div>
  );
};
