import { compareDates, getMonth, getYear } from "../../helpers/dateHelpers";
import { PastEventCard } from "./PastEventCard";
import "./PastEvents.scss";

export const PastEvents = ({ events, filters, date }) => {
  const filteredEvents = events.filter(
    (event) =>
      filters[event?.response] &&
      compareDates(
        new Date(getYear(date), getMonth(date) - 1, 1),
        new Date(getYear(new Date(event.endAt)), getMonth(new Date(event.endAt)) - 1, 1)
      ) &&
      new Date(event.endAt) < new Date()
  );

  return (
    <section className="past-events row mt-5 box text-start">
      <h1 className="box-header col-12">Past Events</h1>
      {filteredEvents.length ? (
        <div className="past-events-list col-12">
          {filteredEvents.map((ev) => (
            <PastEventCard key={ev.id} event={ev} />
          ))}
        </div>
      ) : (
        <div className="no-past-events">
          <br />
          <h4 style={{ padding: "0px 20px 20px 20px" }}>There are no past events this month</h4>
        </div>
      )}
    </section>
  );
};
