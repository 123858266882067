import "./TicketCard.scss";

export const TicketCard = ({ ticket }) => {
  return (
    <div className="mb-3 d-flex justify-content-between checkout-event-pricing-ticket">
      <p className="ticket-name">
        <span>{ticket.amount} x</span> {ticket.name}
      </p>

      <p className="ticket-price">€{(ticket.price * ticket.amount).toFixed(2)}</p>
    </div>
  );
};
