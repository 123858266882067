import { useEffect, useState } from "react";
import { getOrder } from "../../services/orderService";
import "./PaymentSuccess.scss";

export const PaymentSuccess = () => {
  const [email, setEmail] = useState("");
  const [ticketAmount, setTicketAmount] = useState(0);

  const locationPathSplitted = window.location.pathname.split("/");
  const orderId = locationPathSplitted[2];

  useEffect(() => {
    getOrder(orderId)
      .then((result) => {
        setEmail(result.email);
        setTicketAmount(result.data.reduce((a, c) => a += c.quantity, 0));
      })
      .catch((err) => console.error(err));

    localStorage.removeItem("event");
    localStorage.removeItem("tickets");
  }, []);

  return (
    <div className="row my-5">
      <div class="col-12 col-md-3 col-lg-4">
        <div class="row ms-3">
          <div class="col-12 col-md-11 white-bg py-3">
            <img src="" class="w-100" />
            <meta content="http://tickets.evedo.co" itemprop="image" />
            <h5 class="" id="event_shortdescription">
              sample{" "}
            </h5>
            <div class="pt-4">
              <p class="xs mt-2 price-line">
                <img src="/img/event/icn-ticket.svg" width="20" />
                <span class="date-highlight pr-2 mr-2">Sep 28 - 12</span>{" "}
              </p>
            </div>
          </div>
          <div class="col-12 text-center my-5">
            <span class="type-of-event">
              <img src="/img/event/public-icn.svg" width="14" />
              &nbsp;
              <span class="mr-2 public">Public</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9 col-lg-8 white-bg text-center m-auto">
        <div class="row">
          <div class="col-12 my-5">
            <div class="tickets spread fast-transitions">
              <div class="ticket-preview text-center">
                <div>
                  <div>XXXXX XXX XXXXXX</div>
                  <div></div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div></div>
              </div>
            </div>

            <div class="text-ticket">
              <div class="success"></div>
              <h2>
                You've bought <span>{ticketAmount} ticket{ticketAmount > 1 ? 's' : ''}</span> successfully!
              </h2>
              <p>
                We've sent it to <strong>{email}</strong>
                <br />
                but you can also find it in My tickets page.
              </p>

              <a class="btn btn-primary" href="/profile/tickets/" target="_blank">
                My tickets
              </a>
              <a class="btn btn-primary ms-1" href="/event/tickets/print/?order=56" target="_blank">
                Print now
              </a>
            </div>
          </div>
        </div>
        <div class="row text-center mb-5">
          <div class="offset-3 col-6 offset-md-4 col-md-4">
            <h3 class="my-3">Add to calendar:</h3>
          </div>
          <div class="col-12 text-center">
            <a
              class="btn btn-secondary"
              href="https://calendar.google.com/calendar/render?action=TEMPLATE&amp;dates=20220928T070000Z/20221012T070000Z&amp;ctz=EET&amp;text=sample&amp;details=sample"
              target="_blank"
              rel="nofollow"
            >
              <i class="fab fa-google"></i>
            </a>
            <a
              class="btn btn-secondary ms-1"
              href="https://outlook.live.com/calendar/deeplink/compose?path=/calendar/action/compose&amp;rru=addevent&amp;startdt=2022-09-28T07:00:00Z&amp;enddt=2022-10-12T07:00:00Z&amp;subject=sample&amp;body=sample"
              target="_blank"
              rel="nofollow"
            >
              <i class="fab fa-windows"></i>
            </a>
            <a class="btn btn-secondary ms-1" href="/events/ics?id=33" target="_blank" rel="nofollow">
              <i class="fas fa-calendar-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
