import './TicketCategoryCard.scss';

export const TicketCategoryCard = ({ ticket, selectedTickets, setSelectedTickets }) => {
  const handleSelect = (e) => {
    let found = false;
    let newSelectedTickets;
    const value = +e.target.value;

    if (value > 0) {
      newSelectedTickets = selectedTickets.map(x => {
        if (x.information.name === ticket.name) {
          found = true;
          x.amount = +e.target.value;
        }

        return x;
      });
    } else {
      newSelectedTickets = selectedTickets.filter(x => {
        if(x.information.name === ticket.name) {
          found = true;
          return false;
        }
      
        return true;
      });
    }

    if (found) {
      setSelectedTickets(newSelectedTickets);
    } else if (value > 0) {
      setSelectedTickets([...newSelectedTickets, { amount: +e.target.value, information: ticket }]);
    }
  };

  return (
    <div className="py-3 d-flex justify-content-between checkout-ticket-category-card">
      <div className="pl-3 left-container " style={{ borderColor: ticket.color }}>
        <p className="title ms-2">{ticket.name}</p>
        <p className="description ms-2">{ticket.description}</p>
      </div>

      <div className="d-flex justify-content-between right-container">
        <div className="mr-6 price-container text-right">
          <p className="price">{ticket.price > 0 ? `€${ticket.price.toFixed(2)}` : 'FREE'}</p>
          <p className="amount-left">
            {ticket.total - ticket.sold > 50 ? '50+' : ticket.total - ticket.sold} left · Ends on {new Date(ticket.endAt).toLocaleString('default', { month: 'long', day: 'numeric' })}
          </p>
        </div>

        <select className="amount-select" default={0} onChange={handleSelect}>
          {new Array(ticket.total - ticket.sold >= 10 ? 11 : ticket.total - ticket.sold + 1).fill(0).map((x, i) => <option key={x + i} value={x + i}>{x + i}</option>)}
        </select>
      </div>
    </div>
  );
};