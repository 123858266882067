import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { postDiscount } from "../../../services/discountServices";
import { getEvent } from "../../../services/eventService";

import "./MultiUseCode.scss";

export const MultiUseCode = () => {
  const [discountFields, setDiscountFields] = useState([]);
  const [discountFieldsNumb, setDiscountFieldsNumb] = useState(1);
  const [codeType, setCodeType] = useState("one");
  const [event, setEvent] = useState(null);
  const locationParams = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentState, setCurrentState] = useState({
    numberUses: "tickets",
    ticketsNumber: 1,
    ticketsOrders: 1,
    discountType: "currency"
  });

  useEffect(() => {
    getEvent(locationParams["event_id"])
      .then((result) => {
        if (result?.categories?.length > 0) {
          addCategoriesChecked(result.categories);
        }
        setEvent(result);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (searchParams.get("discount-type") == "one") {
      setSearchParams("discount-type=many");
      setCodeType("many");
    } else {
      setSearchParams("discount-type=one");
      setCodeType("one");
    }

    if (codeType == "one" && currentState.numberUses == "unlimited") {
      const changedCurrentState = { ...currentState };
      changedCurrentState.numberUses = "tickets";
      setCurrentState(changedCurrentState);
    }
  }, [codeType]);

  const onClickAdd = (e) => {
    e.preventDefault();
    setDiscountFields((el) => [...el, discountFieldsNumb]);
    setDiscountFieldsNumb((el) => el + 1);
  };

  const onClickRemove = (e, element) => {
    e.preventDefault();
    const copyFields = [...discountFields];
    const copyIndex = copyFields.findIndex((el) => el == element);
    let copyData = currentState;

    delete copyData[`discount-${element}`];
    copyFields.splice(copyIndex, 1);
    setDiscountFields(copyFields);
    setCurrentState(copyData);
  };

  const handleChange = (e) => {
    if (e.target.name.includes("categories-")) {
      disableCheckbox(e);
    } else {
      const changedCurrentState = { ...currentState };

      changedCurrentState[`${e.target.name}`] = e.target.value;
      setCurrentState(changedCurrentState);
    }
  };

  const saveTickets = (e) => {
    e.preventDefault();
    const form = e.target;

    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add("was-validated");
    } else {
      form.classList.add("was-validated");

      let dataArray = [];

      if (codeType == "one") {
        for (let i = 0; i < Number(currentState.discountQuantity); i++) {
          dataArray.push(setData(Math.random()));
        }
      } else {
        for (const property in currentState) {
          if (property.includes("discount-")) {
            dataArray.push(setData(currentState[property]));
          }
        }
      }

      Promise.all(
        dataArray.map((element) => {
          postDiscount({}, element);
        })
      ).catch((err) => console.error(err));
    }
  };

  function setData(codeNumber) {
    let categories = [];
    let keys = Object.keys(currentState);

    keys.forEach((el) => {
      if (el.includes("categories-")) {
        const categoryId = el.split("categories-")[1];
        categories.push(categoryId);
      }
    });

    const data = {
      code: codeNumber,
      ticketCodeType: codeType,
      categories: categories,
      event: event?._id ? event._id : "",
      ticketSettings: event?.settings?._id ? event.settings._id : "",
      discountFixed: currentState.discountType === "currency" ? currentState.discountNumber : "",
      discountPercentage: currentState.discountType === "percent" ? currentState.discountNumber : "",
      ticketUsageType: currentState.discountType,
      numberUses:
        currentState.numberUses === "unlimited"
          ? 1000000
          : currentState.numberUses === "tickets"
          ? currentState.ticketsNumber
          : currentState.ticketsOrders,
      numberUsed: 0,
      startAt:
        currentState.startDate && currentState.startTime ? `${currentState.startDate} ${currentState.startTime}` : "",
      endAt: currentState.endDate && currentState.endTime ? `${currentState.endDate} ${currentState.endTime}` : "",
      active: true,
      label: ""
    };
    return data;
  }
  function disableCheckbox(e) {
    const parent = e.target.parentNode;
    const changedCurrentState = { ...currentState };
    if (e.target.checked) {
      parent.classList.remove("disable");
      changedCurrentState[e.target.name] = true;
    } else {
      parent.classList.add("disable");
      delete changedCurrentState[e.target.name];
    }

    setCurrentState(changedCurrentState);
  }

  function addCategoriesChecked(categories) {
    const changedCurrentState = { ...currentState };
    categories.forEach((el) => {
      if (el.price > 0) changedCurrentState[`categories-${el._id}`] = true;
    });
    setCurrentState(changedCurrentState);
  }
  return (
    <div className="d-flex flex-column justify-content-center ">
      <div className="d-flex m-auto">
        <button className="btn-multi-use" onClick={() => setCodeType("one")}>
          One
        </button>
        <button className="btn-multi-use ms-3" onClick={() => setCodeType("many")}>
          Many
        </button>
      </div>

      <form className="multi-use-code-form needs-validation  row g-3" onSubmit={(e) => saveTickets(e)} noValidate>
        <fieldset>
          {codeType === "one" ? (
            <div className="col-12">
              <div className="row align-items-center">
                <label htmlFor="inputEmail4" className="form-label col-3">
                  Number of codes:
                </label>
                <div className="col-4 discount-codes">
                  <input
                    className="w-100"
                    type="number"
                    id="quantity"
                    name="discountQuantity"
                    min="1"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <p className="col-3 m-0">We will generate random codes</p>
              </div>
            </div>
          ) : (
            <div className="col-12">
              <div className="row align-items-center">
                <label htmlFor="inputEmail4" className="form-label col-3">
                  Create discount codes:
                </label>
                <div className="col-4 discount-codes input-padding">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="(E.g.:50%OFF)"
                      className="form-control"
                      id="inputEmail4"
                      name="discount-0"
                      onChange={(e) => handleChange(e)}
                    />
                    <button className="add" onClick={onClickAdd}></button>
                  </div>
                  {discountFields.length > 0
                    ? discountFields.map((el, index) => (
                        <div key={`discount-${el}`} className="input-group">
                          <input
                            type="text"
                            placeholder="(E.g.:50%OFF)"
                            name={`discount-${el}`}
                            className="form-control"
                            id="inputEmail4"
                            onChange={(e) => handleChange(e)}
                          />
                          <button className="remove" onClick={(e) => onClickRemove(e, el)}></button>
                        </div>
                      ))
                    : ""}
                </div>
                <p className="col-3 m-0">Come up with something easily memorable.</p>
              </div>
            </div>
          )}

          <div className="row  align-items-center mt-3">
            <label htmlFor="inputEmail4" className="form-label col-3 m-0">
              Apply discount of:
            </label>
            <div className="col-4 input-padding">
              <div className="input-group">
                <div className=" col-6 col-md-7">
                  <input
                    type="number"
                    className="form-control"
                    aria-label="Text input with dropdown button"
                    name="discountNumber"
                    onChange={(e) => handleChange(e)}
                    required={true}
                  />
                </div>
                <select
                  name="discountType"
                  className="form-select time col-1"
                  aria-label="Default select example"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="currency">US$</option>
                  <option value="percent">%</option>
                </select>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3">
            <div className="row">
              <label className="form-check-label col-3" htmlFor="flexCheckDefault">
                Apply to categories:
              </label>
              <div className="col-6">
                {event?.categories?.length > 0 ? (
                  event.categories.map((element) => (
                    <div key={element._id} className={`d-flex ${element.price == 0 ? "disable" : ""} `}>
                      <input
                        className={`form-check-input ${element.price == 0 ? "disable-cursor" : ""}`}
                        type="checkbox"
                        disabled={element.price == 0 ? true : ""}
                        defaultChecked={`${element.price == 0 ? "" : true}`}
                        id="flexCheckDefault"
                        name={`categories-${element._id}`}
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="event-time ms-1 "> </span>
                      <span className="w-50">{element.name} </span>
                      <p className="d-inline ms-5 ">${element.price}</p>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex mt-3">
            <label className=" col-3">Apply during period:</label>
            <div className="d-flex ">
              <div className="input-group period">
                <input
                  className="date"
                  name="startDate"
                  placeholder="Start date"
                  type="date"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                />
                <input
                  className="time"
                  name="startTime"
                  placeholder="Time"
                  type="time"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className="divider">-</div>

              <div className="input-group period">
                <input
                  className="date"
                  name="endDate"
                  placeholder="End date"
                  type="date"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                />
                <input
                  className="time"
                  name="endTime"
                  placeholder="Time"
                  type="time"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="details ms-5">Leave fields blank if you don't need time limitations.</div>
          </div>

          <div className="d-flex mt-3">
            <label className=" col-3">Number of uses:</label>
            <div className="d-flex flex-column col-6">
              {codeType !== "one" ? (
                <label>
                  <input
                    className="custom  d-inline"
                    name="numberUses"
                    type="radio"
                    value="unlimited"
                    onChange={(e) => handleChange(e)}
                  />
                  <span></span> Unlimited uses
                </label>
              ) : (
                ""
              )}

              <label>
                <input
                  className="custom d-inline"
                  name="numberUses"
                  type="radio"
                  value="tickets"
                  defaultChecked
                  onChange={(e) => handleChange(e)}
                />
                <span></span>
                Up to{" "}
                <input
                  className="rounded"
                  maxLength="3"
                  type="text"
                  defaultValue="1"
                  name="ticketsNumber"
                  onChange={(e) => handleChange(e)}
                />{" "}
                ticket(s)
              </label>

              <label>
                <input
                  className="custom  d-inline"
                  name="numberUses"
                  type="radio"
                  value="orders"
                  onChange={(e) => handleChange(e)}
                />
                <span></span>
                Up to{" "}
                <input
                  className="rounded"
                  maxLength="3"
                  type="text"
                  defaultValue="1"
                  name="ticketsOrders"
                  onChange={(e) => handleChange(e)}
                />{" "}
                'order(s)
              </label>
            </div>
          </div>
          <div className="d-flex m-4 button-container m-auto mt-5">
            <button className="btn-create me-3" type="submit">
              Create
            </button>
            <button className="btn-cancel">Cancel</button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};
