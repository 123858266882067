import "./DeleteProfileButton.scss";

export const DeleteProfileButton = () => {
  return (
    <div className="delete-profile-btn buttons col-12">
      <button className="alert alert-danger row">
        <i className="fas fa-exclamation-triangle"></i>
        Delete your profile on Plentix
      </button>
    </div>
  );
};
