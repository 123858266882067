import "./Success.scss";

export const Success = ({ newPage }) => {
  return (
    <div className="mask steps mb-5">
      <div className="wrapper">
        <div className="slide justify-content-center finish d-flex flex-column align-items-center h-100">
          <img className="mx-auto my-5" src="/img/svg/all-setup-image.svg" alt="Welcome to Plentix" />
          <h2 className="h3">
            <span className="fw-700 text-dark-blue">Great job! All set up.</span>
          </h2>
          <div className="text-m text-dark-grey mb-5">You are all setup! Go give Plentix a try.</div>
          <button
            className="nav-button-primary text-white d-flex align-items-center py-3 px-5 mt-5"
            onClick={() => {
              window.location.href = `${window.location.origin}/`;
            }}
          >
            Continue <img className="ms-3" src="/img/svg/icon-arrow-right-long.svg" alt="Continue" />
          </button>
        </div>
      </div>
    </div>
  );
};
