import { useState } from "react";
import { TicketsList } from "./TicketsList";
import { Button } from "./Button";
import { Input } from "./Input";
import "./TicketsForm.scss";
import { completeOrder, postOrder } from "../../services/orderService";

export const TicketsForm = ({ event, selectedTickets, setSelectedTickets }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleClick = async () => {
    const orderData = [];

    selectedTickets.map((ticket) => {
      orderData.push({ 
        ticketCategoryId: ticket.information._id,
        quantity: ticket.amount,
        price: ticket.information.price,
        currencyId: event.settings.currency,
        userId: localStorage.getItem("userId"),
        email: email,
        name: name,
        discount: 0,
        seating: "",
      });
    });

    const order = {
      data: orderData,
      settings: selectedTickets[0].information.settings,
      event: event._id,
      price: selectedTickets.reduce((a, c) => (a += c.information.price * c.amount), 0),
      user: localStorage.getItem("userId"),
      email: email,
      currency: event.settings.currency
    }

    const orderInfo = await postOrder(order);
    const ticketsWithRequiredInfo = selectedTickets.filter((ticket) => 'required' in ticket.information);

    localStorage.setItem("event", JSON.stringify(event));
    localStorage.setItem("tickets", JSON.stringify(selectedTickets));

    if (ticketsWithRequiredInfo.length) {
      window.location.href = `${window.location.origin}/orders/${orderInfo._id}/required`;
    } else {
      if (order.price > 0) {
        window.location.href = `${window.location.origin}/orders/${orderInfo._id}/pay`;
      } else {
        await completeOrder(orderInfo._id, {});
        window.location.href = `${window.location.origin}/orders/${orderInfo._id}/finish`;
      }
    }
  };

  return (
    <div className="col-lg-4 col-md-4 checkout-tickets-form">
      <h3 className="form-heading">Ticket Options</h3>
      <TicketsList selectedTickets={selectedTickets} setSelectedTickets={setSelectedTickets} />
      <Input className="mb-2" type="text" labelText="Your name:" name="name" value={name} onChange={(e) => setName(e.target.value)} />
      <Input className="mb-2" type="text" labelText="Receive tickets @ email:" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <Button text={"Order now"} onClick={handleClick} />
    </div>
    //
    //   <TicketsList selectedTickets={selectedTickets} setSelectedTickets={setSelectedTickets} />
    //   <Button text={'Order now'} />
    // </div>
  );
};
