import { get, post, patch, deleteReq } from "../helpers/fetcher";

export const getCurrencies = async () => {
  let endpoint = `/currencies`;

  return get(endpoint);
};

export const getCurrency = async (id) => {
  const endpoint = `/currencies/${id}`;

  return get(endpoint);
};
