import { get, post, patch, deleteReq } from "../helpers/fetcher";

// export const getEvents = async () =>{
//     const endpoint = `/events`;

//     return get(endpoint);
// }

export const postCategories = async (user, body) => {
  const endpoint = `/categories`;
  // const header = {user};
  const header = { "Content-Type": "application/json" };

  return post(endpoint, header, body);
};

export const patchCategories = async (user,id, body) => {
  const endpoint = `/categories/${id}`;
  // const header = {user};
  const header = { "Content-Type": "application/json" };

  return patch(endpoint, header, body);
};
