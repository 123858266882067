import React from "react";
import MobileNav from "./components/mobile-nav";
import DesktopNav from "./components/desktop-nav";

import allCategories from "../../../data/categories.json";

import "./navigation.scss";
const texts = {
  admin: {
    title: "Settings",
    profile: "Profile",
    my_events: "My events",
    my_promoters: "My promoters",
    create_event: "Create event",
    create_promoter: "Create promoter",
    messages: "Messages",
    my_tickets: "My tickets",
    my_calendar: "My events",
    friends: "Friends",
    logout: "Log out as"
  },
  searchPlaceholder: "Search for events",
  searchTargetNotFound: "Don’t see what you’re looking for?",
  searchSeeMore: "See more results",
  events: "Events",
  promoters: "Promoters",
  discover: "Discover",
  forOrganizers: "For organizers",
  learnAboutPlentix: "Learn about Plentix",
  whyPlentix: "Why Plentix?",
  howItWorks: "How it works?",
  eventPartnersLookFor: "Looking for partners to build your event?",
  browseOnB2B: "Browse on Evedo B2B",
  hey: "Hey",
  education: "Education",
  music: "Music & Entertainment",
  hobbies: "Hobbies",
  other: "Other",
  features: "Features",
  pricing: "Pricing",
  signIn: "Sign in",
  signUp: "Sign up",
  youHave: "You have",
  notifications: "notifications",
  notification: "notification",
  new: "new",
  hey_stranger: "Hey, Stranger!"
};
export class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      discoverCategories: allCategories,
      nearbyEventCards: [],
      isLoaded: false,
      selectedLanguage: "en_UK",
      messagesLength: 0,
      openSearch: false,
      isMobile: false
    };
    this.languageSwitch = this.languageSwitch.bind(this);
    this.toggleDynamicSearch = this.toggleDynamicSearch.bind(this);
    this.isMobile = this.isMobile.bind(this);
  }
  componentDidMount() {
    this.isMobile();
    if (!this.state.isLoaded) {
      this.setState({
        selectedLanguage: this.props.selectedLanguage ? this.props.selectedLanguage : "en_UK",
        isLoaded: true
      });
      if (this.props.isLogged) {
        fetch("/messages/check/")
          .then((res) => res.json())
          .then((result) => this.setState({ messagesLength: Object.keys(result.messages).length }))
          .catch((error) => console.log(error));
      }
      window.addEventListener("resize", this.isMobile);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.isMobile);
  }
  isMobile() {
    this.setState({ isMobile: window.innerWidth < 768 });
  }
  languageSwitch(e) {
    e.preventDefault();
    let el = e.currentTarget;
    fetch(`/change-language/?language=${el.getAttribute("data-lang")}`)
      .then((res) => res.json())
      .then((res) => {
        this.setState(
          {
            selectedLanguage: el.getAttribute("data-lang")
          },
          () => (window.location = window.location.href)
        );
      });
  }
  toggleDynamicSearch() {
    let oldState = this.state.openSearch;
    const int = setInterval(() => {
      if (this.state.openSearch && document.getElementById("nav-autocomplete-search")) {
        document.getElementById("nav-autocomplete-search").focus();
        clearInterval(int);
      }
      !this.state.openSearch ? clearInterval(int) : console.log("not-cleared ", this.state.openSearch);
    }, 100);
    this.setState(
      {
        openSearch: !this.state.openSearch
      },
      () => int
    );
  }
  render() {
    return (
      <nav>
        {!this.state.isMobile ? (
          <DesktopNav
            myHosts={this.props.hosts}
            myEvents={this.props.events}
            messages={this.state.messagesLength}
            texts={texts}
            isLogged={this.props.isLogged}
            languages={this.props.languages}
            selectedLanguage={this.state.selectedLanguage}
            languageSwitch={this.languageSwitch}
            name={this.props.userFirstName}
            image={this.props.userImage}
            notifications={this.props.notifications ? this.props.notifications : 0}
            categories={Object.values(this.state.discoverCategories)}
            toggleDynamicSearch={this.toggleDynamicSearch}
            openSearch={this.state.openSearch}
            hostsCount={this.props.hostsCount}
          />
        ) : (
          <MobileNav
            myHosts={this.props.hosts}
            myEvents={this.props.events}
            messages={this.state.messagesLength}
            texts={texts}
            // texts={this.props.texts}
            isLogged={this.props.isLogged}
            languages={this.props.languages}
            selectedLanguage={this.state.selectedLanguage}
            languageSwitch={this.languageSwitch}
            name={this.props.userFirstName}
            image={this.props.userImage}
            notifications={this.props.notifications ? this.props.notifications : 0}
            categories={Object.values(this.state.discoverCategories)}
            toggleDynamicSearch={this.toggleDynamicSearch}
            openSearch={this.state.openSearch}
            hostsCount={this.props.hostsCount}
          />
        )}
      </nav>
    );
  }
}
